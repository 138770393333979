export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
background: whitesmoke;
position: relative;
border-radius: 7px;

#Admin_Calendar_List {
z-index: 1;
width: 100%;
height: 100%;
padding: 2rem;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: stretch;
align-content: stretch;

& > ._Main {
width: 50%;
height: 100%;
padding-right: 2rem;
background: aliceblue;
overflow-y: scroll;

ul {
width: 100%;
display: flex;
flex-direction: column;

  li {
  width: 100%;
  height: auto;
  padding: 2rem;
  border: 2px solid #555;
  background: rgba(250, 250, 250, 0.7);
  border-radius: 7px;
  margin: 1rem 0;
  color: #777;
  display: flex;
  align-items: center;
  justify-content: left;

    &.Active {
    color: #fff;
    background: #007bff;
    border-color: #007bff;
    }

    &:hover {
    cursor: pointer;
    // border: 2px solid #999;
    border: 2px solid aquamarine;
    }

  }

}

}

& > ._Sidebar {
width: 50%;
}

}

`;

return Style;

}

