//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_App_Community.js';

//----------------------------------------------------------------------------------------
  export const App_Community = ({ Props }) => {
//----------------------------------------------------------------------------------------

  let URI = `${Public.ENV.URL}/Apps/Core/Landing/Intro`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Data
//----------------------------------------------------------------------------------------

  // State
  const [activeIndex, setActiveIndex] = useState(-1);
  const [lastActiveIndex, setLastActiveIndex] = useState(null);
  
  // URIs
  let URI_Media = `${Public.ENV.URL}/Apps/Community/Media`;
  
  // Data
  const boxes = [
    {ID: "YouTube", URL: "https://www.youtube.com/@initori" }, 
    {ID: "Instagram", URL: "https://www.instagram.com/initori/" }, 
    {ID: "X", URL: "https://twitter.com/Initori_" }
  ];

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Controller
//----------------------------------------------------------------------------------------

  useEffect(() => {
    const gamepad = Public.Input.Gamepad;
    gamepad.setHandlers({
      onRight: () => {
        Public.Scripts.Audio.Sound({ID: "Move"});
        setLastActiveIndex(null);        
        setActiveIndex(prevIndex => Math.min(prevIndex + 1, boxes.length - 1)) 
      },
      onLeft: () => {
        Public.Scripts.Audio.Sound({ID: "Move"});
        setLastActiveIndex(null);
        setActiveIndex(prevIndex => Math.max(prevIndex - 1, 0))
      }
    });
    return () => { gamepad.cleanup(); };
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Functions
//----------------------------------------------------------------------------------------

  const Select = (index) => {
    if(lastActiveIndex !== null) {
      setLastActiveIndex(null);
      return;
    }
    setActiveIndex(index);
  };

  const Unselect = () => {
    setLastActiveIndex(activeIndex);
    setActiveIndex(null);
  };

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
return (html`
<${Styled}>
<div className="Section_Community">

  <div className="Boxes">
    
  ${boxes.map((Item, index) => {

    const isActive = index === activeIndex;
    const isLastActive = index === lastActiveIndex && activeIndex !== lastActiveIndex;
    let boxClasses = `Box ${isActive ? 'Active' : ''} ${isLastActive ? 'Inactive' : ''}`;
    boxClasses += ` ${Item.ID}`;

    return html`
    <div className=${boxClasses}
      onMouseEnter=${() => Select(index)}
      onMouseLeave=${Unselect}
      key=${`Item.IDs_Dashboard_${Item.ID}`}>
      <a href="${Item.URL}" target="_blank">

        <h2>${Item.ID}</h2>

        <div className="movil">
          <div className="pantalla">
            <!--<iframe src="https://www.youtube.com/@initori"/>-->
            <img src=${`${URI_Media}/${Item.ID}.png`} alt=${Item.ID} />
          </div>
          <div className="botonInicio"></div>
        </div>

      </a>
    </div>
    `;
  })}

  </div>

</div>
<//>
`);

};