  import { Public_Routes_Admin } from "./Admin/Public_Routes_Admin.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class Public_Routes { constructor (Public) {
//----------------------------------------------------------------------------------------

  this.Public = Public;

  this.Admin = new Public_Routes_Admin(Public);

}
//----------------------------------------------------------------------------------------
}