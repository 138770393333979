export const Styles = (styled, Proto, Props) => {
return styled.div`

width: 100%;
height: 100%;
padding: 0;
margin: 0;
background: #eee;
display: flex;

#App_Trailer {
width: 100%;
height: 100%;
background: aliceblue;
z-index: 100;
display: flex;
justify-content: center;
align-items: center;
}


/* Video */

._Video {
width: 100%;
height: 100%;
top: 0; left: 0;
position: absolute;
overflow: hidden;
z-index: 100;
border-radius: 7px;
display: flex;
justify-content: center;

cursor: pointer;
}

._Video_Play {
font-size: 3rem;
top: calc(50% - 2.6rem);
left: calc(50% - 2.6rem);
z-index: 110;
position: absolute;
color: #eee;
text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
pointer-events: none;
cursor: pointer;

  &:hover {
  color: #fff;
  }

}

video {
// width: 100%;
height: 100%;
position: absolute;
border-radius: 7px;
animation: start 0.5s;
// pointer-events: none;
}

@keyframes start {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

`;

}