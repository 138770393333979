/*██████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█████████████░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░██░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░██████████████░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░█░░░█░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░██░░░░░██░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░████████████░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
*/

  import { TREE } from "../TREE.js";
  import { SIG } from "./SIG/SIG.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_SIG extends TREE { constructor (MAIA) { super(MAIA);
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  
  this.Signals = [];
  this.Sigs = [];

  this.Count = 0;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  this.Convert();

  this.Count++;
  if(this.Count >= 180) { 
    this.Count = 0;
    // console.log(this.Sigs);
  }

  if(this.Sigs.length <= 0) { return; }
  
  this.Clean();

  this.Sigs.map((Sig) => { Sig.Run(); });


}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Clean () {
//----------------------------------------------------------------------------------------

  this.Sigs.map((Sig) => { 

    // Time
    if(Sig.Time === "Infinity") { return; }
    else {

      // Runs
      if(Sig.Runs <= 0) { Sig.Active = false; } else { Sig.Runs -= 1; }

      // Count
      if(Sig.Count >= Sig.Time) { Sig.Void = true; } else { Sig.Count += 1;}

    }

  });

  // Remove Voids
  let Sigs_Void = this.Sigs.filter((Sig) => { return Sig.Void; });
  
  // Remove the reference in the Ent~Target
  Sigs_Void.map((Sig_Void) => {
    let Target = Sig_Void.Target;
    Target.Sigs = Target.Sigs.filter(Sig => Sig.ID !== Sig_Void.ID);
  });

  // Remove Sigs where Void = true
  this.Sigs = this.Sigs.filter((Sig) => { return !Sig.Void; });
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Create (Signal = {ID, Key, Value, Target, Sender}) {
//----------------------------------------------------------------------------------------

  // Check Sigs repeated
  let Sig_Repeated = this.Sigs.find((s) => s.Target.ID === Signal.Target.ID && s.Key === Signal.Key)
  if(Sig_Repeated?.Type === 'Once') { return; } 

  // Check Signals repeated
  let index = this.Signals.findIndex(s => s.Target.ID === Signal.Target.ID && s.Key === Signal.Key);
  if (index !== -1) { this.Signals.splice(index, 1); }

  // Push the new Signal into the pool
  this.Signals.push(Signal);
  return true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Convert () {
//----------------------------------------------------------------------------------------
// Convert the Signals into Sigs.
//----------------------------------------------------------------------------------------

  if(this.Signals.length <= 0) return;

  this.Signals.map((Signal, i) => {

    if(Signal.Wait >= 0) { Signal.Wait -= 1; return; }
        
    let Ent = Signal.Target;
    if(!Ent) { console.error("Ent Target not defined in Signal", Signal, Ent); return; }

    let Sig = new SIG(this.MAIA, Signal);

    // Avoid repeated Sigs
    if (!Ent.Sigs.some(sig => sig.ID === Sig.ID))  { Ent.Sigs.push(Sig); }
    if (!this.Sigs.some(sig => sig.ID === Sig.ID)) { this.Sigs.push(Sig); }
    
    // Remove
    this.Signals.splice(i, 1);
    
  });

  // this.Signals = [];

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Find (Sig_ID, Callback) {
//----------------------------------------------------------------------------------------

  return this.Sigs.find((Sig) => Sig.ID === Sig_ID);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Remove (Sig) {
//----------------------------------------------------------------------------------------

  if(!Sig) { return; }

  this.Sigs = this.Sigs.filter((sig) => Sig.ID !== sig.ID)
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Get_Data () {
//----------------------------------------------------------------------------------------

  let Data_Sigs = [];

  // Make a copy of all Ents Data
  this.Sigs.map((Sigs) => {
    Data_Sigs.push(Sigs.Get_Data());
  });

  // Remove the "Special Sigs"
  // Ents_Data = Ents_Data.filter((ent) => ent.ID !== 'Hover');
  // Ents_Data = Ents_Data.filter((ent) => ent.ID !== 'Seed');
      
  return Data_Sigs;

}
//----------------------------------------------------------------------------------------
}