export const Textarea = ({ text, onSave }) => {

  const html = Public.Libs.html;
  const { useEffect, useRef, useState, useLayoutEffect } = Public.Libs.React;

  const [Text, SetText] = useState(text);
  
  const handleSave = () => {
    onSave(Text);
    SetText("");
  };

  return html`
    <div>
      <textarea value=${Text} onInput=${e => SetText(e.target.value)}></textarea>
      <button onClick=${handleSave}>Guardar</button>
    </div>
  `;

}