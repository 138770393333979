export class CORE_UTILS_Cookies { constructor () {



}
//------------------------------------------------------------------------------------
  getCookie(name) {
//------------------------------------------------------------------------------------
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

//------------------------------------------------------------------------------------
  setCookie(name, value, options = {}) {
//------------------------------------------------------------------------------------

  options = {
    path: '/',
    // add other defaults here if necessary
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

// Example of use:
// setCookie('user', 'John', {secure: true, 'max-age': 3600});

//------------------------------------------------------------------------------------
  deleteCookie(name) {
//------------------------------------------------------------------------------------

  setCookie(name, "", {
    'max-age': -1
  });

}


}