export class CORE_RESOURCES_Thumbnail { constructor (CORE_RESOURCES) {

  this.MAIA = CORE_RESOURCES.MAIA;

}
//----------------------------------------------------------------------------------------
  Canvas_To_Blob (canvas) {
//----------------------------------------------------------------------------------------
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) { resolve(blob); } 
      else { reject(new Error('Canvas to Blob failed')); }
    }, 'image/png');
  });
}
//----------------------------------------------------------------------------------------
  Resize_Canvas(canvas, width) {
//----------------------------------------------------------------------------------------

  // Crear nuevo canvas para cambiar el tamaño
  let outputCanvas = document.createElement("canvas");
  let ctx = outputCanvas.getContext("2d");

  // Definir el tamaño deseado
  let scaleFactor = width / canvas.width;
  let newHeight = canvas.height * scaleFactor;

  outputCanvas.width = width;
  outputCanvas.height = newHeight;

  // Dibujar la imagen escalada en el nuevo canvas
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, width, newHeight);

  return outputCanvas;

}
//------------------------------------------------------------------------------------
  async Round_Canvas(canvas, percentage) {
//------------------------------------------------------------------------------------

  const width = canvas.width;
  const height = canvas.height;
  const size = Math.max(width, height);
  const percentageValue = parseInt(percentage) / 100;

  // Crear un nuevo canvas cuadrado
  const circularCanvas = document.createElement('canvas');
  circularCanvas.width = size;
  circularCanvas.height = size;
  const circularCtx = circularCanvas.getContext('2d');

  // Crear una máscara redondeada
  const radius = size * percentageValue;
  circularCtx.beginPath();
  circularCtx.moveTo(size / 2, 0);
  circularCtx.arcTo(size, 0, size, size / 2, radius);
  circularCtx.arcTo(size, size, size / 2, size, radius);
  circularCtx.arcTo(0, size, 0, size / 2, radius);
  circularCtx.arcTo(0, 0, size / 2, 0, radius);
  circularCtx.closePath();
  circularCtx.clip();

  // Calcular el factor de escalado para que la imagen llene todo el cuadrado
  const scale = size / Math.min(width, height);
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;

  // Calcular el offset para centrar la imagen
  const offsetX = (size - scaledWidth) / 2;
  const offsetY = (size - scaledHeight) / 2;

  // Dibujar el contenido del canvas original, escalado y centrado en el nuevo canvas
  circularCtx.drawImage(canvas, offsetX, offsetY, scaledWidth, scaledHeight);

  return circularCanvas;

}
//----------------------------------------------------------------------------------------


}