//----------------------------------------------------------------------------------------
  import { Style } from './Style/Style_Admin_Docs.js';
//----------------------------------------------------------------------------------------

export const Admin_Docs = ({ Props }) => {

  Props.URI = `${Public.ENV.URL}/Apps/Core/Main/Admin/Parts/Inc/Docs`;

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const location = RouterDOM.useLocation();

  const thePanel = useRef(null);

//████████████████████████████████████████████████████████████████████████████████████

  useEffect(() => { if(!thePanel.current) { return; }
    Toggle_Docs();
  }, [thePanel]);

  const Toggle_Docs = () => {
    const iframe = document.createElement('iframe');
    // iframe.src = `${Public.ENV.URL}/Apps/Board/Core/Main/Admin/Parts/Docs/Docs/build/index.html`;
    iframe.src = `${Public.ENV.URL}/Apps/Board/Main/Admin/Parts/Inc/Docs/build/index.html`;
    thePanel.current.appendChild(iframe);
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>

<div className="Admin_Docs" ref=${thePanel}>

</div>

<//>
`);

};