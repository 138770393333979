//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class Public_Input_Gamepad { constructor (Public) {
//----------------------------------------------------------------------------------------
    
  this.Public = Public;

  this.State = {
    A: { Active: false, release: true },
    B: { Active: false, release: true },
    X: { Active: false, release: true },
    Y: { Active: false, release: true },
    L1: { Active: false, release: true },
    R1: { Active: false, release: true },
    Select: { Active: false, release: true },
    Start: { Active: false, release: true },
    Axe_X: { Active: false, release: true, value: 0 },
    Axe_Y: { Active: false, release: true, value: 0 },
    Right: { Active: false, release: true },
    Left: { Active: false, release: true },
    Down: { Active: false, release: true },
    Up: { Active: false, release: true },
  };

  this.buttonMappings = {
    0: 'A', 1: 'B', 2: 'X', 3: 'Y',
    4: 'L1', 5: 'R1',
    8: 'Select', 9: 'Start',
    // Considerar la inclusión de otros botones según sea necesario
  };

  this.axisNeutral = true;
  this.listenToGamepad();

}
//----------------------------------------------------------------------------------------
  listenToGamepad() {
//----------------------------------------------------------------------------------------
  window.addEventListener('gamepadconnected', this.onGamepadConnected);
  window.addEventListener('gamepaddisconnected', this.onGamepadDisconnected);
}
//----------------------------------------------------------------------------------------
  onGamepadConnected = () => {
//----------------------------------------------------------------------------------------
  this.pollGamepad();
  return;
  Public.Scripts.Notify.Send({
    Title: "Gamepad", Description: `Connected`, Image: "Gamepad", Time: 3000
  });
}
//----------------------------------------------------------------------------------------
  onGamepadDisconnected = () => {
//----------------------------------------------------------------------------------------
  cancelAnimationFrame(this.requestRef);
  return;
  Public.Scripts.Notify.Send({
    Title: "Gamepad", Description: `Disconnected`, Image: "Gamepad", Time: 3000
  });
}
//----------------------------------------------------------------------------------------
  pollGamepad = () => {
//----------------------------------------------------------------------------------------
  this.requestRef = requestAnimationFrame(this.pollGamepad);
  const gamepad = navigator.getGamepads()[0];
  if (gamepad) {
    this.handleGamepadInput(gamepad);
  }
}
//----------------------------------------------------------------------------------------
  setHandlers(handlers) {
//----------------------------------------------------------------------------------------
  this.handlers = { ...this.handlers, ...handlers };
}
//----------------------------------------------------------------------------------------
  handleGamepadInput = (gamepad) => {
//----------------------------------------------------------------------------------------
  const axeXValue = gamepad.axes[0]; // Supongamos que este es el eje X del joystick

  if(!this.handlers) { return; }
  
  // Verificar el estado del eje X para determinar la dirección
  if (axeXValue > 0.5 && this.axisNeutral) { // Movimiento hacia la derecha
    if (this.handlers.onRight) {
      this.handlers.onRight();
    }
    this.axisNeutral = false;
  } else if (axeXValue < -0.5 && this.axisNeutral) { // Movimiento hacia la izquierda
    if (this.handlers.onLeft) {
      this.handlers.onLeft();
    }
    this.axisNeutral = false;
  } else if (axeXValue >= -0.5 && axeXValue <= 0.5) {
    this.axisNeutral = true; // Restablecer el estado neutral
  }

  // Asegúrate de procesar el resto de los botones y ejes aquí...
};
//----------------------------------------------------------------------------------------
  cleanup = () => {
//----------------------------------------------------------------------------------------
  window.removeEventListener('gamepadconnected', this.onGamepadConnected);
  window.removeEventListener('gamepaddisconnected', this.onGamepadDisconnected);
  cancelAnimationFrame(this.requestRef);
}
//----------------------------------------------------------------------------------------
}