import { Items_Merch_List } from "./List/Items_Merch_List.js";
import { Items_Merch_Detail } from "./Detail/Items_Merch_Detail.js";
import { Style } from './Style/Style_Items_Merch.js';

export const Items_Merch = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const RouterDOM = Public.Libs.RouterDOM;
  const Styled = Public.Styles.Styled(Style, {});

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>

  <${RouterDOM.Route} exact path='/items/merch'>
    <${Items_Merch_List} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/items/merch/:id'>
    <${Items_Merch_Detail} Props=${Props} />
  <//>

<//>
`);
}