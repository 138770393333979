
  import { Public_Input_Gamepad } from "./Gamepad/Public_Input_Gamepad.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class Public_Input { constructor (Public) {
//----------------------------------------------------------------------------------------

  this.Public = Public;

  this.Gamepad = new Public_Input_Gamepad(Public);

  this.Keyboard = {
    Left:   { Active: false, release: true }, // ⬅️  [37]
    Up:     { Active: false, release: true }, // ⬆️  [38]
    Right:  { Active: false, release: true }, // ➡️  [39]
    Down:   { Active: false, release: true }, // ⬇️  [40]
    Space:  { Active: false, release: true }, // [] [49]
    Enter:  { Active: false, release: true }, // [] [13]
    Shift:  { Active: false, release: true }, // [] [16] 
    Ctrl:   { Active: false, release: true }, // [] [17] 
    Supr:   { Active: false, release: true }, // [] [46] 
    Z:      { Active: false, release: true },  // [] [90]
    X:      { Active: false, release: true },  // [] [88]
    Q:      { Active: false, release: true },  // [] [81]
    W:      { Active: false, release: true },  // [] [87]
  },

  this.Cursor = {
    Hover: {
      target: undefined,
      Position: {x: 0, y: 0},
    },
    Left: {
      Active: false,
      release: true,
      target: undefined,
      type: "mouseup", // undefined, mousedown, mouseup, double
      PositionFirst: {x: 0, y: 0},
      PositionNow: {x: 0, y: 0},
    },
    Right: {
      Active: false,
      release: true,
      target: undefined,
      Position: {x: 0, y: 0},
    }
  }

  this.Wheel = {
    DeltaY: { value: 0 },
  }

  this.Init();

}
//------------------------------------------------------------------------------------
  async Init () {
//------------------------------------------------------------------------------------

  document.addEventListener('keydown', this.handleKeyboardShortcuts.bind(this));

  document.addEventListener('fullscreenchange', function() {
    if (document.fullscreenElement === null) {
      document.querySelector("#Board_Sidebar").style.display = "flex";
    }
  });
  
}
//------------------------------------------------------------------------------------
  handleKeyboardShortcuts(event) {
//------------------------------------------------------------------------------------
  if (event.ctrlKey && event.shiftKey && event.key === 'F') {
    Public.Scripts.Toggle_Fullscreen();
  }

  if (event.key === 'Tab') { event.preventDefault(); }
}
//----------------------------------------------------------------------------------------
}