import { Slider } from './Slider/Slider.js';

export class Public_Scripts_Animation { constructor () {

  // this.anime = Lib;

  this.Slider = Slider;

}

//----------------------------------------------------------------------------------------
  Method () {
//----------------------------------------------------------------------------------------  

  /*
  this.ANIME({
      target: '.anime',
      translateX: 250,
  });
  */

//----------------------------------------------------------------------------------------
}


}