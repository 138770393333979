export class CORE_TIME { constructor () {

  this.lastTimestamp = performance.now();
  this.Delta = 0; // Tiempo transcurrido entre frames
  this.currentSecond = Math.floor(this.lastTimestamp / 1000);
  this.frameCount = 0;
  this.framesLastSecond = 0;

}
//-----------------------------------------------------------------------------
  Timer (Callback) { 
//-----------------------------------------------------------------------------
  let t1 = performance.now(); console.log(t1)
  Callback();
  let t2 = performance.now(); console.log(t2)
  let diff = t2 - t1;
  alert("diff"); 
  return diff;
}
//----------------------------------------------------------------------------------------
  CalculateFPS (currentFrameTime) {
//----------------------------------------------------------------------------------------

  let currentTimestamp = performance.now();
  this.Delta = parseFloat((currentTimestamp - this.lastTimestamp).toFixed(2));
  
  // Usar Delta para escalar movimientos, animaciones, etc.
  
  let currentSecond = Math.floor(currentTimestamp / 1000);
  if (currentSecond !== this.currentSecond) {
      this.currentSecond = currentSecond;
      this.framesLastSecond = this.frameCount;
      this.frameCount = 1;
  } else {
      this.frameCount++;
  }
  this.lastTimestamp = currentTimestamp;

}
//----------------------------------------------------------------------------------------
}