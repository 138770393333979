import { Styled } from './Style/Style_Admin_Onda_Community.js';

export const Admin_Onda_Community = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, undefined);
  let URI = `${Public.ENV.URL}/Apps/Admin/Parts/Onda/Community`;

//████████████████████████████████████████████████████████████████████████████████████

  const [Accounts, SetAccounts] = useState([
    {
      ID: "Youtube",
      Name: "initori",
      URL: "https://www.youtube.com/@",
      Followers: 4,
    },
    {
      ID: "Twitter",
      Name: "initori_",
      URL: "https://twitter.com/",
      Followers: 77,
    },
    {
      ID: "Instagram",
      Name: "initori",
      URL: "https://www.instagram.com/",
      Followers: 82,
    },
    /* 
    {
      ID: "Facebook",
      Name: "initori.inc",
      URL: "https://www.facebook.com/",
      Followers: 0,
    },
    {
      ID: "Reddit",
      Name: "initori",
      URL: "https://www.reddit.com/user/",
      Followers: 0,
    },
    {
      ID: "Twitch",
      Name: "initori_inc",
      URL: "https://www.twitch.tv/",
      Followers: 0,
    },
    {
      ID: "TikTok",
      Name: "initori_",
      URL: "https://www.tiktok.com/@",
      Followers: 0,
    },
    {
      ID: "Github",
      Name: "initori",
      URL: "https://github.com/",
      Followers: 0,
    },
    {
      ID: "Linkedin",
      Name: "initori",
      URL: "https://www.linkedin.com/company/",
      Followers: 0,
    }
    */
  ]);

//=====================================================================================

  const Get_Followers = () => {
    

  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Style}>

<h2 className="_Title">Redes</h2>

<div className="_Accounts">

  ${Accounts.map((Account, i) => {
    return html`
    <a href="${Account.URL}${Account.Name}" target="_blank" key="Admin_Onda_Community_${i}">
    <div className="_Account">
      <div className="_Header">
        <img src="${URI}/Media/${Account.ID}.png"/>
        <span>${Account.Name}</span>
      </div>
      <span className="_Followers">${Account.Followers}</span>
    </div>
    </a>
    `;
  })}

</div>

<//>
`);
}