export const Style = (styled, Proto, Props) => {
return styled.div`

.ContainerFlex{
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
//padding: 0 1%;

  & > *{
  // Usar un Grid de 2D
  width: auto;
  width: 21%;
  margin: 2%;

  &:last-child{
      // margin-right: auto;
      
      // margin-left: 3%;
      
  }

  @media (max-width: 920px) {
    width: 46%;
    margin: 4% 0 4% 0;
  }

  @media (max-width: 600px) {
    width: 96%;
    margin: 4% 0 4% 0;
  }


  }

}


`;
}