//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_App_Intro.js';
import { Style_Popit } from './Style/Style_App_Popit.js';

//----------------------------------------------------------------------------------------

export const App_Intro = ({ Props }) => {

  let URI = `${Public.ENV.URL}/Apps/Intro`;
  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const Styled_Popit = Public.Styles.Styled(Style_Popit, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const Part_Parallax = Public.Scripts.Choose_Part('Parallax');  

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const theMain = useRef(null);
  const bookRef = useRef(null);

  const [Loaded, SetLoaded] = useState(false);
  const [Images, SetImages] = useState([
    {URL: undefined},
    {URL: `${URI}/Media/Parallax/1.png`},
    {URL: `${URI}/Media/Parallax/2.png`},
  ]);

  // Popit Book
 const pages = [
  {
      id: 'page_0',
      html: /*html*/`
      
      `,
    },
    {
      id: 'page_1',
      html: /*html*/`
       <div id="popup_1" class="popup popup_a">
        <a href="/Items/Chips/1" class="PopupLink">
          <div class="PopupImage" style="background-image: url(${URI}/Media/Aventura.png);"></div>
          <div class="PopupText"><h2 class="_Title">Aura Adventure</h2></div>
        </a>
        </div>
        <div id="popup_2" class="popup popup_b">
        <a href="/Items/Chips/1" class="PopupLink">
          <img src="${URI}/Media/Aura.png"/>
        </a>
        </div>
      `,
    },
    {
      id: 'page_2',
      html: /*html*/`
        <div id="popup_2" class="popup popup_a">
        <a href="/Items/Chips/1" class="PopupLink">
          <div class="PopupImage" style="background-image: url(${URI}/Media/Aventura.png);"></div>
          <div class="PopupText"><h2 class="_Title">About</h2></div>
        </a>
        </div>
      `,
    },
    
  ];

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {
    SetLoaded(true);
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// 📙 Book
//----------------------------------------------------------------------------------------

const createBook = () => {
  if (!bookRef.current) return;

  bookRef.current.innerHTML = '';

  pages.forEach((page, index) => {
    const pageElement = document.createElement('div');
    pageElement.id = page.id;
    pageElement.className = 'page';
    pageElement.innerHTML = page.html;

    if (index !== 0 && index !== pages.length - 1) {
      pageElement.addEventListener('click', function() {
        this.classList.toggle('flip');
        const prevPage = this.previousElementSibling;
        const nextPage = this.nextElementSibling;

        if (prevPage) prevPage.querySelector('.popup_a')?.classList.toggle('hide');
        this.querySelector('.popup_a')?.classList.toggle('hide');
        this.querySelector('.popup_b')?.classList.toggle('hide');
        if (nextPage) nextPage.querySelector('.popup_b')?.classList.toggle('hide');
      });
    }

    // Set initial hide states for popups
    if (index > 0) { pageElement.querySelector('.popup_a')?.classList.add('hide'); }
    if (index > 1) { pageElement.querySelector('.popup_b')?.classList.add('hide'); }

    bookRef.current.appendChild(pageElement);
  });

  // Add an extra page at the end if needed
  /* if (pages.length === 4) {
    const lastPage = document.createElement('div');
    lastPage.id = 'page_5';
    lastPage.className = 'page';
    bookRef.current.appendChild(lastPage);
  } */

};

  useEffect(() => {
    if (bookRef.current) {
      createBook();
    }
  }, [bookRef.current]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>

<div className="Main_Intro" ref=${theMain}>

  <div id="Nav" className="Navbar">
    <!--<h1 className="_Title">
    <img src="${Public.ENV.URL}/Apps/Sidebar/Menu/Media/Manual.png" className="icon"/>
    Inicio
    </h1>-->
    <h2 className="_Title">Inicio</h2>
  </div>

  <${Styled_Popit}><div id="book" ref=${bookRef}></div><//>

</div>

<//>
`);

};