export const Style = (styled, Proto, Props) => {
return styled.div`

  ._Items {
    width: 100%;
    height: auto;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    ._Item{
    width: 100%;
    height: 70vh;
    margin: 3%;
    // background: aquamarine;
    border: 2px dashed #ccc;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

      ._Image {
      display: flex;
      justify-content: center;
      align-items: center;
      // background: aqua;

        img {
        width: auto;
        max-height: 24rem;
        transform: scale(0.8);
        transition: transform 0.2s ease-in-out;
        }

      }

      &:hover img { 
      transform: scale(1);
      }

      ._Name {
      font-size: 3rem;
      font-weight: bold;
      text-align: center;
      font-family: 'Courier';
      opacity: 0.1;
      transition: opacity 0.3s ease-in-out;
      }

      &:hover ._Name { 
      // text-decoration: underline;
      color: #88b7d5;
      opacity: 1;
      }

    }

  }


`;
}

