//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_App_Blog_List.js';

//----------------------------------------------------------------------------------------

export const App_Blog_List = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------


//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {
    
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>
<div className="App_Blog_List">
  
  <div className="Navbar">
    <!--<img src="${Public.ENV.URL}/Apps/Blog/.About/Icon/Icon.png" className="icon"/>-->
    <h2 className="_Title">Blog</h2>
    <div className="_Info"><i className="icofont-info-square icofont-1x"/></div>
    <!--<ul>
      <li className="_Active">Dev</li>
      <li>Marketing</li>
      <li>Ideas</li>
    </ul>-->
  </div>

  <div className="_Main">

    <ul>
    
      <li>
        <div className="_Thumbnail"><img src="${Public.ENV.URL}/Apps/Blog/Media/Aura_Keyboard.png"/></div>
        <div className="_Title">Crea un motor de videojuegos</div>
      </li>

      <li>
        <div className="_Thumbnail"><img src="${Public.ENV.URL}/Apps/Blog/Media/Community.png"/></div>
        <div className="_Title">Step-funding</div>
      </li>

      <li>
        <div className="_Thumbnail"><img src="${Public.ENV.URL}/Apps/Blog/Media/Map.png"/></div>
        <div className="_Title">El camino de initori</div>
      </li>

    </ul>

  </div>

</div>
<//>
`);

};
