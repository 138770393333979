//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class CAMERA_ISO { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
/*
We have three separate coordinate systems used for different things:

1. Space (3D)

	We apply the usual 3D coordinates to define the boxes using x,y,z.

2. Isometric (2D)

	When the 3D space is flattened into an isometric view, we use oblique x and y
	axes separated by 120 degrees.

	All this does is treat all 3d coordinates as if they are at z=0.

	For example, if use have a box at (0,0,0) and we raised it to (0,0,1), it would
	look to be in the exact same position as a box at (1,1,0), so the 2d isometric
	coordinates are (1,1).  This is a side effect of the isometric perspective.  So
	the isometric 2D coordinates gets the "apparent" coordinates for all boxes if
	they were at z=0.

	This is accomplished by adding z to x and y.  That is all.

	(Isometric coordinates are useful for determining when boxes overlap on the
	screen.)

3. Screen (2D)

	Before drawing, we convert the isometric coordinates to the usual x,y screen
	coordinates.

	This is done by multiplying each isometric 2D coordinate by its respective
	oblique axis vector and taking the sum.

	We then multiply this position by "scale" value to implement zoom in/out
	features for the camera.

	Then we add to an "origin" to implement panning features for the camera.

*/
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
	spaceToScreen (spacePos) {
//----------------------------------------------------------------------------------------
	// Convert the given 3D space coordinates to 2D screen coordinates.
	return this.isoToScreen(this.spaceToIso(spacePos));
}
//----------------------------------------------------------------------------------------
	isoToScreen (isoPos) {
//----------------------------------------------------------------------------------------
// Convert the given 2D isometric coordinates to 2D screen coordinates.
	return {
    x: isoPos.w * this.MAIA.TREE.CAMERA.ZOOM.Scale, // + this.origin.w,
    y: -isoPos.h * this.MAIA.TREE.CAMERA.ZOOM.Scale // + this.origin.h,
  };
}
//----------------------------------------------------------------------------------------
	spaceToIso (spacePos) {
//----------------------------------------------------------------------------------------
// Convert 3D space coordinates to flattened 2D isometric coordinates.
// x and y coordinates are oblique axes separated by 120 degrees.
// h(width),v(height) are the horizontal and vertical distances from the origin.
//----------------------------------------------------------------------------------------
  var z = (spacePos.z == undefined) ? 0 : spacePos.z;
  var x = spacePos.x + z;
  var y = spacePos.y + z;

  return {
    
    // Isometric Coords
    x: x,
    y: y,

    // Screen Coords
    w: (x-y)*Math.sqrt(3)/2, // Math.cos(Math.PI/6)
    h: (x+y)/2,              // Math.sin(Math.PI/6)

    // w: (x-z)/Math.sqrt(2),      
    // h: (x+(2*y)+z)/Math.sqrt(6),

    // u=(x-z)/sqrt(2);
    // v=(x+2*y+z)/sqrt(6);

  };
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Wich_Cubit_Front (Cubit_Yang, Cubit_Yin) {
//----------------------------------------------------------------------------------------

  // If no isometric separation axis is found,
  // then the two blocks do not overlap on the screen.
  // This means there is no "front" block to identify.
  // console.log(Cubit_Yang.ID, Cubit_Yin.ID, this.getIsoSepAxis(Cubit_Yang, Cubit_Yin))

  if (this.getIsoSepAxis(Cubit_Yang, Cubit_Yin)) {
    // alert(Cubit_Yang.ID + " - " + Cubit_Yin.ID)
    return null;
  }

  // Find a 3D separation axis, and use it to determine
  // which block is in front of the other.
  var a = Cubit_Yang.POS.Get_Bounds();
  var b = Cubit_Yin.POS.Get_Bounds();

  switch(this.getSpaceSepAxis(Cubit_Yang, Cubit_Yin)) {
    case 'x': return (a.xmin < b.xmin) ? Cubit_Yang : Cubit_Yin;
    case 'y': return (a.ymin < b.ymin) ? Cubit_Yang : Cubit_Yin;
    case 'z': return (a.zmin < b.zmin) ? Cubit_Yin : Cubit_Yang;
    default: { 
      // console.info(" ====== INTERSECTION =======");
      return "INTERSECTION";
    }
  }

}
//----------------------------------------------------------------------------------------
  getIsoSepAxis (Cubit_Yang, Cubit_Yin) {
//----------------------------------------------------------------------------------------
// Try to find an axis in 2D isometric that separates the two given blocks.
// This helps identify if the the two blocks are overlap on the screen.
//----------------------------------------------------------------------------------------
  var sepAxis = null;

  var a = this.getIsoBounds(Cubit_Yang);
  var b = this.getIsoBounds(Cubit_Yin);

  if (this.areRangesDisjoint(a.xmin,a.xmax,b.xmin,b.xmax)) {
    sepAxis = 'x';
  }
  if (this.areRangesDisjoint(a.ymin,a.ymax,b.ymin,b.ymax)) {
    sepAxis = 'y';
  }
  if (this.areRangesDisjoint(a.zmin,a.zmax,b.zmin,b.zmax)) {
    sepAxis = 'z';
  }
  return sepAxis;
}
//----------------------------------------------------------------------------------------
	getSpaceSepAxis (Cubit_Yang, Cubit_Yin) {
//----------------------------------------------------------------------------------------
// Try to find an axis in 3D space that separates the two given blocks.
// This helps identify which block is in front of the other.
//----------------------------------------------------------------------------------------
  var sepAxis = null;

  var a = Cubit_Yang.POS.Get_Bounds();
  var b = Cubit_Yin.POS.Get_Bounds();

  if (this.areRangesDisjoint(a.xmin,a.xmax,b.xmin,b.xmax)) {
    sepAxis = 'x';
  }
  else if (this.areRangesDisjoint(a.ymin,a.ymax,b.ymin,b.ymax)) {
    sepAxis = 'y';
  }
  else if (this.areRangesDisjoint(a.zmin,a.zmax,b.zmin,b.zmax)) {
    sepAxis = 'z';
  }
  return sepAxis;
}
//----------------------------------------------------------------------------------------
  getIsoBounds (block) {
//----------------------------------------------------------------------------------------
// For the given block, get the min and max values on each isometric axis.
//----------------------------------------------------------------------------------------
  var verts = this.getIsoVerts(block);
  return {
    xmin: verts.frontDown.x,
    xmax: verts.backUp.x,
    ymin: verts.frontDown.y,
    ymax: verts.backUp.y,
    zmin: verts.leftDown.w,
    zmax: verts.rightDown.w,
  };
}
//----------------------------------------------------------------------------------------
  getIsoVerts (block) {
//----------------------------------------------------------------------------------------
// Get the given block's vertices in flattened 2D isometric coordinates.
//----------------------------------------------------------------------------------------
  var verts = this.getIsoNamedSpaceVerts(block);
  return {
    leftDown:  this.spaceToIso(verts.leftDown),
    rightDown: this.spaceToIso(verts.rightDown),
    backDown:  this.spaceToIso(verts.backDown),
    frontDown: this.spaceToIso(verts.frontDown),
    leftUp:    this.spaceToIso(verts.leftUp),
    rightUp:   this.spaceToIso(verts.rightUp),
    backUp:    this.spaceToIso(verts.backUp),
    frontUp:   this.spaceToIso(verts.frontUp),
  };
}
//----------------------------------------------------------------------------------------
	getIsoNamedSpaceVerts (block) {
//----------------------------------------------------------------------------------------
// Get a block's vertices with helpful aliases.
// Each vertex is named from its apparent position in an isometric view.
//----------------------------------------------------------------------------------------
  
  if(!block) { console.error("📦 Not Found: Block", block); return; }
  if(!block?.POS) { console.error("📦 Not Found: Block POS", block); return; }
  if(!block?.POS?.Coords || !block.POS.Size) { console.error("📦 Not Found: Size or Coords", block); return;}

  var p = block.POS.Coords;
  var s = block.POS.Size;

  return {
    rightDown: {x:p.x+s.x, y:p.y,     z:p.z},
    leftDown:  {x:p.x,     y:p.y+s.y, z:p.z},
    backDown:  {x:p.x+s.x, y:p.y+s.y, z:p.z},
    frontDown: {x:p.x,     y:p.y,     z:p.z},
    rightUp:   {x:p.x+s.x, y:p.y,     z:p.z+s.z},
    leftUp:    {x:p.x,     y:p.y+s.y, z:p.z+s.z},
    backUp:    {x:p.x+s.x, y:p.y+s.y, z:p.z+s.z},
    frontUp:   {x:p.x,     y:p.y,     z:p.z+s.z},
  };
}
//----------------------------------------------------------------------------------------
  areRangesDisjoint (amin, amax, bmin, bmax) {
//----------------------------------------------------------------------------------------
// Determine if the given ranges are disjoint (i.e. do not overlap).
// For determining drawing order, this camera considers two
// ranges to be disjoint even if they share an endpoint.
// Thus, we use less-or-equal (<=) instead of strictly less (<).
//----------------------------------------------------------------------------------------
  return (amax <= bmin || bmax <= amin);
}
//----------------------------------------------------------------------------------------
}