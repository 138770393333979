export const Scrollbar = (styled, Proto, Props) => { return styled.css`

  // Scrollbar

  /* Estilo general para el scrollbar */
  ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
  }

  /* Estilo para la barra de desplazamiento (thumb) */
  ::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 4px;
  }

  /* Estilo para la barra de desplazamiento al pasar el mouse */
  ::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
  }

  /* Estilo para el track (la pista del scrollbar) */
  ::-webkit-scrollbar-track {
      background: transparent;
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 transparent;
  }

`}