//----------------------------------------------------------------------------------------

  import { Styled } from './Style/Style_Admin_Onda_Edit.js';

//----------------------------------------------------------------------------------------
  export const Admin_Onda_Edit = ({ Props }) => {
//----------------------------------------------------------------------------------------

  let URI = `${Public.ENV.URL}/Apps/Parts/Main/Admin/Parts/Onda`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();
  const history = RouterDOM.useHistory();

//████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

  const theFileInput = useRef(undefined);
  const thePreview = useRef(undefined);
  const theText = useRef(undefined);
  const theSubmit = useRef(undefined);

  const [Item, SetItem] = useState(undefined);
  const [Text, SetText] = useState(Item?.Text);
  const [Files, SetFiles] = useState(Item?.Files);
  const [Result, SetResult] = useState("Guardar");

//=====================================================================================

  useEffect(() => { Get_Item(); }, []);

  const Get_Item = async () => { if(!id) { return; }
    Public.DB.Read({
      URI: `Admin/Onda`,
      Items: [id], 
      Callback: (items) => { SetItem(items[0]); }
    });
  }

  useEffect(() => { if(!Item) { return; }
    if (Item.Files) { SetFiles(Item.Files); }
    if (Item.Text) { SetText(Item.Text); }
  }, [Item]);

  useEffect(() => { if(!Files) { return; }
    Preview_File({target: {files: Files}})
  }, [Files]);

//=====================================================================================

  const Set_Files = (files) => {
    SetText(theText.current.value);
    SetFiles(files);
  }

  const isValidFileType = (Blob) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/gif'];
    return allowedTypes.includes(Blob.type);
  };

  const isValidFileSize = (Blob) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
    return Blob.size <= MAX_FILE_SIZE;
  };

  const Preview_File = (e) => {
    if (e.target.files.length === 0) { console.error("No se seleccionó ningún archivo"); return; }
    const [file] = e.target.files;
    const Blob = file.Blob;
    if (!isValidFileType(Blob)) { console.error("Tipo de archivo no permitido"); return; }
    if (!isValidFileSize(Blob)) { console.error("El archivo es demasiado grande"); return; }
    thePreview.current.src = URL.createObjectURL(Blob);
  };

  const Process_Files = async (files) => {
    if(!files) { return []; }
    return Promise.all(files.map(async (file) => {
      const b64 = await blobToBase64(file.Blob);
      const jsonString = JSON.stringify({blob: b64});
      return  {
        Name: "File",
        Format: "png",
        Blob: jsonString,
      };
    })).then((file) => {
      return file;
    });
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };  

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      // Usar DataTransferItemList para acceder al archivo
      if (event.dataTransfer.items[0].kind === 'file') {
          const file = event.dataTransfer.items[0].getAsFile();
          theFileInput.current.files = event.dataTransfer.files; // Asigna el archivo al input
          Set_Files([{Blob: file}]);
          // Preview_File({target: {files: [file]}}); // Activa la función de previsualización
      }
    } else {
      // Usar DataTransfer para acceder al archivo
      theFileInput.current.files = event.dataTransfer.files;
      Set_Files([{Blob: event.dataTransfer.files[0]}]);
      // Preview_File({target: {files: event.dataTransfer.files}});
    }
  };

//=====================================================================================

  const Submit = () => {
    
    SetText(theText.current.value);

    if(Item) { Edit() }
    else { Save(); }
    
  }

  const Edit = async () => {

    let Processed_Files = await Process_Files(Files);

    let Item_Data = {
      ID: id,
      Text: theText.current.value,
      Networks: [
        {Type: "Twitter", Published: Item.Networks.find((n) => n.Type === 'Twitter')?.Published},
        {Type: "Instagram", Published: Item.Networks.find((n) => n.Type === 'Instagram')?.Published},
      ],
      Files: Processed_Files // [{blob_base64}, {blob_base64}...]
    };

    Public.DB.Save({
      URI: `Admin/Onda`,
      Items: [Item_Data],
      Callback: (Response) => {
        console.info("Save:", Response);
        SetResult(html`<i className="icofont-ui-check icofont-1x"></i>`);
      }
    });

  }

  const Save = async () => {
    
    let Processed_Files = await Process_Files(Files);

    let id = `Onda_${Date.now()}`;

    let Item_Data = {
      ID: id,
      Text: theText.current.value,
      Networks: [
        {Type: "Twitter", Published: false},
        {Type: "Instagram", Published: false},
      ],
      Files: Processed_Files // [{blob_base64}, {blob_base64}...]
    };

    Public.DB.Save({
      URI: `Admin/Onda`,
      Items: [Item_Data],
      Callback: (Response) => {
        console.info("Edit:", Response);
        SetResult(html`<i className="icofont-ui-check icofont-1x"></i>`);
        history.push(`/Admin/Onda/Detail/${id}`);
      }
    });

  }

  const Back = () => {

    if(Item) { history.push(`/Admin/Onda/Detail/${id}`); }
    else { history.push(`/Admin/Onda`);}

  }

//=====================================================================================

return (html`
<${Style}>

  <div className="_List">

    <div className="_Navbar">
      <h2 className="_Title">Onda</h2>
      <div className="_Button" onClick=${() => Back() }>
        <i className="icofont-arrow-left icofont-1x"/>
      <//>
    </div>

    <div className="Onda_Edit">

      <div className="_Top">
        <img className="_Preview" ref=${thePreview} 
        src="${Files && Public.Scripts.Blob_To_Img_URL(Files[0]?.Blob)}"/>
        <textarea name="message" ref=${theText} defaultValue=${Text} onChange=${() => {}} />
      </div>

      <div className="_Bottom">
        <input id="FileInput" type="file" accept="image/png, image/jpeg, image/gif"
        ref=${theFileInput} onChange=${(e) => Set_Files([{Blob: e.target.files[0]}])}/>
        <label className="FileLabel" htmlFor="FileInput"
        onDragOver=${onDragOver} onDrop=${onDrop}>Archivo</label>
        <div ref=${theSubmit} className="_Submit" onClick=${() => { Submit() }}>${Result}</div>
      </div>

    </div>

  </div>

  <div className="_Sidebar">
    
  </div>

<//>
`);
}