import { Items_Asset_List } from "./List/Items_Asset_List.js";
import { Items_Asset_Detail } from "./Detail/Items_Asset_Detail.js";
import { Style } from './Style/Style_Items_Asset.js';

export const Items_Asset = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const RouterDOM = Public.Libs.RouterDOM;
  const Styled = Public.Styles.Styled(Style, {});

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>

  <${RouterDOM.Route} exact path='/items/assets'>
    <${Items_Asset_List} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/items/assets/:id'>
    <${Items_Asset_Detail} Props=${Props} />
  <//>

<//>
`);
}