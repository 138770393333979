export class Public_Scripts_Vectorial { constructor () {

    // this.Screen = Screen_SVG;
    // this.AddText();
    // this.AddSquare();

}


//----------------------------------------------------------------------------------------
    AddText(Screen_SVG) {
//----------------------------------------------------------------------------------------
    
    let Screen = Screen_SVG;

    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttributeNS(null,"id","svgDoc");
    svg.setAttributeNS(null,"height","100%");
    svg.setAttributeNS(null,"width","100%");

    Screen.DOM.appendChild(svg);
    var svgDoc = document.getElementById('svgDoc');

    var txtElem = document.createElementNS("http://www.w3.org/2000/svg", "text");
    txtElem.setAttributeNS(null,"x",20);
    txtElem.setAttributeNS(null,"y",100);

    var theText = "Hello World! This is SVG Text created with JavaScript.";
    var theMSG = document.createTextNode(theText);
    txtElem.appendChild(theMSG);
        
    svgDoc.appendChild(txtElem);
//----------------------------------------------------------------------------------------
}
//----------------------------------------------------------------------------------------
    AddSquare() {
//----------------------------------------------------------------------------------------

    // Create the <svg>
    const svgns = "http://www.w3.org/2000/svg";
    var svg = document.createElementNS(svgns, "svg");
    svg.setAttributeNS(null,"id","svgDoc2");
    svg.setAttributeNS(null,"height","100%");
    svg.setAttributeNS(null,"width","100%");
    this.Screen.DOM.appendChild(svg);
    var svgDoc = document.getElementById('svgDoc');

    // make a simple rectangle
    let newRect = document.createElementNS(svgns, "rect");
    newRect.setAttribute("x", "150");
    newRect.setAttribute("y", "150");
    newRect.setAttribute("width", "100px");
    newRect.setAttribute("height", "100px");
    newRect.setAttribute("fill", "#5cceee");

    // append the new rectangle to the screen.
    svgDoc.appendChild(newRect);

//----------------------------------------------------------------------------------------
}

}