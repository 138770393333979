//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_Board_Items_Navbar.js';

//----------------------------------------------------------------------------------------
export const Board_Items_Navbar = ({ Props }) => {
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, memo } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const location = RouterDOM.useLocation();

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------

  const thePanel = useRef(null);
  const infoDialog = useRef(null);
  // const Sections = ['Chips', 'Books', 'Cards', 'Merch', 'Assets'];
  const Sections = ['Chips', 'Books', 'Merch'];
  const [Index, SetIndex] = useState("");
  const [Prev, SetPrev] = useState("");
  const [Next, SetNext] = useState("");

//----------------------------------------------------------------------------------------

  const normalizeIndex = (index) => {
    if(index < 0) { index = Sections.length - 1; }
    if(index >= Sections.length) { index = 0; }
    return index;
  }

  const Refresh_Path = () => {
    // Given a location path (/items/books) we retrieve the index position (1)
    const path = location.pathname.split('/').pop();
    const capitalizedPath = path.charAt(0).toUpperCase() + path.slice(1);
    let index = Sections.indexOf(capitalizedPath);

    if(!path) { index = 0; }

    SetIndex(Sections[normalizeIndex(index)]);
    SetNext(Sections[normalizeIndex(index+1)].toLowerCase());
    SetPrev(Sections[normalizeIndex(index-1)].toLowerCase());
  }

  useEffect(() => {
    if(!thePanel.current) { return; }
    Refresh_Path();
  }, [location.pathname]);

  const handleInfoClick = () => {
    infoDialog.current.showModal();
  }

  const handleCloseDialog = () => {
    infoDialog.current.close();
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
return (html`
<${Styled} ref=${thePanel}>

  <div className="Navbar">
    <h2 className="_Title">${Index}</h2>
    <!--<div className="_Info" onClick=${handleInfoClick}><i className="icofont-info-square icofont-1x"/></div>-->
    <!--<div className="_Sections">
      ${Sections.map((Section, i) => { return html`
        <${RouterDOM.Link} to="/Items/${Section}" key="Key_Board_Items_Navbar_Sections_${i}">
        <div className="_Section ${Section === Index && 'Active'}">
          <img src="${Public.ENV.APP}/Items/${Section}/.About/Icon/Icon.png" className="_Icon"/>
        </div>
        <//>
      `
      })}
    </div>
    -->
  </div>

  <dialog ref=${infoDialog}>
    <h2>Chips</h2>
    <div>Los <i>Chips</i> son los cartuchos de videojuegos en <div className="initori">initori</div>.</div>
    <br/>
    <button onClick=${handleCloseDialog}>Cerrar</button>
  </dialog>

<//>
`);
}
