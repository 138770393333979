export const Style = (styled, Proto, Props) => {
return styled.div`
  
width: 100%;
height: 100%;
display: flex;

.Admin_Docs {
width: 100%;
height: 100%;
display: flex;
}

iframe {
width: 100%;
height: 100%;
display: flex;
}

`;
}