//----------------------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class CAMERA_ANIMATION { constructor (MAIA) {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Focus (Ent, Coords_Extra, Time, Callback) {
//----------------------------------------------------------------------------------------

  if(!Ent || !Ent.CUBIT) { 
    console.error("Camera Animation: Focus: Ent not defined or Cubit");
    return; }

  if(!Time) { Time = 1; } // 1 second

  // Data
  let Coords_Start = {
    w: this.MAIA.TREE.CAMERA.Focus.w,
    h: this.MAIA.TREE.CAMERA.Focus.h }

  let Coords_End = this.MAIA.TREE.CAMERA.FOCUS.Get_Center(Ent);
  Coords_End.w += Coords_Extra.w;
  Coords_End.h += Coords_Extra.h;
    
  // Run
  this.MAIA.TREE.CAMERA.Set_Animation({
    Time: Time, 
    Function: (Time) => {

      let progress = Time.Actual / Time.Total;
      let displacementW = Math.round((Coords_End.w - Coords_Start.w) * progress);
      let displacementH = Math.round((Coords_End.h - Coords_Start.h) * progress);
      
      this.MAIA.TREE.CAMERA.Focus = {
        w: Coords_Start.w + displacementW,
        h: Coords_Start.h + displacementH
      };

    },
    Callback: (Time) => { // Open Panel_Ent

      let progress = Time.Actual / Time.Total;
      let displacementW = Math.round((Coords_End.w - Coords_Start.w) * progress);
      let displacementH = Math.round((Coords_End.h - Coords_Start.h) * progress);
      this.MAIA.TREE.MODE.ACTIVE.GRAB.Saved.w -= displacementW;
      this.MAIA.TREE.MODE.ACTIVE.GRAB.Saved.h -= displacementH;

      this.MAIA.TREE.CAMERA.Reset_Animation();
      Callback();

    }
  });

}
//----------------------------------------------------------------------------------------
}