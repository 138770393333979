//----------------------------------------------------------------------------------------

  import { TREE } from "../TREE.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_PANEL extends TREE { constructor (MAIA) { super(MAIA);
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Panels = [];
  
  this.NodeRoot = undefined;

  this.ENV = {
    URI: this.MAIA.ENV.URI + '/TREE/PANEL',
    ROUTE: this.MAIA.ENV.ROUTE // /panel
  }
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
  
  // Reference DOM
  this.NodeRoot = this.MAIA.TREE.CLIENT.DOM.State.Panel.Ref;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Fetch (Panel_ID) {
//----------------------------------------------------------------------------------------
  
  // EVT.ID can be something like "Evt" or "Evt/Add"
  const subIDs = Panel_ID.split('/');
  const basePath = `${this.ENV.URI}/Index`;
  let URI = basePath;

  subIDs.forEach((id, index) => {
    if (index === 0) { URI += `/${id}`; } 
    else { URI += `/Parts/${id}`; }
  });

  URI += `/Panel_${subIDs.join('_')}.js`;
  
  return (async () => {
    const {default: Panel} = await import(URI);
    return Panel;
  })();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Create (Evt, Callback) {
//----------------------------------------------------------------------------------------

  if(this.Get_Panel(Evt.ID)) {
    console.log("The Panel exists, deleting.", Evt.ID);
    this.Delete(Evt.ID);
    return;
  }

  // Await for the dynamic import of the <TemplatePanel>:
  await this.Fetch(Evt.Props.Type).then((TemplatePanel) => {

    let Proto = Evt;
    let Props = Evt.Props.Data;

    // Root
    let Div = document.createElement('div');
    Div.setAttribute("data-panel", "" + Evt.ID);

    // React
    const html = this.MAIA.TREE.CLIENT.LIBRARIES.State['html'];
    let ReactNode = (html`<${TemplatePanel} EVT=${Proto} Proto=${Proto} Props=${Props} />`); 

    // Default Node
    let Node = Evt.Props.Data?.Node;
    if(!Node) { Node = {} }
    if(!Node.Dom) { Node.Dom = this.NodeRoot;  }
    if(!Node.Action) { Node.Action = 'APPEND';  }

    // APPEND | ROOT | REPLACE
    if(Node.Action === 'ROOT') { Node.Dom.appendChild(Div); }
    else if(Node.Action === 'APPEND') {  Node.Dom.appendChild(Div); }
    else if(Node.Action === 'REPLACE') { Node.Dom.innerHTML = ''; Node.Dom.appendChild(Div); }

    // Create Node Root
    let reactDOM = this.MAIA.TREE.CLIENT.LIBRARIES.State['ReactDOM'];
    Evt.Node.Root = reactDOM.createRoot(Div);
    Evt.Node.React = ReactNode;
    Evt.Node.Dom = Node.Dom;

    // Render Div here:
    this.Panels.push(Evt);

    Callback();

  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Delete (ID) {
//----------------------------------------------------------------------------------------
  
  let Evt = this.Get_Panel(ID);
  
  if(Evt) {

    // Delete the item reference in this.Panels[]
    this.Panels = this.Panels.filter(item => item.ID !== ID);
                  
    // Delete parent container
    if (Evt.Node.Root) {
      const containerDiv = Evt.Node.Root._internalRoot.containerInfo; // Obtener el div
      Evt.Node.Root.unmount(); // Desmontar el componente React
      containerDiv.remove();
    }

  }

}
//----------------------------------------------------------------------------------------
  Delete_All () {
//----------------------------------------------------------------------------------------

  // Delete the Node.
  if(this.NodeRoot) { this.NodeRoot.innerHTML = ""; }

  // Delete the IDs references.
  this.Panels = [];
  
}
//----------------------------------------------------------------------------------------
  Get_Panel (ID) {
//----------------------------------------------------------------------------------------

  let Panel = this.Panels.find((panel) => { return panel.ID === ID});
  if (Panel) { return Panel; }
  else { return false; }

}
//----------------------------------------------------------------------------------------
  Get_Component (ID) {
//----------------------------------------------------------------------------------------

  return this.NodeRoot.querySelectorAll(`[data-panel=${ID}]`)[0];

}
//----------------------------------------------------------------------------------------
  Contains (Component_ID, Listener) {
//----------------------------------------------------------------------------------------
// Check if the Listener is over a particular Panel.

  if(!this.Get_Component(Component_ID)) { return false; }

  if(this.Get_Component(Component_ID).contains(Listener)){
    return true;
  }else{
    return false;
  }

}
//----------------------------------------------------------------------------------------
  Contains_Any (Listener) {
//----------------------------------------------------------------------------------------
// Check if the Listener is over any Panel

  return this.Panels.some((Panel) => this.Contains(Panel.ID, Listener));

}
//----------------------------------------------------------------------------------------
}