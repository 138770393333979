import { Styled } from './Style/Style_Login.js';

export const App_Login = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/User/Login`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;

  // Refs
  const theForm = useRef(null);

  const [ID, SetID] = useState(null);

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

  const Login = async (e) => {

    e.preventDefault();
    const sendForm = new FormData(theForm.current);
    await fetch(`${Public.ENV.URL}/user/login`, { 
      method: 'POST',
      body: sendForm,
    })
    .then(fetch => fetch.json())
    .then(res => { 
      Public.Scripts.Notify.Send({
        Title: "Login", Description: `Login ID #${Public.TOKEN.User_ID}`, Image: "User", Time: 3000
      });
      console.log(res); 
      Public.TOKEN = Public.Token.Get('ACCESS');
      SetID(Public.TOKEN.User_ID);
    });

  }
   
/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<${Style}>

  <img className="_Background" src="${URI}/Media/Nivel_0.png"/>
  
  <div className="_Box">
  ${ID}
  ${html`
  <div>
    <form onSubmit=${(e)=> { Login(e); }} ref=${theForm}>
      <input type="text" name="Name"/>
      <input type="password" name="Password"/>
      <input type="submit" value="Entrar"/>
    </form>
  </div>
  `}
  </div>

<//>
`);
  
};