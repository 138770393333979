export const Global = (styled, Proto, Props) => {

return styled.createGlobalStyle`

  :root {
    // --color-background: antiquewhite;
    --color-background: aliceblue;
  }

  * {
  user-select: none;
  }

  *, *:before, *:after {
  box-sizing: border-box;
  }

  html {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  padding: 0px;
  // background: aqua;
  overflow: hidden;
  }

  html::selection {
  background-color: #55c57a;
  color: #fff;
  }

  body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ddd;
  box-sizing: inherit;
  font-family: "Open Sans";
  font-size: 1.6rem;
  }

  html a {
  color: inherit;
  text-decoration: inherit;
  }

  ul {
  list-style: none;
  margin: 0;
  padding: 0;
  }

  input {
  max-width: 100%;
  }

  iframe {
  border: none;
  padding: 0;
  margin: 0;
  }

  html {
  font-size: 55.5%;
  }

  @media only screen and (max-width: 50em) {
    html {
      font-size: 45%;
    }
  }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 50%;
    }
  }
  @media only screen and (min-width: 100em) {
    html {
      font-size: 65%;
    }
  }

`
};