
  import { Styles } from './Styles/Styles_Init.js';
  import { Digi } from './Parts/Digi/Digi.js';
  import { Trailer } from './Parts/Trailer/Trailer.js';
  import { Readme } from './Parts/Readme/Readme.js';
  import { Quest } from './Parts/Quest/Quest.js';

//----------------------------------------------------------------------------------------
  export const App_Egg = ({ }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------
 
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Styles, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const location = RouterDOM.useLocation();
  const history = RouterDOM.useHistory();

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Easter Egg
//----------------------------------------------------------------------------------------

  const The_Onda = useRef(null);
  const [Egg, SetEgg] = useState(undefined);
  const pathParts = location.pathname.split('/').filter(part => part);
  const firstParam = pathParts[0];
  const id = pathParts[1];
  // const {id} = RouterDOM.useParams();

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [Mode, SetMode] = useState('/readme');
  
  const Ondas = [
  // { label: 'Guía', angle: '60deg', Icon: "Aura.png", Sound: "Lifegame_1", URL: '/readme', Router: true},
  // { label: 'Digitori', angle: '90deg', Icon: "Egg.png", Sound: "Lifegame_3", URL: '/egg', Router: true},
  // { label: 'Tráiler', angle: '120deg', Icon: "ui-play", Sound: "Lifegame_2", URL: '/trailer', Router: true},

  { label: 'Guía', angle: '300deg', Icon: "fa-solid fa-book", Sound: "Lifegame_1", URL: '/readme', Router: true},
  { label: 'Tráiler', angle: '180deg', Icon: "fa-solid fa-play", Sound: "Lifegame_2", URL: '/trailer', Router: true},
  { label: 'Digitori', angle: '240deg', Icon: "fa-solid fa-egg", Sound: "Lifegame_3", URL: '/egg', Router: true},
  { label: 'Youtube', angle: '120deg', Icon: "fa-brands fa-youtube", Sound: "Lifegame_4", URL: 'https://www.youtube.com/@initori'},
  { label: 'Twitter', angle: '60deg', Icon: "fa-brands fa-twitter", Sound: "Lifegame_1", URL: "https://www.twitter.com/initori_"},
  { label: 'Instagram', angle: '0deg', Icon: "fa-brands fa-instagram", Sound: "Lifegame_2", URL: "https://www.instagram.com/initori.inc"},
  ];

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Egg_Get = async (e) => {
    let Form = new FormData();
    Form.append("Key", id);
    Public.Scripts.Route({Route: 'init/egg/get', Form: Form, 
    Callback: (res) => { SetEgg(res); } 
    });
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {

    if(firstParam == undefined || firstParam == '/' || firstParam == 'init') { SetMode('Init') } 
    else {
      // setIsFlipped(null);
      if(firstParam == 'init') { SetMode('/init') }
      else if(firstParam == 'readme') { SetMode('/readme') }
      else if(firstParam == 'trailer') { SetMode('/trailer') }
      else if(firstParam == 'quest') { SetMode('/quest') }
      else if(firstParam == 'egg') { SetMode('/egg') }
      else { SetMode('Init') }
    }

  }, [firstParam]);

  useEffect(() => {
    if(Mode === '/egg' && id) { Egg_Get(); }
  }, [Mode]);

// Puntos Data
//----------------------------------------------------------------------------------------

  function calculateCoordinates(angleInDegrees) {
    let radius = 250;
    const angleInRadians = parseFloat(angleInDegrees) * (Math.PI / 180);
    const x = radius + radius * Math.cos(angleInRadians);
    const y = radius + radius * Math.sin(angleInRadians);
    return { x, y };
  }

// onClick
//----------------------------------------------------------------------------------------

  const handleClick = (event, puntoData) => {

    Tone.context.resume();
    let FileSound = `${Public.ENV.URL}/Public/Media/Sounds/Egg/Init/Lifegame/${puntoData.Sound}.mp3`;
    const player = new Tone.Player().toDestination();
    player.load(FileSound).then(() => { player.start(); })
    .catch(error => { console.error("Error al cargar el archivo de audio:", error); });

    if (puntoData.Router) {
      event.preventDefault();
      if(puntoData.URL === Mode) { 
        // history.push('/readme'); 
        // history.push(puntoData.URL); 
      } else { 
        history.push(puntoData.URL); 
      }
    }

  };

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>

<div id="App">

<!--<div className="_Bg"></div>-->
<!--<video autoPlay muted loop className="_Bg_Video">
<source src="${Public.ENV.URL}/Apps/Init/Media/Trailer/Trailer.mp4" type="video/mp4"/>
</video>-->

<div className="_Container">
  
    <div className="_Onda" ref=${The_Onda}>

    <div className="onda-circle">

    ${Ondas.map((puntoData, i) => {
      
      const { x, y } = calculateCoordinates(puntoData.angle);

      return html`
        <a href=${puntoData.URL} key="Key_Point_${i}" target="_blank" onClick=${(e) => handleClick(e, puntoData)}>
        <div key=${i} className="punto" style=${{ left: `${x}px`, top: `${y}px` }}>
          <div className="onda ${puntoData.URL === Mode && 'Active'}">
            <!--<img src="${Public.ENV.URL}/Public/Media/Images/Init/${puntoData.Icon}" />-->
            <!--<i className="icofont-${puntoData.Icon} icofont-1x"/>-->
            <i className="${puntoData.Icon}" />
            <!--<span>${puntoData.label}</span>-->
          </div>
        </div>
        </a>
      `;
      })}
      
    </div>
  </div>

  <div className="_Dispositivo">

    <${RouterDOM.Switch}>
    <${RouterDOM.Route} path=${["/trailer"]} render=${() => { return html`<${Trailer} />` }} />
    <${RouterDOM.Route} path=${["/readme"]} render=${() => { return html`<${Readme} />` }} />
    <${RouterDOM.Route} path=${["/egg", "/egg/:id"]} render=${() => { return html`<${Digi} Egg=${Egg} />` }} />
    <${RouterDOM.Route} path=${["/", "/init"]} render=${() => { return html`<${Digi} />` }} />
    <//>
    
  </div><!--Dispositivo-->

</div><!--Container-->

</div><!--App-->

<//>
`);

};