  
  import { Styled } from './Style/Style_Admin_Studio_Record.js';

export const Admin_Studio_Record = ({ Props }) => {

  let URI = `${Public.ENV.URL}/Apps/Parts/Main/Admin/Parts/Studio/Record`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);

//████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

  const theCanvas = useRef(null);
  const theStart = useRef(null);
  const theStop = useRef(null);
  const theVideo = useRef(null)

  const [Recorder, SetRecorder] = useState(undefined);
  const [Canvas, SetCanvas] = useState(undefined);
  // const [Ctx, SetCtx] = useState(undefined);

  let chunks = [];

  // Canvas
  useEffect(() => { if(theCanvas.current) { 

    SetCanvas(theCanvas.current);

  }}, [theCanvas]);

  // Draw
  useEffect(async () => { if(Canvas) { 

    draw();
    await Define_Recorder();

  } }, [Canvas]);

  // Recorder
  useEffect(async () => { if(Recorder) { 

    Recorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
  
    Recorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'video/webm' });
      const url = URL.createObjectURL(blob);
      theVideo.current.src = url;
      chunks = [];
    };

  } }, [Recorder]);

  const Define_Recorder = async () => {
    
    let canvas = theCanvas.current;

    // Configurar el stream de audio y video
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const canvasStream = canvas.captureStream(30); // 30 FPS
    const combinedStream = new MediaStream([...canvasStream.getVideoTracks(), ...audioStream.getAudioTracks()]);

    // Configurar MediaRecorder
    SetRecorder(new MediaRecorder(combinedStream));
    
  } 

  function startRecording () {

    // Iniciar grabación
    Recorder.start();

    // Habilitar y deshabilitar botones
    theStart.current.disabled = true;
    theStop.current.disabled = false;

  }

  function stopRecording () {

    if (Recorder) { Recorder.stop(); }

    // Habilitar y deshabilitar botones
    theStart.current.disabled = false;
    theStop.current.disabled = true;
  }

  function draw () {

    let canvas = theCanvas.current;

    // let Canvas = theCanvas.current;
    let Ctx = canvas.getContext('2d');

    // Ejemplo de animación: dibujar un rectángulo que se mueve
    const time = Date.now() / 1000;
    const x = (canvas.width / 2) + Math.sin(time) * (canvas.width / 4);
    const y = (canvas.height / 2);

    Ctx.clearRect(0, 0, canvas.width, canvas.height);
    Ctx.fillStyle = 'blue';
    Ctx.fillRect(x - 25, y - 25, 50, 50);

    requestAnimationFrame(()=> draw());

  }

//=====================================================================================

return (html`
<${Style}>

<canvas id="TheCanvas" ref=${theCanvas} width="640" height="480"></canvas>
<br />
<button ref=${theStart} onClick=${()=> startRecording()}>Iniciar grabación</button>
<button ref=${theStop} onClick=${()=> stopRecording()}>Detener grabación</button>
<br />
<video ref=${theVideo} controls></video>

<//>
`);
}

/*
<div ref=${theMicro} className="_Micro" onClick=${(e) => { Micro(e) }}>Grabar</div>

const Micro = async (e) => {
    
  try {

    // Solicitar grabación
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);

    // Aquí empieza la grabación
    mediaRecorder.start();
    console.log("Empezando a grabar...");

    // Detener la grabación después de 10 segundos
    setTimeout(() => {
      mediaRecorder.stop();
      console.log("Deteniendo la grabación...");
    }, 5000);

    // Cuando se detiene la grabación, obtén los datos de audio y haz algo con ellos
    mediaRecorder.addEventListener("dataavailable", (event) => {

      const audioBlob = event.data;
      console.log("Datos de audio obtenidos:", audioBlob);

      // Aquí puedes hacer algo con los datos de audio, como enviarlos a un servidor
      const audioElement = new Audio();
      audioElement.src = URL.createObjectURL(audioBlob);
      audioElement.play();

    });

  } catch (error) {
    console.error("No se puede acceder al micrófono:", error);
  }
  
}
*/