//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------

  import { MODE } from '../Core/MODE.js';
  import { MODE_EDIT_UTILS } from './UTILS/MODE_EDIT_UTILS.js';
  import { MODE_EDIT_PANEL } from './PANEL/MODE_EDIT_PANEL.js';
  import { MODE_EDIT_CREATE } from './CREATE/MODE_EDIT_CREATE.js';
  import { MODE_EDIT_HOVER } from './HOVER/MODE_EDIT_HOVER.js';
  import { MODE_EDIT_SELECT } from './SELECT/MODE_EDIT_SELECT.js';
  import { MODE_EDIT_GRAB } from "./GRAB/MODE_EDIT_GRAB.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT extends MODE { constructor (MAIA) { super(MAIA);
//---------------------------------------------------------------------------

  this.MAIA = MAIA;
  this.ID = "EDIT";
  
  this.UTILS = new MODE_EDIT_UTILS(MAIA);
  this.PANEL = new MODE_EDIT_PANEL(MAIA);
  this.CREATE = new MODE_EDIT_CREATE(MAIA);
  this.HOVER = new MODE_EDIT_HOVER(MAIA);
  this.SELECT = new MODE_EDIT_SELECT(MAIA);
  this.GRAB = new MODE_EDIT_GRAB(MAIA);

  // Refactor por this.Selected = {Cubit, Zone};
  // this.HOVER.Higher;
  this.ZoneSelected;

  this.Loaded = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Init () {
//---------------------------------------------------------------------------

  this.PANEL.Init();
  this.HOVER.Init();
  this.CREATE.Init();
  this.GRAB.Init();

}
//---------------------------------------------------------------------------
  Load () {
//---------------------------------------------------------------------------

  // Check Load can continue
  let Player = this.MAIA.TREE.ENT.Find('Aura');
  if(!Player.CUBIT) { return; }

  // Focus Camera on Player
  let Coords = this.MAIA.TREE.CAMERA.FOCUS.Get_Center(Player);
  Coords.w = this.MAIA.TREE.CAMERA.Origin.w - Coords.w; 
  Coords.h = this.MAIA.TREE.CAMERA.Origin.h - Coords.h;
  this.MAIA.TREE.CAMERA.Move = Coords; 
  this.MAIA.TREE.MODE.ACTIVE.GRAB.Saved = Coords;
  this.MAIA.TREE.CAMERA.Set_Animation({Function: 'Edit'});

  // Signal: Mode.Edit.Init
  this.MAIA.TREE.SIG.Create({
    ID: this.MAIA.CORE.UTILS.Random_ID('SIG'),
    Key: 'Mode.Edit.Init',
    Value: true,
    Target: this.MAIA.TREE.ENT.Find('Root'),
    Sender: undefined
  });

  // Sound Reset
  this.MAIA.TREE.SOUND.Reset();
  
  return true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Reset () {
//---------------------------------------------------------------------------
  
  // Remove: Ent['Hover'], Ent['Seed']
  this.MAIA.TREE.ENT.Remove({ID: 'Editor'});
  this.MAIA.TREE.ENT.Remove({ID: 'Hover'});

  // Reset Panels
  this.MAIA.TREE.PANEL.Delete_All(); // deprecate; en cascada sub-modes de 'Editor'

  // Camera Reset
  this.MAIA.TREE.CAMERA.Reset_Animation();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Run () {
//---------------------------------------------------------------------------

  if(this.MAIA.TREE.MODE.ACTIVE.ID !== 'EDIT') { return false; }

  this.MAIA.TREE.CLIENT.DOM.State.Render.Ref.style.cursor = "auto";

  this.GRAB.Run();

  let Input = this.MAIA.TREE.CLIENT.INPUT.State;

  //------------------------------  ♻️ z-Index  --------------------------------

  if(Input.Wheel.DeltaY.value !== 0) {
    
    let Ent = this.MAIA.TREE.MODE.ACTIVE.SELECT.Ent;
    let Cubit = Ent?.CUBIT;

    // If no Cubit selected, no movement with z-cursor
    if(!Ent) { return; }

    // If the Ent selected has an open Panel, disable z-cursor
    if(Ent.ID === this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Ent')?.State.Selected_Ent?.ID) {
      return;
    }
    
    let Coord_Z = Ent.CUBIT.POS.Coords.z;
    if(Input.Wheel.DeltaY.value > 0){ Coord_Z -= 5; } else
    if(Input.Wheel.DeltaY.value < 0){ Coord_Z += 5; }

    Cubit.POS.Teleport({ x: Cubit.POS.Coords.x, y:Cubit.POS.Coords.y, z: Coord_Z });

    // Traslate the Coords into 2D, and "save" them
    let Saved_2D = this.MAIA.TREE.CAMERA.MATRIX.Position_3D_To_2D(Cubit.POS.Coords)
    Cubit.SavedCubit = {...Saved_2D}; // Debug

  }

  //------------------------------  ♻️ Reset  --------------------------------
  
  if(Input.Keyboard.Ctrl.Active) {
    // if(this.HOVER.CubitHovered) { this.HOVER.CubitHovered._Ent.Mode = 'Default'; }
    return;
  }
  
  // Clean Create
  this.CREATE.Clear();

  // Reset Conditions
  this.HOVER.Is_Selected = false;

  // Reset Zone
  this.ZoneSelected = undefined;

  //------------------------------  🏗️ Debug  --------------------------------

  if(this.SELECT.CubitSelected) {
    /* this.MAIA.CORE.DEBUG.Add(`
    ${this.SELECT.CubitSelected.POS.Coords.x}, 
    ${this.SELECT.CubitSelected.POS.Coords.y}, 
    ${this.SELECT.CubitSelected.POS.Coords.z}`); */
  }

  //------------------------------  🖱️ Cursor  --------------------------------

  let Cursor = Input.Cursor.Hover.Position;
  this.Cursor = Cursor;
  
  //------------------------------  🖱️ Hover  ---------------------------------

  if(this.MAIA.CLIENT.DOM.State.Panel.Active) { return; }
  this.HOVER.Follow(Cursor);
  
  //-----------------------------  🪁 Overlap  --------------------------------

  let Cubits_Under = this.HOVER.Find_Under();

  //------------------------------  🔝 Higher  --------------------------------

  this.HOVER.Set_Higher(Cubits_Under);
  let CubitHigher = this.HOVER.Higher;
  
  if(this.HOVER.Higher) {

    this.ZoneSelected = this.UTILS.Get_Zone(this.HOVER.Ent.CUBIT, this.HOVER.Higher);

  /*██████████████████████████████   Follow Cursor   ████████████████████████████████*/

    this.CREATE.Follow();

  }
  /*███████████████████████████████     Hovered     █████████████████████████████████*/

   this.HOVER.Focus();

  /*████████████████████████████████   ✅ Select   ███████████████████████████████████*/

  this.MAIA.TREE.CLIENT.INPUT.Read_Input(["Cursor", "Left"], () => {
    
    // Add new Ent, if this.CREATE.Ent is active
    this.CREATE.Confirm();

    if(CubitHigher && CubitHigher._Ent.Mode === 'Selected') {
      // Reset with Toggle
      this.SELECT.Unselect();
    } else { 
      
      this.SELECT.Unselect();

      // Assign the new selected Cubit
      if(CubitHigher) { this.SELECT.Select(CubitHigher._Ent); }
  
    }
    
  }, true, "Mode_Edit_Cursor_Left");

  /*████████████████████████████████   🧊 Create   ███████████████████████████████████*/

  if(this.MAIA.TREE.CLIENT.INPUT.State.Cursor.Left.type === 'double') {

  }

  /*████████████████████████████████   📟 Select   ███████████████████████████████████*/

  // if(this.MAIA.TREE.CLIENT.INPUT.State.Cursor.Left.type === 'double') {
  this.MAIA.TREE.CLIENT.INPUT.Read_Input(["Cursor", "Right"], async () => {

    // If it was adding a new Ent, reset
    if(this.CREATE.Ent) { this.CREATE.Reset(); return; }

    // Hide Panel by default
    // await this.PANEL.Ent.Evt('Panel_Ent').Action('Unselect');
    
    if(CubitHigher && CubitHigher._Ent.Mode === 'Selected'
    || CubitHigher && CubitHigher._Ent.Mode === 'Hovered') {

      // Toggle: If Selected, unselect
      if(CubitHigher._Ent.ID === this.PANEL.Ent.Evt('Panel_Ent')?.State.Selected_Ent?.ID
      && this.PANEL.Ent.Evt('Panel_Ent')?.State.Opened === true) {

        // this.PANEL.Ent.Evt('Panel_Ent').State.Selected_Ent = CubitHigher._Ent;
        this.PANEL.Ent.Evt('Panel_Ent').State.Selected_Ent = null;
        // await this.PANEL.Ent.Evt('Panel_Ent').Action('Toggle');
        this.PANEL.Toggle();
        this.SELECT.Unselect();

      }
      // If not Selected, focus
      else {

        // Select Ent, Set in State & Open Panel
        // this.SELECT.Select(CubitHigher._Ent);
        this.SELECT.Open(CubitHigher._Ent);

      }

    }
    
  }, true, "Mode_Edit_Cursor_Right");
  // }

  this.MAIA.TREE.CLIENT.INPUT.Read_Input(["Keyboard", "Supr"], () => {
    
    // if(CubitHigher && CubitHigher._Ent?.Mode === 'Selected') {
      this.MAIA.TREE.ENT.Remove(this.SELECT.Ent, 'Ents');
    // }

  }, true, "Mode_Edit_Keyboard_Supr");

}
//------------------------------------------------------------------------------------
}