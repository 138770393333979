// import init, { greet } from "./Rust/pkg/hello_wasm.js";
// import init from "./Rust/pkg/hello_world.js";

export class CORE_WASM { constructor (MAIA) {

  this.MAIA = MAIA;
  this.URI = this.MAIA.ENV.URI + '/CORE/WASM/Rust';

  this.Scripts = {};
  
  // :: LOAD ::
  this.Loaded = false;
  this.Loading = false;
  
  this.Run();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Run () {
//----------------------------------------------------------------------------------------

  // Instantiate our wasm module
  // const helloWorld = await init(`${this.URI}/pkg/hello_world_bg.wasm`);

  // Call the Add function export from wasm, save the result
  // const addResult = helloWorld.rusty(24, 24, 24);

  // Set the result onto the body
  // console.log("🟪", addResult);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Load () {
//----------------------------------------------------------------------------------------

  if(this.Loaded)   { return true; }
  if(!this.Loading) { this.Loading = true;

    this.Loaded = true;
    
  }

}
//----------------------------------------------------------------------------------------
}