export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
position: relative;
border-radius: 7px;

#Admin_Calendar {
z-index: 1;
width: 100%;
height: 100%;
display: flex;
}

`;

return Style;

}

