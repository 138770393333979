export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: auto;
height: 100%;
background: rgb(27, 25, 25);

display: flex;
flex-direction: column;

// display: ${Props.Sidebar && Props.Sidebar};

  @media only screen and (max-width: 40rem) {
  flex-direction: row;
  height: 10rem;
  }

  a, .Sidebar_Item {
  width: 10rem;
  height: 10rem;

  display: flex; 
  align-items: center;
  justify-content: center;

    img, canvas {
    max-width: 100%;
    max-height: 100%;
    }

    @media only screen and (max-width: 40rem) {
    width: 20%;
    padding: 2.5%;
    height: 100%;
    // background: blueviolet;
    }

  }


`;

return Style;

}