import { default as Admin } from './Parts/Admin.js';

export const App_Admin = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const styled = Public.Libs.styled;
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████

  const Styled = styled.div`
  width: 100%;
  height: 100%;
  `;
  
//████████████████████████████████████████████████████████████████████████████████████

  const theAdmin = useRef();
  const [Node, SetNode] = useState(null);

  useEffect(() => {

    // La interfaz de Admin no está "Protegida", ya que los archivos (.js, imágenes)
    // están dentro de la carpeta /Public.
    // Lo que están protegidas son las "acciones" que pueden ejecutarse en el servidor,
    // ya que las rutas comprobarán que el Token sea válido.

    // En cualquier caso, actualmente los Usuarios no descargarán los archivos de "Admin",
    // ya que dichos archivos son Symlinks que apuntan fuera de la carpeta /Public,
    // y que únicamente se encuentran en el Ordenador Local.

    // DEBUG MODE
    // <${AdminApp} Props=${Props} />

    /*
    if (Public.TOKEN.PRIVILEGE === 'ADMIN') {
      const {default: AdminApp} = await import(`${Public.ENV.URL}/Apps/Main/Admin/Parts/Admin.js`);
      SetNode(html`
      <${RouterDOM.Router}>
        <${AdminApp} Props=${Props} />
      <//>
      `); 
    }
    */

  }, []);

  useEffect(() => {

    if(Node && theAdmin.current) {
      Public.Libs.ReactDOM.render(Node, theAdmin.current);
    }

  }, [Node]);

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled} ref=${theAdmin}>
  <${Admin} Props=${Props} />
<//>
`);

};