//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄

  import { RENDER_BOARD_BG } from "./BG/RENDER_BOARD_BG.js";
  import { RENDER_BOARD_FOG } from "./FOG/RENDER_BOARD_FOG.js";
  import { RENDER_BOARD_DARK } from "./DARK/RENDER_BOARD_DARK.js";
  import { RENDER_BOARD_RAIN } from "./RAIN/RENDER_BOARD_RAIN.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class RENDER_BOARD { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Canvas = undefined;
  this.Ctx = undefined;

  // Data
  this.Dark = undefined;
  this.Lights = []; // > ToDo: Mover a this.DARK

  // Interfaces
  this.BG = new RENDER_BOARD_BG(MAIA); 
  this.FOG = new RENDER_BOARD_FOG(MAIA);
  this.DARK = new RENDER_BOARD_DARK(MAIA);
  this.RAIN = new RENDER_BOARD_RAIN(MAIA);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Canvas = this.MAIA.TREE.RENDER.Canvas;
  this.Ctx = this.MAIA.TREE.RENDER.Ctx;

  this.BG.Init();
  this.FOG.Init();
  this.DARK.Init();
  this.RAIN.Init();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Under () {
//----------------------------------------------------------------------------------------

  this.BG.Run();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Over () {
//----------------------------------------------------------------------------------------

  this.FOG.Run();
  this.DARK.Run(this.Dark, this.Lights);
  this.RAIN.Run();

}
//----------------------------------------------------------------------------------------
}