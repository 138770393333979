//----------------------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class CAMERA_FOCUS { constructor (MAIA) {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Get_Position (Coords) {
//----------------------------------------------------------------------------------------

  let {w, h} = this.MAIA.TREE.CAMERA.MATRIX.Position_3D_To_2D(Coords);
  w += this.MAIA.TREE.CAMERA.Focus.w;
  h += this.MAIA.TREE.CAMERA.Focus.h;
  return {w, h};

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Move ({w, h}) {
//----------------------------------------------------------------------------------------
  
  this.MAIA.TREE.CAMERA.Focus.w = this.MAIA.TREE.CAMERA.Origin.w + w;
  this.MAIA.TREE.CAMERA.Focus.h = this.MAIA.TREE.CAMERA.Origin.h + h;

}
//----------------------------------------------------------------------------------------
  Focus (Ent) {
//----------------------------------------------------------------------------------------
  
  let Positio_Center = this.Get_Center(Ent);
  this.MAIA.TREE.CAMERA.Focus = Positio_Center;
  
}
//----------------------------------------------------------------------------------------
  Get_Center (Ent) { // Change Ent for Cubit (?)
//----------------------------------------------------------------------------------------

  if(!Ent.Find_Type('CUBIT')) {
    console.error("Ent or Cubit not defined", Ent)
    return null; }
  
  let Coords = this.MAIA.TREE.CAMERA.MATRIX.Position_3D_To_2D({...Ent.CUBIT.POS.Coords});
  if(Ent.GRAVITY) { Coords.h += Ent.GRAVITY.Levitatio.Actual; } // Ignore Levitatio
  // if(Ent.JUMP) { Focus.h += Ent.JUMP.State.Float_Actual; } // Ignore Jump
  
  let Origin = this.MAIA.TREE.CAMERA.Origin;
  let HalfEnt = {w:(Ent.CUBIT.POS.Size.x - Ent.CUBIT.POS.Size.y)/2, h: Ent.CUBIT.POS.Size.z/2}
  
  let FinalFocus = {
    w: - Coords.w + (Origin.w - HalfEnt.w), //+ (Math.abs(this.MAIA.TREE.CAMERA.Move.w)/2),
    h: - Coords.h + (Origin.h + HalfEnt.h)  //+ (Math.abs(this.MAIA.TREE.CAMERA.Move.h)/2)
  }

  FinalFocus.h += HalfEnt.h;

  // Math.ceil/floor causes a glitch, moving the focus of Aura 1px up and down
  // FinalFocus = {w: Math.ceil(FinalFocus.w), h: Math.ceil(FinalFocus.h)}

  FinalFocus = {w: FinalFocus.w, h: FinalFocus.h}

  return FinalFocus;
  
}
//----------------------------------------------------------------------------------------
}