import { Styled } from './Style/Style_Admin_Calendar_Detail.js';

export const Admin_Calendar_Detail = ({ Props }) => {

  let URI = `${Public.ENV.URL}/Apps/Parts/Main/Admin/Parts/Calendar`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();

//=====================================================================================

  const [Item, SetItem] = useState(null);
  
  useEffect(() => { Get_Item(); }, []);

  const Get_Item = async () => {
    Public.DB.Read({
      URI: `Admin/Calendar`, 
      Items: [id], 
      Callback: (items) => { SetItem(items[0]); }
    });
  }

  useEffect(() => { if(!Item) { return }
    console.info("ITEM", Item)
  }, [Item]);

//=====================================================================================

return (html`
<${Style}>

<div id="Admin_Calendar_Detail">

${Item ?
html`
<div>
  <img src=${Public.Scripts.Blob_To_Img_URL(Item.Files[0].Blob)}/>
  ${Item.Name}
  ${Item.Date}
</div>
`
:
""}

</div>

<//>
`);
}