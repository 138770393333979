export const Style = (styled, Proto, Props) => {

return styled.div`
width: 100%;
height: 100%;
max-height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.Section_Community {
width: 100%;
height: 80%;
padding: 2rem 4rem;
overflow-y: scroll;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

  .Boxes {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly; 

  @media only screen and (max-width: 50rem) {
    display: block;
  }

  .Box {
  width: 30%;
  border-radius: 9px;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  overflow: hidden;
  background-color: #eee;
  color: #eee;
  border: transparent 2px solid;

  @media only screen and (max-width: 50rem) {
    width: 100%;
    height: 50%;
    margin-bottom: 5%;
  }
    
  &.YouTube { background: #cf3932; border-color: #cf3932; }
  &.Instagram { background: #e79ee8; border-color: #e79ee8; }
  &.X { background: #222; border-color: #222; }

    a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 100%;
    height: 100%;
    // background-color: aliceblue;
    }

    h2 {
    width: 100%;
    position: absolute;
    top: 2rem;
    margin-top: 2.2rem;
    font-size: 2.5rem;
    // color: #fff;
    color: #eee;
    text-align: center;
    z-index: 2;
    padding: 0 2rem;
    font-family: "Ginora Sans";
    font-weight: bold;
    }

    &.Active h2{  
    // color: #2f618a;
    }

    &:last-child .Separator{
    visibility: hidden;
    }

    }

  }

  .movil {
  position: absolute;
  top: 60%;
  left: 10%;
  width: 80%;
  height: 600px;
  background-color: #2c1c1e;
  border: 1px solid #555;
  border-radius: 30px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
  z-index: 2;
  transition: top 2s ease-in-out;
  }

  .movil::before {
  content: '';
  position: absolute;
  top: 10px;
  width: 140px;
  height: 20px;
  background-color: #2c1c1e;
  border-radius: 0 0 10px 10px;
  z-index: 3;
  }

  .pantalla {
  width: calc(100% - 16px); /* Ajusta el ancho para simular bordes del móvil */
  height: calc(100% - 40px); /* Ajusta el alto considerando la muesca y el borde inferior */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
  position: relative;
  z-index: 2;
  // display: flex;

    iframe, img {
    // position: absolute;
    z-index: 2;
    width: 100%;
    // max-height: 100%;
    border-radius: 20px;
    // opacity: 0.5;
    // filter: grayscale(100%);
    }

  }

  .botones {
  position: absolute;
  width: 3px;
  height: 50px;
  background: rgba(255,255,255,0.5);
  left: -10px;
  top: 100px;
  border-radius: 1px;
  }

  .sensor {
  position: absolute;
  width: 60px;
  height: 10px;
  background: rgba(0,0,0,0.5);
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  }

  //============================================================================================
  // Halo
  //============================================================================================

  @keyframes Mobile_Animation {
    from {
    top: 60%;
    // opacity: 0.9;
    img { filter: grayscale(100%);}
    }
    to {
    top: 20%;
    // opacity: 1;
    img { filter: grayscale(0%);}
    }
  }

  .Box .movil {
    img{
    filter: grayscale(100%);
    }
  }

  .Box.Active .movil {
  top: 20%;
  animation: Mobile_Animation 0.5s ease forwards;
    img{
    filter: grayscale(0%);
    }
  }

  .Box.Inactive .movil {
  top: 60%;
  animation: Mobile_Animation 0.5s ease forwards;
  animation-direction: reverse;
    img{
    filter: grayscale(100%);
    }
  }

}

`;

}