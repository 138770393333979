export const Load_i18n = (i18n, initReactI18next) => {

return i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({

    // we init with resources
    resources: {
      en: {
        translations: {
          welcome: "Hello <br/> <strong>World</strong>",
          explore: "Explore"
        }
      },
      es: {
        translations: {
          welcome: "das sung",
          explore: "Explora"
        }
      }
    },
    fallbackLng: "es",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

}
