
  import { Styled } from './Style/Style_Admin_Calendar_List.js';

//========================================================================================

export const Admin_Calendar_List = ({ Props }) => {

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// 📚 Dependencies
//----------------------------------------------------------------------------------------

 let URI = `${Public.ENV.URL}/Apps/Parts/Main/Admin/Parts/Calendar`;
 const { useState, useEffect, useRef } = Public.Libs.React;
 const html = Public.Libs.html;
 const Style = Styled(Public.Libs.styled, {}, URI);
 const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// 💾 State
//----------------------------------------------------------------------------------------

  const thePanel = useRef(undefined);
  const [Items, SetItems] = useState(null);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// 🦉 Scripts
//----------------------------------------------------------------------------------------

  const Get_Calendar = async () => {
    Public.DB.Read({
      URI: `Admin/Calendar/Items`, 
      Items: ['ALL'], 
      Callback: (items) => { 
        SetItems(Sort_Items(items)); }
    });
  }

  const Sort_Items = (array) => {
    return array.sort((a, b) => {
      return new Date(a.Date) - new Date(b.Date);
    });
  }

  const Get_Month_Name = numeroMes => ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"][numeroMes - 1];

  const List_Days_Current_Month = () => {
    const today = new Date();
    const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    const monthName = Get_Month_Name(today.getMonth() + 1);
    return [...Array(daysInMonth).keys()].map(day => `${day + 1} de ${monthName}`);
  } 
  
  const Scroll = () => {
    const today = new Date().getDate() - 1;
    const element = document.querySelector(`#day-${today}`);
    if (element) { element.scrollIntoView({ behavior: 'smooth', block: 'center' }); }
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// 🤖 Hooks 
//----------------------------------------------------------------------------------------

  useEffect(() => {
    Get_Calendar();
  }, []);

  useEffect(() => { if(!Items) { return; }
    console.log(Items);
    Scroll();
  }, [Items]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// 💻 Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>

<div id="Admin_Calendar_List" ref=${thePanel}>

<div className="_Main">
<ul>
  ${List_Days_Current_Month().map((day, index) => {
    const today = new Date().getDate();
    return html`<li id="day-${index}" key=${index} className=${index + 1 === today ? 'Active' : ''}>${day}</li>`;
  })}
</ul>
</div>

<div className="_Sidebar">
  Sidebar
</div>

</div>

<//>
`);
}