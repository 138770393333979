//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class RENDER_GEOMETRY { constructor(MAIA) {
//----------------------------------------------------------------------------------------
  
  this.MAIA = MAIA;
  this.Canvas = undefined;
  this.Ctx = undefined;

  this.camera = undefined;

	// This function allows us to draw using different coordinate systems.
	// It accepts a nondescript position vector and tries to detect
	// what coordinate system it is in by looking at its properties.
	//		(x,y,z)   <- treated as a space coordinate
	//		(x,y)     <- treated as a space coordinate at z=0
	//                   (same as 2D isometric XY)
	//		(h,v)     <- treated as a special 2D isometric coordinate
	//                   (with horizontal and vertical distance from origin)

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
 
  this.Canvas = this.MAIA.TREE.RENDER.Canvas;
  this.Ctx = this.MAIA.TREE.RENDER.Ctx;
  this.camera = this.MAIA.TREE.CAMERA.ISO;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  transform (pos) {
//----------------------------------------------------------------------------------------
  
  var x,y,z;

  if (pos.x != undefined && pos.y != undefined) {
    x = pos.x;
    y = pos.y;
    z = (pos.z == undefined) ? 0 : pos.z;
    return this.camera.spaceToScreen({x:x, y:y, z:z});
  }
  else if (pos.w != undefined && pos.h != undefined) {
    return this.camera.isoToScreen(pos);
  }
  else {
    console.log("x",pos.x,"y",pos.y,"z",pos.z,"h",pos.w,"v",pos.h);
    console.log("painter.transform: Unable to detect coordinate system of given vector");
  }

}
//----------------------------------------------------------------------------------------
  moveTo (CTX, pos) {
//----------------------------------------------------------------------------------------
  var v = this.transform(pos);
  CTX.moveTo(v.x,v.y);
}
//----------------------------------------------------------------------------------------
  lineTo (CTX, pos) {
//----------------------------------------------------------------------------------------
  var v = this.transform(pos);
  CTX.lineTo(v.x,v.y);
}
//----------------------------------------------------------------------------------------
  quad (CTX, p1, p2, p3, p4) {
//----------------------------------------------------------------------------------------
  this.moveTo(CTX, p1);
  this.lineTo(CTX, p2);
  this.lineTo(CTX, p3);
  this.lineTo(CTX, p4);
}
//----------------------------------------------------------------------------------------
  fillQuad (CTX, p1, p2, p3, p4, color, lineWidth) {
//----------------------------------------------------------------------------------------
  CTX.beginPath();
  this.quad(CTX, p1,p2,p3,p4);
  CTX.closePath();
  CTX.fillStyle = color;
  CTX.fill();
  if (lineWidth) {
    CTX.lineWidth = lineWidth;
    CTX.strokeStyle = color;
    CTX.stroke();
  }
}
//----------------------------------------------------------------------------------------
  fillQuadGradient (CTX, p1, p2, p3, p4, color1, color2) {
//----------------------------------------------------------------------------------------
  var v1 = this.transform(p1);
  var v4 = this.transform(p4);
  var v2 = this.transform(p2);
  var dx = v4.x-v1.x;
  var dy = v4.y-v1.y;
  var dist = Math.sqrt(dx*dx+dy*dy);
  dx /= dist;
  dy /= dist;
  var dx2 = v2.x-v1.x;
  var dy2 = v2.y-v1.y;
  dist = Math.sqrt(dx2*dx2+dy2*dy2);
  dx *= dist;
  dy *= dist;
  
  //var grad = CTX.createLinearGradient(v1.x, v1.y, v2.x, v2.y);
  var grad = CTX.createLinearGradient(v1.x,v1.y, v1.x-dy, v1.y+dx);
  grad.addColorStop(0, color1);
  grad.addColorStop(1, color2);
  this.fillQuad(CTX, p1,p2,p3,p4, grad);
}
//----------------------------------------------------------------------------------------
  strokeQuad (CTX, p1, p2, p3, p4, color, lineWidth) {
//----------------------------------------------------------------------------------------
  CTX.beginPath();
  this.quad(CTX, p1,p2,p3,p4);
  CTX.closePath();
  CTX.strokeStyle = color;
  CTX.lineWidth = lineWidth;
  CTX.lineJoin = "round";
  CTX.stroke();
}
//----------------------------------------------------------------------------------------
  line (CTX, p1, p2, color, lineWidth) {
//----------------------------------------------------------------------------------------
  CTX.beginPath();
  this.moveTo(CTX, p1);
  this.lineTo(CTX, p2);
  CTX.strokeStyle = color;
  CTX.lineCap = 'butt';
  CTX.lineWidth = lineWidth;
  CTX.stroke();
}
//----------------------------------------------------------------------------------------
  fillCircle (CTX, p1, radius, color) {
//----------------------------------------------------------------------------------------
  var v = this.transform(p1);
  CTX.beginPath();
  CTX.arc(v.x,v.y,radius,0,2*Math.PI);
  CTX.fillStyle = color;
  CTX.fill();
}
//----------------------------------------------------------------------------------------
  strokeCircle (CTX, p1, radius, color) {
//----------------------------------------------------------------------------------------
  var v = this.transform(p1);
  CTX.beginPath();
  CTX.arc(v.x,v.y,radius,0,2*Math.PI);
  CTX.fillStyle = color;
  CTX.fill();
}
//----------------------------------------------------------------------------------------
}