//----------------------------------------------------------------------------------------
  export class DATA_PROTO { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Protos = [];

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

}
//----------------------------------------------------------------------------------------
  async Create ({Ent, if_OK = () => {}}) { // Refactor por Save()
//----------------------------------------------------------------------------------------

  // Ent Data
  let Ent_Data = await Ent.Get_Data();

  // FormData
  let formData = new FormData();
  formData.append('User_ID', this.MAIA.ENV.User?.ID || 0);
  formData.append('Chip_ID', JSON.stringify(this.MAIA.ENV.Chip?.ID));
  formData.append('Ent', JSON.stringify(Ent_Data));
  console.info("💾 Save Ent", Ent_Data);

  // Thumbnail
  let Evt_Paint = Ent.Evts.find((evt) => evt.Type === 'PAINT');
  let Canvas = Evt_Paint.Canvas;
  let Canvas_Blob = await this.MAIA.CORE.RESOURCES.Thumbnail.Canvas_To_Blob(Canvas);
  formData.append('Canvas', Canvas_Blob, `Default.png`);

  // Route
  await fetch(`${this.MAIA.ENV.ROUTE}/data/proto/save`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.json())
  .then(res => { 
    if(res.Result === 'OK') {
      Public.Scripts.Notify.Send({Title: "Proto", Description: `Proto guardado`, Image: "Proto", Time: 3000});
      if (if_OK) { if_OK(); }
    }
    else {
      Public.Scripts.Notify.Send({Title: "Proto", Description: `Error al guardar`, Image: "Error", Time: 3000});
    }
  });

}
//----------------------------------------------------------------------------------------
}