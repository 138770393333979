
  import { MODE } from "../Core/MODE.js";

//-------------------------------------------------------------------------------------------------
  export class MODE_PLAY extends MODE { constructor (MAIA) { super(MAIA);
//-------------------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.ID = "PLAY";
  this.ShowMenu = false;
  this.Player = undefined;

  this.Loaded = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {  
//----------------------------------------------------------------------------------------

  this.MAIA.TREE.PANEL.Delete_All();

  // Cursor default
  this.MAIA.TREE.CLIENT.DOM.State.Render.Ref.style.cursor = "auto";

}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------
  
  // this.MAIA.TREE.SOUND.Stop();

  this.MAIA.TREE.PANEL.Delete_All();

  // Camera Reset
  this.MAIA.TREE.CAMERA.Reset_Animation();

}
//---------------------------------------------------------------------------
  Load () {
//---------------------------------------------------------------------------

  // Focus Camera on Player
  let Player = this.MAIA.TREE.ENT.Find('Aura');
  if(!Player) { return; }
    
  this.Player = Player;
  this.MAIA.TREE.CAMERA.Set_Animation({Function: 'Play'});

  // Signal: Mode.Play.Init
  this.MAIA.TREE.SIG.Create({
    ID: this.MAIA.CORE.UTILS.Random_ID('SIG'),
    Target: this.MAIA.TREE.ENT.Find('Root'),
    Key: 'Mode',
    Value: "Play",
    Wait: 20,
    Sender: undefined,
  });

  return true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  // Change Mode? (Now donde by EVT:Root_Mode)
  /* 
  if(this.MAIA.ENV.MODE !== 'PLAY') {
    this.MAIA.TREE.CLIENT.INPUT.Read_Input(["Keyboard", "Enter"], () => {
      this.MAIA.TREE.MODE.Init('EDIT');
    }, true, "Mode_Enter");
  }

  this.MAIA.TREE.CLIENT.INPUT.Read_Input(["Gamepad", "Start"], () => { 
    
  }, true);
  */

}
//----------------------------------------------------------------------------------------
}