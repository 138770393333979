export const Styles = (styled, Proto, Props) => {

return styled.div`
width: 100%;
height: 100%;
max-height: 100%;

.Board_Items_Dashboard {
width: 100%;
height: 100%;
// padding: 2rem 8rem;
overflow-y: scroll;
display: flex;
// flex-grow: 1;
flex-direction: column;
justify-content: center;
align-items: center;


}
`;

}