//------------------------------------------------------------------------------------

  import { Styles_Core } from './Core/Styles_Core.js';
  import { Styles_Proto } from './Proto/Styles_Proto.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//------------------------------------------------------------------------------------
  export class Public_Styles { constructor (Public) {
//------------------------------------------------------------------------------------

  this.Public = Public;
  
  this.Core = Styles_Core(this.Public.Libs.styled, Styles_Proto, {});
  this.Proto = Styles_Proto;

  let Sidebar;
  if(Public.ENV.MODE === 'PLAY' && Public.ENV.DOOR === 'LOCAL') { Sidebar = "flex"; }
  else { Sidebar = "flex"; }

  this.ENV = {
    Sidebar: "flex"
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//------------------------------------------------------------------------------------
  Styled (Style, Props) {
//------------------------------------------------------------------------------------

  const Styled = Style(this.Public.Libs.styled, this.Proto, Props);
  return Styled;

}
//------------------------------------------------------------------------------------
}