export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
color: #333;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

.App_Blog_Detail {
width: 100%;
height: 100%;
padding: 0;
overflow-y: scroll;

display: flex;
justify-content: center;
flex-direction: column;
justify-content: space-between;

  ._Main {
  height: 100%;
  }


}

`;

return Style;
}
