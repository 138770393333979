export const Input = (styled, Proto, Props) => { return styled.css`

.Form {

input[type="text"] {
width: 100%;
margin-bottom: 1rem;
padding: 1rem;
border: 1px solid #cccccc;
border-radius: 4px;
box-sizing: border-box;
}

button {
width: 100%;
padding: 2rem;
border: none;
border-radius: 4px;
background-color: #0056b3;
color: white;
cursor: pointer;
transition: background-color 0.3s ease;
margin: 1rem 0;
text-align: center;
color: #fff;
}

button:hover {
background-color: #003d82;
}

}

.Container_Input_Range {
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 1rem;
}

  .Input_Range {
  width: 100%;
  height: 100%;
  }

  .Input_Range_Text {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  }

.Container_Input_Text {
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 1rem;
}

.Input_Label {
width: 50%;
font-size: 1.8rem;
color: #333;
text-transform: uppercase;
font-family: 'Ginora Sans';
font-weight: bold;
--mi-color-sombra: rgba(225, 225, 225, 0.3);
        text-shadow: 
          1px 0 var(--mi-color-sombra), 
          0 1px var(--mi-color-sombra), 
          -1px 0 var(--mi-color-sombra), 
          0 -1px var(--mi-color-sombra);
}

.Input_Text {
width: 100%;
width: 100%;
// margin-bottom: 1rem;
padding: 1rem;
border: 1px solid #cccccc;
border-radius: 4px;
box-sizing: border-box;
}

.Input_Color {
width: 100%;
}

.Textarea {
width: 100%;
padding: 2rem;
border: 2px solid #ccc;
border-radius: 10px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
resize: none;
transition: box-shadow 0.3s, border-color 0.3s;
line-height: 1.5;
vertical-align: top; 
font-family: 'Ginora Sans';


  &:focus {
  outline: none;
  border-color: aqua;
  box-shadow: 0 0 8px rgba(0, 0, 255, 0.5);
  }

}

.Input_Button {
display: inline-block;
width: 100%;
padding: 1rem 2rem;
font-size: 2rem;
margin-top: 1rem;
color: white;
background-color: #007BFF; /* Azul bonito */
border: none;
border-radius: 4px;
cursor: pointer;
text-align: center;
text-decoration: none;
transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    // transform: scale(1.05);
  }

  &:active {
    // transform: scale(1.02);
  }

}

`}