const consoleLogger = {
    type: 'logger',
    log (args) {
        this.output('log', args);
    },
    warn (args) {
        this.output('warn', args);
    },
    error (args) {
        this.output('error', args);
    },
    output (type, args) {
        if (console && console[type]) {
            // *PRODUCTION*
            // console[type].apply(console, args);
        }
    }
};
class Logger {
    constructor(concreteLogger1, options1 = {
    }){
        this.init(concreteLogger1, options1);
    }
    init(concreteLogger, options = {
    }) {
        this.prefix = options.prefix || 'i18next:';
        this.logger = concreteLogger || consoleLogger;
        this.options = options;
        this.debug = options.debug;
    }
    setDebug(bool) {
        this.debug = bool;
    }
    log(...args) {
        return this.forward(args, 'log', '', true);
    }
    warn(...args) {
        return this.forward(args, 'warn', '', true);
    }
    error(...args) {
        return this.forward(args, 'error', '');
    }
    deprecate(...args) {
        return this.forward(args, 'warn', 'WARNING DEPRECATED: ', true);
    }
    forward(args, lvl, prefix, debugOnly) {
        if (debugOnly && !this.debug) return null;
        if (typeof args[0] === 'string') args[0] = `${prefix}${this.prefix} ${args[0]}`;
        return this.logger[lvl](args);
    }
    create(moduleName) {
        return new Logger(this.logger, {
            ...{
                prefix: `${this.prefix}:${moduleName}:`
            },
            ...this.options
        });
    }
}
const __default = new Logger();
class EventEmitter {
    constructor(){
        this.observers = {
        };
    }
    on(events, listener) {
        events.split(' ').forEach((event)=>{
            this.observers[event] = this.observers[event] || [];
            this.observers[event].push(listener);
        });
        return this;
    }
    off(event, listener) {
        if (!this.observers[event]) return;
        if (!listener) {
            delete this.observers[event];
            return;
        }
        this.observers[event] = this.observers[event].filter((l)=>l !== listener
        );
    }
    emit(event, ...args) {
        if (this.observers[event]) {
            const cloned = [].concat(this.observers[event]);
            cloned.forEach((observer)=>{
                observer(...args);
            });
        }
        if (this.observers['*']) {
            const cloned = [].concat(this.observers['*']);
            cloned.forEach((observer)=>{
                observer.apply(observer, [
                    event,
                    ...args
                ]);
            });
        }
    }
}
function defer() {
    let res;
    let rej;
    const promise = new Promise((resolve, reject)=>{
        res = resolve;
        rej = reject;
    });
    promise.resolve = res;
    promise.reject = rej;
    return promise;
}
function makeString(object) {
    if (object == null) return '';
    return '' + object;
}
function copy(a, s, t) {
    a.forEach((m)=>{
        if (s[m]) t[m] = s[m];
    });
}
function getLastOfPath(object, path, Empty) {
    function cleanKey(key) {
        return key && key.indexOf('###') > -1 ? key.replace(/###/g, '.') : key;
    }
    function canNotTraverseDeeper() {
        return !object || typeof object === 'string';
    }
    const stack = typeof path !== 'string' ? [].concat(path) : path.split('.');
    while(stack.length > 1){
        if (canNotTraverseDeeper()) return {
        };
        const key = cleanKey(stack.shift());
        if (!object[key] && Empty) object[key] = new Empty();
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            object = object[key];
        } else {
            object = {
            };
        }
    }
    if (canNotTraverseDeeper()) return {
    };
    return {
        obj: object,
        k: cleanKey(stack.shift())
    };
}
function setPath(object, path, newValue) {
    const { obj , k  } = getLastOfPath(object, path, Object);
    obj[k] = newValue;
}
function pushPath(object, path, newValue, concat) {
    const { obj , k  } = getLastOfPath(object, path, Object);
    obj[k] = obj[k] || [];
    if (concat) obj[k] = obj[k].concat(newValue);
    if (!concat) obj[k].push(newValue);
}
function getPath(object, path) {
    const { obj , k  } = getLastOfPath(object, path);
    if (!obj) return undefined;
    return obj[k];
}
function getPathWithDefaults(data, defaultData, key) {
    const value = getPath(data, key);
    if (value !== undefined) {
        return value;
    }
    return getPath(defaultData, key);
}
function deepExtend(target, source, overwrite) {
    for(const prop in source){
        if (prop !== '__proto__' && prop !== 'constructor') {
            if (prop in target) {
                if (typeof target[prop] === 'string' || target[prop] instanceof String || typeof source[prop] === 'string' || source[prop] instanceof String) {
                    if (overwrite) target[prop] = source[prop];
                } else {
                    deepExtend(target[prop], source[prop], overwrite);
                }
            } else {
                target[prop] = source[prop];
            }
        }
    }
    return target;
}
function regexEscape(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}
var _entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
};
function escape(data) {
    if (typeof data === 'string') {
        return data.replace(/[&<>"'\/]/g, (s)=>_entityMap[s]
        );
    }
    return data;
}
const isIE10 = typeof window !== 'undefined' && window.navigator && window.navigator.userAgent && window.navigator.userAgent.indexOf('MSIE') > -1;
const chars = [
    ' ',
    ',',
    '?',
    '!',
    ';'
];
function looksLikeObjectPath(key, nsSeparator, keySeparator) {
    nsSeparator = nsSeparator || '';
    keySeparator = keySeparator || '';
    const possibleChars = chars.filter((c)=>nsSeparator.indexOf(c) < 0 && keySeparator.indexOf(c) < 0
    );
    if (possibleChars.length === 0) return true;
    const r = new RegExp(`(${possibleChars.map((c)=>c === '?' ? '\\?' : c
    ).join('|')})`);
    let matched = !r.test(key);
    if (!matched) {
        const ki = key.indexOf(keySeparator);
        if (ki > 0 && !r.test(key.substring(0, ki))) {
            matched = true;
        }
    }
    return matched;
}
function deepFind(obj, path, keySeparator = '.') {
    if (!obj) return undefined;
    if (obj[path]) return obj[path];
    const paths = path.split(keySeparator);
    let current = obj;
    for(let i = 0; i < paths.length; ++i){
        if (!current) return undefined;
        if (typeof current[paths[i]] === 'string' && i + 1 < paths.length) {
            return undefined;
        }
        if (current[paths[i]] === undefined) {
            let j = 2;
            let p = paths.slice(i, i + j).join(keySeparator);
            let mix = current[p];
            while(mix === undefined && paths.length > i + j){
                j++;
                p = paths.slice(i, i + j).join(keySeparator);
                mix = current[p];
            }
            if (mix === undefined) return undefined;
            if (path.endsWith(p)) {
                if (typeof mix === 'string') return mix;
                if (p && typeof mix[p] === 'string') return mix[p];
            }
            const joinedPath = paths.slice(i + j).join(keySeparator);
            if (joinedPath) return deepFind(mix, joinedPath, keySeparator);
            return undefined;
        }
        current = current[paths[i]];
    }
    return current;
}
class ResourceStore extends EventEmitter {
    constructor(data1, options2 = {
        ns: [
            'translation'
        ],
        defaultNS: 'translation'
    }){
        super();
        if (isIE10) {
            EventEmitter.call(this);
        }
        this.data = data1 || {
        };
        this.options = options2;
        if (this.options.keySeparator === undefined) {
            this.options.keySeparator = '.';
        }
        if (this.options.ignoreJSONStructure === undefined) {
            this.options.ignoreJSONStructure = true;
        }
    }
    addNamespaces(ns) {
        if (this.options.ns.indexOf(ns) < 0) {
            this.options.ns.push(ns);
        }
    }
    removeNamespaces(ns) {
        const index = this.options.ns.indexOf(ns);
        if (index > -1) {
            this.options.ns.splice(index, 1);
        }
    }
    getResource(lng, ns, key, options = {
    }) {
        const keySeparator = options.keySeparator !== undefined ? options.keySeparator : this.options.keySeparator;
        const ignoreJSONStructure = options.ignoreJSONStructure !== undefined ? options.ignoreJSONStructure : this.options.ignoreJSONStructure;
        let path = [
            lng,
            ns
        ];
        if (key && typeof key !== 'string') path = path.concat(key);
        if (key && typeof key === 'string') path = path.concat(keySeparator ? key.split(keySeparator) : key);
        if (lng.indexOf('.') > -1) {
            path = lng.split('.');
        }
        const result = getPath(this.data, path);
        if (result || !ignoreJSONStructure || typeof key !== 'string') return result;
        return deepFind(this.data && this.data[lng] && this.data[lng][ns], key, keySeparator);
    }
    addResource(lng, ns, key, value, options = {
        silent: false
    }) {
        let keySeparator = this.options.keySeparator;
        if (keySeparator === undefined) keySeparator = '.';
        let path = [
            lng,
            ns
        ];
        if (key) path = path.concat(keySeparator ? key.split(keySeparator) : key);
        if (lng.indexOf('.') > -1) {
            path = lng.split('.');
            value = ns;
            ns = path[1];
        }
        this.addNamespaces(ns);
        setPath(this.data, path, value);
        if (!options.silent) this.emit('added', lng, ns, key, value);
    }
    addResources(lng, ns, resources, options = {
        silent: false
    }) {
        for(const m in resources){
            if (typeof resources[m] === 'string' || Object.prototype.toString.apply(resources[m]) === '[object Array]') this.addResource(lng, ns, m, resources[m], {
                silent: true
            });
        }
        if (!options.silent) this.emit('added', lng, ns, resources);
    }
    addResourceBundle(lng, ns, resources, deep, overwrite, options = {
        silent: false
    }) {
        let path = [
            lng,
            ns
        ];
        if (lng.indexOf('.') > -1) {
            path = lng.split('.');
            deep = resources;
            resources = ns;
            ns = path[1];
        }
        this.addNamespaces(ns);
        let pack = getPath(this.data, path) || {
        };
        if (deep) {
            deepExtend(pack, resources, overwrite);
        } else {
            pack = {
                ...pack,
                ...resources
            };
        }
        setPath(this.data, path, pack);
        if (!options.silent) this.emit('added', lng, ns, resources);
    }
    removeResourceBundle(lng, ns) {
        if (this.hasResourceBundle(lng, ns)) {
            delete this.data[lng][ns];
        }
        this.removeNamespaces(ns);
        this.emit('removed', lng, ns);
    }
    hasResourceBundle(lng, ns) {
        return this.getResource(lng, ns) !== undefined;
    }
    getResourceBundle(lng, ns) {
        if (!ns) ns = this.options.defaultNS;
        if (this.options.compatibilityAPI === 'v1') return {
            ...{
            },
            ...this.getResource(lng, ns)
        };
        return this.getResource(lng, ns);
    }
    getDataByLanguage(lng) {
        return this.data[lng];
    }
    hasLanguageSomeTranslations(lng) {
        const data1 = this.getDataByLanguage(lng);
        const n = data1 && Object.keys(data1) || [];
        return !!n.find((v)=>data1[v] && Object.keys(data1[v]).length > 0
        );
    }
    toJSON() {
        return this.data;
    }
}
const __default1 = {
    processors: {
    },
    addPostProcessor (module) {
        this.processors[module.name] = module;
    },
    handle (processors, value, key, options, translator) {
        processors.forEach((processor)=>{
            if (this.processors[processor]) value = this.processors[processor].process(value, key, options, translator);
        });
        return value;
    }
};
const checkedLoadedFor = {
};
class Translator extends EventEmitter {
    constructor(services, options3 = {
    }){
        super();
        if (isIE10) {
            EventEmitter.call(this);
        }
        copy([
            'resourceStore',
            'languageUtils',
            'pluralResolver',
            'interpolator',
            'backendConnector',
            'i18nFormat',
            'utils', 
        ], services, this);
        this.options = options3;
        if (this.options.keySeparator === undefined) {
            this.options.keySeparator = '.';
        }
        this.logger = __default.create('translator');
    }
    changeLanguage(lng) {
        if (lng) this.language = lng;
    }
    exists(key, options = {
        interpolation: {
        }
    }) {
        if (key === undefined || key === null) {
            return false;
        }
        const resolved = this.resolve(key, options);
        return resolved && resolved.res !== undefined;
    }
    extractFromKey(key, options) {
        let nsSeparator = options.nsSeparator !== undefined ? options.nsSeparator : this.options.nsSeparator;
        if (nsSeparator === undefined) nsSeparator = ':';
        const keySeparator = options.keySeparator !== undefined ? options.keySeparator : this.options.keySeparator;
        let namespaces = options.ns || this.options.defaultNS || [];
        const wouldCheckForNsInKey = nsSeparator && key.indexOf(nsSeparator) > -1;
        const seemsNaturalLanguage = !this.options.userDefinedKeySeparator && !options.keySeparator && !this.options.userDefinedNsSeparator && !options.nsSeparator && !looksLikeObjectPath(key, nsSeparator, keySeparator);
        if (wouldCheckForNsInKey && !seemsNaturalLanguage) {
            const m = key.match(this.interpolator.nestingRegexp);
            if (m && m.length > 0) {
                return {
                    key,
                    namespaces
                };
            }
            const parts = key.split(nsSeparator);
            if (nsSeparator !== keySeparator || nsSeparator === keySeparator && this.options.ns.indexOf(parts[0]) > -1) namespaces = parts.shift();
            key = parts.join(keySeparator);
        }
        if (typeof namespaces === 'string') namespaces = [
            namespaces
        ];
        return {
            key,
            namespaces
        };
    }
    translate(keys, options, lastKey) {
        if (typeof options !== 'object' && this.options.overloadTranslationOptionHandler) {
            options = this.options.overloadTranslationOptionHandler(arguments);
        }
        if (!options) options = {
        };
        if (keys === undefined || keys === null) return '';
        if (!Array.isArray(keys)) keys = [
            String(keys)
        ];
        const keySeparator = options.keySeparator !== undefined ? options.keySeparator : this.options.keySeparator;
        const { key , namespaces  } = this.extractFromKey(keys[keys.length - 1], options);
        const namespace = namespaces[namespaces.length - 1];
        const lng = options.lng || this.language;
        const appendNamespaceToCIMode = options.appendNamespaceToCIMode || this.options.appendNamespaceToCIMode;
        if (lng && lng.toLowerCase() === 'cimode') {
            if (appendNamespaceToCIMode) {
                const nsSeparator = options.nsSeparator || this.options.nsSeparator;
                return namespace + nsSeparator + key;
            }
            return key;
        }
        const resolved = this.resolve(keys, options);
        let res = resolved && resolved.res;
        const resUsedKey = resolved && resolved.usedKey || key;
        const resExactUsedKey = resolved && resolved.exactUsedKey || key;
        const resType = Object.prototype.toString.apply(res);
        const noObject = [
            '[object Number]',
            '[object Function]',
            '[object RegExp]'
        ];
        const joinArrays = options.joinArrays !== undefined ? options.joinArrays : this.options.joinArrays;
        const handleAsObjectInI18nFormat = !this.i18nFormat || this.i18nFormat.handleAsObject;
        const handleAsObject = typeof res !== 'string' && typeof res !== 'boolean' && typeof res !== 'number';
        if (handleAsObjectInI18nFormat && res && handleAsObject && noObject.indexOf(resType) < 0 && !(typeof joinArrays === 'string' && resType === '[object Array]')) {
            if (!options.returnObjects && !this.options.returnObjects) {
                if (!this.options.returnedObjectHandler) {
                    this.logger.warn('accessing an object - but returnObjects options is not enabled!');
                }
                return this.options.returnedObjectHandler ? this.options.returnedObjectHandler(resUsedKey, res, {
                    ...options,
                    ns: namespaces
                }) : `key '${key} (${this.language})' returned an object instead of string.`;
            }
            if (keySeparator) {
                const resTypeIsArray = resType === '[object Array]';
                const copy1 = resTypeIsArray ? [] : {
                };
                let newKeyToUse = resTypeIsArray ? resExactUsedKey : resUsedKey;
                for(const m in res){
                    if (Object.prototype.hasOwnProperty.call(res, m)) {
                        const deepKey = `${newKeyToUse}${keySeparator}${m}`;
                        copy1[m] = this.translate(deepKey, {
                            ...options,
                            ...{
                                joinArrays: false,
                                ns: namespaces
                            }
                        });
                        if (copy1[m] === deepKey) copy1[m] = res[m];
                    }
                }
                res = copy1;
            }
        } else if (handleAsObjectInI18nFormat && typeof joinArrays === 'string' && resType === '[object Array]') {
            res = res.join(joinArrays);
            if (res) res = this.extendTranslation(res, keys, options, lastKey);
        } else {
            let usedDefault = false;
            let usedKey = false;
            const needsPluralHandling = options.count !== undefined && typeof options.count !== 'string';
            const hasDefaultValue = Translator.hasDefaultValue(options);
            const defaultValueSuffix = needsPluralHandling ? this.pluralResolver.getSuffix(lng, options.count, options) : '';
            const defaultValue = options[`defaultValue${defaultValueSuffix}`] || options.defaultValue;
            if (!this.isValidLookup(res) && hasDefaultValue) {
                usedDefault = true;
                res = defaultValue;
            }
            if (!this.isValidLookup(res)) {
                usedKey = true;
                res = key;
            }
            const missingKeyNoValueFallbackToKey = options.missingKeyNoValueFallbackToKey || this.options.missingKeyNoValueFallbackToKey;
            const resForMissing = missingKeyNoValueFallbackToKey && usedKey ? undefined : res;
            const updateMissing = hasDefaultValue && defaultValue !== res && this.options.updateMissing;
            if (usedKey || usedDefault || updateMissing) {
                this.logger.log(updateMissing ? 'updateKey' : 'missingKey', lng, namespace, key, updateMissing ? defaultValue : res);
                if (keySeparator) {
                    const fk = this.resolve(key, {
                        ...options,
                        keySeparator: false
                    });
                    if (fk && fk.res) this.logger.warn('Seems the loaded translations were in flat JSON format instead of nested. Either set keySeparator: false on init or make sure your translations are published in nested format.');
                }
                let lngs = [];
                const fallbackLngs = this.languageUtils.getFallbackCodes(this.options.fallbackLng, options.lng || this.language);
                if (this.options.saveMissingTo === 'fallback' && fallbackLngs && fallbackLngs[0]) {
                    for(let i = 0; i < fallbackLngs.length; i++){
                        lngs.push(fallbackLngs[i]);
                    }
                } else if (this.options.saveMissingTo === 'all') {
                    lngs = this.languageUtils.toResolveHierarchy(options.lng || this.language);
                } else {
                    lngs.push(options.lng || this.language);
                }
                const send = (l, k, specificDefaultValue)=>{
                    const defaultForMissing = hasDefaultValue && specificDefaultValue !== res ? specificDefaultValue : resForMissing;
                    if (this.options.missingKeyHandler) {
                        this.options.missingKeyHandler(l, namespace, k, defaultForMissing, updateMissing, options);
                    } else if (this.backendConnector && this.backendConnector.saveMissing) {
                        this.backendConnector.saveMissing(l, namespace, k, defaultForMissing, updateMissing, options);
                    }
                    this.emit('missingKey', l, namespace, k, res);
                };
                if (this.options.saveMissing) {
                    if (this.options.saveMissingPlurals && needsPluralHandling) {
                        lngs.forEach((language)=>{
                            this.pluralResolver.getSuffixes(language).forEach((suffix)=>{
                                send([
                                    language
                                ], key + suffix, options[`defaultValue${suffix}`] || defaultValue);
                            });
                        });
                    } else {
                        send(lngs, key, defaultValue);
                    }
                }
            }
            res = this.extendTranslation(res, keys, options, resolved, lastKey);
            if (usedKey && res === key && this.options.appendNamespaceToMissingKey) res = `${namespace}:${key}`;
            if ((usedKey || usedDefault) && this.options.parseMissingKeyHandler) res = this.options.parseMissingKeyHandler(res);
        }
        return res;
    }
    extendTranslation(res, key, options, resolved, lastKey) {
        if (this.i18nFormat && this.i18nFormat.parse) {
            res = this.i18nFormat.parse(res, options, resolved.usedLng, resolved.usedNS, resolved.usedKey, {
                resolved
            });
        } else if (!options.skipInterpolation) {
            if (options.interpolation) this.interpolator.init({
                ...options,
                ...{
                    interpolation: {
                        ...this.options.interpolation,
                        ...options.interpolation
                    }
                }
            });
            const skipOnVariables = typeof res === 'string' && (options.interpolation && options.interpolation.skipOnVariables || this.options.interpolation.skipOnVariables);
            let nestBef;
            if (skipOnVariables) {
                const nb = res.match(this.interpolator.nestingRegexp);
                nestBef = nb && nb.length;
            }
            let data1 = options.replace && typeof options.replace !== 'string' ? options.replace : options;
            if (this.options.interpolation.defaultVariables) data1 = {
                ...this.options.interpolation.defaultVariables,
                ...data1
            };
            res = this.interpolator.interpolate(res, data1, options.lng || this.language, options);
            if (skipOnVariables) {
                const na = res.match(this.interpolator.nestingRegexp);
                const nestAft = na && na.length;
                if (nestBef < nestAft) options.nest = false;
            }
            if (options.nest !== false) res = this.interpolator.nest(res, (...args)=>{
                if (lastKey && lastKey[0] === args[0] && !options.context) {
                    this.logger.warn(`It seems you are nesting recursively key: ${args[0]} in key: ${key[0]}`);
                    return null;
                }
                return this.translate(...args, key);
            }, options);
            if (options.interpolation) this.interpolator.reset();
        }
        const postProcess = options.postProcess || this.options.postProcess;
        const postProcessorNames = typeof postProcess === 'string' ? [
            postProcess
        ] : postProcess;
        if (res !== undefined && res !== null && postProcessorNames && postProcessorNames.length && options.applyPostProcessor !== false) {
            res = __default1.handle(postProcessorNames, res, key, this.options && this.options.postProcessPassResolved ? {
                i18nResolved: resolved,
                ...options
            } : options, this);
        }
        return res;
    }
    resolve(keys, options = {
    }) {
        let found;
        let usedKey;
        let exactUsedKey;
        let usedLng;
        let usedNS;
        if (typeof keys === 'string') keys = [
            keys
        ];
        keys.forEach((k)=>{
            if (this.isValidLookup(found)) return;
            const extracted = this.extractFromKey(k, options);
            const key = extracted.key;
            usedKey = key;
            let namespaces = extracted.namespaces;
            if (this.options.fallbackNS) namespaces = namespaces.concat(this.options.fallbackNS);
            const needsPluralHandling = options.count !== undefined && typeof options.count !== 'string';
            const needsZeroSuffixLookup = needsPluralHandling && !options.ordinal && options.count === 0 && this.pluralResolver.shouldUseIntlApi();
            const needsContextHandling = options.context !== undefined && (typeof options.context === 'string' || typeof options.context === 'number') && options.context !== '';
            const codes = options.lngs ? options.lngs : this.languageUtils.toResolveHierarchy(options.lng || this.language, options.fallbackLng);
            namespaces.forEach((ns)=>{
                if (this.isValidLookup(found)) return;
                usedNS = ns;
                if (!checkedLoadedFor[`${codes[0]}-${ns}`] && this.utils && this.utils.hasLoadedNamespace && !this.utils.hasLoadedNamespace(usedNS)) {
                    checkedLoadedFor[`${codes[0]}-${ns}`] = true;
                    this.logger.warn(`key "${usedKey}" for languages "${codes.join(', ')}" won't get resolved as namespace "${usedNS}" was not yet loaded`, 'This means something IS WRONG in your setup. You access the t function before i18next.init / i18next.loadNamespace / i18next.changeLanguage was done. Wait for the callback or Promise to resolve before accessing it!!!');
                }
                codes.forEach((code)=>{
                    if (this.isValidLookup(found)) return;
                    usedLng = code;
                    const finalKeys = [
                        key
                    ];
                    if (this.i18nFormat && this.i18nFormat.addLookupKeys) {
                        this.i18nFormat.addLookupKeys(finalKeys, key, code, ns, options);
                    } else {
                        let pluralSuffix;
                        if (needsPluralHandling) pluralSuffix = this.pluralResolver.getSuffix(code, options.count, options);
                        const zeroSuffix = '_zero';
                        if (needsPluralHandling) {
                            finalKeys.push(key + pluralSuffix);
                            if (needsZeroSuffixLookup) {
                                finalKeys.push(key + zeroSuffix);
                            }
                        }
                        if (needsContextHandling) {
                            const contextKey = `${key}${this.options.contextSeparator}${options.context}`;
                            finalKeys.push(contextKey);
                            if (needsPluralHandling) {
                                finalKeys.push(contextKey + pluralSuffix);
                                if (needsZeroSuffixLookup) {
                                    finalKeys.push(contextKey + zeroSuffix);
                                }
                            }
                        }
                    }
                    let possibleKey;
                    while(possibleKey = finalKeys.pop()){
                        if (!this.isValidLookup(found)) {
                            exactUsedKey = possibleKey;
                            found = this.getResource(code, ns, possibleKey, options);
                        }
                    }
                });
            });
        });
        return {
            res: found,
            usedKey,
            exactUsedKey,
            usedLng,
            usedNS
        };
    }
    isValidLookup(res) {
        return res !== undefined && !(!this.options.returnNull && res === null) && !(!this.options.returnEmptyString && res === '');
    }
    getResource(code, ns, key, options = {
    }) {
        if (this.i18nFormat && this.i18nFormat.getResource) return this.i18nFormat.getResource(code, ns, key, options);
        return this.resourceStore.getResource(code, ns, key, options);
    }
    static hasDefaultValue(options) {
        const prefix = 'defaultValue';
        for(const option in options){
            if (Object.prototype.hasOwnProperty.call(options, option) && prefix === option.substring(0, prefix.length) && undefined !== options[option]) {
                return true;
            }
        }
        return false;
    }
}
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
class LanguageUtil {
    constructor(options4){
        this.options = options4;
        this.supportedLngs = this.options.supportedLngs || false;
        this.logger = __default.create('languageUtils');
    }
    getScriptPartFromCode(code) {
        if (!code || code.indexOf('-') < 0) return null;
        const p = code.split('-');
        if (p.length === 2) return null;
        p.pop();
        if (p[p.length - 1].toLowerCase() === 'x') return null;
        return this.formatLanguageCode(p.join('-'));
    }
    getLanguagePartFromCode(code) {
        if (!code || code.indexOf('-') < 0) return code;
        const p = code.split('-');
        return this.formatLanguageCode(p[0]);
    }
    formatLanguageCode(code) {
        if (typeof code === 'string' && code.indexOf('-') > -1) {
            const specialChips = [
                'hans',
                'hant',
                'latn',
                'cyrl',
                'cans',
                'mong',
                'arab'
            ];
            let p = code.split('-');
            if (this.options.lowerCaseLng) {
                p = p.map((part)=>part.toLowerCase()
                );
            } else if (p.length === 2) {
                p[0] = p[0].toLowerCase();
                p[1] = p[1].toUpperCase();
                if (specialChips.indexOf(p[1].toLowerCase()) > -1) p[1] = capitalize(p[1].toLowerCase());
            } else if (p.length === 3) {
                p[0] = p[0].toLowerCase();
                if (p[1].length === 2) p[1] = p[1].toUpperCase();
                if (p[0] !== 'sgn' && p[2].length === 2) p[2] = p[2].toUpperCase();
                if (specialChips.indexOf(p[1].toLowerCase()) > -1) p[1] = capitalize(p[1].toLowerCase());
                if (specialChips.indexOf(p[2].toLowerCase()) > -1) p[2] = capitalize(p[2].toLowerCase());
            }
            return p.join('-');
        }
        return this.options.cleanCode || this.options.lowerCaseLng ? code.toLowerCase() : code;
    }
    isSupportedCode(code) {
        if (this.options.load === 'languageOnly' || this.options.nonExplicitSupportedLngs) {
            code = this.getLanguagePartFromCode(code);
        }
        return !this.supportedLngs || !this.supportedLngs.length || this.supportedLngs.indexOf(code) > -1;
    }
    getBestMatchFromCodes(codes) {
        if (!codes) return null;
        let found;
        codes.forEach((code)=>{
            if (found) return;
            let cleanedLng = this.formatLanguageCode(code);
            if (!this.options.supportedLngs || this.isSupportedCode(cleanedLng)) found = cleanedLng;
        });
        if (!found && this.options.supportedLngs) {
            codes.forEach((code)=>{
                if (found) return;
                let lngOnly = this.getLanguagePartFromCode(code);
                if (this.isSupportedCode(lngOnly)) return found = lngOnly;
                found = this.options.supportedLngs.find((supportedLng)=>{
                    if (supportedLng.indexOf(lngOnly) === 0) return supportedLng;
                });
            });
        }
        if (!found) found = this.getFallbackCodes(this.options.fallbackLng)[0];
        return found;
    }
    getFallbackCodes(fallbacks, code) {
        if (!fallbacks) return [];
        if (typeof fallbacks === 'function') fallbacks = fallbacks(code);
        if (typeof fallbacks === 'string') fallbacks = [
            fallbacks
        ];
        if (Object.prototype.toString.apply(fallbacks) === '[object Array]') return fallbacks;
        if (!code) return fallbacks.default || [];
        let found = fallbacks[code];
        if (!found) found = fallbacks[this.getScriptPartFromCode(code)];
        if (!found) found = fallbacks[this.formatLanguageCode(code)];
        if (!found) found = fallbacks[this.getLanguagePartFromCode(code)];
        if (!found) found = fallbacks.default;
        return found || [];
    }
    toResolveHierarchy(code, fallbackCode) {
        const fallbackCodes = this.getFallbackCodes(fallbackCode || this.options.fallbackLng || [], code);
        const codes = [];
        const addCode = (c)=>{
            if (!c) return;
            if (this.isSupportedCode(c)) {
                codes.push(c);
            } else {
                this.logger.warn(`rejecting language code not found in supportedLngs: ${c}`);
            }
        };
        if (typeof code === 'string' && code.indexOf('-') > -1) {
            if (this.options.load !== 'languageOnly') addCode(this.formatLanguageCode(code));
            if (this.options.load !== 'languageOnly' && this.options.load !== 'currentOnly') addCode(this.getScriptPartFromCode(code));
            if (this.options.load !== 'currentOnly') addCode(this.getLanguagePartFromCode(code));
        } else if (typeof code === 'string') {
            addCode(this.formatLanguageCode(code));
        }
        fallbackCodes.forEach((fc)=>{
            if (codes.indexOf(fc) < 0) addCode(this.formatLanguageCode(fc));
        });
        return codes;
    }
}
let sets = [
    {
        lngs: [
            'ach',
            'ak',
            'am',
            'arn',
            'br',
            'fil',
            'gun',
            'ln',
            'mfe',
            'mg',
            'mi',
            'oc',
            'pt',
            'pt-BR',
            'tg',
            'tl',
            'ti',
            'tr',
            'uz',
            'wa'
        ],
        nr: [
            1,
            2
        ],
        fc: 1
    },
    {
        lngs: [
            'af',
            'an',
            'ast',
            'az',
            'bg',
            'bn',
            'ca',
            'da',
            'de',
            'dev',
            'el',
            'en',
            'eo',
            'es',
            'et',
            'eu',
            'fi',
            'fo',
            'fur',
            'fy',
            'gl',
            'gu',
            'ha',
            'hi',
            'hu',
            'hy',
            'ia',
            'it',
            'kk',
            'kn',
            'ku',
            'lb',
            'mai',
            'ml',
            'mn',
            'mr',
            'nah',
            'nap',
            'nb',
            'ne',
            'nl',
            'nn',
            'no',
            'nso',
            'pa',
            'pap',
            'pms',
            'ps',
            'pt-PT',
            'rm',
            'sco',
            'se',
            'si',
            'so',
            'son',
            'sq',
            'sv',
            'sw',
            'ta',
            'te',
            'tk',
            'ur',
            'yo'
        ],
        nr: [
            1,
            2
        ],
        fc: 2
    },
    {
        lngs: [
            'ay',
            'bo',
            'cgg',
            'fa',
            'ht',
            'id',
            'ja',
            'jbo',
            'ka',
            'km',
            'ko',
            'ky',
            'lo',
            'ms',
            'sah',
            'su',
            'th',
            'tt',
            'ug',
            'vi',
            'wo',
            'zh'
        ],
        nr: [
            1
        ],
        fc: 3
    },
    {
        lngs: [
            'be',
            'bs',
            'cnr',
            'dz',
            'hr',
            'ru',
            'sr',
            'uk'
        ],
        nr: [
            1,
            2,
            5
        ],
        fc: 4
    },
    {
        lngs: [
            'ar'
        ],
        nr: [
            0,
            1,
            2,
            3,
            11,
            100
        ],
        fc: 5
    },
    {
        lngs: [
            'cs',
            'sk'
        ],
        nr: [
            1,
            2,
            5
        ],
        fc: 6
    },
    {
        lngs: [
            'csb',
            'pl'
        ],
        nr: [
            1,
            2,
            5
        ],
        fc: 7
    },
    {
        lngs: [
            'cy'
        ],
        nr: [
            1,
            2,
            3,
            8
        ],
        fc: 8
    },
    {
        lngs: [
            'fr'
        ],
        nr: [
            1,
            2
        ],
        fc: 9
    },
    {
        lngs: [
            'ga'
        ],
        nr: [
            1,
            2,
            3,
            7,
            11
        ],
        fc: 10
    },
    {
        lngs: [
            'gd'
        ],
        nr: [
            1,
            2,
            3,
            20
        ],
        fc: 11
    },
    {
        lngs: [
            'is'
        ],
        nr: [
            1,
            2
        ],
        fc: 12
    },
    {
        lngs: [
            'jv'
        ],
        nr: [
            0,
            1
        ],
        fc: 13
    },
    {
        lngs: [
            'kw'
        ],
        nr: [
            1,
            2,
            3,
            4
        ],
        fc: 14
    },
    {
        lngs: [
            'lt'
        ],
        nr: [
            1,
            2,
            10
        ],
        fc: 15
    },
    {
        lngs: [
            'lv'
        ],
        nr: [
            1,
            2,
            0
        ],
        fc: 16
    },
    {
        lngs: [
            'mk'
        ],
        nr: [
            1,
            2
        ],
        fc: 17
    },
    {
        lngs: [
            'mnk'
        ],
        nr: [
            0,
            1,
            2
        ],
        fc: 18
    },
    {
        lngs: [
            'mt'
        ],
        nr: [
            1,
            2,
            11,
            20
        ],
        fc: 19
    },
    {
        lngs: [
            'or'
        ],
        nr: [
            2,
            1
        ],
        fc: 2
    },
    {
        lngs: [
            'ro'
        ],
        nr: [
            1,
            2,
            20
        ],
        fc: 20
    },
    {
        lngs: [
            'sl'
        ],
        nr: [
            5,
            1,
            2,
            3
        ],
        fc: 21
    },
    {
        lngs: [
            'he',
            'iw'
        ],
        nr: [
            1,
            2,
            20,
            21
        ],
        fc: 22
    }
];
let _rulesPluralsTypes = {
    1: function(n) {
        return Number(n > 1);
    },
    2: function(n) {
        return Number(n != 1);
    },
    3: function(n) {
        return 0;
    },
    4: function(n) {
        return Number(n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2);
    },
    5: function(n) {
        return Number(n == 0 ? 0 : n == 1 ? 1 : n == 2 ? 2 : n % 100 >= 3 && n % 100 <= 10 ? 3 : n % 100 >= 11 ? 4 : 5);
    },
    6: function(n) {
        return Number(n == 1 ? 0 : n >= 2 && n <= 4 ? 1 : 2);
    },
    7: function(n) {
        return Number(n == 1 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2);
    },
    8: function(n) {
        return Number(n == 1 ? 0 : n == 2 ? 1 : n != 8 && n != 11 ? 2 : 3);
    },
    9: function(n) {
        return Number(n >= 2);
    },
    10: function(n) {
        return Number(n == 1 ? 0 : n == 2 ? 1 : n < 7 ? 2 : n < 11 ? 3 : 4);
    },
    11: function(n) {
        return Number(n == 1 || n == 11 ? 0 : n == 2 || n == 12 ? 1 : n > 2 && n < 20 ? 2 : 3);
    },
    12: function(n) {
        return Number(n % 10 != 1 || n % 100 == 11);
    },
    13: function(n) {
        return Number(n !== 0);
    },
    14: function(n) {
        return Number(n == 1 ? 0 : n == 2 ? 1 : n == 3 ? 2 : 3);
    },
    15: function(n) {
        return Number(n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2);
    },
    16: function(n) {
        return Number(n % 10 == 1 && n % 100 != 11 ? 0 : n !== 0 ? 1 : 2);
    },
    17: function(n) {
        return Number(n == 1 || n % 10 == 1 && n % 100 != 11 ? 0 : 1);
    },
    18: function(n) {
        return Number(n == 0 ? 0 : n == 1 ? 1 : 2);
    },
    19: function(n) {
        return Number(n == 1 ? 0 : n == 0 || n % 100 > 1 && n % 100 < 11 ? 1 : n % 100 > 10 && n % 100 < 20 ? 2 : 3);
    },
    20: function(n) {
        return Number(n == 1 ? 0 : n == 0 || n % 100 > 0 && n % 100 < 20 ? 1 : 2);
    },
    21: function(n) {
        return Number(n % 100 == 1 ? 1 : n % 100 == 2 ? 2 : n % 100 == 3 || n % 100 == 4 ? 3 : 0);
    },
    22: function(n) {
        return Number(n == 1 ? 0 : n == 2 ? 1 : (n < 0 || n > 10) && n % 10 == 0 ? 2 : 3);
    }
};
const deprecatedJsonVersions = [
    'v1',
    'v2',
    'v3'
];
const suffixesOrder = {
    zero: 0,
    one: 1,
    two: 2,
    few: 3,
    many: 4,
    other: 5
};
function createRules() {
    const rules = {
    };
    sets.forEach((set)=>{
        set.lngs.forEach((l)=>{
            rules[l] = {
                numbers: set.nr,
                plurals: _rulesPluralsTypes[set.fc]
            };
        });
    });
    return rules;
}
class PluralResolver {
    constructor(languageUtils, options5 = {
    }){
        this.languageUtils = languageUtils;
        this.options = options5;
        this.logger = __default.create('pluralResolver');
        if ((!this.options.compatibilityJSON || this.options.compatibilityJSON === 'v4') && (typeof Intl === 'undefined' || !Intl.PluralRules)) {
            this.options.compatibilityJSON = 'v3';
            this.logger.error('Your environment seems not to be Intl API compatible, use an Intl.PluralRules polyfill. Will fallback to the compatibilityJSON v3 format handling.');
        }
        this.rules = createRules();
    }
    addRule(lng, obj) {
        this.rules[lng] = obj;
    }
    getRule(code, options = {
    }) {
        if (this.shouldUseIntlApi()) {
            try {
                return new Intl.PluralRules(code, {
                    type: options.ordinal ? 'ordinal' : 'cardinal'
                });
            } catch  {
                return;
            }
        }
        return this.rules[code] || this.rules[this.languageUtils.getLanguagePartFromCode(code)];
    }
    needsPlural(code, options = {
    }) {
        const rule = this.getRule(code, options);
        if (this.shouldUseIntlApi()) {
            return rule && rule.resolvedOptions().pluralCategories.length > 1;
        }
        return rule && rule.numbers.length > 1;
    }
    getPluralFormsOfKey(code, key, options = {
    }) {
        return this.getSuffixes(code, options).map((suffix)=>`${key}${suffix}`
        );
    }
    getSuffixes(code, options = {
    }) {
        const rule = this.getRule(code, options);
        if (!rule) {
            return [];
        }
        if (this.shouldUseIntlApi()) {
            return rule.resolvedOptions().pluralCategories.sort((pluralCategory1, pluralCategory2)=>suffixesOrder[pluralCategory1] - suffixesOrder[pluralCategory2]
            ).map((pluralCategory)=>`${this.options.prepend}${pluralCategory}`
            );
        }
        return rule.numbers.map((number)=>this.getSuffix(code, number, options)
        );
    }
    getSuffix(code, count, options = {
    }) {
        const rule = this.getRule(code, options);
        if (rule) {
            if (this.shouldUseIntlApi()) {
                return `${this.options.prepend}${rule.select(count)}`;
            }
            return this.getSuffixRetroCompatible(rule, count);
        }
        this.logger.warn(`no plural rule found for: ${code}`);
        return '';
    }
    getSuffixRetroCompatible(rule, count) {
        const idx = rule.noAbs ? rule.plurals(count) : rule.plurals(Math.abs(count));
        let suffix = rule.numbers[idx];
        if (this.options.simplifyPluralSuffix && rule.numbers.length === 2 && rule.numbers[0] === 1) {
            if (suffix === 2) {
                suffix = 'plural';
            } else if (suffix === 1) {
                suffix = '';
            }
        }
        const returnSuffix = ()=>this.options.prepend && suffix.toString() ? this.options.prepend + suffix.toString() : suffix.toString()
        ;
        if (this.options.compatibilityJSON === 'v1') {
            if (suffix === 1) return '';
            if (typeof suffix === 'number') return `_plural_${suffix.toString()}`;
            return returnSuffix();
        } else if (this.options.compatibilityJSON === 'v2') {
            return returnSuffix();
        } else if (this.options.simplifyPluralSuffix && rule.numbers.length === 2 && rule.numbers[0] === 1) {
            return returnSuffix();
        }
        return this.options.prepend && idx.toString() ? this.options.prepend + idx.toString() : idx.toString();
    }
    shouldUseIntlApi() {
        return !deprecatedJsonVersions.includes(this.options.compatibilityJSON);
    }
}
class Interpolator {
    constructor(options6 = {
    }){
        this.logger = __default.create('interpolator');
        this.options = options6;
        this.format = options6.interpolation && options6.interpolation.format || ((value)=>value
        );
        this.init(options6);
    }
    init(options = {
    }) {
        if (!options.interpolation) options.interpolation = {
            escapeValue: true
        };
        const iOpts = options.interpolation;
        this.escape = iOpts.escape !== undefined ? iOpts.escape : escape;
        this.escapeValue = iOpts.escapeValue !== undefined ? iOpts.escapeValue : true;
        this.useRawValueToEscape = iOpts.useRawValueToEscape !== undefined ? iOpts.useRawValueToEscape : false;
        this.prefix = iOpts.prefix ? regexEscape(iOpts.prefix) : iOpts.prefixEscaped || '{{';
        this.suffix = iOpts.suffix ? regexEscape(iOpts.suffix) : iOpts.suffixEscaped || '}}';
        this.formatSeparator = iOpts.formatSeparator ? iOpts.formatSeparator : iOpts.formatSeparator || ',';
        this.unescapePrefix = iOpts.unescapeSuffix ? '' : iOpts.unescapePrefix || '-';
        this.unescapeSuffix = this.unescapePrefix ? '' : iOpts.unescapeSuffix || '';
        this.nestingPrefix = iOpts.nestingPrefix ? regexEscape(iOpts.nestingPrefix) : iOpts.nestingPrefixEscaped || regexEscape('$t(');
        this.nestingSuffix = iOpts.nestingSuffix ? regexEscape(iOpts.nestingSuffix) : iOpts.nestingSuffixEscaped || regexEscape(')');
        this.nestingOptionsSeparator = iOpts.nestingOptionsSeparator ? iOpts.nestingOptionsSeparator : iOpts.nestingOptionsSeparator || ',';
        this.maxReplaces = iOpts.maxReplaces ? iOpts.maxReplaces : 1000;
        this.alwaysFormat = iOpts.alwaysFormat !== undefined ? iOpts.alwaysFormat : false;
        this.resetRegExp();
    }
    reset() {
        if (this.options) this.init(this.options);
    }
    resetRegExp() {
        const regexpStr = `${this.prefix}(.+?)${this.suffix}`;
        this.regexp = new RegExp(regexpStr, 'g');
        const regexpUnescapeStr = `${this.prefix}${this.unescapePrefix}(.+?)${this.unescapeSuffix}${this.suffix}`;
        this.regexpUnescape = new RegExp(regexpUnescapeStr, 'g');
        const nestingRegexpStr = `${this.nestingPrefix}(.+?)${this.nestingSuffix}`;
        this.nestingRegexp = new RegExp(nestingRegexpStr, 'g');
    }
    interpolate(str, data, lng, options) {
        let match;
        let value;
        let replaces;
        const defaultData = this.options && this.options.interpolation && this.options.interpolation.defaultVariables || {
        };
        function regexSafe(val) {
            return val.replace(/\$/g, '$$$$');
        }
        const handleFormat = (key)=>{
            if (key.indexOf(this.formatSeparator) < 0) {
                const path = getPathWithDefaults(data, defaultData, key);
                return this.alwaysFormat ? this.format(path, undefined, lng, {
                    ...options,
                    ...data,
                    interpolationkey: key
                }) : path;
            }
            const p = key.split(this.formatSeparator);
            const k = p.shift().trim();
            const f = p.join(this.formatSeparator).trim();
            return this.format(getPathWithDefaults(data, defaultData, k), f, lng, {
                ...options,
                ...data,
                interpolationkey: k
            });
        };
        this.resetRegExp();
        const missingInterpolationHandler = options && options.missingInterpolationHandler || this.options.missingInterpolationHandler;
        const skipOnVariables = options && options.interpolation && options.interpolation.skipOnVariables || this.options.interpolation.skipOnVariables;
        const todos = [
            {
                regex: this.regexpUnescape,
                safeValue: (val)=>regexSafe(val)
            },
            {
                regex: this.regexp,
                safeValue: (val)=>this.escapeValue ? regexSafe(this.escape(val)) : regexSafe(val)
            }, 
        ];
        todos.forEach((todo)=>{
            replaces = 0;
            while(match = todo.regex.exec(str)){
                value = handleFormat(match[1].trim());
                if (value === undefined) {
                    if (typeof missingInterpolationHandler === 'function') {
                        const temp = missingInterpolationHandler(str, match, options);
                        value = typeof temp === 'string' ? temp : '';
                    } else if (skipOnVariables) {
                        value = match[0];
                        continue;
                    } else {
                        this.logger.warn(`missed to pass in variable ${match[1]} for interpolating ${str}`);
                        value = '';
                    }
                } else if (typeof value !== 'string' && !this.useRawValueToEscape) {
                    value = makeString(value);
                }
                const safeValue = todo.safeValue(value);
                str = str.replace(match[0], safeValue);
                if (skipOnVariables) {
                    todo.regex.lastIndex += safeValue.length;
                    todo.regex.lastIndex -= match[0].length;
                } else {
                    todo.regex.lastIndex = 0;
                }
                replaces++;
                if (replaces >= this.maxReplaces) {
                    break;
                }
            }
        });
        return str;
    }
    nest(str, fc, options = {
    }) {
        let match;
        let value;
        let clonedOptions = {
            ...options
        };
        clonedOptions.applyPostProcessor = false;
        delete clonedOptions.defaultValue;
        function handleHasOptions(key, inheritedOptions) {
            const sep = this.nestingOptionsSeparator;
            if (key.indexOf(sep) < 0) return key;
            const c = key.split(new RegExp(`${sep}[ ]*{`));
            let optionsString = `{${c[1]}`;
            key = c[0];
            optionsString = this.interpolate(optionsString, clonedOptions);
            optionsString = optionsString.replace(/'/g, '"');
            try {
                clonedOptions = JSON.parse(optionsString);
                if (inheritedOptions) clonedOptions = {
                    ...inheritedOptions,
                    ...clonedOptions
                };
            } catch (e) {
                this.logger.warn(`failed parsing options string in nesting for key ${key}`, e);
                return `${key}${sep}${optionsString}`;
            }
            delete clonedOptions.defaultValue;
            return key;
        }
        while(match = this.nestingRegexp.exec(str)){
            let formatters = [];
            let doReduce = false;
            if (match[0].indexOf(this.formatSeparator) !== -1 && !/{.*}/.test(match[1])) {
                const r = match[1].split(this.formatSeparator).map((elem)=>elem.trim()
                );
                match[1] = r.shift();
                formatters = r;
                doReduce = true;
            }
            value = fc(handleHasOptions.call(this, match[1].trim(), clonedOptions), clonedOptions);
            if (value && match[0] === str && typeof value !== 'string') return value;
            if (typeof value !== 'string') value = makeString(value);
            if (!value) {
                this.logger.warn(`missed to resolve ${match[1]} for nesting ${str}`);
                value = '';
            }
            if (doReduce) {
                value = formatters.reduce((v, f)=>this.format(v, f, options.lng, {
                        ...options,
                        interpolationkey: match[1].trim()
                    })
                , value.trim());
            }
            str = str.replace(match[0], value);
            this.regexp.lastIndex = 0;
        }
        return str;
    }
}
function parseFormatStr(formatStr) {
    let formatName = formatStr.toLowerCase().trim();
    let formatOptions = {
    };
    if (formatStr.indexOf('(') > -1) {
        const p = formatStr.split('(');
        formatName = p[0].toLowerCase().trim();
        const optStr = p[1].substring(0, p[1].length - 1);
        if (formatName === 'currency' && optStr.indexOf(':') < 0) {
            if (!formatOptions.currency) formatOptions.currency = optStr.trim();
        } else if (formatName === 'relativetime' && optStr.indexOf(':') < 0) {
            if (!formatOptions.range) formatOptions.range = optStr.trim();
        } else {
            const opts = optStr.split(';');
            opts.forEach((opt)=>{
                if (!opt) return;
                const [key, ...rest] = opt.split(':');
                const val = rest.join(':');
                if (val.trim() === 'false') formatOptions[key.trim()] = false;
                if (val.trim() === 'true') formatOptions[key.trim()] = true;
                if (!isNaN(val.trim())) formatOptions[key.trim()] = parseInt(val.trim(), 10);
                if (!formatOptions[key.trim()]) formatOptions[key.trim()] = val.trim();
            });
        }
    }
    return {
        formatName,
        formatOptions
    };
}
class Formatter {
    constructor(options7 = {
    }){
        this.logger = __default.create('formatter');
        this.options = options7;
        this.formats = {
            number: (val, lng, options8)=>{
                return new Intl.NumberFormat(lng, options8).format(val);
            },
            currency: (val, lng, options8)=>{
                return new Intl.NumberFormat(lng, {
                    ...options8,
                    style: 'currency'
                }).format(val);
            },
            datetime: (val, lng, options8)=>{
                return new Intl.DateTimeFormat(lng, {
                    ...options8
                }).format(val);
            },
            relativetime: (val, lng, options8)=>{
                return new Intl.RelativeTimeFormat(lng, {
                    ...options8
                }).format(val, options8.range || 'day');
            },
            list: (val, lng, options8)=>{
                return new Intl.ListFormat(lng, {
                    ...options8
                }).format(val);
            }
        };
        this.init(options7);
    }
    init(services, options = {
        interpolation: {
        }
    }) {
        const iOpts = options.interpolation;
        this.formatSeparator = iOpts.formatSeparator ? iOpts.formatSeparator : iOpts.formatSeparator || ',';
    }
    add(name, fc) {
        this.formats[name] = fc;
    }
    format(value, format, lng, options) {
        const formats = format.split(this.formatSeparator);
        const result = formats.reduce((mem, f)=>{
            const { formatName , formatOptions  } = parseFormatStr(f);
            if (this.formats[formatName]) {
                let formatted = mem;
                try {
                    const valOptions = options && options.formatParams && options.formatParams[options.interpolationkey] || {
                    };
                    const l = valOptions.locale || valOptions.lng || options.locale || options.lng || lng;
                    formatted = this.formats[formatName](mem, l, {
                        ...formatOptions,
                        ...options,
                        ...valOptions
                    });
                } catch (error) {
                    this.logger.warn(error);
                }
                return formatted;
            } else {
                this.logger.warn(`there was no format function for ${formatName}`);
            }
            return mem;
        }, value);
        return result;
    }
}
function remove(arr, what) {
    let found = arr.indexOf(what);
    while(found !== -1){
        arr.splice(found, 1);
        found = arr.indexOf(what);
    }
}
class Connector extends EventEmitter {
    constructor(backend, store, services1, options8 = {
    }){
        super();
        if (isIE10) {
            EventEmitter.call(this);
        }
        this.backend = backend;
        this.store = store;
        this.services = services1;
        this.languageUtils = services1.languageUtils;
        this.options = options8;
        this.logger = __default.create('backendConnector');
        this.state = {
        };
        this.queue = [];
        if (this.backend && this.backend.init) {
            this.backend.init(services1, options8.backend, options8);
        }
    }
    queueLoad(languages, namespaces, options, callback) {
        const toLoad = [];
        const pending = [];
        const toLoadLanguages = [];
        const toLoadNamespaces = [];
        languages.forEach((lng)=>{
            let hasAllNamespaces = true;
            namespaces.forEach((ns)=>{
                const name = `${lng}|${ns}`;
                if (!options.reload && this.store.hasResourceBundle(lng, ns)) {
                    this.state[name] = 2;
                } else if (this.state[name] < 0) {
                } else if (this.state[name] === 1) {
                    if (pending.indexOf(name) < 0) pending.push(name);
                } else {
                    this.state[name] = 1;
                    hasAllNamespaces = false;
                    if (pending.indexOf(name) < 0) pending.push(name);
                    if (toLoad.indexOf(name) < 0) toLoad.push(name);
                    if (toLoadNamespaces.indexOf(ns) < 0) toLoadNamespaces.push(ns);
                }
            });
            if (!hasAllNamespaces) toLoadLanguages.push(lng);
        });
        if (toLoad.length || pending.length) {
            this.queue.push({
                pending,
                loaded: {
                },
                errors: [],
                callback
            });
        }
        return {
            toLoad,
            pending,
            toLoadLanguages,
            toLoadNamespaces
        };
    }
    loaded(name, err, data) {
        const s = name.split('|');
        const lng = s[0];
        const ns = s[1];
        if (err) this.emit('failedLoading', lng, ns, err);
        if (data) {
            this.store.addResourceBundle(lng, ns, data);
        }
        this.state[name] = err ? -1 : 2;
        const loaded = {
        };
        this.queue.forEach((q)=>{
            pushPath(q.loaded, [
                lng
            ], ns);
            remove(q.pending, name);
            if (err) q.errors.push(err);
            if (q.pending.length === 0 && !q.done) {
                Object.keys(q.loaded).forEach((l)=>{
                    if (!loaded[l]) loaded[l] = [];
                    if (q.loaded[l].length) {
                        q.loaded[l].forEach((ns1)=>{
                            if (loaded[l].indexOf(ns1) < 0) loaded[l].push(ns1);
                        });
                    }
                });
                q.done = true;
                if (q.errors.length) {
                    q.callback(q.errors);
                } else {
                    q.callback();
                }
            }
        });
        this.emit('loaded', loaded);
        this.queue = this.queue.filter((q)=>!q.done
        );
    }
    read(lng, ns, fcName, tried = 0, wait = 350, callback) {
        if (!lng.length) return callback(null, {
        });
        return this.backend[fcName](lng, ns, (err, data2)=>{
            if (err && data2 && tried < 5) {
                setTimeout(()=>{
                    this.read.call(this, lng, ns, fcName, tried + 1, wait * 2, callback);
                }, wait);
                return;
            }
            callback(err, data2);
        });
    }
    prepareLoading(languages, namespaces, options = {
    }, callback) {
        if (!this.backend) {
            this.logger.warn('No backend was added via i18next.use. Will not load resources.');
            return callback && callback();
        }
        if (typeof languages === 'string') languages = this.languageUtils.toResolveHierarchy(languages);
        if (typeof namespaces === 'string') namespaces = [
            namespaces
        ];
        const toLoad = this.queueLoad(languages, namespaces, options, callback);
        if (!toLoad.toLoad.length) {
            if (!toLoad.pending.length) callback();
            return null;
        }
        toLoad.toLoad.forEach((name)=>{
            this.loadOne(name);
        });
    }
    load(languages, namespaces, callback) {
        this.prepareLoading(languages, namespaces, {
        }, callback);
    }
    reload(languages, namespaces, callback) {
        this.prepareLoading(languages, namespaces, {
            reload: true
        }, callback);
    }
    loadOne(name, prefix = '') {
        const s = name.split('|');
        const lng = s[0];
        const ns = s[1];
        this.read(lng, ns, 'read', undefined, undefined, (err, data2)=>{
            if (err) this.logger.warn(`${prefix}loading namespace ${ns} for language ${lng} failed`, err);
            if (!err && data2) this.logger.log(`${prefix}loaded namespace ${ns} for language ${lng}`, data2);
            this.loaded(name, err, data2);
        });
    }
    saveMissing(languages, namespace, key, fallbackValue, isUpdate, options = {
    }) {
        if (this.services.utils && this.services.utils.hasLoadedNamespace && !this.services.utils.hasLoadedNamespace(namespace)) {
            this.logger.warn(`did not save key "${key}" as the namespace "${namespace}" was not yet loaded`, 'This means something IS WRONG in your setup. You access the t function before i18next.init / i18next.loadNamespace / i18next.changeLanguage was done. Wait for the callback or Promise to resolve before accessing it!!!');
            return;
        }
        if (key === undefined || key === null || key === '') return;
        if (this.backend && this.backend.create) {
            this.backend.create(languages, namespace, key, fallbackValue, null, {
                ...options,
                isUpdate
            });
        }
        if (!languages || !languages[0]) return;
        this.store.addResource(languages[0], namespace, key, fallbackValue);
    }
}
function get() {
    return {
        debug: false,
        initImmediate: true,
        ns: [
            'translation'
        ],
        defaultNS: [
            'translation'
        ],
        fallbackLng: [
            'dev'
        ],
        fallbackNS: false,
        supportedLngs: false,
        nonExplicitSupportedLngs: false,
        load: 'all',
        preload: false,
        simplifyPluralSuffix: true,
        keySeparator: '.',
        nsSeparator: ':',
        pluralSeparator: '_',
        contextSeparator: '_',
        partialBundledLanguages: false,
        saveMissing: false,
        updateMissing: false,
        saveMissingTo: 'fallback',
        saveMissingPlurals: true,
        missingKeyHandler: false,
        missingInterpolationHandler: false,
        postProcess: false,
        postProcessPassResolved: false,
        returnNull: true,
        returnEmptyString: true,
        returnObjects: false,
        joinArrays: false,
        returnedObjectHandler: false,
        parseMissingKeyHandler: false,
        appendNamespaceToMissingKey: false,
        appendNamespaceToCIMode: false,
        overloadTranslationOptionHandler: function handle(args) {
            var ret = {
            };
            if (typeof args[1] === 'object') ret = args[1];
            if (typeof args[1] === 'string') ret.defaultValue = args[1];
            if (typeof args[2] === 'string') ret.tDescription = args[2];
            if (typeof args[2] === 'object' || typeof args[3] === 'object') {
                var options9 = args[3] || args[2];
                Object.keys(options9).forEach(function(key) {
                    ret[key] = options9[key];
                });
            }
            return ret;
        },
        interpolation: {
            escapeValue: true,
            format: (value, format, lng, options10)=>value
            ,
            prefix: '{{',
            suffix: '}}',
            formatSeparator: ',',
            unescapePrefix: '-',
            nestingPrefix: '$t(',
            nestingSuffix: ')',
            nestingOptionsSeparator: ',',
            maxReplaces: 1000,
            skipOnVariables: true
        }
    };
}
function transformOptions(options10) {
    if (typeof options10.ns === 'string') options10.ns = [
        options10.ns
    ];
    if (typeof options10.fallbackLng === 'string') options10.fallbackLng = [
        options10.fallbackLng
    ];
    if (typeof options10.fallbackNS === 'string') options10.fallbackNS = [
        options10.fallbackNS
    ];
    if (options10.supportedLngs && options10.supportedLngs.indexOf('cimode') < 0) {
        options10.supportedLngs = options10.supportedLngs.concat([
            'cimode'
        ]);
    }
    return options10;
}
function noop() {
}
function bindMemberFunctions(inst) {
    const mems = Object.getOwnPropertyNames(Object.getPrototypeOf(inst));
    mems.forEach((mem)=>{
        if (typeof inst[mem] === 'function') {
            inst[mem] = inst[mem].bind(inst);
        }
    });
}
class I18n extends EventEmitter {
    constructor(options10 = {
    }, callback1){
        super();
        if (isIE10) {
            EventEmitter.call(this);
        }
        this.options = transformOptions(options10);
        this.services = {
        };
        this.logger = __default;
        this.modules = {
            external: []
        };
        bindMemberFunctions(this);
        if (callback1 && !this.isInitialized && !options10.isClone) {
            if (!this.options.initImmediate) {
                this.init(options10, callback1);
                return this;
            }
            setTimeout(()=>{
                this.init(options10, callback1);
            }, 0);
        }
    }
    init(options = {
    }, callback) {
        if (typeof options === 'function') {
            callback = options;
            options = {
            };
        }
        if (!options.defaultNS && options.ns) {
            if (typeof options.ns === 'string') {
                options.defaultNS = options.ns;
            } else if (options.ns.indexOf('translation') < 0) {
                options.defaultNS = options.ns[0];
            }
        }
        const defOpts = get();
        this.options = {
            ...defOpts,
            ...this.options,
            ...transformOptions(options)
        };
        if (this.options.compatibilityAPI !== 'v1') {
            this.options.interpolation = {
                ...defOpts.interpolation,
                ...this.options.interpolation
            };
        }
        if (options.keySeparator !== undefined) {
            this.options.userDefinedKeySeparator = options.keySeparator;
        }
        if (options.nsSeparator !== undefined) {
            this.options.userDefinedNsSeparator = options.nsSeparator;
        }
        function createClassOnDemand(ClassOrObject) {
            if (!ClassOrObject) return null;
            if (typeof ClassOrObject === 'function') return new ClassOrObject();
            return ClassOrObject;
        }
        if (!this.options.isClone) {
            if (this.modules.logger) {
                __default.init(createClassOnDemand(this.modules.logger), this.options);
            } else {
                __default.init(null, this.options);
            }
            let formatter;
            if (this.modules.formatter) {
                formatter = this.modules.formatter;
            } else if (typeof Intl !== 'undefined') {
                formatter = Formatter;
            }
            const lu = new LanguageUtil(this.options);
            this.store = new ResourceStore(this.options.resources, this.options);
            const s = this.services;
            s.logger = __default;
            s.resourceStore = this.store;
            s.languageUtils = lu;
            s.pluralResolver = new PluralResolver(lu, {
                prepend: this.options.pluralSeparator,
                compatibilityJSON: this.options.compatibilityJSON,
                simplifyPluralSuffix: this.options.simplifyPluralSuffix
            });
            if (formatter && (!this.options.interpolation.format || this.options.interpolation.format === defOpts.interpolation.format)) {
                s.formatter = createClassOnDemand(formatter);
                s.formatter.init(s, this.options);
                this.options.interpolation.format = s.formatter.format.bind(s.formatter);
            }
            s.interpolator = new Interpolator(this.options);
            s.utils = {
                hasLoadedNamespace: this.hasLoadedNamespace.bind(this)
            };
            s.backendConnector = new Connector(createClassOnDemand(this.modules.backend), s.resourceStore, s, this.options);
            s.backendConnector.on('*', (event, ...args)=>{
                this.emit(event, ...args);
            });
            if (this.modules.languageDetector) {
                s.languageDetector = createClassOnDemand(this.modules.languageDetector);
                s.languageDetector.init(s, this.options.detection, this.options);
            }
            if (this.modules.i18nFormat) {
                s.i18nFormat = createClassOnDemand(this.modules.i18nFormat);
                if (s.i18nFormat.init) s.i18nFormat.init(this);
            }
            this.translator = new Translator(this.services, this.options);
            this.translator.on('*', (event, ...args)=>{
                this.emit(event, ...args);
            });
            this.modules.external.forEach((m)=>{
                if (m.init) m.init(this);
            });
        }
        this.format = this.options.interpolation.format;
        if (!callback) callback = noop;
        if (this.options.fallbackLng && !this.services.languageDetector && !this.options.lng) {
            const codes = this.services.languageUtils.getFallbackCodes(this.options.fallbackLng);
            if (codes.length > 0 && codes[0] !== 'dev') this.options.lng = codes[0];
        }
        if (!this.services.languageDetector && !this.options.lng) {
            this.logger.warn('init: no languageDetector is used and no lng is defined');
        }
        const storeApi = [
            'getResource',
            'hasResourceBundle',
            'getResourceBundle',
            'getDataByLanguage', 
        ];
        storeApi.forEach((fcName)=>{
            this[fcName] = (...args)=>this.store[fcName](...args)
            ;
        });
        const storeApiChained = [
            'addResource',
            'addResources',
            'addResourceBundle',
            'removeResourceBundle', 
        ];
        storeApiChained.forEach((fcName)=>{
            this[fcName] = (...args)=>{
                this.store[fcName](...args);
                return this;
            };
        });
        const deferred = defer();
        const load = ()=>{
            const finish = (err, t)=>{
                if (this.isInitialized && !this.initializedStoreOnce) this.logger.warn('init: i18next is already initialized. You should call init just once!');
                this.isInitialized = true;
                if (!this.options.isClone) this.logger.log('initialized', this.options);
                this.emit('initialized', this.options);
                deferred.resolve(t);
                callback(err, t);
            };
            if (this.languages && this.options.compatibilityAPI !== 'v1' && !this.isInitialized) return finish(null, this.t.bind(this));
            this.changeLanguage(this.options.lng, finish);
        };
        if (this.options.resources || !this.options.initImmediate) {
            load();
        } else {
            setTimeout(load, 0);
        }
        return deferred;
    }
    loadResources(language, callback = noop) {
        let usedCallback = callback;
        let usedLng = typeof language === 'string' ? language : this.language;
        if (typeof language === 'function') usedCallback = language;
        if (!this.options.resources || this.options.partialBundledLanguages) {
            if (usedLng && usedLng.toLowerCase() === 'cimode') return usedCallback();
            const toLoad = [];
            const append = (lng)=>{
                if (!lng) return;
                const lngs = this.services.languageUtils.toResolveHierarchy(lng);
                lngs.forEach((l)=>{
                    if (toLoad.indexOf(l) < 0) toLoad.push(l);
                });
            };
            if (!usedLng) {
                const fallbacks = this.services.languageUtils.getFallbackCodes(this.options.fallbackLng);
                fallbacks.forEach((l)=>append(l)
                );
            } else {
                append(usedLng);
            }
            if (this.options.preload) {
                this.options.preload.forEach((l)=>append(l)
                );
            }
            this.services.backendConnector.load(toLoad, this.options.ns, usedCallback);
        } else {
            usedCallback(null);
        }
    }
    reloadResources(lngs, ns, callback) {
        const deferred = defer();
        if (!lngs) lngs = this.languages;
        if (!ns) ns = this.options.ns;
        if (!callback) callback = noop;
        this.services.backendConnector.reload(lngs, ns, (err)=>{
            deferred.resolve();
            callback(err);
        });
        return deferred;
    }
    use(module) {
        if (!module) throw new Error('You are passing an undefined module! Please check the object you are passing to i18next.use()');
        if (!module.type) throw new Error('You are passing a wrong module! Please check the object you are passing to i18next.use()');
        if (module.type === 'backend') {
            this.modules.backend = module;
        }
        if (module.type === 'logger' || module.log && module.warn && module.error) {
            this.modules.logger = module;
        }
        if (module.type === 'languageDetector') {
            this.modules.languageDetector = module;
        }
        if (module.type === 'i18nFormat') {
            this.modules.i18nFormat = module;
        }
        if (module.type === 'postProcessor') {
            __default1.addPostProcessor(module);
        }
        if (module.type === 'formatter') {
            this.modules.formatter = module;
        }
        if (module.type === '3rdParty') {
            this.modules.external.push(module);
        }
        return this;
    }
    changeLanguage(lng, callback) {
        this.isLanguageChangingTo = lng;
        const deferred = defer();
        this.emit('languageChanging', lng);
        const setLngProps = (l)=>{
            this.language = l;
            this.languages = this.services.languageUtils.toResolveHierarchy(l);
            this.resolvedLanguage = undefined;
            if ([
                'cimode',
                'dev'
            ].indexOf(l) > -1) return;
            for(let li = 0; li < this.languages.length; li++){
                const lngInLngs = this.languages[li];
                if ([
                    'cimode',
                    'dev'
                ].indexOf(lngInLngs) > -1) continue;
                if (this.store.hasLanguageSomeTranslations(lngInLngs)) {
                    this.resolvedLanguage = lngInLngs;
                    break;
                }
            }
        };
        const done = (err, l)=>{
            if (l) {
                setLngProps(l);
                this.translator.changeLanguage(l);
                this.isLanguageChangingTo = undefined;
                this.emit('languageChanged', l);
                this.logger.log('languageChanged', l);
            } else {
                this.isLanguageChangingTo = undefined;
            }
            deferred.resolve((...args)=>this.t(...args)
            );
            if (callback) callback(err, (...args)=>this.t(...args)
            );
        };
        const setLng = (lngs)=>{
            if (!lng && !lngs && this.services.languageDetector) lngs = [];
            const l = typeof lngs === 'string' ? lngs : this.services.languageUtils.getBestMatchFromCodes(lngs);
            if (l) {
                if (!this.language) {
                    setLngProps(l);
                }
                if (!this.translator.language) this.translator.changeLanguage(l);
                if (this.services.languageDetector) this.services.languageDetector.cacheUserLanguage(l);
            }
            this.loadResources(l, (err)=>{
                done(err, l);
            });
        };
        if (!lng && this.services.languageDetector && !this.services.languageDetector.async) {
            setLng(this.services.languageDetector.detect());
        } else if (!lng && this.services.languageDetector && this.services.languageDetector.async) {
            this.services.languageDetector.detect(setLng);
        } else {
            setLng(lng);
        }
        return deferred;
    }
    getFixedT(lng, ns, keyPrefix) {
        const fixedT = (key, opts, ...rest)=>{
            let options11;
            if (typeof opts !== 'object') {
                options11 = this.options.overloadTranslationOptionHandler([
                    key,
                    opts
                ].concat(rest));
            } else {
                options11 = {
                    ...opts
                };
            }
            options11.lng = options11.lng || fixedT.lng;
            options11.lngs = options11.lngs || fixedT.lngs;
            options11.ns = options11.ns || fixedT.ns;
            const keySeparator = this.options.keySeparator || '.';
            const resultKey = keyPrefix ? `${keyPrefix}${keySeparator}${key}` : key;
            return this.t(resultKey, options11);
        };
        if (typeof lng === 'string') {
            fixedT.lng = lng;
        } else {
            fixedT.lngs = lng;
        }
        fixedT.ns = ns;
        fixedT.keyPrefix = keyPrefix;
        return fixedT;
    }
    t(...args) {
        return this.translator && this.translator.translate(...args);
    }
    exists(...args) {
        return this.translator && this.translator.exists(...args);
    }
    setDefaultNamespace(ns) {
        this.options.defaultNS = ns;
    }
    hasLoadedNamespace(ns, options = {
    }) {
        if (!this.isInitialized) {
            this.logger.warn('hasLoadedNamespace: i18next was not initialized', this.languages);
            return false;
        }
        if (!this.languages || !this.languages.length) {
            this.logger.warn('hasLoadedNamespace: i18n.languages were undefined or empty', this.languages);
            return false;
        }
        const lng = this.resolvedLanguage || this.languages[0];
        const fallbackLng = this.options ? this.options.fallbackLng : false;
        const lastLng = this.languages[this.languages.length - 1];
        if (lng.toLowerCase() === 'cimode') return true;
        const loadNotPending = (l, n)=>{
            const loadState = this.services.backendConnector.state[`${l}|${n}`];
            return loadState === -1 || loadState === 2;
        };
        if (options.precheck) {
            const preResult = options.precheck(this, loadNotPending);
            if (preResult !== undefined) return preResult;
        }
        if (this.hasResourceBundle(lng, ns)) return true;
        if (!this.services.backendConnector.backend) return true;
        if (loadNotPending(lng, ns) && (!fallbackLng || loadNotPending(lastLng, ns))) return true;
        return false;
    }
    loadNamespaces(ns, callback) {
        const deferred = defer();
        if (!this.options.ns) {
            callback && callback();
            return Promise.resolve();
        }
        if (typeof ns === 'string') ns = [
            ns
        ];
        ns.forEach((n)=>{
            if (this.options.ns.indexOf(n) < 0) this.options.ns.push(n);
        });
        this.loadResources((err)=>{
            deferred.resolve();
            if (callback) callback(err);
        });
        return deferred;
    }
    loadLanguages(lngs, callback) {
        const deferred = defer();
        if (typeof lngs === 'string') lngs = [
            lngs
        ];
        const preloaded = this.options.preload || [];
        const newLngs = lngs.filter((lng)=>preloaded.indexOf(lng) < 0
        );
        if (!newLngs.length) {
            if (callback) callback();
            return Promise.resolve();
        }
        this.options.preload = preloaded.concat(newLngs);
        this.loadResources((err)=>{
            deferred.resolve();
            if (callback) callback(err);
        });
        return deferred;
    }
    dir(lng) {
        if (!lng) lng = this.resolvedLanguage || (this.languages && this.languages.length > 0 ? this.languages[0] : this.language);
        if (!lng) return 'rtl';
        const rtlLngs = [
            'ar',
            'shu',
            'sqr',
            'ssh',
            'xaa',
            'yhd',
            'yud',
            'aao',
            'abh',
            'abv',
            'acm',
            'acq',
            'acw',
            'acx',
            'acy',
            'adf',
            'ads',
            'aeb',
            'aec',
            'afb',
            'ajp',
            'apc',
            'apd',
            'arb',
            'arq',
            'ars',
            'ary',
            'arz',
            'auz',
            'avl',
            'ayh',
            'ayl',
            'ayn',
            'ayp',
            'bbz',
            'pga',
            'he',
            'iw',
            'ps',
            'pbt',
            'pbu',
            'pst',
            'prp',
            'prd',
            'ug',
            'ur',
            'ydd',
            'yds',
            'yih',
            'ji',
            'yi',
            'hbo',
            'men',
            'xmn',
            'fa',
            'jpr',
            'peo',
            'pes',
            'prs',
            'dv',
            'sam',
            'ckb'
        ];
        return rtlLngs.indexOf(this.services.languageUtils.getLanguagePartFromCode(lng)) > -1 || lng.toLowerCase().indexOf('-arab') > 1 ? 'rtl' : 'ltr';
    }
    static createInstance = (options11 = {
    }, callback2)=>new I18n(options11, callback2)
    ;
    cloneInstance(options = {
    }, callback = noop) {
        const mergedOptions = {
            ...this.options,
            ...options,
            ...{
                isClone: true
            }
        };
        const clone = new I18n(mergedOptions);
        const membersToCopy = [
            'store',
            'services',
            'language'
        ];
        membersToCopy.forEach((m)=>{
            clone[m] = this[m];
        });
        clone.services = {
            ...this.services
        };
        clone.services.utils = {
            hasLoadedNamespace: clone.hasLoadedNamespace.bind(clone)
        };
        clone.translator = new Translator(clone.services, clone.options);
        clone.translator.on('*', (event, ...args)=>{
            clone.emit(event, ...args);
        });
        clone.init(mergedOptions, callback);
        clone.translator.options = clone.options;
        clone.translator.backendConnector.services.utils = {
            hasLoadedNamespace: clone.hasLoadedNamespace.bind(clone)
        };
        return clone;
    }
    toJSON() {
        return {
            options: this.options,
            store: this.store,
            language: this.language,
            languages: this.languages,
            resolvedLanguage: this.resolvedLanguage
        };
    }
}
const instance = I18n.createInstance();
instance.createInstance = I18n.createInstance;
const createInstance1 = instance.createInstance;
const init1 = instance.init;
const loadResources1 = instance.loadResources;
const reloadResources1 = instance.reloadResources;
const use1 = instance.use;
const changeLanguage1 = instance.changeLanguage;
const getFixedT1 = instance.getFixedT;
const t1 = instance.t;
const exists1 = instance.exists;
const setDefaultNamespace1 = instance.setDefaultNamespace;
const hasLoadedNamespace1 = instance.hasLoadedNamespace;
const loadNamespaces1 = instance.loadNamespaces;
const loadLanguages1 = instance.loadLanguages;
export { instance as default };
export { createInstance1 as createInstance };
export { init1 as init };
export { loadResources1 as loadResources };
export { reloadResources1 as reloadResources };
export { use1 as use };
export { changeLanguage1 as changeLanguage };
export { getFixedT1 as getFixedT };
export { t1 as t };
export { exists1 as exists };
export { setDefaultNamespace1 as setDefaultNamespace };
export { hasLoadedNamespace1 as hasLoadedNamespace };
export { loadNamespaces1 as loadNamespaces };
export { loadLanguages1 as loadLanguages };
