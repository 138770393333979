export const Style = (styled, Proto, Props) => {

  const Style = styled.div`
  width: 100%;
  height: 100%;
  color: #333;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  .App_Aura {
    width: 100%;
    height: 100%;
    padding: 6rem 12%;

    @media only screen and (max-width: 40rem) {
      padding: 2rem 4%;
    }
  
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
  
    ._Top {
    flex: 1;
    width: 100%;
    height: calc(100% - 100px);
    border-radius: 15px;
    padding: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
  
    ._Text {
    flex: 1;
    width: 50%;
    height: auto;
    max-height: 100%;
    overflow-y: auto;

    color: #777;
    font-family: 'Ginora Sans';
    text-align: center;
    font-size: 2.4rem; 
    // background: antiquewhite;

    display: flex;
    flex-direction: column;
    align-items: flex-start; 

      pre {
      text-align: left;
      max-width: 100%;
      max-height: 300px;
      overflow: scroll;
      font-size: 0.8em;
      // padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 7px;
      }

      ._Message, ._Response {
      width: auto;
      display: block;
      margin: 10px 0;
      padding: 10px 15px;
      border-radius: 10px;
      max-width: 80%;
      word-wrap: break-word;
      font-size: 2.25rem;
      line-height: 1.4;
      }

      ._Message {
      cursor: pointer;
      margin-left: auto;
      align-self: flex-end;
      background-color: #ceedfd;
      text-align: right;
      color: #333;
      border-bottom-right-radius: 0;
      }

      ._Response {
      background-color: #f1f0f0;
      align-self: flex-start;
      text-align: left;
      color: #000;
      border-bottom-left-radius: 0;
      border: 1px solid transparent;

        &:hover {
        cursor: pointer;
        border: 1px solid #ccc;
        }

      }

      ._Message.Loading {
        background-color: #ceedfd;
        color: #888;
        font-style: italic;
        text-align: center;
        border: 1px dashed #bbb;
        animation: loadingAnimation 1.5s infinite;
      }

      @keyframes loadingAnimation {
        0% { opacity: 0.5; }
        50% { opacity: 1; }
        100% { opacity: 0.5; }
      }

      }
  
      ._Aura {
      // width: 50%;
      width: 30rem;
      height: 30rem;
      position: relative;
      // overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;
      
      visibility: hidden;

        &._Loaded { 
        visibility: visible;
        }
  
        ._Cubit {
        width: 100%;
        height: 100%;
        animation: Animation_Levitate 3s ease-in-out infinite;
        position: relative;
          
          img {
          width: 100%;
          // height: 100%;
          position: absolute;
          top: 0; 
          left: 0;
          }

          ._Body {
          width: 100%;
          // height: 100%;
          position: absolute;
          top: 0; 
          left: 0;
          }
  
          ._Face {
            
            img {
            width: 100%;
            position: absolute;
            top: 0; 
            left: 0;
            }
          
          }
  
          ._Centella {
          width: 100%;
          position: absolute;
          top: 0; 
          left: 0;

            &._Animation_Glow {
            animation: Animation_Glow 2s ease-in-out infinite;
            }

          }

        }
  
        ._Shadow {
        width: 100%;
        height: 100%;
        position: absolute;

          img {
          width: 100%;
          top: 0;
          left: 0;
          animation: Animation_Shadow 3s ease-in-out infinite;
          transform-origin: center;
          position: absolute;
          }
        
        }

      }
  
      @keyframes Animation_Glow {
        0% {
          filter: brightness(100%) saturate(100%);
        }
        50% {
          filter: brightness(150%) saturate(150%);
        }
        75% {
          filter: brightness(150%) saturate(150%);
        }
        100% {
          filter: brightness(100%) saturate(100%);
        }
      }

      @keyframes Animation_Levitate {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-20px);
        }
      }
  
      @keyframes Animation_Shadow {
        0%, 100% {
          opacity: 1;
          transform: scaleX(1);
        }
        50% {
          opacity: 0.5;
          transform: scaleX(1.05);
        }
      }
  
      @media only screen and (max-width: 60rem) {
        ._Aura { width: 20rem; height: 20rem; } 
      }
  
      @media only screen and (max-width: 40rem) {
        flex-direction: column;
        ._Text {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column-reverse;
        }
        ._Aura { width: 15rem; height: 15rem; margin-bottom: 1rem; } 
      }
      
    }
  
    ._Bottom {
    width: 100%;
    position: relative;
  
      textarea {
      width: 100%;
      height: auto;
      padding: 2rem 10rem 2rem 4rem;
      font-size: 2rem;
      color: #777;
      background: aliceblue;
      border: 3px solid rgba(0, 0, 0, 0.1);
      border-radius: 64px;
      resize: none;
      overflow: hidden;

        &:focus {
        outline: none !important;
        border: 3px solid #88cbf2;      
        }

      }

      ._Button_Send {
      width: 6rem;
      height: 6rem;
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      background: #00BFD8;
      color: #eee;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
        
        &:hover {
        background-color: #87CEEB;
        color: #fff;
        }

        i {
        font-size: 5rem;
        }

      }

    }
  }
  `;
  
  return Style;
  }
  