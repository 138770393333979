//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_App_Blog.js';
  import { App_Blog_Detail } from './Detail/App_Blog_Detail.js';
  import { App_Blog_List } from './List/App_Blog_List.js';

//----------------------------------------------------------------------------------------

export const App_Blog = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------


//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------


return (html`
<${Styled}>

  <${RouterDOM.Route} exact path='/blog'>
    <${App_Blog_List} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/blog/:id'>
    <${App_Blog_Detail} Props=${Props} />
  <//>

<//>
`);

};
