export const Range = ({ name, value, rangeMin, rangeMax, onChange }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/
    
  const html = Public.Libs.html;
  const { useEffect, useState } = Public.Libs.React;

/*█████████████████████████████████   💾 STATE   ███████████████████████████████████*/

  const [localValue, setLocalValue] = useState(value);

/*█████████████████████████████████   🦉 SCRIPTS   ███████████████████████████████████*/

  const updateValue = (newValue, commit = false) => {
    const numericValue = Math.max(rangeMin, Math.min(rangeMax, Number(newValue)));
    setLocalValue(numericValue);
    if (commit) {
      onChange(name, numericValue); // Comunica el cambio al componente padre
    }
  };

/*█████████████████████████████████   🤖 HOOKS   ███████████████████████████████████*/

  useEffect(() => {
    setLocalValue(value); // Sincroniza con el valor propuesto externamente
  }, [value]);

/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

  return (html`
    <div className="Container_Input_Range">
      ${name}
      <input 
        className="Input_Range"
        type="range" 
        min=${rangeMin} 
        max=${rangeMax} 
        value=${localValue} 
        onChange=${(e) => updateValue(e.target.value)}
        onMouseUp=${() => updateValue(localValue, true)}
        onBlur=${() => updateValue(localValue, true)}
      />
      <button onClick=${() => updateValue(Number(localValue) + 1, true)}>+</button>
      <button onClick=${() => updateValue(Number(localValue) - 1, true)}>-</button>
      <input className="Input_Text" type="text" value=${localValue} 
        onChange=${(e) => updateValue(e.target.value)}
        onBlur=${() => updateValue(localValue, true)}
        onKeyPress=${(e) => e.key === 'Enter' && updateValue(localValue, true)}
      />
    </div>
  `);
};