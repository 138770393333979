// import { Card } from "../../../../../Core/API/MODEL/Card/Card.js";
import { Style } from './Style/Style_Items_Books.js';

export const Items_Books = ({ Props }) => {

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

  const Component_Book = Public.Scripts.Choose_Part('Book');  

//████████████████████████████████████████████████████████████████████████████████████

  const [Books, SetBooks] = useState(undefined);

//=====================================================================================

  useEffect(() => {
    Get_Books();
  }, []);

  const Get_Books = () => {
    Public.DB.Read({
      URI: `Items/Books`, 
      Items: ["1", "2", "3"],
      Callback: (items) => {  
        SetBooks(items);
      }
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>
<div className="_Books">

    ${Books && Array.isArray(Books) && Books.map(function(Item, i){

      return html`
      <${RouterDOM.Link} to="/items/books/${Item.ID}" key="Items_Books_${i}">
        <${Suspense} fallback=${html`<div>...</div>`}>
          ${html`<${Component_Book} Props=${Item} />`}
        <//>
      <//>
      `;

    })}

</div>
<//>
`);
}