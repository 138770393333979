export const Style = (styled, Proto, Props) => {
return styled.div`

transform: translate3d(0, 0, 0.1px);

._Books {
  width: 100%;
  height: auto;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;

  perspective: 2000px;
  position: relative;
  z-index: 1;
  transform: translate3d(0.1px, 0.1px, 0.1px);

  & > *{
    // Usar un Grid de 2D
    width: auto;
    width: 20%;
    max-width: 20%;
    margin: 0 5% 4% 0;
  }

}

`;
}