export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: row;

._Ondas {
display: flex;
flex-wrap: wrap;
margin-bottom: 4rem;
}

._Onda {
width: auto;
height: auto;
max-height: 30rem;
background-color: aliceblue;
margin: 1rem;
padding: 1rem;
border-radius: 7px;
cursor: pointer;
background: #eee;

display: flex;
flex-direction: column;

  img {
  width: auto;
  max-width: 20rem;
  margin-right: 1rem;
  border-radius: 7px;
  }

  ._Text {
  color: #333;
  margin: 0;
  padding: 1rem;
  }

}

`;

return Style;

}

