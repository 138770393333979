import { Style } from './Style/Style_Items_Asset_Detail.js';

export const Items_Asset_Detail = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();

//████████████████████████████████████████████████████████████████████████████████████

  const [Item, SetItem] = useState(null);

  useEffect(() => {
    Get_Asset();
  }, []);

  const Get_Asset = async () => {
    Public.DB.Read({
      URI: `Items/Assets`, 
      Items: ["ALL"], 
      Callback: (items) => {  
        SetItem(items[0]);
      }
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>

${Item && html`
  <div className="_Image">
    <img src=${Public.Scripts.Blob_To_Img_URL(Item.Files[0].Blob)}/>
  </div>
  <div className="_Content">
    <h3>${Item.Name}</h3>
    <span>${Item.Price}</span>
    <p>${Item.Description}</p>
  </div>
`}

<//>
`);
}