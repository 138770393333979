//----------------------------------------------------------------------------------------

  import { Styled } from './Style/Style_Admin_Inc.js';
  import { Admin_Docs } from "./Docs/Admin_Docs.js";
  import { Admin_Apis } from "./Apis/Admin_Apis.js";
  import { Admin_Digi } from "./Digi/Admin_Digi.js";
  import { Admin_Database } from "./Database/Admin_Database.js";

//----------------------------------------------------------------------------------------
  export const Admin_Inc = ({ Props }) => {
//----------------------------------------------------------------------------------------

  Props.URI = `${Public.ENV.URL}/Apps/Inc/Parts/Inc`;
  const Style = Styled(Public.Libs.styled, {}, Props.URI);
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const RouterDOM = Public.Libs.RouterDOM;

//█████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

  const [Sections, SetSections] = useState(["Lab", "Digi", "Apis", "Docs"]);

//=====================================================================================

return (html`
<${Style}>

  <div className="_Navbar">
    <h2 className="_Title">Inc</h2>
  </div>

  <div className="_Grid">

    <${RouterDOM.Route} exact path='/admin/server'>
      ${Sections.map((section) => {
        let route = section.toLowerCase();
        return html`
        <${RouterDOM.Link} to="/admin/server/${route}" key="Menu_${section}">
        <div className="_Box" key="Key_${section}">
          <img src="${Props.URI}/${section}/.About/Icon/Icon.png"/>
          <div className="_Placeholder"></div>
          <div className="_Title">${section}</div>
        </div>
        <//>
        `;
      })}
    <//>

  </div>

  <${RouterDOM.Route} exact path='/admin/server/docs'>
    <${Admin_Docs} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/admin/server/database'>
    <${Admin_Database} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/admin/server/digi'>
    <${Admin_Digi} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/admin/server/lab'>
    Lab
  <//>

  <${RouterDOM.Route} exact path='/admin/server/apis'>
    <${Admin_Apis} Props=${Props} />
  <//>


<//>
`);
}