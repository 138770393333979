export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: stretch;
align-content: stretch;
background: whitesmoke;
position: relative;
overflow: hidden;
border-radius: 7px;

._Background {
z-index: 0;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0.8;
}

#Admin_Calendar {
z-index: 1;
width: 100%;
padding: 2rem;
overflow-y: scroll;

ul {
width: 100%;
display: flex;
flex-direction: column;

  li {
  width: 100%;
  height: auto;
  padding: 2rem;
  border: 2px solid #444;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 7px;
  margin: 2rem 0;
  color: #eee;
  display: flex;
  align-items: center;
  justify-content: left;

  img {
    width: 60px;
    margin-right: 2rem;
  }

  &:hover {
  cursor: pointer;
  border: 2px solid aquamarine;
  }

  }

}

}

`;

return Style;

}

