//----------------------------------------------------------------------------------------
  export class DATA_SCENE { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.ID = undefined;
  this.About = {
    Name: undefined,
    Description: undefined
  };

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Load (Scene_ID, Callback) {
//----------------------------------------------------------------------------------------

  // Data
  if (typeof Scene_ID !== "string") { Scene_ID = JSON.stringify(Scene_ID) }

  // FormData
  let formData = new FormData();
  formData.append('User_ID', this.MAIA.ENV.User?.ID);
  formData.append('Chip_ID', this.MAIA.ENV.Chip?.ID);
  formData.append('Scene_ID', Scene_ID);

  await fetch(`${this.MAIA.ENV.ROUTE}/data/scene/active`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => {

    this.MAIA.ENV.Scene.ID = Scene_ID;

    if(Callback) { // Reload browser
      Callback();
      return; 
    }
    else {
      this.MAIA.DATA.Load({ 
        User_ID: this.MAIA.ENV.User.ID, 
        Chip_ID: this.MAIA.ENV.Chip.ID, 
        Scene_ID: Scene_ID 
      });
    }

  });

}
//----------------------------------------------------------------------------------------
  async Drop () {
//----------------------------------------------------------------------------------------

  let Form = new FormData();
  Form.append('Chip_ID', JSON.stringify(this.MAIA.ENV.Chip.ID));
  Form.append('Scene_ID', JSON.stringify(this.MAIA.ENV.Scene.ID));

  await fetch(`${this.MAIA.ENV.ROUTE}/data/scene/drop`, { 
      method: 'POST', 
      body: Form
  })
  .then(response => response.json())
  .then(res => {

    if(res.Response === 'OK') {
      Public.Scripts.Notify.Send({Title: "Escena", Description: `Escena eliminada`, Image: "Drop", Time: 3000});
      if (Callback) { Callback(); }
    }
    else {
      Public.Scripts.Notify.Send({Title: "Escena", Description: `Error al eliminar`, Image: "Error", Time: 3000});
    }

  });

}
//----------------------------------------------------------------------------------------
}