import { Style } from './Style/Style_Items_Asset_List.js';

export const Items_Asset_List = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████

  const [Items, SetItems] = useState(null);
  
  useEffect(() => {
    Get_Asset();
  }, []);

  const Get_Asset = async () => {
    Public.DB.Read({
      URI: `Items/Assets`,
      Items: ['ALL'], 
      Callback: (items) => {  
        SetItems(items);
      }
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>

<div className="_Items">

  ${Items && Items.map((Item, i) => {

    return html`

      <div className="_Item" key="Asset_${i}">
      <${RouterDOM.Link} to="/items/assets/${Item.ID}" key="ItemsAsset_${Item.ID}">
      <div className="_Image">
      <img src=${Public.Scripts.Blob_To_Img_URL(Item.Files[0].Blob)}/>
      </div>
      <p className="_Name">${Item.Name}</p>
      <//>
      </div>

    `
  })}

</div>
<//>
`);
}