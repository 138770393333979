//----------------------------------------------------------------------------------------

  import { DATA_PROTO } from "./PROTO/DATA_PROTO.js";
  import { DATA_ENT } from "./ENT/DATA_ENT.js";
  import { DATA_SCENE } from "./SCENE/DATA_SCENE.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class MAIA_DATA { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.PROTO = new DATA_PROTO(MAIA);
  this.ENT = new DATA_ENT(MAIA);
  this.SCENE = new DATA_SCENE(MAIA);

  // Load
  this.Loaded = false;
  this.Loading = false;
  // this.Load();

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.PROTO.Init();
  this.ENT.Init();
  this.SCENE.Init();

}
//----------------------------------------------------------------------------------------
  Start () {
//----------------------------------------------------------------------------------------
  
  if(!this.Loading) { // 1️⃣

    this.Loading = true;
    
    // If the User is logged, then load its last Scene.
    // If the User is Guest, then load the default 'Welcome' Scene.

    let Scene_ID = undefined;
    if (typeof this.MAIA.ENV.User.ID === "number") { Scene_ID = null }
    else { Scene_ID = this.MAIA.ENV.Scene.ID }
    
    this.Load({
      User_ID: this.MAIA.ENV.User?.ID || 0,
      Chip_ID: this.MAIA.ENV.Chip?.ID,
      Scene_ID: Scene_ID,
    });
    
  }

  return this.Loaded; // ✅ | ❌

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Load ({User_ID, Chip_ID, Scene_ID}) {
//----------------------------------------------------------------------------------------

  let formData = new FormData();
  formData.append('User_ID', User_ID);
  formData.append('Chip_ID', JSON.stringify(Chip_ID));
  formData.append('Scene_ID', Scene_ID);

  await fetch(`${this.MAIA.ENV.ROUTE}/data/load`, { 
    method: 'POST',
    body: formData,
  })
  // .then(response => response.json())
  .then(fetch => fetch.formData())
  .then((FormData) => {
    
    // Scene ID, About
    this.SCENE.ID = FormData.get('Scene_ID');
    this.SCENE.About = JSON.parse(FormData.get('Scene_About'));
    this.MAIA.ENV.Scene.ID = this.SCENE.ID; // remove ?

    // Ents, Protos
    FormData.delete('Scene_ID');
    FormData.delete('Scene_About');
    this.ENT.Ents = Public.DB.Deserialize_FormData(FormData, "Ents", "Evts.Props.Files");
    this.PROTO.Protos = Public.DB.Deserialize_FormData(FormData, "Protos", "Evts.Props.Files");
    
    // console.log("⛩️ FormData", FormData)
    // console.log("====================================");
    // for (let [key, value] of FormData.entries()) { console.log(`${key}: ${value}`);}
    // console.log("====================================");

    this.Loaded = true;

  });

  // Regenerate Tree
  if (this.MAIA.TREE.Inited) { 
    this.MAIA.TREE.Reset();
    this.Init('PLAY');
  }

}
//----------------------------------------------------------------------------------------
  async Save (Callback) {
//----------------------------------------------------------------------------------------
  
  // Data
  let Scene_ID = this.MAIA.ENV.Scene.ID;
  if (typeof Scene_ID !== "string") { Scene_ID = JSON.stringify(Scene_ID); }

  // FormData
  let formData = new FormData();
  formData.append('Scene_ID', Scene_ID);
  formData.append('Scene_About', JSON.stringify(this.SCENE.About));
  formData.append('User_ID', this.MAIA.ENV.User?.ID || 0);
  formData.append('Chip_ID', JSON.stringify(this.MAIA.ENV.Chip.ID));
  let Ents = this.MAIA.TREE.ENT.Get_Data();
  console.log("SAVE ENTS", Ents)
  formData.append('Ents', JSON.stringify(Ents));
  
  // Thumbnail
  let Canvas = this.MAIA.TREE.RENDER.Canvas;
  Canvas = await this.MAIA.CORE.RESOURCES.Thumbnail.Resize_Canvas(Canvas, 400);
  Canvas = await this.MAIA.CORE.RESOURCES.Thumbnail.Round_Canvas(Canvas, "5%");
  let Canvas_Blob = await this.MAIA.CORE.RESOURCES.Thumbnail.Canvas_To_Blob(Canvas);
  formData.append('Thumbnail', Canvas_Blob, `Thumbnail-${this.MAIA.ENV.Scene.ID}.png`);

  console.info("💾 Save", Ents);

  await fetch(`${this.MAIA.ENV.ROUTE}/data/save`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => { 
    console.log(res);
    if(res === 'OK') {
      Public.Scripts.Notify.Send({Title: "Escena", Description: `Escena guardada`, Image: "Save", Time: 3000});
      if (Callback) { Callback(); }
    }
    else {
      Public.Scripts.Notify.Send({Title: "Escena", Description: `Error al guardar`, Image: "Error", Time: 3000});
    }
  });

}
//----------------------------------------------------------------------------------------
}