// ES Modules
import htm from './htm/htm.js';

// Idiom
import i18next from "./i18next/i18next.min.js";
import { Load_i18n } from "../Scripts/Idiom/Load_i18next.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class Public_Libs { constructor (Public) {
//----------------------------------------------------------------------------------------

  this.Public = Public;
  
  this.Loaded = false;
  this.Loading = 0;

  this.Init();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  // ⚛️ React
  this.React = window.React;
  this.ReactDOM = window.ReactDOM;

  // 🚠 Router
  this.Load_Script([`${this.Public.ENV.URL}/Public/Modules/Router/PLAIN/react-router-dom.min.js`], () => {
    this.RouterDOM = window.ReactRouterDOM;
    this.RouterDOM.Router = window.ReactRouterDOM.BrowserRouter;
  });

  // 📜 html
  this.html = htm.bind(React.createElement);

  // 🖌️ Styled
  this.Load_Script([`${this.Public.ENV.URL}/Public/Modules/Styled/styled-components.js`], () => {
    this.styled = window.styled;
  });

  /* 
  // 🎹 Tone.js
  this.Load_Script([`${this.Public.ENV.URL}/Public/Modules/Tone/Tone.js`], () => {
    this.Tone = window.Tone;
  });

  this.Load_Script([`${this.Public.ENV.URL}/Public/Modules/Tone/StartAudioContext.js`], () => {
    this.StartAudioContext = window.StartAudioContext;
  });
  */

  // 🇪🇦 Idioms
  this.Load_Script([`${this.Public.ENV.URL}/Public/Modules/i18next/react-i18next.js`], () => {
    Load_i18n(i18next, ReactI18next.initReactI18next);
    this.i18n = { Core: i18next, React: ReactI18next };
  });

  // 🏓 Anime.js
  this.Load_Script([`${this.Public.ENV.URL}/Public/Modules/Anime/anime.js`], () => {
    this.anime = window.anime;
  });

  // 🔆 Highlight.js
  this.Load_Script([
    `${this.Public.ENV.URL}/Public/Modules/Highlight/highlight.min.js`,
  ], 
  () => {

    this.hljs = window.hljs;
    
    // Load Plugins and Languages
    this.Load_Script([
      `${this.Public.ENV.URL}/Public/Modules/Highlight/Plugins/highlightjs-copy.min.js`,
      `${this.Public.ENV.URL}/Public/Modules/Highlight/Languages/css.min.js`,
      `${this.Public.ENV.URL}/Public/Modules/Highlight/Languages/javascript.min.js`,
    ], 
    () => {

      this.hljs.addPlugin(new CopyButtonPlugin());

    });

  });

  // 🔊 Howler.js
  this.Load_Script([`${this.Public.ENV.URL}/Public/Modules/Howler/howler.min.js`], () => {
    this.Howler = window.Howler;
    this.Howl = window.Howl;
  });

  // 🧙‍♂️ Prism
  // this.Prism = window.Prism;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Load () {
//----------------------------------------------------------------------------------------

  this.Loading++;
  if(window.styled && window.ReactRouterDOM && this.Howler && this.Howl) { return true; }
  else { await new Promise(r => setTimeout(r, 100)); }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Load_Script (URLs, Callback) {
//----------------------------------------------------------------------------------------

  const promises = URLs.map(URL => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = URL;
      script.onload = resolve;
      script.onerror = reject;
      script.async = true;
      document.body.appendChild(script);
    });
  });

  try {
    await Promise.all(promises);
    Callback();
  } catch (error) {
    console.error('Error al cargar uno o más scripts:', error);
  }

}
//----------------------------------------------------------------------------------------
}