export const Containers = (styled, Proto, Props) => {
return styled.css`


// Navbar
// ================================================================================================

  .Navbar{
  width: 100%;
  padding-left: 1rem;
  padding-bottom: 12px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  ._Space {
  flex: 1;
  }

  ._Title {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Ginora Sans Bold';
  color: transparent;
  text-shadow: rgba(255,255,255,0.5) 0px 2px 2px;
  background: linear-gradient(to bottom, #1EEDDC 0%, #1AACCC 100%);
  background-clip: text;
  -webkit-background-clip: text;
  }

  & > i:hover{
  cursor: pointer;
  color: #777;
  }

  ._Info {
  font-size: 3rem;
  color: #1AACCC;
  
    &:hover {
    cursor: pointer;
    color: #1EEDDC;
    }

  }

  & ._Sections {
  display: flex;
  align-items: center;
  justify-items: baseline;
  justify-content: center;
  align-items: baseline;

    & ._Section {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-family: 'Ginora Sans';
    margin: 0 2rem;

      ._Icon {
      height: 5rem;
      margin-bottom: 1rem;
      filter: grayscale(90%);
      opacity: 0.3;
      }

      &:hover, &.Active {
      cursor: pointer;
      color: #777;    

        ._Icon {
        opacity: 1;
        filter: grayscale(0%);
        }

      }

    }

  }

  }



.Board{
min-width: 0;
height: 100vh;
display: flex;
align-items: stretch;
position: relative;

  @media only screen and (max-width: 40rem) {
    flex-direction: column;
  }
  
}

.Boxes {
width: 100%;
height: auto;
display: flex;
// flex-grow: 1;
justify-content: space-evenly; 
// gap: 5%;
// background-color: #9ddce0;
;

& img{  
opacity: 0.5 !important;
filter: grayscale(100%) !important;
}

.Separator {
visibility: hidden;
position: absolute;
top: 42%;
right: 0;
width: 3px;
height: 42px;
border-radius: 100%;
background: #ddd;
/*
width: 12px;
height: 12px;
border-radius: 100%;
background: #ddd;
border: 1px solid #d5d5d5;
opacity: 0.7;
*/
}

.Box {
width: 33.3%;
// flex-grow: 1;
border-radius: 9px;
position: relative;
overflow: hidden;
cursor: pointer;

  &:hover {
  }

  a {
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  width: 100%;
  height: 100%;
  // background-color: aliceblue;
  }

  .Image {
  height: 57%;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  img {
  z-index: 2;
  max-width: 100%;
  max-height: 100%;
  filter: grayscale(30%);
  opacity: 0.9;
  }

  &.Active img {
  opacity: 0.99 !important;
  filter: grayscale(0%) !important;
  }
  
  &:hover img {
  
  }

  h2 {
  margin-top: 2.2rem;
  font-size: 2.2rem;
  color: #fff;
  color: #777;
  text-align: center;
  z-index: 2;
  padding: 0 2rem;
  font-family: "Ginora Sans";
  font-weight: bold;
  }

  &.Active h2{  
  color: #2f618a;
  }

  &:last-child .Separator{
  visibility: hidden;
  }


  }

}

`}