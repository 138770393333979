export const Admin_Database = ({ Props }) => {

 const { useState, useEffect, useRef } = Public.Libs.React;
 const html = Public.Libs.html;

//████████████████████████████████████████████████████████████████████████████████████

const Styled = styled.div`
width: 100%;
height: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
`;

//=====================================================================================

//=====================================================================================

return (html`
<div>

  Database
  
</div>
`);
}