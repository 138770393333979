export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: row;

.Onda_Detail {
width: 100%;

  ._Item {
  // background-color: aliceblue;
  background: #fff;
  margin: 1rem;
  padding: 1rem;
  border-radius: 7px;
  // cursor: pointer;

  display: flex;
  flex-direction: row;

    img {
    width: 100%;
    max-width: 30rem;
    margin-right: 1rem;
    border-radius: 7px;
    border: 1px solid #ccc;
    }

    ._Text {
    color: #333;
    margin: 0;
    padding: 2rem;
    font-size: 2rem;
    }

  }


}

`;

return Style;

}

