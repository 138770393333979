
  // import { GameOfLife } from "../Scripts/GameOfLife/GameOfLife.js";
  import { Styles } from '../Styles/Styles_Game.js';

//----------------------------------------------------------------------------------------
  export const Game = ({ Egg }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Public.Styles.Styled(Styles, {});

//████████████████████████████████████████████████████████████████████████████████████

  // Mode
  const [Mode, SetMode] = useState('Empty');

  // Dialog
  const [IsDialogVisible, SetDialogVisible] = useState(false);

  useEffect(() => {
    if(Egg) { 
      console.log("🥚", Egg)
      SetDialogVisible(true);
    }    
  }, [Egg]);

  const Dialogs = {
    Empty: [ "¡Hola! ¡Haz clic aquí!", "¡Visita nuestro stand!", "Allí comienza todo." ],
    Aura: [ "¡Hola! ¡Haz click aquí!", "¡Has descubierto el secreto!", "Tu sorpresa aguarda en el stand." ],
    Eroda: [ "...", "Parece que me has encontrado.", "Si deseas continuar, vuelve tus pasos atrás.", "Algo te aguarda en el stand." ],
    Fuzz: [ "zzzz...", "¡Oh! Me has despertado.", "Parece que tu Digitori también ha despertado.", "Deberías volver al stand." ],
  };
  
  // Chars
  const [phraseIndex, setPhraseIndex] = useState(0); 
  const [charIndex, setCharIndex] = useState(0);
  const [text, setText] = useState('');
  
  const [Frame, SetFrame] = useState(0);
  const [Animating, SetAnimating] = useState(false);
  let Empty = [
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
      0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
      0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
      0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
      0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
      0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]
  ];

  let Digitori = [
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  ];

  let Aura = [
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0,
    0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  ];

  let Eroda = [
    [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1,
    1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1,
    1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1
  ],
  [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1
    ]
  ];

  let Fuzz = [
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0,
    0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    [
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0,
    0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  ];

  // Egg
  const [Character, SetCharacter] = useState(Egg?.Character);

  // Grid
  const [Group, SetGroup] = useState(Empty);
  const [Grid, SetGrid] = useState(Empty[0]);
  const [Index, SetIndex] = useState(0);

//----------------------------------------------------------------------------------------

  useEffect(() => { if(!Egg) { return; }
         if(Character === 'Aura') { SetGroup(Aura); SetMode('Aura'); }
    else if(Character === 'Eroda') { SetGroup(Eroda); SetMode('Eroda'); }
    else if(Character === 'Fuzz') { SetGroup(Fuzz); SetMode('Fuzz'); }
    else if(Character === 'Digitori') { SetGroup(Digitori); SetMode('Digitori'); }
    else { SetGroup('Empty') }
  }, [Character]);

  useEffect(() => {
    
  }, [Group]);

//----------------------------------------------------------------------------------------

  useEffect(() => { 
    
    if(!Group || Group.length <= 1) { return; }

    const intervalId = setInterval(() => {
      
      // console.log(Animating, Egg, Frame)
      if(!Animating && !Egg) { return; }
      if(Egg && Frame === 0) { SetFrame(Frame + 1); return; }

      SetIndex(prevIndex => {

        SetFrame(Frame + 1);
        
        if(!Egg) {

          Public.Scripts.Audio.Play({URI: `${Public.ENV.URL}/Public/Media/Sounds/Egg/Init/Lifegame/Lifegame_${Frame+1}.mp3`});

          /* 
          // Sound every frame
          Tone.context.resume();
          let FileSound = `${Public.ENV.URL}/Public/Media/Sounds/Egg/Init/Lifegame/Lifegame_${Frame+1}.mp3`;
          const player = new Tone.Player().toDestination();
          player.load(FileSound).then(() => { player.start(); })
          .catch(error => { console.error("Error al cargar el archivo de audio:", error); });
          */

        }
                
        const newIndex = (prevIndex + 1) % Group.length;
        SetGrid(Group[newIndex]);
        return newIndex;
      });

    }, 1000);
    return () => clearInterval(intervalId);

  }, [Group, Animating, Frame]);

  useEffect(() => { 
    
   if(Frame >= 4) { 
    SetFrame(0);
    SetAnimating(false);
   }

  }, [Frame]);

//=====================================================================================

  // Game of Life
  const [Count, SetCount] = useState(0);  

  useEffect(() => { 
    // GameOfLife(); 
  });

  useEffect(() => { // Count active Bits

    if(!Grid) { return; }
    const filteredArray = Grid.filter((element) => element === 1);
    const count = filteredArray.length;
    SetCount(count);

  }, [Grid]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Sound_OK = () => {

    if(!Egg) {

      Public.Scripts.Audio.Play({URI: `${Public.ENV.URL}/Public/Media/Sounds/Egg/Init/Torii.mp3`});
      /*
      Tone.context.resume();
      let FileSound = `${Public.ENV.URL}/Public/Media/Sounds/Egg/Init/Torii.mp3`;
      const player = new Tone.Player().toDestination();
      player.load(FileSound).then(() => { player.start(); })
      .catch(error => { console.error("Error al cargar el archivo de audio:", error); });
      return; 
      */
    }
    else {
      Public.Scripts.Audio.Play({URI: `${Public.ENV.URL}/Public/Media/Sounds/Egg/${Egg.Character}/OK.mp3`});
    }


    /*
    Tone.context.resume();
    let FileSound = `${Public.ENV.URL}/Public/Media/Sounds/Egg/${Egg.Character}/OK.mp3`;
    const player = new Tone.Player().toDestination();
    player.load(FileSound).then(() => { player.start(); })
    .catch(error => { console.error("Error al cargar el archivo de audio:", error); });
    */

  }

  const Play_Note = (coords) => {
    
    return; 
    function getNoteFromIndex(x, y) {
      const center = { x: 4, y: 4 };
      const distance = Math.sqrt((x - center.x) ** 2 + (y - center.y) ** 2);
      const octave = Math.floor((distance / 4) + 4);
      const note = ["C", "D", "E", "F", "G", "A", "B"][x % 7];
      return `${note}${octave}`;
    }

    let note = getNoteFromIndex(coords.x, coords.y);
    Tone.context.resume();
    var synth = new Tone.Synth().toDestination();
    synth.volume.value = -10;
    synth.triggerAttackRelease(note, '8n');

  }

  const Toggle_Bit = (coords) => {
    
    let newGrid = Grid.slice();
    let index = ((coords.y-1) * 11) + (coords.x - 1);

    if(newGrid[index] === 0) { newGrid[index] = 1; }
    else 
    if(newGrid[index] === 1) { newGrid[index] = 0; } 
    
    SetGrid(newGrid);

  }

  const Handle_Bit = (index) => {
    
    // Note
    function getCoordinates (i) {
      const x = ((i) % 11) + 1;
      const y = (Math.floor((i) / 11)) +1;
      return { x, y };
    }

    let coords = getCoordinates(index);
    
    Play_Note(coords);

    // Toggle
    Toggle_Bit(coords);

  }

// Dialog
//----------------------------------------------------------------------------------------

  useEffect(() => {
    
    if(!Dialogs[Mode]) { console.log(`Mode ${Mode} not defined`); }

    // Si el texto se ha completado, no hay necesidad de continuar.
    if (charIndex >= Dialogs[Mode][phraseIndex].length) return;
    
    // Función que agrega las letras una por una.
    const typeLetter = () => {
      if (charIndex < Dialogs[Mode][phraseIndex].length) {
        setText(prevText => prevText + Dialogs[Mode][phraseIndex][charIndex]);
        setCharIndex(prevCharIndex => prevCharIndex + 1);
      }
    };
    
    // Usando setTimeout en lugar de setInterval.
    const timeoutId = setTimeout(typeLetter, 50); // 100ms entre cada letra.

    // Limpiar el timeout cuando el componente se desmonta o se cambia la frase.
    return () => clearTimeout(timeoutId);

  }, [charIndex, phraseIndex]);

  
  const handleDialogClick = () => {

    console.log("click")
    SetAnimating(!Animating);

    Sound_OK();

    if (charIndex < Dialogs[Mode][phraseIndex].length) {
        // Si el texto aún se está escribiendo, muestra el texto completo.
        setText(Dialogs[Mode][phraseIndex]);
        setCharIndex(Dialogs[Mode][phraseIndex].length);
    } else if (phraseIndex < Dialogs[Mode].length - 1) {
        // Si no es la última frase, avanza a la siguiente.
        setPhraseIndex(prevIndex => prevIndex + 1);
        setText(''); // Resetear el texto.
        setCharIndex(0); // Empezar desde el primer carácter.
    } else {
        SetDialogVisible(false); // Ocultar el diálogo si es la última frase.
    }

  };

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>

${IsDialogVisible ? html`
  <div className="_Dialog">
      ${text}
  </div>
` : null}

<div id="Digitama" className="_Square" onClick=${() => { handleDialogClick()} }><!--Grid->

  <!--<img src="${Public.ENV.URL}/Public/Media/Database/Guardians/Aura.gif" className="Digitama"/>-->

  <div id="Grid">
    
    ${Grid && Grid.map((Cell, index) => {

      if(Cell === 0) { return html`
      <div className="Cell Void" onClick=${(event) => { Handle_Bit(index); }} key="Cell_${index}"/>`; }
      else
      if(Cell === 1) { return html`
      <div className="Cell Full" onClick=${(event) => { Handle_Bit(index); }} key="Cell_${index}"/>`; }

    })}

  </div>

</div>

<//>
`);

//----------------------------------------------------------------------------------------
};