//-----------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class CLIENT_LIBRARIES { constructor (MAIA) {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.State = {
    Tone: undefined,
    React: undefined,
    ReactDOM: undefined,
    html: undefined,
    styled: undefined
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Init (Libraries) {
//-----------------------------------------------------------------------------

  this.Set_Libraries(Libraries);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Add_Library (libraryName, libraryValue) {
//-----------------------------------------------------------------------------

  this.State[libraryName] = libraryValue;

}
//-----------------------------------------------------------------------------
  Set_Libraries (Libraries) {
//-----------------------------------------------------------------------------

  // Add the libraries loaded from the <script> tags,
  // wich comes from the Board, and may have no module version yet.
  this.Add_Library("React", Libraries.React);
  this.Add_Library("ReactDOM", Libraries.ReactDOM);
  this.Add_Library("html", Libraries.html);
  this.Add_Library("styled", Libraries.styled);
  this.Add_Library("Tone", Libraries.Tone);
  this.Add_Library("anime", Libraries.anime);
  // this.Add_Library("Audio", Libraries.Audio);
  
}
//-----------------------------------------------------------------------------
}