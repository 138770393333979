//----------------------------------------------------------------------------------------

  import { Styled } from './Style/Style_Admin_Studio.js';
  import { Admin_Studio_Record } from './Record/Admin_Studio_Record.js';

//----------------------------------------------------------------------------------------
  export const Admin_Studio = ({ Props }) => {
//----------------------------------------------------------------------------------------

  Props.URI = `${Public.ENV.URL}/Apps/Admin/Parts/Studio`;
  const Style = Styled(Public.Libs.styled, {}, Props.URI);
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const RouterDOM = Public.Libs.RouterDOM;
  const location = RouterDOM.useLocation();

//████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

  const [Sections, SetSections] = useState(["Record"]);

//=====================================================================================

return (html`
<${Style}>

<div className="_Navbar">
<h2 className="_Title">Studio</h2>
</div>

<div className="_Grid">

<${RouterDOM.Route} exact path='/admin/studio'>
  ${Sections.map((section) => {
    // let route = section.toLowerCase();
    let route = section;
    return html`
    <${RouterDOM.Link} to="/Admin/Studio/${route}" key="Menu_${section}">
    <div className="_Box" key="Key_${section}">
      <img src="${Props.URI}/${section}/.About/Icon/Icon.png"/>
      <div className="_Placeholder"></div>
      <div className="_Title">${section}</div>
    </div>
    <//>
    `;
  })}
<//>

</div>


<${RouterDOM.Route} exact path="/admin/studio/record">
  <div className="_">
  <${Admin_Studio_Record} Props=${Props} />
  </div>
<//>

<//>
`);
}