//----------------------------------------------------------------------------------------

  import { App_Main } from "./Main/App_Main.js";
  import { App_Sidebar } from "./Sidebar/App_Sidebar.js";
  // import { Style } from './Style/Style_Board.js';

//----------------------------------------------------------------------------------------
  export default ({ }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------
 
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const RouterDOM = Public.Libs.RouterDOM;
  // const { useTranslation, Trans } = Public.i18n.React;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄

  // const [Fullscreen, SetFullScreen] = useState(false);
  const [Modal, SetModal] = useState({type: "", options: {}});
  const [Maia, SetMaia] = useState({ID: 0});
  const [Section, SetSection] = useState("intro"); // ???

//----------------------------------------------------------------------------------------

  useEffect(() => {
      
    /*
    if(Public.ENV.MODE === 'PLAY')       { console.info("🎮 MODE::PLAY"); }
    else if(Public.ENV.MODE === 'EDIT')  { console.info("⚙️ MODE::EDIT"); }
    else if(Public.ENV.MODE === 'ADMIN') { console.info("🗝️ MODE::ADMIN"); }
    else { console.info("* MODE::undefined"); }
    */
    
  }, [Public.ENV.MODE]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Props
//----------------------------------------------------------------------------------------

  const Props = {
    Section: {
      get: Section,
      set: SetSection,
      Maia: {
        get: Maia,
        set: SetMaia
      }
    },
    Modal: {
      get: Modal,
      set: SetModal
    },
    Node: {
      Board: undefined,
      Sidebar: undefined,
      Main: undefined
    }
  }

  const theBoard = useRef(null);
  useEffect(()=> { Props.Node.Board = theBoard.current; }, [Props.Node]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${RouterDOM.Router}>

<div className="Board" ref=${theBoard}>
  
  <${App_Sidebar} Props=${Props} id="App_Sidebar" />
  <${App_Main} Props=${Props} />

</div>

<//>
`);

//----------------------------------------------------------------------------------------
};