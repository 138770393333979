/*▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██████████████████████████████████   📁 imports   ████████████████████████████████████
██████████████████████████████████████████████████████████████████████████████████████
██*/                                                                              /*██
██*/  /*-----------------------------------------------------------------------   /*██
██/*  |  📁 Template                                                          |   /*██
██*/  /*---------------------------------------------------------------------*/   /*██
██*/  import { Styled_Maia } from './Style/Styled_Maia.js';                       /*██
██*/                                                                              /*██
██*/  /*-----------------------------------------------------------------------   /*██
██/*  |  📁 App                                                               |   /*██
██*/  /*---------------------------------------------------------------------*/   /*██
██*/  import { MAIA } from './Core/MAIA.js';                                      /*██
██*/                                                                              /*██
████████████████████████████████████████████████████████████████████████████████████*/

export const App_MAIA = ({ Props }) => { 
  
  const { useRef, useEffect } = Public.Libs.React;
  const html = Public.Libs.html;

  // DOM references.
  const theCase = useRef(null);
  const theRender = useRef(null);
  const theCanvas = useRef(null);
  const thePanel = useRef(null);
  const theLog = useRef(null);

  let _MAIA;
  let URI_Logo = `${Public.ENV.URL}/Public/Media/Images/Logo/Maia.png`;


useEffect(() => { // <=============================mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm
  
/*▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
███████████████████████████████████   💻 PROPS   █████████████████████████████████████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀*/

  Props = {
    ENV: {
      User: Props.ENV.User,
      Chip: Props.ENV.Chip,
      Scene: Props.ENV.Scene,
      Mode: Props.ENV.Mode,
    },

    Tree: {
      Client: {
        Agent: undefined,
        Window: window,
        Style: Props.Tree.Client.Style,
        DOM: {
          Case: theCase.current,
          Render: theRender.current,
          Canvas: theCanvas.current,
          Panel: thePanel.current,
          Log: theLog.current
        },
      },
      Render: Props.Tree.Render
    },

    Reload: () => {
      // window.location.reload(false); 
      // document.body.requestFullscreen();
      // _MAIA = new MAIA(Agent, Options);
    }

  }

/*▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
████████████████████████████████████   🎮 MAIA   █████████████████████████████████████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀*/

  _MAIA = new MAIA(Props);
  
  return () => {
    
    // Reset
    console.log("❌ Unmounted MAIA");
    _MAIA.TREE.SOUND.Reset();

    _MAIA.Loaded = false;
    _MAIA = null;
    
  }

}, []); // <=============================mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm

/*▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██████████████████████████████████   🖼️ Preact   █████████████████████████████████████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀*/
    
  return (html`
  <${Styled_Maia} Style=${Props.Tree?.Client?.Style}>
  <div id="Maia_Case" ref=${theCase}>
  <div id="Logo">
    <!--<img src="${Public.ENV.URL}/Public/Media/Symbol/Logo/Kanji.png"/>-->
    <!--<i className="devicon-raspberrypi-line"></i>-->
    <img src="${URI_Logo}" />
    <span>MAIA</span>
  </div>
  <div id="Maia_Screen">
    <div id="Maia_Render" ref=${theRender}>
      <div id="Maia_Log" ref=${theLog}></div>
      <canvas id="Maia_Canvas" ref=${theCanvas}></canvas>
      <div id="Maia_Panel" ref=${thePanel} className="focus"></div>
    </div>
  </div>
  </div>
  <//>
  `);
  
  };