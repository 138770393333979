import { OAuth } from "./OAuth/OAuth.js";
import { Styled } from './Style/Style_Admin_Apis.js';

export const Admin_Apis = ({ Props }) => {

 const { useState, useEffect, useRef } = Public.Libs.React;
 const html = Public.Libs.html;
 const Style = Styled(Public.Libs.styled, {}, Props.URI);

//████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

return (html`
<${Style}>

  <h2>OAuth:</h2>
  <div className="Grid_Boxes">
  <${OAuth}/>
  </div>
  
<//>
`);
}