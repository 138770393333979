//----------------------------------------------------------------------------------------
  export class DATA_PROTO { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Protos = [];

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

}
//----------------------------------------------------------------------------------------
}