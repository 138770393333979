//--------------------------------------------------------------------------------------
  export class Public_DB { constructor() {
//--------------------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
/**
* Deserialize_FormData processes FormData to link Blob objects to nested item structures based on path-like keys.
* 
* @param {FormData} FormData - Contains all data entries.
* @param {string} mainKey - Initial key segment in FormData representing the primary JSON data structure.
* @param {string} filesPath - Dot-separated path to locate file containers within items.
* 
* The keys in FormData resembling paths (e.g., "Protos/Aura/Jump") are split into:
* - 'mainKey' (e.g., "Protos"): Specifies the starting point in FormData.
* - 'Ent ID' (e.g., "Aura"): Represents the real identifier of an entity within the parsed items.
* - 'File ID' (e.g., "Jump"): Identifies the specific file within the entity where the Blob is to be attached.
*/
//--------------------------------------------------------------------------------------
  Deserialize_FormData (FormData, mainKey, filesPath) {
//--------------------------------------------------------------------------------------

  const items = JSON.parse(FormData.get(mainKey) || '[]');

  function getObjectsFromPath(obj, path) {
    return path.split('.').reduce((currents, key) => {
      if (!currents.length) return [];
      return currents.flatMap(current =>
        Array.isArray(current[key]) ? current[key] : (current[key] ? [current[key]] : [])
      );
    }, [obj]);
  }

  FormData.forEach((value, key) => {
    if (key !== mainKey && key.includes('/')) {
      // Aquí ajustamos la manera de dividir la clave para extraer correctamente el ID del Ent y el ID del File.
      const segments = key.split('/');
      const prefix = segments[0];
      if (prefix === mainKey) {
        const fileID = segments.pop(); // ID del archivo
        const entID = segments.slice(1).join('/'); // Junta los segmentos para formar el ID del Ent, ignorando la 'mainKey'
        let item = items.find(item => item.ID === entID);

        if (item) {
          const fileContainers = getObjectsFromPath(item, filesPath);
          let file = fileContainers.find(f => f.ID === fileID);

          if (file) { file.Blob = value; }
        }
      }
    }
  });

  return items;
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//--------------------------------------------------------------------------------------
  async Read ({ URI, Items, Interface, Callback }) {
//--------------------------------------------------------------------------------------

  // console.error("📦 DB: Read", `🖇️ ${URI}`, Items, Interface, Callback);
  if(!URI || URI === 'undefined') { return; }

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));
  formData.append('Interface', JSON.stringify(Interface ? Interface : {}));

  await fetch(`${Public.ENV.URL}/db/read`, { method: 'POST', body: formData })
  .then(fetch => fetch.formData())
  .then(res => { 
    const items = this.Deserialize_FormData(res, "Items", "Files");
    console.log("Read", items);
    Callback(items);
  })
  .catch(error => {
    console.error("DB: Read:", error);
  });

}
//--------------------------------------------------------------------------------------
  async File ({URI, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: File", `🖇️ ${URI}`);

  let formData = new FormData();
  formData.append('URI', URI);

  await fetch(`${Public.ENV.URL}/db/file`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.blob())
  .then(res => { Callback(res); });

}
//--------------------------------------------------------------------------------------
  async Save ({URI, Items, Interface, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: Save", `🖇️ ${URI}`, Items);

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));

  await fetch(`${Public.ENV.URL}/db/save`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => { Callback(res); });

}
//--------------------------------------------------------------------------------------
  async Edit ({URI, Items, Interface, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: Edit", `🖇️ ${URI}`, Items);

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));
  formData.append('Interface', JSON.stringify(Interface ? Interface : {}));

  await fetch(`${Public.ENV.URL}/db/edit`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => { Callback(res); });

}
//--------------------------------------------------------------------------------------
  async Drop ({URI, Items, Interface, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: Delete", `🖇️ ${URI}`, Items);

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));
  
  await fetch(`${Public.ENV.URL}/db/drop`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => { Callback(res); });

}
//--------------------------------------------------------------------------------------
}