
  import { LCD } from "./Parts/LCD.js";
  import { Styles } from './Styles/Styles_Digi.js';

//----------------------------------------------------------------------------------------
  export const Digi = ({ Egg }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Public.Styles.Styled(Styles, {});
  const RouterDOM = Public.Libs.RouterDOM;
  
//████████████████████████████████████████████████████████████████████████████████████

  const [Letters, SetLetters] = useState("VERITASEMPEREST");

  useEffect(() => { if(!Egg) { return; }
    
    // console.log("🥚", Egg);
    if(Egg.Prize) { SetLetters(Egg.Prize); }
    else if(Egg.Ask) { SetLetters(Egg.Ask); }

  }, [Egg]);
  
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Generate_Digicode = () => {

    if(!Letters) { return; }

    const numLetters = Letters.length;
    const radius = 155;
    
    return Letters.split("").map((Letter, i) => {

      let angle = (i / (numLetters / 2)) * Math.PI;
      let x = Math.cos(angle) * radius;
      let y = Math.sin(angle) * radius;
      let transform = `translate(${x}px, ${y}px) rotate(${angle}rad)`;
      
      i++;
      let className = "";
      if(numLetters > i) { className = "Active"; }
      else 
      if(numLetters === i) { className = "Active Last"; }

      return html`
      <span className="${className}" style=${{
        transform: transform
      }}
      key="Span_Letter_${i}">${Letter}</span>
      `;
    })

  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>

  <div id="Digicode" className="_Digicode Active ${!Egg && "Legible"} ${Egg?.Character}">
    ${Generate_Digicode(Letters)}
  </div>

  <${LCD} Egg=${Egg} />

<//>
`);

//----------------------------------------------------------------------------------------
};