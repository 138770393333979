//----------------------------------------------------------------------------------------
  export class DATA_ENT { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Ents = [];

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

}
//----------------------------------------------------------------------------------------
  Find (Ent_ID, Group) {
//----------------------------------------------------------------------------------------

  return this.Ents.find((ent) => ent.ID === Ent_ID);

}
//----------------------------------------------------------------------------------------
}