//-----------------------------------------------------------------------------
  export class CORE_TIME { constructor () {
//-----------------------------------------------------------------------------

  this.Count = 0;
  this.Count_Max = 3600;
  
  this.lastTimestamp = performance.now();
  this.currentSecond = Math.floor(this.lastTimestamp / 1000);
  this.frameCount = 0;
  this.framesLastSecond = 0;

  // Delta - Tiempo transcurrido entre frames, en ¿milisegundos?
  this.Delta = 0;
  this.deltaHistory = [];

}
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------
  
  let t = performance.now();

  this.Calculate_Delta(t);
  this.Calculate_FPS(t);
  this.Calculate_Count();

}
//----------------------------------------------------------------------------------------
  Calculate_FPS (t) {
//----------------------------------------------------------------------------------------

  let currentSecond = Math.floor(t / 1000);
  if (currentSecond !== this.currentSecond) {
    this.currentSecond = currentSecond;
    this.framesLastSecond = this.frameCount;
    this.frameCount = 1;
  } else {
    this.frameCount++;
  }

  this.lastTimestamp = t;

}
//-----------------------------------------------------------------------------
  Calculate_Count () {
//-----------------------------------------------------------------------------
    
  this.Count++;
  const maxGlobalFrameCount = 3600;
  if (this.Count >= maxGlobalFrameCount) { this.Count = 0; }

}
//-----------------------------------------------------------------------------
  Calculate_Delta (t) {
//-----------------------------------------------------------------------------
    
  let rawDelta = (t - this.lastTimestamp) / 1000;  // Convertir la diferencia de tiempo a segundos
  this.lastTimestamp = t;  // Actualizar el último timestamp para el próximo frame

  // Añadir el delta crudo al historial para el promedio móvil
  this.deltaHistory.push(rawDelta);
  if (this.deltaHistory.length > 5) {
    this.deltaHistory.shift(); // Mantener solo los últimos 5 deltas
  }

  // Calcular el promedio móvil del delta
  this.Delta = this.deltaHistory.reduce((a, b) => a + b, 0) / this.deltaHistory.length;

  // Opcional: Imprimir el delta calculado para depuración
  // console.log(`Calculated Delta: ${this.Delta}`);

}
//-----------------------------------------------------------------------------
  Timer (Callback) { 
//-----------------------------------------------------------------------------
  let t1 = performance.now(); console.log(t1)
  Callback();
  let t2 = performance.now(); console.log(t2)
  let diff = t2 - t1;
  alert("diff"); 
  return diff;
}
//----------------------------------------------------------------------------------------
}