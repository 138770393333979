//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT_SELECT { constructor (MAIA) {
//---------------------------------------------------------------------------

  this.MAIA = MAIA;

  // Cubit
  this.CubitSelected = undefined;

  // If the trigger click for drag & drop was on the selected cubit.
  this.PressSelected = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Init () {
//---------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Focus (Ent) {
//---------------------------------------------------------------------------

  // Unselect
  if(this.CubitSelected) { 
    this.CubitSelected._Ent.Mode = 'Default';
    this.CubitSelected = undefined;
  }

  // Select
  if(!Ent) { this.CubitSelected = null; return; }
  this.CubitSelected = Ent.CUBIT;
  Ent.Mode = 'Selected';

}
//---------------------------------------------------------------------------
}