export const Notify = (styled, Proto, Props) => { return styled.createGlobalStyle`

.Container_Notify {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.Notify {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  padding: 2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 20rem;
  max-width: 30rem;
  cursor: pointer;
}

.Notify img {
  width: 50px;
  height: auto;
  margin-right: 2rem;
}

.Notify .Text {
  display: flex;
  flex-direction: column;
}

.Notify .Text .Title {
  font-weight: bold;
  margin-bottom: 5px;
}

.Notify .Text .Description {
  font-size: 14px;
}

`}