import { Style } from './Style/Style_Items_Merch_Detail.js';

export const Items_Merch_Detail = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();

//████████████████████████████████████████████████████████████████████████████████████

  const [Item, SetItem] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    Get_Merch();
    return () => { setIsMounted(false); };
  }, []);

  const Get_Merch = async () => {
    const currentIsMounted = true;
    Public.DB.Read({
      URI: `Admin/Merch`, 
      Items: ["ALL"], 
      Callback: (items) => {  
        if (currentIsMounted) {
          console.log("items", items[0])
          SetItem(items[0]);
        }
      }
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>

${Item && html`
  <div className="_Image">
    <img src=${Public.Scripts.Blob_To_Img_URL(Item.Files[0].Blob)}/>
  </div>
  <div className="_Content">
    <h3>${Item.Name}</h3>
    <span>${Item.Price}</span>
    <p>${Item.Description}</p>
  </div>
`}

<//>
`);
}