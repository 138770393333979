export const Styles = (styled, Proto, Props) => {

return styled.div`
width: 100%;
height: 100%;
max-height: 100%;
padding: 0rem;
// overflow-y: scroll;
position: relative;
display: flex;
flex-direction: column;
padding: 4rem 6rem;

.Board_Items_Main {
width: 100%;
height: 100vh;
padding: 0rem;
overflow-y: scroll;
}

.Board_Title{
margin: 4rem 8rem 0rem 8rem;
color: #222;
font-size: 22px;
font-weight: bold;
font-family: 'Kenney Square';
padding-bottom: 12px;
border-bottom: 2px solid #222;

display: flex;
align-items: center;
justify-content: space-between;

& > i:hover{
cursor: pointer;
color: #777;
}

&__Label {
display: flex;
align-items: center;
justify-items: baseline;

  .icon {
    width: 40px;
    margin-right: 1rem;
  }

  & > i{
  margin-right: 2rem;
  }

  &:hover {
  cursor: pointer;
  color: #777;
  }

}

}

`;

}