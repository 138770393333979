export const Text = (styled, Proto, Props) => {
return styled.css`

  .Text_Icon {
    --ColorShadow1: rgba(128, 128, 128, 0.8);
    --ColorLight1: rgba(235, 235, 235, 0.2);
    --ColorLight2: rgba(205, 205, 205, 0.3);
    text-shadow: 
    1px 0 var(--ColorShadow1),  0 1px var(--ColorShadow1),  -1px 0 var(--ColorShadow1),  0 -1px var(--ColorShadow1),
    2px 0 var(--ColorLight1),  0 2px var(--ColorLight1),  -2px 0 var(--ColorLight1),  0 -2px var(--ColorLight1);
    // 4px 0 var(--ColorLight2),  0 4px var(--ColorLight2),  -4px 0 var(--ColorLight2),  0 -4px var(--ColorLight2)
    // ;
  }


  #Nav {
  width: 100%;

    ._Title {
    // font-family: 'Open Sans Bold';
    font-family: 'Ginora Sans';
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.64);
    font-weight: bold;

      img { 
      height: 48px;
      margin-right: 16px;
      }

    }

    ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

      li {
      width: auto;
      color: #777;
      font-family: 'Ginora Sans';
      font-size: 2rem;
      padding: 1rem 2rem;
      margin: 0 1rem;
      border-bottom: 3px solid transparent;

        &._Active, &:hover {
        cursor: pointer;
        border-bottom: 3px solid #87afe6;
        color: #87afe6;
        }

      }

    }

  }

`}