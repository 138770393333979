import { Styled } from './Style/Style_Select.js';

export const Select = ({ EVT, Props }) => {

  const html = Public.Libs.html;
  const { useEffect, useRef, useState, useLayoutEffect } = Public.Libs.React;
  const Style = Styled(Public.Libs.styled, EVT.Styles);

  const [Options, SetOptions] = useState(Props.Options);
  
  return html`
  <${Style}>
  <div className="select-container">

  <select className="select-input" onChange=${(e) => { Props.onChange(e.target.value) }}>
    ${Options.map((option, index) => { return html`
      <option key=${index} value=${option}>${option}</option>
    `})}

  </select>
  </div>
  <//>
  `;

}