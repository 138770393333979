//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class RENDER_PAINTER { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Plane (Cubit_Size) {
//----------------------------------------------------------------------------------------

  if(!Cubit_Size) { console.error("Cubit Size not provided", Cubit_Size); return; }

  let Size = structuredClone(Cubit_Size);

  // Generate Temp Cubit, assume that the coords are 0, 0, 0.
  let Cubit = {
    POS: { Size: Size, Coords: {x: 0, y: 0, z: 0}}
  };

  let Verts = this.MAIA.TREE.CAMERA.ISO.getIsoVerts(Cubit);
  let Scale = this.MAIA.TREE.CAMERA.ZOOM.Scale;

  if(!Verts?.backUp) { console.error(Cubit, Verts); }

  let Canvas_Height = Math.round(Verts.backUp.h - Verts.frontDown.h) * Scale;
  let Canvas_Width = Math.round(-(Verts.leftUp.w) + Verts.rightUp.w) * Scale;

  // Generate Canvas
  var Canvas = document.createElement("canvas");
  let Ctx = Canvas.getContext('2d');
  Canvas.height = Canvas_Height;
  Canvas.width = Canvas_Width;

  // Paint the Canvas.
  Ctx.fillStyle = "transparent"; // Ctx.fillStyle = "#333";
  Ctx.fillRect(0, 0, Canvas.width, Canvas.height);
  
  return {Canvas, Ctx};

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Point ({w, h}, CTX = this.CTX, Color, Radius) {
//----------------------------------------------------------------------------------------

  let scale = this.MAIA.TREE.CAMERA.ZOOM.Scale;
  if(!Color) { Color = 'blue'; }
  if(!Radius) { Radius = 3; }

  CTX.globalAlpha = 0.7;
  let circle = new Path2D();
  circle.arc(w-0, h-0, Radius, 0, 2 * Math.PI, false);
  CTX.fillStyle = Color;
  CTX.fill(circle);
  CTX.globalAlpha = 1;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Cubit ({Cubit, Ctx, Color, Opacity, Plain}) {
//----------------------------------------------------------------------------------------

  let Canvas;

  if(!Cubit) { console.error("Cubit not provided", Cubit, Ctx, Color, Opacity); return; }

  if(Ctx) { Canvas = Ctx.canvas; } 
  else {
    Canvas = this.MAIA.TREE.RENDER.PAINTER.Plane(Cubit.POS.Size).Canvas;
    Ctx = Canvas.getContext('2d');
  }

  let SaveCoords = structuredClone(Cubit.POS.Coords);
  Cubit.POS.Coords = {x:0, y:0, z:0};
  let Scale = this.MAIA.TREE.CAMERA.ZOOM.Scale;
  let moveHorizontally = -(((Cubit.POS.Size.x*Scale) - (Cubit.POS.Size.y*Scale))/2.31);
  Ctx.translate(Canvas.width/2 + moveHorizontally, Canvas.height);

  // Draw Cube over Context
  let GeoCanvas = this.Cube({Cubit, Ctx, Color, Opacity, Plain});
  
  Ctx.translate(-Canvas.width/2 + moveHorizontally, -Canvas.height);
  Ctx.globalAlpha = 1;
  Cubit.POS.Coords = {...SaveCoords};

  return GeoCanvas;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Cube ({Cubit, Ctx, Opacity, Color, Plain}) {
//----------------------------------------------------------------------------------------

  if(!Cubit)   { console.error("Cubit not provided", Cubit); return; }
  if(!Ctx)     { console.error("CCtxubit not provided", Ctx); return; }
  if(!Color)   { Color = "rgba(30, 190, 270, 0.3)"; }
  if(!Opacity && Opacity !== 0) { Opacity = 1; }
  if(!Plain)   { Plain = false; }
  
  // Utils
  let GEOMETRY = this.MAIA.TREE.RENDER.GEOMETRY;

  // Opacity
  Ctx.globalAlpha = Opacity;

  // Color
  Ctx.fillStyle = Color;
  Ctx.fillRect(0, 0, Ctx.canvas.width, Ctx.canvas.height);
  let rgba = this.MAIA.TREE.RENDER.UTILS.Hex_To_RGBA(Color, Opacity);
  
  // Vertices
  var b = this.MAIA.TREE.CAMERA.ISO.getIsoNamedSpaceVerts(Cubit);

  if(Plain) {
		
    Ctx.beginPath();
    GEOMETRY.moveTo(Ctx, b.frontDown);
    GEOMETRY.lineTo(Ctx, b.leftDown);
    GEOMETRY.lineTo(Ctx, b.leftUp);
    GEOMETRY.lineTo(Ctx, b.backUp);
    GEOMETRY.lineTo(Ctx, b.rightUp);
    GEOMETRY.lineTo(Ctx, b.rightDown);
    Ctx.fillStyle = rgba;
    Ctx.fill();

  }
  else {
    
    // Color
    let colorLight = this.MAIA.TREE.RENDER.UTILS.ShadeColor(0.1, rgba);
    let colorMedium = this.MAIA.TREE.RENDER.UTILS.ShadeColor(0, rgba);
    let colorDark = this.MAIA.TREE.RENDER.UTILS.ShadeColor(-0.1, rgba);

    // fill in the grout for the inside edges
    var lineWidth = 1;
    GEOMETRY.line(Ctx, b.leftUp, b.frontUp, colorMedium, lineWidth);
    GEOMETRY.line(Ctx, b.rightUp, b.frontUp, colorMedium, lineWidth);
    GEOMETRY.line(Ctx, b.frontDown, b.frontUp, colorMedium, lineWidth);

    // Do not add line width when filling faces.
    // This prevents a perimeter padding around the hexagon.
    // Nonzero line width could cause the perimeter of another box
    // to bleed over the edge of a box in front of it.
    lineWidth = 0;

    // fill each visible face of the block.

    // left face
    GEOMETRY.fillQuad(Ctx, b.frontDown, b.leftDown, b.leftUp, b.frontUp, colorMedium, lineWidth);

    // top face
    GEOMETRY.fillQuad(Ctx, b.frontUp, b.leftUp, b.backUp, b.rightUp, colorLight, lineWidth);

    // right face
    GEOMETRY.fillQuad(Ctx, b.frontDown, b.frontUp, b.rightUp, b.rightDown, colorDark, lineWidth);

  }

  return Ctx.canvas;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Intersect_Of (canvasA, canvasB, alpha = 0) {
//----------------------------------------------------------------------------------------
   
  // Creamos un nuevo canvas C basado en las dimensiones de A
  var canvasC = document.createElement('canvas');
  canvasC.width = canvasA.width;
  canvasC.height = canvasA.height;
  
  var ctxC = canvasC.getContext('2d');
  
  // Creamos un contexto temporal para manipular canvasB
  var tempCanvas = document.createElement('canvas');
  tempCanvas.width = canvasB.width;
  tempCanvas.height = canvasB.height;
  var tempCtx = tempCanvas.getContext('2d');

  // Dibuja la imagen B en el canvas temporal
  if (canvasB.width > 0 && canvasB.height > 0) {
    tempCtx.drawImage(canvasB, 0, 0);
  } else {
    console.error("The canvasB element has a width or height of 0.");
  }  
  
  // Obtiene los datos de imagen
  var imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
  var data = imageData.data;

  // Cambia todos los píxeles a negro con opacidad completa si la opacidad original es mayor que 0
  for (var i = 0; i < data.length; i += 4) {
    if (data[i + 3] > 0) {
      data[i]     = 0;   // Rojo
      data[i + 1] = 0;   // Verde
      data[i + 2] = 0;   // Azul
      data[i + 3] = 255; // Alpha
    }
  }

  // Pone los datos de imagen modificados de vuelta en el canvas temporal
  tempCtx.putImageData(imageData, 0, 0);

  // Ahora dibuja la imagen B (modificada) en C
  ctxC.drawImage(tempCanvas, 0, 0);
  
  // Cambia el modo de mezcla a 'source-in'
  ctxC.globalCompositeOperation = 'source-in';
  
  // Dibuja la imagen A en C
  ctxC.drawImage(canvasA, 0, 0);

  // Restauramos el modo de mezcla a su valor por defecto
  ctxC.globalCompositeOperation = 'source-over';

  // Cambiamos la opacidad y dibujamos nuevamente la imagen A,
  // para los píxeles que no coinciden con los de B
  ctxC.globalAlpha = alpha;
  ctxC.drawImage(canvasA, 0, 0);

  // Restauramos la opacidad a su valor por defecto
  ctxC.globalAlpha = 1.0;

  return canvasC;


}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Clean (Canvas) {
//----------------------------------------------------------------------------------------
  let Ctx = Canvas.getContext('2d');
  Ctx.fillStyle = "transparent"; // Ctx.fillStyle = "#333";
  Ctx.fillRect(0, 0, Ctx.canvas.width, Ctx.canvas.height);
}
//----------------------------------------------------------------------------------------
}