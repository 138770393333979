export class CAMERA_ZOOM { constructor (MAIA) {

  this.MAIA = MAIA;

  this.Scale = 1;

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------


}
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  /* Down */  if(Input.Wheel.DeltaY.value > 0){ this.MAIA.TREE.CAMERA.ZOOM.More(0.1); } else
  /* Up */    if(Input.Wheel.DeltaY.value < 0){ this.MAIA.TREE.CAMERA.ZOOM.Less(0.1); }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Set_Scale (value) { return false;
//----------------------------------------------------------------------------------------

  if(value < 0.5) return;
  this.Scale = value;

  // Recreate <Canvas> of Cubits (only those who are in the "Focus")
  // this.MAIA.CHIP.Cubit[0].Children[0].Render_Area();
  // this.MAIA.CHIP.Cubit[0].Children[1].Render_Area();

}
//----------------------------------------------------------------------------------------
  More (perc) {
//----------------------------------------------------------------------------------------

  let value = this.Scale + (this.Scale * perc);
  this.Set_Scale(value);

}
//----------------------------------------------------------------------------------------
  Less (perc) {
//----------------------------------------------------------------------------------------

  let value = this.Scale - (this.Scale * perc);
  this.Set_Scale(value);

}
//----------------------------------------------------------------------------------------
}