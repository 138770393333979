export const Styles = (styled, Proto, Props) => {
return styled.div`

width: 100%;
height: 100%;
padding: 0;
margin: 0;
z-index: 100;

._Circle {
// visibility: hidden;
// position: absolute;
margin-left: 13.5%; 
margin-top: 13.5%;
width: 73%;
height: 73%;
overflow: hidden;
z-index: 100;
background: #333;
border-radius: 100%;
// border: 15px solid rgba(255, 255, 255, 0.9);
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) inset, 
-2px -2px 10px rgba(255, 255, 255, 0.1),
inset 0px 0px 15px rgba(0, 0, 0, 0.2);
display: flex;
justify-content: center;
align-items: center;

  @media (max-width: 768px) {
  // width: 420px;
  // height: 420px;
  }


  ._Logotipo {
  position: absolute;
  bottom: 17.5%;
  color: #333;
  font-family: "Ginora Sans";
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  
  color: #eee;
  font-size: 2rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;   
    
    img {
    width: 2.4rem;
    margin-left: -0.5rem;
    margin-right: 0.25rem;
    margin-top: -0.25rem;
    }

    span {
      @media (max-width: 768px) {
      font-size: 2rem;
      }
    }

    /*
    &.Active {
      img:hover {
      transform: rotate(1440deg);
      transition: 1.7s;
      }
    }
    */

  }

}

`;

}