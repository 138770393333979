//--------------------------------------------------------------------------------------
  export class Public_DB { constructor() {
//--------------------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//--------------------------------------------------------------------------------------
 Deserialize_FormData({ Data, Data_ID, URI_Files }) {
//--------------------------------------------------------------------------------------

  const items = JSON.parse(Data.get(Data_ID) || '[]');

  function getObjectsFromPath(obj, paths) {
    let currentLevel = [obj];

    paths.forEach(key => {
      currentLevel = currentLevel.flatMap(levelItem => {
        if (levelItem && key in levelItem) {
          const value = levelItem[key];
          return Array.isArray(value) ? value : [value];
        }
        return [];
      });
    });

    return currentLevel;
  }

  // Procesar cada item en items
  items.forEach(item => {
    const fileContainers = getObjectsFromPath(item, URI_Files.split('.').filter(Boolean));

    fileContainers.forEach(file => {
      if (file && file.URI) {
        const blob = Data.get(file.URI);
        if (blob) {
          file.Blob = blob;
        }
      }
    });
  });

  return items;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//--------------------------------------------------------------------------------------
  async Read ({ URI, Items, Interface, Callback }) {
//--------------------------------------------------------------------------------------

  // console.error("📦 DB: Read", `🖇️ ${URI}`, Items, Interface, Callback);
  if(!URI || URI === 'undefined') { return; }

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));
  formData.append('Interface', JSON.stringify(Interface ? Interface : {}));

  await fetch(`${Public.ENV.URL}/db/read`, { method: 'POST', body: formData })
  .then(fetch => fetch.formData())
  .then(res => { 

    let InterfaceFiles = Interface?._Files || ".Files";
    const items = this.Deserialize_FormData({Data: res, Data_ID: "Items", URI_Files: InterfaceFiles});
    // console.log("Read", items);
    Callback(items);

  })
  .catch(error => {
    console.error("DB: Read:", error);
  });

}
//--------------------------------------------------------------------------------------
  async File ({URI, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: File", `🖇️ ${URI}`);

  let formData = new FormData();
  formData.append('URI', URI);

  await fetch(`${Public.ENV.URL}/db/file`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.blob())
  .then(res => { Callback(res); });

}
//--------------------------------------------------------------------------------------
  async Save ({URI, Items, Interface, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: Save", `🖇️ ${URI}`, Items);

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));

  await fetch(`${Public.ENV.URL}/db/save`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => { Callback(res); });

}
//--------------------------------------------------------------------------------------
  async Edit ({URI, Items, Interface, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: Edit", `🖇️ ${URI}`, Items);

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));
  formData.append('Interface', JSON.stringify(Interface ? Interface : {}));

  await fetch(`${Public.ENV.URL}/db/edit`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => { Callback(res); });

}
//--------------------------------------------------------------------------------------
  async Drop ({URI, Items, Interface, Callback}) {
//--------------------------------------------------------------------------------------

  // console.info("📦 DB: Delete", `🖇️ ${URI}`, Items);

  let formData = new FormData();
  formData.append('URI', URI);
  formData.append('Items', JSON.stringify(Items));
  
  await fetch(`${Public.ENV.URL}/db/drop`, { 
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(res => { Callback(res); });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//--------------------------------------------------------------------------------------
  async Open ({ URI }) {
//--------------------------------------------------------------------------------------

  if(!URI || URI === 'undefined') { return; }

  let formData = new FormData();
  formData.append('URI', URI);

  await fetch(`${Public.ENV.URL}/db/open`, { method: 'POST', body: formData })
  .then(fetch => fetch.formData())
  .then(res => { 

    console.log("Open Result:", res);

  })
  .catch(error => {
    console.error("DB: Open:", error);
  });

}
//--------------------------------------------------------------------------------------
}