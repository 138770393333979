export const Styled = (styled, Styles) => {

const Style = styled.div`
width: 100%;

.select-container {
width: 100%;
padding: 0 1rem;
}

.select-input {
width: 100%;
height: 35px;
border-radius: 5px;
border: 1px solid #ccc;
padding: 5px;
font-size: 16px;
color: #333;
outline: none;
}

.select-input:focus {
border-color: #66afe9;
box-shadow: 0 0 5px rgba(102, 175, 233, 0.75);
}

`;

return Style;

}