//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class RENDER_BOARD_RAIN { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Canvas = undefined;
  this.Ctx = undefined;

  // State
  this.State = "Off";
  this.Drops = [];
  this.dropCreationInterval = null;
  this.lastTime = 0;

  this.FIXED_STEP = 16;
  this.WIND_VELOCITY = -0.1; // Determines how slanted the rain drops fall, 0 = straight down
  this.DROP_COUNT = 200; // Adjust for more/less rain drops
  this.DROP_WIDTH = 1; // Increase for thicker rain
  this.DROP_X_BUFFER = 50; // How far to the sides of the screen drops will spawn
  this.DROP_COLOR = "lightblue";
  this.DROP_MIN_VELOCITY = 0.3;
  this.DROP_MAX_VELOCITY = 0.6;
  this.DROP_MIN_LENGTH = 20;
  this.DROP_MAX_LENGTH = 40;
  this.DROP_MIN_ALPHA = 0.3;
  this.DROP_MAX_ALPHA = 1;

  this.math = {
      // Random integer between min and max
      randomInteger: function (min, max) {
          return Math.round((Math.random() * (max - min)) + min);
      },
      // Linear Interpolation
      lerp: function (a, b, n) {
          return a + ((b - a) * n);
      },
      scaleVector: function (v, s) {
          v.x *= s;
          v.y *= s;
      },
      normalizeVector: (v) => {
          var m = Math.sqrt(v.x * v.x + v.y * v.y);
          this.math.scaleVector(v, 1 / m);
      }
  };

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
  
  this.Canvas = this.MAIA.TREE.RENDER.Canvas;
  this.Ctx = this.MAIA.TREE.RENDER.Ctx;

  return true;
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  if(this.State != "On") { return; }

  this.Drops_Init();
  this.Drops_Render();

}
// ████████████████████████████████████  🌧️ Drops  ████████████████████████████████████
//----------------------------------------------------------------------------------------
  Drops_Init () {
//----------------------------------------------------------------------------------------
    
  // Function to load drops[]
  var dt = this.MAIA.CORE.TIME.Delta * 1000;

  // Mantenemos la protección contra dt excesivamente grandes
  if (dt > 100) { dt = this.FIXED_STEP; }

  // El bucle de pasos fijos permanece igual
  while (dt >= this.FIXED_STEP) {
      this.updateDrops(this.FIXED_STEP);
      dt -= this.FIXED_STEP;
  }

  // Si no hemos alcanzado el DROP_COUNT, añadir una gota
  if (this.Drops.length < this.DROP_COUNT) {
      var drop = {};
      this.resetDrop(drop);
      drop.y = this.math.randomInteger(0, this.Canvas.height);
      this.Drops.push(drop);
  }

}
//----------------------------------------------------------------------------------------
  Drops_Render () {
//----------------------------------------------------------------------------------------
	
  this.Ctx.save();

  if(this.Ctx.strokeStyle === '#000000') {
    // alert("blak")
  }

	this.Ctx.strokeStyle = this.DROP_COLOR;
	this.Ctx.lineWidth = this.DROP_WIDTH;
	this.Ctx.compositeOperation = "lighter";

	for (var i = 0; i < this.Drops.length; ++i) {
		var drop = this.Drops[i];

		var x1 = Math.round(drop.x);
		var y1 = Math.round(drop.y);

		var v = { x: drop.vx, y: drop.vy };
		this.math.normalizeVector(v);
		this.math.scaleVector(v, -drop.l);

		var x2 = Math.round(x1 + v.x);
		var y2 = Math.round(y1 + v.y);

		this.Ctx.globalAlpha = drop.a;
		this.Ctx.beginPath();
		this.Ctx.moveTo(x1, y1);
		this.Ctx.lineTo(x2, y2);
		this.Ctx.stroke();
		this.Ctx.closePath();
	}
  
	this.Ctx.restore();

};
//----------------------------------------------------------------------------------------
  updateDrops (dt) {
//----------------------------------------------------------------------------------------

    for (var i = this.Drops.length - 1; i >= 0; --i) {
		var drop = this.Drops[i];
		drop.x += drop.vx * dt;
		drop.y += drop.vy * dt;

		if (drop.y > this.Canvas.height + drop.l) {
			this.resetDrop(drop);
		}
	}
  
};
//----------------------------------------------------------------------------------------
  resetDrop (drop) {
//----------------------------------------------------------------------------------------
  // Reset a drop to the top of the canvas
	var scale = Math.random();
	drop.x = this.math.randomInteger(-this.DROP_X_BUFFER, this.Canvas.width + this.DROP_X_BUFFER);
	drop.vx = this.WIND_VELOCITY;
	drop.vy = this.math.lerp(this.DROP_MIN_VELOCITY, this.DROP_MAX_VELOCITY, scale);
	drop.l = this.math.lerp(this.DROP_MIN_LENGTH, this.DROP_MAX_LENGTH, scale);
	drop.a = this.math.lerp(this.DROP_MIN_ALPHA, this.DROP_MAX_ALPHA, scale);
	drop.y = this.math.randomInteger(-drop.l, 0);
};
//----------------------------------------------------------------------------------------
}