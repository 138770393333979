/*██████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░/                   \░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░/                  // \░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░/                  //   \░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░/                  / /    \░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░/__________________/ /      \░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░\                    \       \░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░\                    \       l░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░\                    \      /░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░\                    \    /░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░\                    \  /░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░\____________________\/░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
----------------------------------------------------------*/
//----------------------------------------------------------------------------------------

  import { RENDER_PAINTER } from './PAINTER/RENDER_PAINTER.js';
  import { RENDER_BOARD } from "./BOARD/RENDER_BOARD.js";
  import { RENDER_UTILS } from "./UTILS/RENDER_UTILS.js";
  import { RENDER_GEOMETRY } from "./GEOMETRY/RENDER_GEOMETRY.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_RENDER { constructor(MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  // Interfaces
  /*🖼️*/ this.PAINTER = new RENDER_PAINTER(MAIA);
  /*🎨*/ this.UTILS = new RENDER_UTILS(MAIA);
  /*📐*/ this.GEOMETRY = new RENDER_GEOMETRY(MAIA);
  /*🟦*/ this.BOARD = new RENDER_BOARD(MAIA); 

  // Data
  this.Canvas = undefined;
  this.Ctx = undefined;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Canvas = this.MAIA.TREE.CLIENT.DOM.State.Canvas.Ref;
  this.Ctx = this.MAIA.TREE.CLIENT.DOM.State.Canvas.Context;

  this.BOARD.Init();
  this.UTILS.Init();
  this.GEOMETRY.Init();

}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

}
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  if(!this.MAIA.TREE.CAMERA.Active) { return; }

  this.BOARD.Under();
  this.Render_Cubits();
  this.BOARD.Over();
    
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Render_Cubits () {
//----------------------------------------------------------------------------------------

  for(let i = 0; i < this.MAIA.TREE.CUBIT.Cubits.length; i++) {
    let Cubit = this.MAIA.TREE.CUBIT.Cubits[i];
    if (!Cubit) { return false; }
    this.Render_Cubit(Cubit);
    this.Paint_Cubit(Cubit);
  }

}
//----------------------------------------------------------------------------------------
  Paint_Cubit (Cubit) {
//----------------------------------------------------------------------------------------
  
  let EVT_PAINT = Cubit._Ent.PAINT;
  if(!EVT_PAINT) { return; }

  let Canvas = EVT_PAINT.Canvas;
  if(!Canvas) { return; }

  let Scale = this.MAIA.TREE.CAMERA.ZOOM.Scale;
  let theCoords = this.MAIA.TREE.CAMERA.FOCUS.Get_Position(Cubit.POS.Coords);
  theCoords.w -= (Canvas.width/2);
  theCoords.h -= (Canvas.height);
  let moveHorizontally = -((Cubit.POS.Size.x - Cubit.POS.Size.y)/2.31); // ToDo: Magic Number
  theCoords.w -= moveHorizontally;
  
  this.Ctx.drawImage(Canvas, theCoords.w, theCoords.h, Canvas.width, Canvas.height);
  
}
//----------------------------------------------------------------------------------------
  Render_Cubit (Cubit) { 
//----------------------------------------------------------------------------------------
  
  let Ent = Cubit._Ent;
  let RENDER = Cubit._Ent.RENDER;
  let Render_Canvas;
  
  if(RENDER && RENDER.Active) {  // console.log("Loading graphics...");

    Render_Canvas = RENDER.Active.Canvas; // STYLE Canvas
    if(!Render_Canvas) { console.error(RENDER.Active); return false; }
    // if(RENDER.Shadow) { Render_Canvas = RENDER.Shadow; }

  }
  else { 
    // console.log("Loading Render...", Cubit._Ent.ID)
    return false; 
  }

//----------------------------------------------------------------------------------------
  
  let scale = this.MAIA.TREE.CAMERA.ZOOM.Scale;
  let theCoords = this.MAIA.TREE.CAMERA.FOCUS.Get_Position(Cubit.POS.Coords);
  theCoords.w -= (Render_Canvas.width/2);
  theCoords.h -= (Render_Canvas.height);
  let moveHorizontally = -((Cubit.POS.Size.x - Cubit.POS.Size.y)/2.31); // ToDo: Magic Number
  theCoords.w -= moveHorizontally; // 

  // Mode
  this.Ctx.save();

  if(Cubit.PHY.Matter === 'NONE') { return; }

  // if(Cubit.PHY.Matter === 'VOID') { return; this.Ctx.globalAlpha = 0; }

  // Edit mode
  if(this.MAIA.TREE.MODE.ACTIVE.ID === 'EDIT') {
    
    // if(Ent.Mode === 'Hovered') { this.Ctx.globalAlpha = 0.7; }
    if(Ent.Mode === 'Hovered') { this.Ctx.filter = 'sepia(40%)'; }

    // Render <Style>
    this.Ctx.drawImage(Render_Canvas, theCoords.w, theCoords.h, Render_Canvas.width, Render_Canvas.height);

    // <CUBES>
    if(Ent.Mode === 'Selected') {
    
      if(Ent.RENDER?.GEO.Canvas) { // }) instanceof HTMLCanvasElement){ 
        this.Ctx.drawImage(Ent.RENDER?.GEO.Canvas, theCoords.w, theCoords.h, Render_Canvas.width, Render_Canvas.height);
      }

    }

  }
  else { // default mode

    // Render <Style>
    this.Ctx.drawImage(Render_Canvas, theCoords.w, theCoords.h, Render_Canvas.width, Render_Canvas.height);

  }

  this.Ctx.restore();  

  return;
  
}
//----------------------------------------------------------------------------------------
}