
  import { Styles } from './Styles/Styles_Readme.js';

//----------------------------------------------------------------------------------------
  export const Readme = ({ }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Public.Styles.Styled(Styles, {});

  
//████████████████████████████████████████████████████████████████████████████████████
  
  const Component_Slider = Public.Scripts.Choose_Part('Slider');
  const [Slides, SetSlides] = useState(undefined);

//=====================================================================================

  useEffect(() => {
    
    let slides = [
      html`
      <div className="_Page" key="Slide_1">
      <h2 className="_Title">¡Inicia tu aventura!</h2>
      <div className="_Content">
        <div className="_Image"><img src="${Public.ENV.URL}/Apps/Init/Media/Readme/Codebase.png"/></div>
        <p className="_Text">
          <span className="_Initori">initori</span> es un estudio de videojuegos educativos.
          
          </p>
      </div>
      </div>
      `,
      html`
      <div className="_Page" key="Slide_3">
      <h2 className="_Title">La aventura de Aura</h2>
      <div className="_Content">
          <div className="_Image"><img src="${Public.ENV.URL}/Apps/Init/Media/Readme/Aura.png"/></div>
          <p className="_Text">
            <span className="_AuraAdventure">Aura Adventure</span> es el primer título 
            de <span className="_Initori">Initori</span>, centrado en la enseñanza de <i>software</i>.
          </p>
      </div>
      </div>
      `,
      html`
      <div className="_Page" key="Slide_4">
      <h2 className="_Title">La aventura de Aura</h2>
      <div className="_Content">
        <div className="_Image"><img src="${Public.ENV.URL}/Apps/Init/Media/Readme/Restaurar.png"/></div>
        <p className="_Text">
          <!--Aura vive en el mundo de <i>Maia</i>, donde todo es posible gracias a la programación.-->
          Tu objetivo será restaurar el mundo digital de <i>Maia</i>, en el que vive Aura y otras criaturas.
        </p>
      </div>
      </div>
      `,
      /*html`
      <div className="_Page" key="Slide_5">
      <h2 className="_Title">Diseña tus códigos</h2>
      <div className="_Content">
        <div className="_Image"><img src="${Public.ENV.URL}/Apps/Init/Media/Readme/Codes.png"/></div>
        <p className="_Text">
          Durante tu aventura encontrarás <span className="_Codes"><span className="_Disk">📀</span> Códigos</span>,
          que te serán muy útiles.
        </p>
      </div>
      </div>
      `,*/
      html`
      <div className="_Page" key="Slide_6">
      <h2 className="_Title">Diseña tus códigos</h2>
      <div className="_Content">
        <div className="_Image"><img src="${Public.ENV.URL}/Apps/Init/Media/Readme/Styles.png"/></div>
        <p className="_Text">
        Podrás escribir tus propios programas, e ir mejorándolos conforme avances.
        </p>
      </div>
      </div>
      `,
      html`
      <div className="_Page" key="Slide_7">
      <h2 className="_Title">Tu propio espacio</h2>
      <div className="_Content">
        <div className="_Image"><img src="${Public.ENV.URL}/Apps/Init/Media/Readme/Books.png"/></div>
        <p className="_Text">
        También podrás diseñar tu propia base secreta; 
        un lugar donde seguir aprendiendo a tu ritmo.
        </p>
      </div>
      </div>
      `,
      html`
      <div className="_Page" key="Slide_8">
      <h2 className="_Title">Tu propia aventura</h2>
      <div className="_Content">
        <div className="_Image"><img src="${Public.ENV.URL}/Apps/Init/Media/Readme/Codes.png"/></div>
        <p className="_Text">
        ¡Y esto es solamente el comienzo! Multitud de misterios te aguardan.
        <!--El mundo de Maia tiene multitud de posibilidades por explorar.-->
        </p>
      </div>
      </div>
      `
    ];

    SetSlides(slides);
    
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>

<div id="App_Readme">
<div className="_Read">

  <${Suspense} fallback=${html`<div>...</div>`}>

    ${Slides && html`<${Component_Slider} Props=${{Slides: Slides}} />`}
    
  <//>

</div>
</div>

<//>
`);

//----------------------------------------------------------------------------------------
};