  
  import { Styled } from './Style/Style_Admin_Onda_List.js';
  import { Admin_Onda_Community } from "../Community/Admin_Onda_Community.js";

export const Admin_Onda_List = ({ Props, Section, SetSection }) => {

  let URI = `${Public.ENV.URL}/Apps/Admin/Parts/Onda`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

  const [Items, SetItems] = useState([]);
  const [Item, SetItem] = useState(undefined);
  const [isMounted, setIsMounted] = useState(false);

//=====================================================================================

  useEffect(() => {
    setIsMounted(true);
    Get_Ondas();
    return () => { setIsMounted(false); };
  }, []);

  const Get_Ondas = async () => {
    const currentIsMounted = true;
    Public.DB.Read({
      URI: `Admin/Onda`, 
      Items: ["ALL"], 
      Callback: (items) => {
        if (currentIsMounted) {
          console.log("Store: Onda", items);
          SetItems(items);
        }
      }
    });
  }

//=====================================================================================

return (html`
<${Style}>

<div className="_List">

  <div className="_Navbar">
    <h2 className="_Title">Onda</h2>
    <${RouterDOM.Link} to="/Admin/Onda/Save" className="_Button">
      <i className="icofont-plus icofont-1x"></i> Añadir
    <//>
  </div>

  <div className="_Ondas">
  ${Items && Array.isArray(Items) && Items.map((Item, i) => {
    return html`
    <${RouterDOM.Link} to="/Admin/Onda/Detail/${Item.ID}" key="Admin_Onda_List_${Item.ID}">
    <div className="_Onda" onClick=${()=> { SetItem(Item) }} key="Admin_Onda_Card_${i}">
      <!--<img src="${Public.ENV.URL}/Public/Media/Database/Tweets/ID/${Item.ID}/File.png" />-->
      <img src=${Public.Scripts.Blob_To_Img_URL(Items[i].Files[0]?.Blob)}/>
      <p className="_Text">${Item.Text}</p>
    </div>
    <//>
    `
  })}
  </div>

</div>

<div className="_Sidebar">
  <${Admin_Onda_Community} Props=${Props}/>
</div>

<//>
`);
}