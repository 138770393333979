//-----------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class CLIENT_INPUT { constructor (MAIA, CLIENT) {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------

  this.MAIA = MAIA;
  this.MAIA.CLIENT = CLIENT;

  this.State = {

  Gamepad: { // Nintendo system
    A: { Active: false, release: true, }, // refactor => release <=> Pressed
    B: { Active: false, release: true, },
    X: { Active: false, release: true, },
    Y: { Active: false, release: true, },
    
    L1: { Active: false, release: true, },
    R1: { Active: false, release: true, },

    Select: { Active: false, release: true, },
    Start: { Active: false, release: true, },

    Axe_X: { Active: false, release: true, value: undefined, },
    Axe_Y: { Active: false, release: true, value: undefined, },

    Right: { Active: false, release: true, },
    Left: { Active: false, release: true, },
    Down: { Active: false, release: true, },
    Up: { Active: false, release: true, },
  },
    
  Keyboard: {
    Left:   { Active: false, release: true }, // ⬅️  [37]
    Up:     { Active: false, release: true }, // ⬆️  [38]
    Right:  { Active: false, release: true }, // ➡️  [39]
    Down:   { Active: false, release: true }, // ⬇️  [40]
    Space:  { Active: false, release: true }, // [] [49]
    Enter:  { Active: false, release: true }, // [] [13]
    Shift:  { Active: false, release: true }, // [] [16] 
    Ctrl:   { Active: false, release: true }, // [] [17] 
    Supr:   { Active: false, release: true }, // [] [46] 
    Z:      { Active: false, release: true },  // [] [90]
    X:      { Active: false, release: true },  // [] [88]
    Q:      { Active: false, release: true },  // [] [81]
    W:      { Active: false, release: true },  // [] [87]
  },

  Cursor: {
    Hover: {
      target: undefined,
      Position: {x: 0, y: 0},
    },
    Left: {
      Active: false,
      release: true,
      target: undefined,
      type: "mouseup", // undefined, mousedown, mouseup, double
      PositionFirst: {x: 0, y: 0},
      PositionNow: {x: 0, y: 0},
    },
    Right: {
      Active: false,
      release: true,
      target: undefined,
      Position: {x: 0, y: 0},
    }
  },

  Wheel: {
    DeltaY: { value: 0 },
  },
    
  };

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Init () {}
//-----------------------------------------------------------------------------
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Run () {
//-----------------------------------------------------------------------------

  this.Refresh_Gamepad();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Get_State_Selector(Selector) {
//-----------------------------------------------------------------------------

  let Input = this.State;

  for (let key of Selector) {
      if (!Input) return null;
      Input = Input[key];
  }

  return Input;
  
}
//-----------------------------------------------------------------------------
  Read_Input (Selector, Callback, NeedRelease, uniqueID) {
//-----------------------------------------------------------------------------

  let Input = this.Get_State_Selector(Selector);

  if (!Input) {
      console.error(Selector);
      return false; // No se ejecutó el callback.
  }

  // Si no se ha inicializado el estado de release para este ID, inicialízalo
  if (!Input.releaseStates) {
      Input.releaseStates = {};
  }

  // Si el estado de release para este ID no se ha establecido aún, establece el valor por defecto (true)
  if (typeof Input.releaseStates[uniqueID] === 'undefined') {
      Input.releaseStates[uniqueID] = true;
  }

  if (Input.Active) {

    // Si no se necesita liberar o ya está liberado para este ID específico, ejecutamos el callback
    if (!NeedRelease || (NeedRelease && Input.releaseStates[uniqueID])) {
        Callback();
        if (NeedRelease) {
            Input.releaseStates[uniqueID] = false;
        }
        // if(uniqueID) { console.log(uniqueID) }
        return true; // Se ejecutó el callback.
    }
  } else {
    // Restablece el estado de release solo para este ID
    Input.releaseStates[uniqueID] = true;
  }

  return false; // No se ejecutó el callback.

}
//-----------------------------------------------------------------------------
  Refresh_Gamepad () {
//-----------------------------------------------------------------------------

  // Gamepad need to be refreshed on every iteration in Chrome.
  if(!navigator?.getGamepads) { return false; }

  let Gamepad = navigator.getGamepads()[0];
  if (Gamepad) {

    this.State.Gamepad.A.Active = Gamepad.buttons[1].pressed;
    this.State.Gamepad.B.Active = Gamepad.buttons[2].pressed;
    this.State.Gamepad.X.Active = Gamepad.buttons[0].pressed;
    this.State.Gamepad.Y.Active = Gamepad.buttons[3].pressed;
    this.State.Gamepad.L1.Active = Gamepad.buttons[4].pressed;
    this.State.Gamepad.R1.Active = Gamepad.buttons[6].pressed;
    this.State.Gamepad.Select.Active = Gamepad.buttons[8].pressed;
    this.State.Gamepad.Start.Active = Gamepad.buttons[9].pressed;
    this.State.Gamepad.Axe_X.value = Gamepad.axes[0];
    this.State.Gamepad.Axe_Y.value = Gamepad.axes[1];

    this.State.Gamepad.Right.Active = false;
    this.State.Gamepad.Left.Active = false;
    this.State.Gamepad.Down.Active = false;
    this.State.Gamepad.Up.Active = false;
    if (this.State.Gamepad.Axe_X.value > 0.9)       { this.State.Gamepad.Right.Active = true;  } 
    else if (this.State.Gamepad.Axe_X.value < -0.9) { this.State.Gamepad.Left.Active = true;  } 
    else if (this.State.Gamepad.Axe_Y.value > 0.9)  { this.State.Gamepad.Down.Active = true;  } 
    else if (this.State.Gamepad.Axe_Y.value < -0.9) { this.State.Gamepad.Up.Active = true;  } 

  }

}
//-----------------------------------------------------------------------------
  Gamepad_Vibration () {
//-----------------------------------------------------------------------------

  // Obtener referencia al gamepad
  if(!navigator?.getGamepads) { return false; }
  let Gamepad = navigator.getGamepads()[0];
  
  if (Gamepad.vibrationActuator) {
    Gamepad.vibrationActuator.playEffect("dual-rumble", {
      duration: 300, // duración de la vibración en milisegundos
      strongMagnitude: 0.1, // intensidad de la vibración fuerte (0.0 a 1.0)
      weakMagnitude: 0.2 // intensidad de la vibración débil (0.0 a 1.0)
    });
  } else {
    // console.error("Este gamepad no tiene motor de vibración, o el navegador no lo implementa.");
  }

}
//-----------------------------------------------------------------------------
  Set_Input_Keyboard (event, value) {
//-----------------------------------------------------------------------------

  // Block only when input text
  if (this.MAIA.CLIENT.DOM.State.Panel.Target?.nodeName == 'INPUT') { return; }
  if (this.MAIA.CLIENT.DOM.State.Panel.Target?.nodeName == 'TEXTAREA') { return; }

  // Block the input when the focus is over some Panel.
  // if(!this.MAIA.CLIENT.DOM.State.Panel.Active) { return false; }

  // Read KeyCodes:
  let key = event.keyCode;

  // Arrow keys
       if(key === 37){ this.State.Keyboard.Left.Active = value; }
  else if(key === 38){ this.State.Keyboard.Up.Active = value; }
  else if(key === 39){ this.State.Keyboard.Right.Active = value; }
  else if(key === 40){ this.State.Keyboard.Down.Active = value; }

  // Special keys
  else if(key === 32){ this.State.Keyboard.Space.Active = value; }
  else if(key === 13){ this.State.Keyboard.Enter.Active = value; }
  else if(key === 16){ this.State.Keyboard.Shift.Active = value; }
  else if(key === 17){ this.State.Keyboard.Ctrl.Active = value; }
  else if(key === 46){ this.State.Keyboard.Supr.Active = value; }
  else if(key === 90){ this.State.Keyboard.Z.Active = value; }
  else if(key === 88){ this.State.Keyboard.X.Active = value; }
  else if(key === 81){ this.State.Keyboard.Q.Active = value; }
  else if(key === 87){ this.State.Keyboard.W.Active = value; }

}
//-----------------------------------------------------------------------------
  Set_Hover (e) {
//-----------------------------------------------------------------------------

  // e.preventDefault(); // > Bug: Impide mover el input Range con fluidez

  this.State.Cursor.Hover.target = e.target;
  this.Set_Target(e.target);

  this.State.Cursor.Hover.Position = {
    x: e.clientX - this.MAIA.CLIENT.DOM.State.Canvas.Rect.left,
    y: e.clientY - this.MAIA.CLIENT.DOM.State.Canvas.Rect.top,
  }
  
}
//-----------------------------------------------------------------------------
  Set_LeftClick (value, type, e) {
//-----------------------------------------------------------------------------

  this.State.Cursor.Left.Active = value;
  this.State.Cursor.Left.type = type;
  this.State.Cursor.Left.target = e.target;
  this.Set_Target(e.target);
  
  this.State.Cursor.Left.PositionFirst = {
    x: e.clientX - this.MAIA.CLIENT.DOM.State.Canvas.Rect.left,
    y: e.clientY - this.MAIA.CLIENT.DOM.State.Canvas.Rect.top
  }

}
//-----------------------------------------------------------------------------
  Set_LeftClickMove (e) {
//-----------------------------------------------------------------------------

  if(this.State.Cursor.Left.Active){
    this.State.Cursor.Left.PositionNow = {
        x: e.clientX - this.MAIA.CLIENT.DOM.State.Canvas.Rect.left,
        y: e.clientY - this.MAIA.CLIENT.DOM.State.Canvas.Rect.top
    }
  }
  
}
//-----------------------------------------------------------------------------
  Set_RightClick (isActive, e){
//-----------------------------------------------------------------------------
  
  e.preventDefault();

  // Target
  this.Set_Target(e.target);
  this.State.Cursor.Right.target = e.target;

  // Active
  this.State.Cursor.Right.Active = isActive;

  // Inactive left
  this.State.Cursor.Left.Active = false;

  if(isActive){
    // Position
    this.State.Cursor.Right.Position = {
        x: e.clientX - this.MAIA.CLIENT.DOM.State.Canvas.Rect.left,
        y: e.clientY - this.MAIA.CLIENT.DOM.State.Canvas.Rect.top,
    }
  }

  return false;

}
//-----------------------------------------------------------------------------
  Set_Target (target) {
//-----------------------------------------------------------------------------

  if (!target) {
    console.error("Target is null or undefined");
    return;
  }

  let currentElement = target;
  let isPanel = false;

  // Itera a través de los ancestros del elemento hasta llegar a #Maia_Panel
  while (currentElement && currentElement.id !== 'Maia_Panel') {
    if (currentElement.getAttribute('data-type') === 'Panel') {
      isPanel = true;
      break; // Rompe el ciclo si encuentra un elemento con data-type="Panel"
    }
    currentElement = currentElement.parentElement;
  }

  // Actualiza el estado dependiendo de si el elemento está dentro de un Panel
  if (isPanel) {
    this.MAIA.CLIENT.DOM.State.Panel.Active = true;
    this.MAIA.CLIENT.DOM.State.Panel.Target = target;
  } else {
    this.MAIA.CLIENT.DOM.State.Panel.Active = false;
    this.MAIA.CLIENT.DOM.State.Panel.Target = undefined;
  }

}
//-----------------------------------------------------------------------------
  Set_Wheel (value) {
//-----------------------------------------------------------------------------

  this.State.Wheel.DeltaY.value = value;
  // console.log(this.State.Wheel.DeltaY.value);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Clean () { // Reset
//-----------------------------------------------------------------------------

  // Mouse wheel
  this.Set_Wheel(0);
  
  // Reset double click
  if(this.State.Cursor.Left.type === 'double') {
    this.State.Cursor.Left.type = undefined;
    this.State.Cursor.Left.Active = false;
  }

  // Reset target active
  // this.MAIA.CLIENT.DOM.State.Panel.Active = false;
  
}
//-----------------------------------------------------------------------------
}