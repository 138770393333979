export class CORE_ADMIN { constructor (MAIA) {

  this.MAIA = MAIA;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Digi ({ID, Data}) {
//----------------------------------------------------------------------------------------  
// 👾 Invocar un Digi en la terminal del Servidor.
//----------------------------------------------------------------------------------------  

  let formData = new FormData();
  formData.append('ID', ID);
  formData.append('Data', Data);
  fetch(`${Public.ENV.URL}/admin/digi`, { method: 'POST', body: formData })
  .then(response => response.text())
  .then(res => { });

}
//----------------------------------------------------------------------------------------
}