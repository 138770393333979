import { Styled } from './Style/Style_Admin_Digi.js';

export const Admin_Digi = ({ Props }) => {

 const { useState, useEffect, useRef } = Public.Libs.React;
 const html = Public.Libs.html;
 const Style = Styled(Public.Libs.styled, {}, Props.URI);

//████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

// const [Sections, SetSections] = useState(['Codebase', 'Database', 'Terminal', 'Library', 'Tests']);

const Digi = async () => {

  let Form_Data = new FormData();
  Form_Data.append('Digi', "logmon");
  Form_Data.append('Data', "Lorem Ipsum");

  await fetch(`${Public.ENV.URL}/admin/digi`, { method: 'POST', body: Form_Data })
  .then(response => response.text())
  .then(res => { console.log(res); });

}


return (html`
<${Style}>

  Digi!
  <button onClick=${() => {Digi()}}>Logmon</button>
  
<//>
`);
}