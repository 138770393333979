//------------------------------------------------------------------------------------

  import { Highlight } from './Highlight/Styles_Core_Modules_Highlight.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//------------------------------------------------------------------------------------
  export const Modules = (styled, Proto, Props) => { return styled.css`
//------------------------------------------------------------------------------------

  // 🟨 Highlight
  //----------------------------------------------------------------------------------
  ${Highlight(styled, Proto, Props)} {}

//------------------------------------------------------------------------------------
`; }