//----------------------------------------------------------------------------------------
  import { Styled } from '../Style/Style_Admin.js';
  // import { Admin_Start } from "./Start/Admin_Start.js";
  // import { Admin_Team } from "./Team/Admin_Team.js";
  // import { Admin_Calendar } from "./Calendar/Admin_Calendar.js";
  import { Admin_Calendar } from "./Calendar/Admin_Calendar.js";
  import { Admin_Server } from "./Server/Admin_Server.js";
  import { Admin_Studio } from "./Studio/Admin_Studio.js";
  import { Admin_Onda } from "./Onda/Admin_Onda.js";
  import { Admin_Inc } from "./Inc/Admin_Inc.js";
//----------------------------------------------------------------------------------------

export default ({ Props }) => {

  Props.URI = `${Public.ENV.URL}/Apps/Admin/Parts`;

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, Props.URI);
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████

  const [Sections, SetSections] = useState([
    "Calendar", "Server", "Studio", "Onda", "Inc"
    // "Team", "Calendar", "Calendar"
  ]);
  const [Section, SetSection] = useState("Calendar");
  const location = RouterDOM.useLocation();

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Style}>

<div id="_Admin">

<div className="_Menu">

  ${Sections.map((section) => {

    // let sectionPath = section.toLowerCase();
    let sectionPath = section;
    let active = location.pathname.startsWith(`/Admin/${sectionPath}`);

    return html`
    <${RouterDOM.Link} to="/Admin/${sectionPath}" key="Menu_${section}">
      <div onClick=${() => SetSection(section)}
      className="_Icon Center ${active ? "active" : ""}" key="Key_${section}">
        <img src="${Props.URI}/${section}/.About/Icon/Icon.png"/>
        <div className="_Placeholder"></div>
        <div className="_Title">${section}</div>
      </div>
    <//>
    `;
    
  })}

</div>

<div className="_Main">

  <${RouterDOM.Route} path='/Admin/Calendar'>
    <${Admin_Calendar} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Admin/Server'>
    <${Admin_Server} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Admin/Studio'>
    <${Admin_Studio} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Admin/Onda'>
    <${Admin_Onda} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Admin/Inc'>
    <${Admin_Inc} Props=${Props} />
  <//>
  
</div>

</div>
<//>
`);

};

/*
<${RouterDOM.Route} path='/Admin/imagi'>
  <${Admin_Calendar} Props=${Props} />
<//>

<${RouterDOM.Route} path='/Admin/team'>
  <${Admin_Team} Props=${Props} />
<//>

<${RouterDOM.Route} path='/Admin/calendar'>
  <${Admin_Calendar} Props=${Props} />
<//>
*/