
  import { Styles } from './Styles/Styles_Trailer.js';

//----------------------------------------------------------------------------------------
  export const Trailer = ({ }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Public.Styles.Styled(Styles, {});

  
//████████████████████████████████████████████████████████████████████████████████████
  
  const theVideo = useRef(null);
  const thePlay = useRef(null);

//----------------------------------------------------------------------------------------

  const Play_Video = () => {

    const videoElement = theVideo.current; 
    const playElement = thePlay.current;
    // let videoElement = ref.target;

    if (videoElement.paused) { 
      videoElement.play(); 
      playElement.style.display = 'none';
    } 
    else { 
      videoElement.pause(); 
      playElement.style.display = 'flex';
    }
    
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>

<div id="App_Trailer">
<div className="_Video">

  <video type='video/mp4' src='${Public.ENV.URL}/Apps/Init/Media/Trailer/Trailer.mp4'
  ref=${theVideo} onClick=${(ref) => { Play_Video(); } } />
  <div ref=${thePlay} className="_Video_Play" onClick=${() => Play_Video()}>
    <i className="icofont-ui-play icofont-2x"/>
  </div>

</div>
</div>

<//>
`);

//----------------------------------------------------------------------------------------
};