// import { CORE_RESOURCES_Tileset } from "./Tileset/CORE_RESOURCES_Tileset.js";
// import { CORE_RESOURCES_Tile } from "./Tile/CORE_RESOURCES_Tile.js";
// import { Sprited } from "./Sprites/Sprited.js";
import { CORE_RESOURCES_Thumbnail } from "./Thumbnail/CORE_RESOURCES_Thumbnail.js";

//----------------------------------------------------------------------------------------

export class CORE_RESOURCES { constructor (MAIA) {

  this.MAIA = MAIA;

  this.Thumbnail = new CORE_RESOURCES_Thumbnail(this);

  // this.Tileset = new CORE_RESOURCES_Tileset(this);
  // this.Tile = new CORE_RESOURCES_Tile(this);
  // this.Sprited = Sprited;
  
}

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Convert_BlobToImage (blob) {
//----------------------------------------------------------------------------------------

  return new Promise(resolve => {
    const url = URL.createObjectURL(blob);
    let img = new Image();
    img.src = url;
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    }
  });

};
//----------------------------------------------------------------------------------------
  Load_Image (src) {
//----------------------------------------------------------------------------------------
  src = `${this.MAIA.ENV.URL}/Public/Media/Tiles/${src}`;
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = src;
    img.onload = () => { 
      // console.info("LOADED", src);
      resolve(img);
    }
    img.onerror = reject;
  });
}
//----------------------------------------------------------------------------------------
  Source_To_Canvas (src) { // deprecated
//----------------------------------------------------------------------------------------
  return new Promise((resolve, reject) => {
    // Crear un nuevo objeto Image
    var img = new Image();

    // Configurar el controlador de eventos onload
    img.onload = function() {
      // Crear un nuevo elemento canvas
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      // Obtener el contexto 2D y dibujar la imagen en el canvas
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Resolver la promesa con el canvas
      resolve(canvas);
    };

    // Configurar el controlador de eventos onerror
    img.onerror = function() {
      // Rechazar la promesa con un mensaje de error
      console.log("Error");
      // reject(new Error('Error al cargar la imagen.'));
    };

    // Configurar el src de la imagen (esto iniciará la carga de la imagen)
    img.src = src;
  });
}
//----------------------------------------------------------------------------------------
}
