//------------------------------------------------------------------------------------ 

  import { Public_Parts } from './Parts/Public_Parts.js';
  import { Public_Routes } from './Routes/Public_Routes.js';
  import { Public_Scripts } from "./Scripts/Public_Scripts.js";
  import { Public_DB } from './Scripts/DB/Public_DB.js';
  import { Public_Token } from './Scripts/Token/Public_Token.js';
  import { Public_Styles } from './Styles/Public_Styles.js';
  import { Public_Libs } from './Modules/Public_Libs.js';
  import { Public_Input } from './Input/Public_Input.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//------------------------------------------------------------------------------------
  export class Public { constructor (ENV) {
//------------------------------------------------------------------------------------

  this.ENV = ENV;

  // App  
  this.App = undefined; // Load dinamically

  // Interfaces
  this.Styles = undefined;
  this.Scripts = undefined;
  this.Parts = undefined;
  this.Token = undefined;
  this.DB = undefined;
  this.TOKEN = undefined;

  window.addEventListener("offline", (e) => { this.ENV.LINE = false; });
  window.addEventListener("online", (e) =>  { this.ENV.LINE = true; });

  this.Routes = new Public_Routes(this);
  this.Libs = new Public_Libs(this);
  this.Input = new Public_Input(this);
  this.Check_Load();


}
//------------------------------------------------------------------------------------
  async Check_Load () {
//------------------------------------------------------------------------------------

  if(this.Libs.Loading >= 100) { 
    console.error("Error: Loading the Libs", this.Libs, this.Libs.Loading);
    return;
  }

  if(await this.Libs.Load(this.ENV.LINE)) { // if online/offline

    this.Styles = new Public_Styles(this);
    this.Scripts = new Public_Scripts(this);
    this.Parts = new Public_Parts();
    this.Token = new Public_Token();
    this.DB = new Public_DB();
    this.TOKEN = this.Token.Get('ACCESS');

    console.log("ENV", this.ENV, this.TOKEN);

    // Check if all Libs are installed. Then, Load App:
    await this.Load_App();
    this.Render_App();

  } else {
    this.Check_Load();
  }

}
//------------------------------------------------------------------------------------
  async Load_App () {
//------------------------------------------------------------------------------------
  if (this.ENV.DOOR === 'LOCAL') {
    this.App = this.Libs.React.lazy(() => import('../Apps/Apps.js'));
  } else if (this.ENV.DOOR === 'SERVER') {
    this.App = this.Libs.React.lazy(() => import('../Apps/.Bundle/Apps.js'));
  }
}
//------------------------------------------------------------------------------------
  async Render_App () {
//------------------------------------------------------------------------------------
  let Div = document.getElementById("App");
  ReactDOM.createRoot(Div).render(
    this.Libs.html`
      <${this.Styles.Core}>
        <${this.Libs.React.Suspense} fallback=${this.Libs.html`<div></div>`}>
          ${this.Libs.html`
            <${this.Libs.RouterDOM.Router}>
              <${this.App}/>
            <//>
          `}
        <//>
      <//>
    `
  );
}
//------------------------------------------------------------------------------------
}