//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_App_Blog_Detail.js';

//----------------------------------------------------------------------------------------

export const App_Blog_Detail = ({ Props }) => {

  const { useState, useEffect } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [filesContent, setFilesContent] = useState([]);
  const [Item, SetItem] = useState(null);

  useEffect(() => {
    Read_Blog();
  }, []);

  useEffect(() => {
    if (Item && Item.Files) {
      const fetchFilesContent = async () => {
        const contents = await Promise.all(Item.Files.map(async (file) => {
          if (file.Format === 'html') {
            const text = await file.Blob.text();
            return { type: 'html', content: text };
          } else if (file.Format === 'png') {
            const imgURL = Public.Scripts.Blob_To_Img_URL(file.Blob);
            return { type: 'png', content: imgURL };
          }
          return null;
        }));
        setFilesContent(contents);
      };
      fetchFilesContent();
    }
  }, [Item]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Read_Blog = () => {
    Public.DB.Read({
      URI: `Admin/Blog`, 
      Items: ["1"],
      Callback: (Items) => { 
        SetItem(Items[0]);
      }
    });
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

const Render = () => {
  if (!Item) { return null; }

  return filesContent.map((file, index) => {
    if (file.type === 'html') {
      return html`
        <div key=${index} className="_Text" dangerouslySetInnerHTML=${{ __html: file.content }}></div>
      `;
    } else if (file.type === 'png') {
      return html`
        <div key=${index} className="_Image">
          <img src=${file.content} className="icon"/>
        </div>
      `;
    }
  });
}

return (html`
  <${Styled}>
    <div className="App_Blog_Detail">
      <div className="Navbar">
        <h2 className="_Title">Blog</h2>
        <div className="_Info"><i className="icofont-info-square icofont-1x"/></div>
      </div>
      <div className="_Main">
        ${Render()}
      </div>
    </div>
  <//>
`);

};
