/*██████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░/                   \░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░/                  // \░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░/                  //   \░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░/                  / /    \░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░/__________________/ /      \░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░\                    \       \░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░\                    \       l░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░\                    \      /░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░\                    \    /░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░\                    \  /░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░\____________________\/░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
----------------------------------------------------------*/
//----------------------------------------------------------------------------------------

  import { RENDER_PAINTER } from './PAINTER/RENDER_PAINTER.js';
  import { RENDER_BOARD } from "./BOARD/RENDER_BOARD.js";
  import { RENDER_UTILS } from "./UTILS/RENDER_UTILS.js";
  import { RENDER_GEOMETRY } from "./GEOMETRY/RENDER_GEOMETRY.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_RENDER { constructor(MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  // Interfaces
  /*🖼️*/ this.PAINTER = new RENDER_PAINTER(MAIA);
  /*🎨*/ this.UTILS = new RENDER_UTILS(MAIA);
  /*📐*/ this.GEOMETRY = new RENDER_GEOMETRY(MAIA);
  /*🟦*/ this.BOARD = new RENDER_BOARD(MAIA); 

  // Canvas
  this.Canvas = undefined;
  this.Ctx = undefined;

  // Cubits
  this.Cubits = [];
  this.Loaded = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Canvas = this.MAIA.TREE.CLIENT.DOM.State.Canvas.Ref;
  this.Ctx = this.MAIA.TREE.CLIENT.DOM.State.Canvas.Ctx;

  this.BOARD.Init();
  this.UTILS.Init();
  this.GEOMETRY.Init();

}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

}
//----------------------------------------------------------------------------------------
  Check_Load () {
//----------------------------------------------------------------------------------------
  return !this.Cubits.some((Cubit) => Cubit._Ent.PAINT && !Cubit._Ent.PAINT?.Loaded_Styles);
}
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  // if(!this.MAIA.TREE.CAMERA.Active) { return; }

  // Culling on Cubits to render only those close to the Player
  if(this.MAIA.TREE.MODE.ACTIVE.ID === 'PLAY') {
    if(this.MAIA.TREE.MODE.ACTIVE.Player?.CUBIT) {
      this.Cubits = this.Culling(this.MAIA.TREE.CUBIT.Cubits, this.MAIA.TREE.MODE.ACTIVE.Player.CUBIT.POS.Coords);
    }
  }
  else {
    this.Cubits = this.MAIA.TREE.CUBIT.Cubits;
  }

  if(!this.Check_Load()) { return; }
  else { this.Loaded = true; }

  // Reduce iterations if FPS are low
  // if (this.MAIA.Performance()) { 
    this.BOARD.Under();
    this.Render_Cubits(this.Cubits);
    this.BOARD.Over();
  // }
    
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Culling (Cubits, Point) {
//----------------------------------------------------------------------------------------

  const maxDistance = 1024;
  const filteredCubits = Cubits.filter(cubit => {
    const dx = Math.abs(cubit.POS.Coords.x + cubit.POS.Size.x / 2 - Point.x);
    const dy = Math.abs(cubit.POS.Coords.y + cubit.POS.Size.y / 2 - Point.y);
    const dz = Math.abs((cubit.POS.Coords.z + cubit.POS.Size.z / 2) * 2 - Point.z * 2);
    return dx <= maxDistance + cubit.POS.Size.x / 2 &&
           dy <= maxDistance + cubit.POS.Size.y / 2 &&
           dz <= (maxDistance + cubit.POS.Size.z / 2) * 2;
  });

  // Marcar los cubits filtrados como activos
  filteredCubits.forEach(cubit => { if(cubit._Ent.PAINT) { cubit._Ent.PAINT.Active = true; } });
  
  return filteredCubits;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Render_Cubits (Cubits) {
//----------------------------------------------------------------------------------------

  for(let i = 0; i < Cubits.length; i++) {
    let Cubit = Cubits[i];
    if (!Cubit) { return false; }
    this.Paint_Cubit(Cubit);
  }

}
//----------------------------------------------------------------------------------------
  Paint_Cubit (Cubit) {
//----------------------------------------------------------------------------------------
  
  let Ent = Cubit._Ent;
  let EVT_PAINT = Cubit._Ent.PAINT;
  if(!EVT_PAINT) { return; }

  if(!EVT_PAINT.Loaded_Styles) {
    // console.log("loading...", EVT_PAINT._Ent.ID)
    return;
  }

  // ToDo: Wait until all Cubits are loaded
  let Canvas = EVT_PAINT.Canvas; 
  if(!Canvas) { 
    // console.error("no canvas", Cubit._Ent.ID)
    return; 
  }


  let Scale = this.MAIA.TREE.CAMERA.ZOOM.Scale;
  let theCoords = this.MAIA.TREE.CAMERA.FOCUS.Get_Position(Cubit.POS.Coords);
  theCoords.w -= (Canvas.width/2);
  theCoords.h -= (Canvas.height);
  let moveHorizontally = -((Cubit.POS.Size.x - Cubit.POS.Size.y)/2.31); // ToDo: Magic Number
  theCoords.w -= moveHorizontally;

  // Filters
  this.Ctx.save();
  if(Ent.Mode === 'Hovered') { this.Ctx.filter = 'brightness(1.1) saturate(1.1)'; }

  // Image
  this.Ctx.drawImage(Canvas, theCoords.w, theCoords.h, Canvas.width, Canvas.height);
  
  /*
  createImageBitmap(Canvas).then(imageBitmap => {
    this.Worker.postMessage({
      action: 'DrawImage',
      imageBitmap: imageBitmap,
      x: theCoords.w,
      y: theCoords.h,
      width: Canvas.width,
      height: Canvas.height
    }, [imageBitmap]);
  }).catch(error => {
    console.error('Error creating ImageBitmap:', error);
  });
  */

  // Select
  if(Ent.Mode === 'Selected') {
    let Canvas_Cube = this.MAIA.TREE.RENDER.PAINTER.Cubit({
      Cubit: Ent.CUBIT,
      Opacity: 0.5,
      Color: "#777777"
    });
    this.Ctx.drawImage(Canvas_Cube, theCoords.w, theCoords.h, Canvas.width, Canvas.height);
  }

  // Restore Filters
  this.Ctx.restore();  

}
//----------------------------------------------------------------------------------------
}