export const Dialog = (styled, Proto, Props) => { return styled.css`

dialog {
  border: none;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  background: #fff;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.4);
}

/* Estilos para el botón de cerrar */
dialog button {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

dialog button:hover {
  background-color: #0056b3;
}

/* Estilos para la cabecera y el contenido */
dialog h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
}

dialog p {
  font-size: 16px;
  color: #666;
}



`}