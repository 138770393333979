export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
padding: 4rem 6rem;

display: flex;
flex-direction: column;
align-items: center;
justify-content: start;
overflow-y: auto;

._About_List {
width: 100%;
padding: 2rem 0rem;
margin-bottom: 4rem;
display: flex;
justify-content: start;
border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  li { 
  margin: 0 2rem;

  ._Thumbnail {
  border: 3px solid #ddd;
  width: 64px;
  height: 64px;
  border-radius: 30%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }

    span {
    width: 100%;
    margin-top: 0.5rem;
    display: block;
    text-align: center;
    color: transparent;
    font-weight: bold;
    color: #999;
    }

    &:hover, &._Active {

      span {    
      color: transparent;
      text-shadow: rgba(255,255,255,0.5) 0px 2px 2px;
      background: linear-gradient(to bottom, #1EEDDC 0%, #1AACCC 100%);
      background-clip: text;
      -webkit-background-clip: text;
      }

      ._Thumbnail {
      border: 3px solid aquamarine;
      }

    }

  }

}

`;

return Style;
}
