export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
padding-left: 2rem;
// margin-left: 6rem;

._Title {
color: rgba(0, 0, 0, 0.7);
}

._Accounts {
width: 100%;
margin: 2rem 0;
border-top: 1px dashed rgba(0, 0, 0, 0.2);

  ._Account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  padding: 1rem;

    &:hover {
    background: rgba(0, 0, 0, 0.1);
    }

    ._Header {
    display: flex;
    align-items: center;
    }

    img {
    width: 3rem;
    margin-right: 2rem;
    }

    span { 
    margin-right: 2rem;
    }

    ._Followers {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    // font-family: 'Ginora Sans';
    }

  }

  ._Title {
  margin-bottom: 1rem;
  color: #555;
  font-weight: bold;
  font-size: 2rem;
  }

  i { 
  font-size: 1.8rem; 
  margin-right: 1rem;
  }

}

`;

return Style;

}