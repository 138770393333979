//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class RENDER_BOARD_BG { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Canvas;
  this.Ctx;

  // State
  this.Style;
  this.Background;
  this.Dark;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Canvas = this.MAIA.TREE.RENDER.Canvas;
  this.Ctx = this.MAIA.TREE.RENDER.Ctx;

  this.Style = this.MAIA.Props.Tree?.Render?.Style;
  this.Background = [];

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  // Clean Canvas
  let Ctx = this.Ctx;
  Ctx.clearRect(0, 0, this.Canvas.width, this.Canvas.height);
  
  if(!this.Style.Transparent && this.Background) {
    
    var gradient = Ctx.createLinearGradient(0, 400, 0, 0);

    this.Background.map((color, index) => {
      let length = this.Background.length;
      let portion = (1 / length);
      let range = index * portion;
      gradient.addColorStop(range, color);
    });

    Ctx.fillStyle = gradient;
    Ctx.fillRect(0, 0, this.Canvas.width, this.Canvas.height);

  }
  else {
    // Transparent
    Ctx.beginPath();
    Ctx.rect(0, 0, this.Canvas.width, this.Canvas.height);
    Ctx.fillStyle = `rgba(255,255,255,0)`;
    Ctx.fill();
  }

}
//----------------------------------------------------------------------------------------
}