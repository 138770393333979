//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class ENT { constructor (MAIA, Ent, Callback) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  this.Data = structuredClone(Ent);
  this.Callback = Callback;

  this.Mode = 'Default'; // Default | Hovered | Selected |  

  // ID
  this.ID = Ent.ID;
  this.Tag = Ent.Tag;
  this.Proto = Ent.Proto;  
  this.Set = Ent.Set; // remove
  
  // Evts
  this.Evts = [];
    this.RENDER = undefined; // deprecated for Ent.Find_Type('RENDER')
    this.CUBIT = undefined; // deprecated for Ent.Find_Type('RENDER')
    this.Display = 'True';

  // Sigs
  this.Sigs = [];
  
  // Init
  this.Init();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
// 🔀 Jogress: Include Proto Evts to Scene Evts, only if no collision
//----------------------------------------------------------------------------------------

  // let Proto = this.MAIA.TREE.ENT.Prototypes.find((ent) => { return ent.ID === this.Proto });
  // let Proto = this.MAIA.DATA.PROTO.Protos.find((ent) => { return ent.ID === this.Proto });

  let Proto = this.MAIA.TREE.ENT.Prototype(this.Proto);

  if (Proto) {
    Proto.Evts.map((proto_evt) => {
      let Proto_Evt = structuredClone(proto_evt);
      // Solo usar los eventos de Proto cuando no haya un evento de instancia con el mismo Proto
      let Evt_Repeated = this.Data.Evts.find((evt) => { return evt.Proto === Proto_Evt.ID; });
      if (!Evt_Repeated) { this.Data.Evts.push(Proto_Evt); }
    });
  }

  // Eliminar eventos duplicados en this.Data, preservando los eventos originales
  const uniqueEvts = [];
  const seenEvtIDs = new Set();

  this.Data.Evts.forEach((evt) => {
    if (!seenEvtIDs.has(evt.ID)) {
      seenEvtIDs.add(evt.ID);
      uniqueEvts.push(evt);
    }
  });

  this.Data.Evts = uniqueEvts;
  // console.log(this.Data?.Evts);
  
  this.Load_Events(this.Data.Evts, this.Callback);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Load_Events (Evts, Callback) {
//----------------------------------------------------------------------------------------

  if (!Evts) { return; }
  try {

    const Results = await Promise.all(Evts.map(async (Evt) => {
      return await this.Load_Event(Evt);
    }));
    
    // console.log(`🔷 ${this.ID}`, Results);

    // Run Callback after all Evts have been fetched
    if (typeof Callback === 'function') { Callback(); } 
    else {
      // console.log(`Se esperaba una función, pero se recibió: ${typeof Callback}`);
    }

    // Ordenar Evts (Primer Cubit y después Render; el resto indistintamente).
    const cubits = this.Evts.filter(evt => evt.Type === 'CUBIT');
    const renders = this.Evts.filter(evt => evt.Type === 'RENDER');
    const others = this.Evts.filter(evt => evt.Type !== 'CUBIT' && evt.Type !== 'RENDER');
    this.Evts = [...cubits, ...renders, ...others];

  } catch (error) {
    console.error('Ent Load_Events:', error);
  }
  
}
//----------------------------------------------------------------------------------------
  async Load_Event (Evt) {
//----------------------------------------------------------------------------------------

  let Ent = this;
  let Event = await this.MAIA.TREE.EVT.Create(Evt, Ent);

  return Event;
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  // if(this.ID === 'Aura') { console.log(this.Mode); }

  this.Evts.map((Event, i) => {

    // alert(Event.ID)
    // console.info("🐧", Event)
    if(!Event?.Run) { console.error("🚨 Evt.Run() not defined", ); alert("🚨"); }
    
    Event.Run();

    // Checks if the Event has to be destroyed.
    // if(Event.Finish()) { 
      // this.Evts.splice(i, 1); 
    // }

  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Evt (Evt_ID) {
//----------------------------------------------------------------------------------------

  return this.Evts.find((Evt) => Evt.ID === Evt_ID);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Sig (Sig_Key) {
//----------------------------------------------------------------------------------------

  return this.Sigs.find((Sig) => Sig.Key === Sig_Key);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Find (Key_ID) { // deprecate, now use .Evt()
//----------------------------------------------------------------------------------------

  // Change "Type" => "ID"
  return this.Evts.find((evt) => evt.ID === Key_ID);

}
//----------------------------------------------------------------------------------------
  Find_Type (Type) { // deprecate, now use .Evt(ID, Property)
//----------------------------------------------------------------------------------------

  // Change "Type" => "ID"
  return this.Evts.find((evt) => evt.Type === Type);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Get (event) {
//----------------------------------------------------------------------------------------

  let Evt = this.Find_Type(event);
  if(Evt) { return Evt; }
  else { 
    // console.error(`No event ${Event} in Ent ${this.ID}`); 
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Get_Data () {
//----------------------------------------------------------------------------------------

  // Purify Ent
  let Ent = { ID: this.ID, Tag: this.Tag, Proto: this.Proto, Evts: [] };

  // Planify Evts to check if someane fails
  this.Evts.forEach((Evt) => {
    try {
      let Evt_Data = Evt.Get_Data();
      let Evt_Data_Copy = this.MAIA.CORE.UTILS.DeepCopy(Evt_Data);

      // Intenta serializar el Evt_Data para detectar posibles errores
      JSON.stringify(Evt_Data_Copy);

      Ent.Evts.push(Evt_Data_Copy);
    } catch (error) {
      console.error(`Error al serializar Evt con ID: ${Evt.ID}`, error);
    }
  });

  return Ent;
}
//----------------------------------------------------------------------------------------
}