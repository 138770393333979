
  import { Game } from "./Game.js";
  import { Login } from "./Login.js";
  import { Styles } from '../Styles/Styles_LCD.js';

//----------------------------------------------------------------------------------------
  export const LCD = ({ Egg }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Public.Styles.Styled(Styles, {});
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████████
  

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>

<div className="_Circle">

  <${Game} Egg=${Egg} />

  ${!Egg && html`<${Login} />`}
  
  <h1 className="_Logotipo ${Public.TOKEN.PRIVILEGE  === 'USER' || Public.TOKEN.PRIVILEGE === 'ADMIN' && 'Active'}">
    <img src="${Public.ENV.URL}/Public/Media/Icons/Favicon/initori.png"/>
    <span>initori</span>
  </h1>

</div>

<//>
`);

//----------------------------------------------------------------------------------------
};