export const Style_Popit = (styled, Proto, Props) => styled.div`
  width: 100%;
  height: 100%;
  background: aliceblue;
  perspective: 1777px;
  padding: 20rem 16rem;

  #book {
    transform-style: preserve-3d;
    transform: rotateX(64deg) rotateY(0deg) rotateZ(0deg);
    position: relative;
    width: 100%;

    .page {
      cursor: pointer;
      width: 50%;
      aspect-ratio: 1 / 1;
      position: absolute;
      transform-style: preserve-3d;
      transform-origin: right center;
      transition-property: transform;
      transition-timing-function: ease-in;
      transition-duration: 1s;

      // background-image: url("${Public.ENV.URL}/Apps/Intro/Style/Runas.png") !important; 
      background-color: #eee !important;
      background-size: contain;

      
      border: 1px solid #ddd;
      border-left: none;

      &:hover {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
      }

      .popup {
        position: absolute;
        transform-style: preserve-3d;
        transform-origin: bottom center;
        transition-timing-function: ease-in;
        transition-duration: 1s;

        &:hover {
          filter: brightness(110%) saturate(108%);
        }

        img {
        width: 100%;
        aspect-ratio: 1 / 1;
        }

      }

      .popup_a {
        height: 50%;
        width: 50%;
        left: 25%; top: 35%;
        transform: rotateX(-90deg);
        &.hide {
          transform: rotateX(-1deg);  
        }
      }

      .popup_b {
        position: relative;
        height: 50%;
        width: 50%;
        left: 25%; top: 35%;
        transform: rotateX(90deg);
        
        &.hide {
          transform: rotateX(1deg);  
        }

        
      }
    }
  }


  #page_0 { 
    background-color: antiquewhite;
    // transform: rotateY(175deg);
    z-index: 80;
    &.flip { transform: rotateY(1deg); }
  }

  #page_1 { 
    background-color: #a3d8a3; /* pastel verde menta */
    transform: rotateY(176deg);
    z-index: 70;
    &.flip { transform: rotateY(2deg); }
  }

  #page_2 {
    background-color: #a3d8a3; /* pastel verde menta */
    transform: rotateY(177deg);
    z-index: 60;
    &.flip { transform: rotateY(3deg); }
  }

  #page_3 {
    background-color: #f8b4b4; /* pastel rojo claro */
    transform: rotateY(178deg);
    z-index: 50;
    &.flip { transform: rotateY(4deg); }
  }

  #page_4 {
    background-color: #d6a2d6; /* pastel morado claro */
    transform: rotateY(179deg);
    z-index: 40;
    &.flip { transform: rotateY(5deg); }
  }

  #page_5 {
    background-color: #a3c2e5; /* pastel azul claro */
    transform: rotateY(180deg);
    z-index: 30;
  }


  #popup_0 { background-color: aqua; /* pastel rosa */ left: 10px; top: 100px; }
  #popup_1 { background-color: #ffb3b3; /* pastel rosa */ left: 10px; top: 100px; }
  #popup_2 { background-color: transparent; }
  #popup_2_b { background-color: #d1a3d1; /* pastel morado */ left: 120px; top: 100px; }
  #popup_3 { background-color: #a3d1d1; /* pastel verde agua */ left: 120px; top: 60px; }
  #popup_3_b { background-color: #f2e1a0; /* pastel amarillo claro */ left: 80px; top: 150px; }
  #popup_4 { background-color: #f2e1a0; /* pastel amarillo claro */ left: 100px; top: 100px; }
  #popup_4_b { background-color: #ffd1b3; /* pastel naranja claro */ left: 60px; top: 80px; }
  #popup_5_b { background-color: #a3d1d1; /* pastel verde agua */ left: 30px; top: 180px; }

`;