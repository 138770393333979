import { CORE_UTILS_Cookies } from "./Cookies/CORE_UTILS_Cookies.js";
import { DeepCopy } from './DeepCopy/DeepCopy.js';

export class CORE_UTILS { constructor (MAIA) {

  this.MAIA = MAIA;
  this.Cookies = new CORE_UTILS_Cookies();
  this.DeepCopy = DeepCopy;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Random_ID (Class) {
//----------------------------------------------------------------------------------------  

  let ID = "";
  let ClassName, BaseClassName;
  
  if(typeof Class === "string") {
    ClassName = Class;
  } 
  else {
    ClassName = Class.constructor.name;
    BaseClassName = Object.getPrototypeOf(Class.constructor).name;
  }

  if(ClassName === 'ENT')          { ID += ClassName; }
  else if(BaseClassName === 'EVT') { ID += ClassName; }
  else if(ClassName === 'SIG')     { ID += ClassName; }

  let Random = Math.floor(Math.random() * 100000);
  ID += "-" + Random;

  return ID;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  StringToBlob (file) {
//----------------------------------------------------------------------------------------  

  const decodedBase64 = atob(file);
  const arrayBuffer = new ArrayBuffer(decodedBase64.length);
  const uint8Array = new Uint8Array(arrayBuffer);  
  for (let i = 0; i < decodedBase64.length; i++) {
    uint8Array[i] = decodedBase64.charCodeAt(i); 
  }

  const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
  return blob;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  ArrayFlat(arr) {
//----------------------------------------------------------------------------------------
  return arr.reduce(function (a, b) { 
    return a.concat(is.arr(b) ? flattenArray(b) : b); 
  }, []);
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Save_Image (image, filename){
//----------------------------------------------------------------------------------------
// https://stackoverflow.com/questions/34704488/how-to-save-a-canvas-image-with-a-specific-filename
// No IE <11 support. Chrome URL bug for large images may crash
//----------------------------------------------------------------------------------------

  var anchorElement, event, blob;
  function image2Canvas(image){  // converts an image to canvas
      function createCanvas(width, height){  // creates a canvas of width height
          var can = document.createElement("canvas");
          can.width = width;
          can.height = height;
          return can;
      };
      var newImage = createCanvas(img.width, img.height); // create new image
      newImage.ctx = newImage.getContext("2d");  // get image context
      newImage.ctx.drawImage(image, 0, 0); // draw the image onto the canvas
      return newImage;  // return the new image
  }
  if(image.toDataURL === undefined){    // does the image have the toDataURL function
      image = image2Canvas(image);  // No then convert to canvas
  }
  // if msToBlob and msSaveBlob then use them to save. IE >= 10
  if(image.msToBlob !== undefined && navigator.msSaveBlob !== undefined){ 
     blob = image.msToBlob(); 
     navigator.msSaveBlob(blob, filename + ".png"); 
     return;
  }
  anchorElement = document.createElement('a');  // Create a download link
  anchorElement.href = image.toDataURL();   // attach the image data URL
  // check for download attribute
  if ( anchorElement.download !== undefined ) {
      anchorElement.download = filename + ".png";  // set the download filename
      if (typeof MouseEvent === "function") {   // does the browser support the object MouseEvent
          event = new MouseEvent(   // yes create a new mouse click event
              "click", {
                  view        : window,
                  bubbles     : true,
                  cancelable  : true,
                  ctrlKey     : false,
                  altKey      : false,
                  shiftKey    : false,
                  metaKey     : false,
                  button      : 0,
                  buttons     : 1,
              }
          );
          anchorElement.dispatchEvent(event); // simulate a click on the download link.
      } else
      if (anchorElement.fireEvent) {    // if no MouseEvent object try fireEvent 
          anchorElement.fireEvent("onclick");
      }
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Rel_To_Abs (value, Container) {
//----------------------------------------------------------------------------------------
// Convert relative values (%) to absolute values.
//----------------------------------------------------------------------------------------

  let Value = structuredClone(value);
  ["x", "y", "z"].map((a) => {
    let value = Value[a];
    if(typeof value === 'string' && value.length > 0) {
      let lastChar = value.slice(-1);          // %
      if(lastChar === "%") {
        let percentage = value.slice(0, -1);   // 100
        percentage = Number(percentage)
        let result = (Container[a] * percentage) / 100;
        Value[a] = result;
      }
    }
  })
  return Value;
}
//----------------------------------------------------------------------------------------
  Find_Circular_Reference (obj) {
//----------------------------------------------------------------------------------------
  const seenObjects = new Map(); // Usamos un Map para mantener un registro del camino

  try {
      (function traverse(object, path) {
        if (typeof object === 'object' && object !== null) {
          if (seenObjects.has(object)) {
              // Referencia circular encontrada
              console.log('Referencia circular encontrada en:', path, 'referenciando a:', seenObjects.get(object));
              return;
          }

          seenObjects.set(object, path);

          for (const key in object) {
              if (Object.prototype.hasOwnProperty.call(object, key)) {
                  traverse(object[key], path.concat([key])); // Agrega la propiedad actual al camino
              }
          }
        }
      })(obj, []);
  } catch (error) {
      console.error('Error en la detección de referencias circulares:', error);
  }
}
//----------------------------------------------------------------------------------------
}