export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
background: antiquewhite;
display: block;
padding: 6rem;

._Avatar {
width: 200px;
}

.Box {
width: auto;
padding: 2rem;
margin-bottom: 1rem;
background-color: antiquewhite;
background: rgba(0, 0, 0, 0.1);
font-size: 2rem;
color: #444;
border: 2px solid #777;
border-radius: 7px;
display: flex;
align-items: center;
cursor: pointer;

&:hover {
background: rgba(255, 255, 255, 0.3);
}

span {
font-size: 5rem;
margin-right: 2rem;
}

strong {
margin-right: 1rem;
}

}


`;

return Style;

}

