//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class RENDER_BOARD_FOG { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  
  this.Canvas = undefined;
  this.Ctx = undefined;

  this.Filter = { Color: undefined, Opacity: 0.1 };

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Canvas = this.MAIA.TREE.RENDER.Canvas;
  this.Ctx = this.MAIA.TREE.RENDER.Ctx;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  if(this.Filter) {
    this.Ctx.globalAlpha = this.Filter.Opacity;
    this.Ctx.fillStyle = this.Filter.Color;
    this.Ctx.fillRect(0, 0, this.Canvas.width, this.Canvas.height);
    this.Ctx.globalAlpha = 1.0;
  }

}
//----------------------------------------------------------------------------------------
}