export const Styles = (styled, Proto, Props) => {
return styled.div`
width: 100%;
height: 100%;
z-index: 100;

._Form {
position: absolute;
top: 0; left: 0;
width: 100%;
max-width: 100%;
height: 100%;
left: 0%;
display: flex;
flex-direction: column;
// position: absolute;
overflow: hidden;
background: rgba(67, 67, 67, 0.7);
backdrop-filter: blur(7px);
border-radius: 100%;
// z-index: 100;
}

._Form input {
all: unset;
width: 100%;
height: 100%;
text-align: center;
// border-radius: 4px;
color: rgba(20, 20, 20, 0.4);
opacity: transparent;
border: none;
}

._Form input:hover {
border: none;
color: #fff;
// background: #333;
cursor: pointer;
background: rgba(250, 250, 250, 0.1);
}

._Form input:focus {
border: none;
background: rgba(187, 222, 252, 0.4);
}

// ._Form input[type=text] {
._Form input._Input_Name {
color: #eee;
font-size: 2rem;
padding-top: 2.1rem;
border-bottom: 1px solid #ccc;
}

// ._Form input[type=password] {
._Form input._Input_Password {
font-size: 2rem;
padding-bottom: 1.2rem;
padding-left: 0.5rem;
// color: #fff;

font-family: 'Digicode Bold';
// text-shadow: 0 0 4px #0ff, 0 0 8px #0ff, 0 0 16px #0ff;
color: rgba(187, 222, 252, 0.9);
animation: pulse 7s infinite;
}

@keyframes pulse {
  0% {
    text-shadow: 0 0 4px #0ff;
  }
  50% {
    text-shadow: 0 0 10px #0ff;
  }
  100% {
    text-shadow: 0 0 2px #0ff;
  }
}

._Form input[type=submit] {
font-size: 2rem;
padding: 0;
}

`;

}