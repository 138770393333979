//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_App_Blog_List.js';

//----------------------------------------------------------------------------------------

export const App_Blog_List = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [Items, SetItems] = useState([]);

  useEffect(() => {
    Get_List();
  }, []);

  const Get_List = async () => {
    Public.DB.Read({
      URI: `Admin/Blog`, 
      Items: ["ALL"], 
      Interface: { ID: true, Title: true, Files: { ID: "Cover" } },
      Callback: (items) => {
        console.log("Admin/Blog", items[0].Files);
        SetItems(items);
      }
    });
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {
    
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>
<div className="App_Blog_List">
  
  <div className="Navbar">
    <!--<img src="${Public.ENV.URL}/Apps/Blog/.About/Icon/Icon.png" className="icon"/>-->
    <h2 className="_Title">Blog</h2>
    <div className="_Info"><i className="icofont-info-square icofont-1x"/></div>
    <!--<ul>
      <li className="_Active">Dev</li>
      <li>Marketing</li>
      <li>Ideas</li>
    </ul>-->
  </div>

  <div className="_Main">

    <ul>
    ${Items && Array.isArray(Items) && Items.map((Item, i) => {
      const Icon_Blob = Item?.Files.find(file => file.ID === "Cover")?.Blob;
      console.log(Item?.Files)
      return html`
      <${RouterDOM.Link} to="/blog/${Item.ID}" key="Admin_Blog_List_${Item.ID}">
      <li>
        <div className="_Thumbnail">
          ${Icon_Blob && html`<img className="_Icon" src=${Public.Scripts.Blob_To_Img_URL(Icon_Blob)}/>`}
        </div>
        <div className="_Title">${Item.Title}</div>
      </li>
      <//>
      `
    })}
    </ul>

  </div>

</div>
<//>
`);

};
