export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
`;

return Style;

}

