export const Fonts = (styled, Proto, Props) => {
return styled.css`

  .initori {
  font-family: "Ginora Sans";
  font-weight: bold;
  // font-style: italic;
  color: #555;
  font-size: 2rem;
  display: inline-block;
  }

  // Auric
  @font-face {
    font-family: "Aurico";
    src: local("Aurico"), url("${Public.ENV.URL}/Public/Media/Fonts/Auric/Aurico-Regular.woff") format("woff2");
  }

  // Ginora
  @font-face {
    font-family: "Ginora Sans";
    src: local("Ginora Sans Regular"), url("${Public.ENV.URL}/Public/Media/Fonts/Ginora/Ginora_Sans_Regular.otf") format("opentype");
  }

  @font-face {
    font-family: "Ginora Sans Bold";
    src: local("Ginora Sans Bold"), url("${Public.ENV.URL}/Public/Media/Fonts/Ginora/Ginora_Sans_Bold.otf") format("opentype");
  }

  // Handwritten
  @font-face {
    font-family: "Handwritten";
    src: local("Handwritten"), url("${Public.ENV.URL}/Public/Media/Fonts/Handwritten/Kalam-Regular.ttf") format("truetype");
  }

  // Open Sans
  @font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url("${Public.ENV.URL}/Public/Media/Fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Open Sans Light";
    src: local("Open Sans Light"), url("${Public.ENV.URL}/Public/Media/Fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
  }
  @font-face {
    font-family: "Open Sans Bold";
    src: local("Open Sans Bold"), url("${Public.ENV.URL}/Public/Media/Fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  }

  /*
  // Pokemon
  @font-face {
    font-family: "Pokemon";
    src: local("Pokemon"), url("../../../../../../Public/Media/Fonts/Pokemon/Pokemon.otf") format("opentype");
  }

  // Digicode
  @font-face {
    font-family: "Digicode";
    src: local("Digicode"), url("../../../../../../Public/Media/Fonts/Digicode/Digicode_Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Digicode Light";
    src: local("Digicode Light"), url("../../../../../../Public/Media/Fonts/Digicode/Digicode_Light.ttf") format("truetype");
  }
  @font-face {
    font-family: "Digicode Bold";
    src: local("Digicode Bold"), url("../../../../../../Public/Media/Fonts/Digicode/Digicode_Bold.ttf") format("truetype");
  }

  // Kenney
  @font-face {
    font-family: "Kenney";
    src: local("Kenney"), url("../../../../../../Public/Media/Fonts/Kenney_Rocket/Kenney_Rocket.ttf") format("truetype");
  }
  @font-face {
    font-family: "Kenney Square";
    src: local("Kenney Square"), url("../../../../../../Public/Media/Fonts/Kenney_Rocket/Kenney_Rocket_Square.ttf") format("truetype");
  }
  */

`}