//----------------------------------------------------------------------------------------

  import { CORE_API }  from './API/CORE_API.js';
  import { CORE_ADMIN }     from './ADMIN/CORE_ADMIN.js';
  import { CORE_RESOURCES } from './RESOURCES/CORE_RESOURCES.js';
  import { CORE_UTILS }     from './UTILS/CORE_UTILS.js';
  import { CORE_WASM }      from './WASM/CORE_WASM.js';
  import { CORE_DEBUG }     from './DEBUG/CORE_DEBUG.js';
  import { CORE_TIME }      from './TIME/CORE_TIME.js';
  
  // import { CORE_RENDER }    from './RENDER/CORE_RENDER.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class MAIA_CORE { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  /*🛣️*/  this.API = new CORE_API(this);
  /*🎩*/  this.ADMIN = new CORE_ADMIN(MAIA);
  /*🧲*/  this.RESOURCES = new CORE_RESOURCES(MAIA);
  /*🛠️*/  this.UTILS = new CORE_UTILS(MAIA);
  /*🆔*/  this.WASM = new CORE_WASM(MAIA);
  /*🚧*/  this.DEBUG = new CORE_DEBUG(MAIA);
  /*⌛*/  this.TIME = new CORE_TIME();
  // /*🌀*/  this.RENDER = new CORE_RENDER(MAIA);

  // :: LOAD ::
  this.Loaded = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Load () {
//----------------------------------------------------------------------------------------
 
  if(this.Loaded)       { return true; }
  if(this.WASM.Load() 
  && this.API.Load()) { 
  // && this.RENDER.Load()) { 
    this.Loaded = true; 
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.DEBUG.Init();
  this.API.Init();
  // this.RENDER.Init();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------
 
  this.DEBUG.Run();
  // this.RENDER.Run();

}
//----------------------------------------------------------------------------------------
}
