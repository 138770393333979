//----------------------------------------------------------------------------------------
  export class Notify { constructor () {
//----------------------------------------------------------------------------------------

  this.Init();

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
  
  let container = document.querySelector('.Container_Notify');
  if (!container) {
    container = document.createElement('div');
    container.className = 'Container_Notify';
    document.body.appendChild(container);
  }
  this.container = container;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Send ({Title, Description, Image, Time, Callback}) {
//----------------------------------------------------------------------------------------  

  const notificacion = document.createElement("div");
  notificacion.className = "Notify";

  const imagen = document.createElement("img");
  imagen.src = `${Public.ENV.URL}/Public/Media/Icons/Notify/${Image}.png`;
  notificacion.appendChild(imagen);

  const texto = document.createElement("div");
  texto.className = "Text";

  const titulo = document.createElement("div");
  titulo.className = "Title";
  titulo.innerText = Title;
  texto.appendChild(titulo);

  const descripcion = document.createElement("div");
  descripcion.className = "Description";
  descripcion.innerText = Description;
  texto.appendChild(descripcion);

  notificacion.appendChild(texto);
  this.container.prepend(notificacion); // Añade la notificación al inicio del contenedor

  Public.Scripts.Audio.Sound({ID: "Pop_Open"});

  notificacion.addEventListener('click', function() {
    Public.Scripts.Audio.Sound({ID: "Pop_Close"});
    this.remove();
  });

  setTimeout(() => {
    if (notificacion.parentNode) {
      notificacion.parentNode.removeChild(notificacion);
    }
    if (Callback) Callback();
  }, Time);

}
//----------------------------------------------------------------------------------------
}