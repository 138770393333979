//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT_SELECT { constructor (MAIA) {
//---------------------------------------------------------------------------

  this.MAIA = MAIA;

  // Ent, Cubit
  this.Ent = undefined;

  // If the trigger click for drag & drop was on the selected cubit.
  this.PressSelected = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Init () {
//---------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Select (Ent) {
//---------------------------------------------------------------------------

  // Unselect previous
  if(this.Ent) { 
    this.Ent.Mode = 'Default';
    this.Ent = null;
    return;
  }

  // Select actual
  if(!Ent) { 
    this.Ent = null;
    return; 
  }
  else {
    Ent.Mode = 'Selected';
    this.Ent = Ent;
  }

}
//---------------------------------------------------------------------------
  Unselect () {
//---------------------------------------------------------------------------

  // Unselect
  if(this.Ent) { 
    this.Ent.Mode = 'Default';
    this.Ent = null;
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Open (Ent) {
//---------------------------------------------------------------------------

  this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Ent').State.Selected_Ent = Ent;
  this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Ent').Action('Refresh'); 

  if(this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Ent')) {
    this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Ent').State.Selected_Ent = Ent;
  }

}
//---------------------------------------------------------------------------
  Close (Ent) {
//---------------------------------------------------------------------------

  if(this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Ent')) {
    this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Ent').State.Selected_Ent = Ent;
  }

}
//---------------------------------------------------------------------------
}