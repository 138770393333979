//----------------------------------------------------------------------------------------
// Genera y retorna un EVT (Evento)
// ToDo: Los EVT deben cargarse dinámicamente, igual que los Paneles.
//----------------------------------------------------------------------------------------

  import { TREE } from "../TREE.js";

  /*
  import { EVT_DATA } from "./Index/DATA/EVT_DATA.js";
  import { EVT_GRID } from "./Index/GRID/EVT_GRID.js";
  import { EVT_CUBIT } from "./Index/CUBIT/EVT_CUBIT.js";
  import { EVT_READER } from "./Index/READER/EVT_READER.js";
  import { EVT_PAINT } from "./Index/PAINT/EVT_PAINT.js";
  import { EVT_GLOW } from "./Index/GLOW/EVT_GLOW.js";
  import { EVT_GRAVITY } from "./Index/GRAVITY/EVT_GRAVITY.js";
  import { EVT_JUMP } from "./Index/JUMP/EVT_JUMP.js";
  import { EVT_MOVE } from "./Index/MOVE/EVT_MOVE.js";
  import { EVT_CAMERA } from "./Index/CAMERA/EVT_CAMERA.js";
  import { EVT_PANEL } from "./Index/PANEL/EVT_PANEL.js";
  import { EVT_RAIN } from "./Index/RAIN/EVT_RAIN.js";
  import { EVT_BG } from "./Index/BG/EVT_BG.js";
  import { EVT_RENDER } from "./Index/RENDER/EVT_RENDER.js";
  import { EVT_SHADOW } from "./Index/SHADOW/EVT_SHADOW.js";
  import { EVT_TOUCH } from "./Index/TOUCH/EVT_TOUCH.js";
  import { EVT_MODE } from "./Index/MODE/EVT_MODE.js";
  import { EVT_SOUND } from "./Index/SOUND/EVT_SOUND.js";
  import { EVT_TELEPORT } from "./Index/TELEPORT/EVT_TELEPORT.js";
  import { EVT_WINDOW } from "./Index/WINDOW/EVT_WINDOW.js";
  */

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_EVT extends TREE { constructor (MAIA) { super(MAIA);
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  
  this.Evts = [];
  this.Classes = new Map();;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------  

  return;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Fetch (Evt_Data) {
//----------------------------------------------------------------------------------------

  // Fetch
  let URI_Evt = `${this.MAIA.ENV.URL}/Apps/Maia/Core/TREE/EVT/Index/${Evt_Data.Type}/EVT_${Evt_Data.Type}.js`;
  return import(URI_Evt)
  .then(module => {
    if (module.default) {
      // console.log("Fetched", Evt_Data.Type)
      this.Classes.set(Evt_Data.Type, module.default); // Save with reference
    }
    return module.default;
  })
  .catch(error => {
    console.error("Error loading event:", Evt_Data, error);
    this.Classes.set(Evt_Data.Type, null);
    return null;
  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Create (Evt_Data, Ent) {
//----------------------------------------------------------------------------------------

  if(!Evt_Data || !Evt_Data.Type) { console.error("Evt Data not defined", Evt_Data); return null; }

  // If the Class has been fetched and saved before
  let Evt_Class = this.Classes.get(Evt_Data.Type);

  if (!Evt_Class) { // If not present, save a loading promise in the map and fetch the class

    const fetchPromise = this.Fetch(Evt_Data);
    this.Classes.set(Evt_Data.Type, fetchPromise);
    Evt_Class = await fetchPromise;

    // Check if Fetch was successful
    if (!Evt_Class) {
      console.error("Failed to load event class after fetch:", Evt_Data.Type);
      return null;
    }
    
  } else if (Evt_Class instanceof Promise) {
    // If a promise is present, it means loading is in progress
    Evt_Class = await Evt_Class; // Wait for the loading to complete
  }

  if (typeof Evt_Class !== "function") {
    console.error("Evt Class not function", Evt_Class);
    return null;
  }

  const Evt = new Evt_Class(this.MAIA, Evt_Data, Ent);

  if(!Evt) { console.error("🚨 Evt", Evt); return false;  }
  if(!Evt._Ent) { console.error("🚨 Evt with no Ent", Evt); return false; }
  
  // Init
  Evt.Inited = await Evt.Init();
  
  if(!Evt.Inited) { console.error("🚨 TREE EVT: Create: Init()", Evt.Inited); }
  // if(!Evt_Of_Type[Evt.Type]) { console.error("Type not official.", Evt) }

  // Push into Ent.Evts[]
  Ent.Evts.push(Evt);

  return Evt; 

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
	Find (Evt_ID) {
//----------------------------------------------------------------------------------------

  return this.Evts.find((Evt, i) => {

    if(Evt.ID === Evt_ID) { return Evt; }

  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Remove (Evt) {
//----------------------------------------------------------------------------------------

  alert("TREE EVT - Remove - deprecated")
  if(!Evt) { return; }
  this.Evts = this.Evts.filter((evt) => evt.ID !== Evt.ID);
  
}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

  console.log("💥 Reset Evts");

  for (let i = 0; i < this.Evts.length; i++) {

    // Realiza cualquier operación necesaria antes de eliminar el objeto
    let Evt = this.Evts[i];
  
    // Asigna null al objeto para eliminar la referencia
    this.Evts[i] = null;

  }

  this.Evts = [];

}
//----------------------------------------------------------------------------------------
}

/*
const Evt_Of_Type = {
  'CAMERA': EVT_CAMERA,
  'CUBIT': EVT_CUBIT,
  'DATA': EVT_DATA,
  'PAINT': EVT_PAINT,
  'BG': EVT_BG,
  'GLOW': EVT_GLOW,
  'GRAVITY': EVT_GRAVITY,
  'GRID': EVT_GRID,
  'JUMP': EVT_JUMP,
  'MODE': EVT_MODE,
  'MOVE': EVT_MOVE,
  'PANEL': EVT_PANEL,
  'RAIN': EVT_RAIN,
  'READER': EVT_READER,
  'RENDER': EVT_RENDER,
  'SHADOW': EVT_SHADOW,
  'SOUND': EVT_SOUND,
  'TOUCH': EVT_TOUCH,
  'TELEPORT': EVT_TELEPORT,
  'WINDOW': EVT_WINDOW,
};
*/