//----------------------------------------------------------------------------------------

  import { Styled } from './Style/Style_Admin_Onda.js';
  import { Admin_Onda_List } from "./List/Admin_Onda_List.js";
  import { Admin_Onda_Edit } from "./Edit/Admin_Onda_Edit.js";
  import { Admin_Onda_Detail } from './Detail/Admin_Onda_Detail.js';

//----------------------------------------------------------------------------------------
  export const Admin_Onda = ({ Props }) => {
//----------------------------------------------------------------------------------------

  Props.URI = `${Public.ENV.URL}/Apps/Admin/Parts/Onda`;
  const Style = Styled(Public.Libs.styled, {}, Props.URI);
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const RouterDOM = Public.Libs.RouterDOM;

//█████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

return (html`
<${Style}>

  <${RouterDOM.Route} exact path='/Admin/Onda'>
    <${Admin_Onda_List} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/Admin/Onda/Detail/:id'>
    <${Admin_Onda_Detail} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/Admin/Onda/Save'>
    <${Admin_Onda_Edit} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/Admin/Onda/Edit/:id'>
    <${Admin_Onda_Edit} Props=${Props} />
  <//>

<//>
`);
}