//----------------------------------------------------------------------------------------

  import { CORE_RESOURCES_Thumbnail } from "./Thumbnail/CORE_RESOURCES_Thumbnail.js";

//----------------------------------------------------------------------------------------

export class CORE_RESOURCES { constructor (MAIA) {

  this.MAIA = MAIA;

  this.Thumbnail = new CORE_RESOURCES_Thumbnail(this);
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Load_Image (src) {
//----------------------------------------------------------------------------------------
  src = `${this.MAIA.ENV.URL}/Public/Media/Tiles/${src}`;
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = src;
    img.onload = () => { 
      // console.info("LOADED", src);
      resolve(img);
    }
    img.onerror = reject;
  });
}
//----------------------------------------------------------------------------------------
  async Save_Canvas (canvas) {
//----------------------------------------------------------------------------------------

  if (!canvas) { return; }

  const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
  const defaultFileName = 'Default.png';
  
  // Chrome
  if (window.showSaveFilePicker) {
    try {
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: defaultFileName,
        types: [{
          description: 'PNG file',
          accept: { 'image/png': ['.png'] }
        }]
      });
      const writable = await fileHandle.createWritable();
      await writable.write(blob);
      await writable.close();
    } catch (error) {
      console.error('Error saving file', error);
    }
  } 
  // Firefox
  else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = defaultFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(url), 100);
  }

}
//----------------------------------------------------------------------------------------
}
