export const Items_Merch_List = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const styled = Public.Libs.styled;
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████

  const Styled = styled.div`

  ._Items {
    width: 100%;
    height: auto;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    ._Item{
    width: 100%;
    height: 70vh;
    margin: 3%;
    // background: aquamarine;
    border: 2px dashed #ccc;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

      ._Image {
      display: flex;
      justify-content: center;
      align-items: center;
      // background: aqua;

        img {
        width: auto;
        max-height: 24rem;
        transform: scale(0.8);
        transition: transform 0.2s ease-in-out;
        }

      }

      &:hover img { 
      transform: scale(1);
      }

      ._Name {
      font-size: 3rem;
      font-weight: bold;
      text-align: center;
      font-family: 'Courier';
      opacity: 0.1;
      transition: opacity 0.3s ease-in-out;
      }

      &:hover ._Name { 
      // text-decoration: underline;
      color: #88b7d5;
      opacity: 1;
      }

    }

  }

  `;

//████████████████████████████████████████████████████████████████████████████████████

  const [Items, SetItems] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  
  useEffect(() => {
    setIsMounted(true);
    Get_Merch();
    return () => { setIsMounted(false); };
  }, []);

  const Get_Merch = async () => {
    const currentIsMounted = true;
    Public.DB.Read({
      URI: `Admin/Merch`, 
      Items: ["ALL"], 
      Callback:(items) => {  
        if (currentIsMounted) {
          SetItems(items);
        }
      }
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>
<div className="_Items">

  ${Items && Array.isArray(Items) && Items.map((Item, i) => {

    return html`

      <div className="_Item" key="Merch_${i}">
      <${RouterDOM.Link} to="/items/merch/${Item.ID}" key="ItemsMerch_${Item.ID}">
      <div className="_Image">
      <img src=${Public.Scripts.Blob_To_Img_URL(Item.Files[0].Blob)}/>
      </div>
      <p className="_Name">${Item.Name}</p>
      <//>
      </div>

    `
  })}

</div>
<//>
`);
}