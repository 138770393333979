export const Styles_Proto = {

  BorderRadius: {
    Min: "3px",
    Med: "7px",
    Max: "12px" 
  },

  Dark: {
    Background: {
      Default: "rgba(0, 0, 0, 0.7)",
      Hover: "rgba(0, 0, 0, 0.50)",
      Active: "rgba(0, 0, 0, 0.80)",
    },
    Border: {
      Default: "#ccc",
    }
  },

  Darker: {
    Background: {
      Default: "rgba(0, 0, 0, 0.9)",
      Hover: "rgba(0, 0, 0, 0.50)",
      Active: "rgba(0, 0, 0, 0.80)",
    },
    Border: {
      Default: "#ccc",
    }
  }, 

}