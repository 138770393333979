//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_App_About.js';
  import { App_About_Detail } from './Detail/App_About_Detail.js';
  import { App_About_List } from './List/App_About_List.js';

//----------------------------------------------------------------------------------------

export const App_About = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();
  
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [Items, SetItems] = useState([]);

  useEffect(() => {
    Get_List();
  }, []);

  const Get_List = async () => {
    Public.DB.Read({
      URI: `Admin/About`, 
      Items: ["ALL"], 
      Interface: { ID: true, Title: true, Files: { ID: "Cover" } },
      Callback: (items) => {
        console.log("Admin/About", items[0].Files);
        SetItems(items);
      }
    });
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------


return (html`
<${Styled}>

  <div className="Navbar">
    <h2 className="_Title">About</h2>
    <div className="_Space"></div>
  </div>

  <ul className="_About_List">
    ${Items && Array.isArray(Items) && Items.map((Item, i) => {
      const Icon_Blob = Item?.Files.find(file => file.ID === "Cover")?.Blob;
      console.log(Item?.Files)
      return html`
      <${RouterDOM.Link} to="/about/${Item.ID}" key="Admin_About_List_${Item.ID}">
      <li className="${id === Item.ID && '_Active'}">
        <div className="_Thumbnail">
          ${Icon_Blob && html`<img className="_Icon" src=${Public.Scripts.Blob_To_Img_URL(Icon_Blob)}/>`}
        </div>
        <span>${Item.Title}</span>
      </li>
      <//>
      `
    })}
  </ul>

  <div className="_Section">

    <${RouterDOM.Route} exact path='/about/:id'>
      <${App_About_Detail} Props=${Props} />
    <//>

  </div>

<//>
`);

};