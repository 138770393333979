import { Styled } from './Style/Style_User.js';

export const App_User = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Main/Landing`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

  const [Item, SetItem] = useState(null);
    
  useEffect(() => { Get_Item(); }, []);

  const Get_Item = async () => {
    Public.DB.Read({
      URI: `Users`,
      Items: [Public.TOKEN.User_ID + ""], 
      Callback: (items) => { SetItem(items[0]); }
    });
  }

  useEffect(() => { if(!Item) { return }
    console.info("Item:", Item);
  }, [Item]);

  const Logout = async (e) => {
    e.preventDefault();
    await fetch(`${Public.ENV.URL}/user/logout`, { 
      method: 'POST',
      body: undefined,
    })
    .then(fetch => fetch.json())
    .then(res => { console.log(res); });
  }

  useEffect(() => {
    return;
    fetch(`${Public.ENV.URL}/user/test`, { 
      method: 'POST',
      body: undefined,
    })
    .then(fetch => fetch.formData())
    .then(res => { console.log(res); });

  }, [])

/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<${Style}>

  ${Item ?
  html`
  <div>
    <img className="_Avatar" src=${Public.Scripts.Blob_To_Img_URL(Item.Files[0].Blob)}/>
    <h2>${Item.Name}</h2> (# ${Public.TOKEN.User_ID})
  </div>
  `
  :
  ""}

  ${Public.TOKEN.User_ID !== undefined &&
  html`
  <div className="Box">
  <span>🪙</span>
  <strong></strong>
  ${Public.TOKEN.PRIVILEGE}
</div>
  <div className="Box" onClick=${(e) => { Logout(e); }}>
    <span>👤</span>
    <strong>Logout</strong>
  </div>
  `}

<//>
`);
  
};