//----------------------------------------------------------------------------------------

  import { Admin_Calendar_List } from "./Core/List/Admin_Calendar_List.js";
  import { Admin_Calendar_Detail } from "./Core/Detail/Admin_Calendar_Detail.js";
  import { Styled } from './Style/Style_Admin_Calendar.js';

//----------------------------------------------------------------------------------------
  export const Admin_Calendar = ({ Props }) => {
//----------------------------------------------------------------------------------------

  Props.URI = `${Public.ENV.URL}/Apps/Parts/Main/Admin/Parts/Calendar`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, Props.URI);
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
return (html`
<${Style}>

<div id="Admin_Calendar">

  <${RouterDOM.Route} exact path='/Admin/Calendar'>
    <${Admin_Calendar_List} Props=${Props} />
  <//>

  <${RouterDOM.Route} exact path='/Admin/Calendar/Detail/:id'>
    <${Admin_Calendar_Detail} Props=${Props} />
  <//>

</div>

<//>
`);

};