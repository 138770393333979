export const Styled = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
background-color: #e5dbcc;

#_Admin {
width: 100%;
height: 100%;
display: flex;
// background: #eee;
// padding: 2rem 6rem;
// border-radius: 5px;
// overflow: hidden;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;

  & ._Menu {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background: rgba(10, 10, 20, 0.77);
  padding: 0 0 2rem 0;
  // margin-bottom: 2rem;
  // overflow: hidden;
    
    & ._Icon {
    position: relative;
    width: 8rem;
    height: 8rem;
    margin: 0 2rem;

      ._Placeholder {
      z-index: 1;
      height: 40%;
      width: 100%;
      bottom: 0%;
      position: absolute;
      background-color: #c4aabb;
      opacity: 0.3;
      border-radius: 100%;
      }

      ._Title {
      visibility: hidden;
      width: auto;
      width: 100%;
      position: absolute;
      z-index: 12;
      bottom: -3.6rem;
      bottom: 0;
      padding: 0.4rem;
      border-radius: 2rem;
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #ccc;
      text-align: center;
      }

      img {
      z-index: 11;
      position: absolute;
      width: 100%;
      height: calc(100%-2rem);
      // border-radius: 100%;
      bottom: 1rem;
      padding: 0 1rem;
      // opacity: 0.5;
      filter: grayscale(100%);
      }

      :hover {
        cursor: pointer;
        ._Title { visibility: visible }
        img { opacity: 1; }
      }

      &.active {
        ._Placeholder { background: aquamarine; }
        img { opacity: 1; filter: grayscale(0%); }
      }

    }

  }

  & > ._Main {
  width: 100%;
  height: 100%;
  overflow: hidden;  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding: 2rem 8rem;
  overflow-y: scroll;
  }

  & ._Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1rem 0 2rem 0;

    & > ._Title {
    width: auto;
    margin: 0;
    }

    & > ._Button {
    width: auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #eee;
    color: #333;

      &:hover {
      cursor: pointer;
      color: #555;
      }

      i {
      font-size: 2rem;
      margin-right: 1rem;
      }

    }

  }

  ._Grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

      ._Box {
      padding: 2rem;
      margin: 0 2rem 2rem 0;
      background: #eee;
      color: #444;
      border: 2px solid #ccc;
      border-radius: 7px;

          img {
          width: 120px;
          height: 120px;
          }

          &:hover {
          cursor: hover;
          border: 2px solid aqua;  
          }

      }

  }

  ._List {
  width: 70%;
  display: flex;
  flex-direction: column;

    li {
    width: 100%;
    height: auto;
    padding: 2rem 4rem 2rem 2rem;
    border: 2px solid #444;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 77px;
    margin: 2rem 0;
    color: #eee;
    display: flex;
    align-items: center;
    justify-content: left;

    img {
    width: 77px;
    margin-right: 2rem;
    border-radius: 100%;
    border: 2px solid #444;
    padding: 0px;
    }

    ._Text {

    }

    ._Name {
    width: 100%;
    display: block;
    font-weight: bold;
    color: #444;
    font-size: 2rem;
    }

    ._Tags {
    margin-top: 1rem;
    display: block;
    
      span {
      display: inline-block;
      padding: 0.5rem 1rem;
      margin-right: 1rem;
      border: 1px dashed #999;
      color: #777;
      // border-radius: 100%;
      }

    }

    &:hover {
    cursor: pointer;
    border: 2px solid aquamarine;

      img {
      border: 2px solid aquamarine;
      }

    }

    }

  }

  ._Form {
  // max-width: 480px;
  // padding: 2rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;

    input, textarea, select, label {
    width: 100%;
    display: block;
    padding: 1rem;
    margin-bottom: 2rem;
    }

    label {
    font-family: 'Ginora Sans';
    font-weight: bold;
    }

  }

  ._Sidebar {
  width: 30%;
  padding-left: 4rem;
  }


}
`;

return Style;

}

