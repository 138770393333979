export class Public_Token { constructor() {

  this.Tokens = [];

}
//--------------------------------------------------------------------------------------
  Get (ID) {
//--------------------------------------------------------------------------------------

  let Token = this.getCookie(ID);
  // console.log(Token);

  if(Token) { Token = this.parseJwt(Token); }
  else { Token = { PRIVILEGE: 'ANONYMOUS'}; }

  // console.log("🪙 TOKEN", Token);
  return Token;

}
//--------------------------------------------------------------------------------------
  getCookie (name) {
//--------------------------------------------------------------------------------------
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}
//--------------------------------------------------------------------------------------
  parseJwt (token) {
//--------------------------------------------------------------------------------------
  
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
  
}
//--------------------------------------------------------------------------------------
}