//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class SIG { constructor (MAIA, Sig) {
//----------------------------------------------------------------------------------------
 
  this.MAIA = MAIA;

  this.ID = Sig.ID;           // ID único
  this.Key = Sig.Key;         // Llave de memoria
  this.Value = Sig.Value;     // Valor a asignar
  this.Time = Sig.Time || 1;  // Iteraciones que se mantiene
  this.Runs = Sig.Runs || 1;  // Número de ejecuciones
  this.Type = Sig.Type || "Classic";
  this.Wait = Sig.Wait || 0;

  this.Target = Sig.Target;   // Ent que reciben la instrucción
  this.Sender = Sig.Sender;   // Ent que envían la instrucción
 
  this.Count = 0;
  this.Void = false;
  this.Active = true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  return true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------
 
  if(this.Target.ID === 'Root') {
    // console.log(this.Target.ID, "| 🔑 : " + this.Key, "| 🚪 : " + this.Value); 
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Get_Data () {
//----------------------------------------------------------------------------------------

}
//----------------------------------------------------------------------------------------
  Set_Data (Data) {
//----------------------------------------------------------------------------------------
  
}
//----------------------------------------------------------------------------------------
}