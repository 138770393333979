//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_App_About_Detail.js';

//----------------------------------------------------------------------------------------

export const App_About_Detail = ({ Props }) => {

  const { useState, useEffect } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [Html, SetHtml] = useState(null);
  const [Item, SetItem] = useState(null);
  const [Page, SetPage] = useState(null);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Effects
//----------------------------------------------------------------------------------------

  useEffect(() => { 
    if(!id) { return; }
    Read_About(); }, [id]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Read_About = () => {
    Public.DB.Read({
      URI: `Admin/About`, 
      Interface: {"Files": true},
      Items: [id],
      Callback: (Items) => { 
        SetItem(Items[0]);
        SetPage(Items[0].Files[0]);
      }
    });
  }

  useEffect(() => { if (!Page) { return; }

    const loadBlobContent = async () => {
      try {
        const html = await Page.Blob.text();
        SetHtml(html);
        // const pageElement = document.getElementById('_Page');
        // pageElement.innerHTML = html;
      }
      catch (err) {
        console.error('Error al cargar el contenido del Blob:', err);
        SetHtml('<p>Error: No se pudo cargar el contenido de la página</p>');
      }
    };

    loadBlobContent();

  }, [Page]);

  useEffect(() => { if (!Html) { return; }
    Set_URL_Images();
    Set_Emojis_Monocolor();
  }, [Html]);

  function Set_URL_Images () {

    const pageNode = document.getElementById('_Page');
    if (!pageNode) {
      console.warn('No se encontró el elemento con ID "_Page"');
      return;
    }
  
    const processImage = async (img) => {
      const src = img.getAttribute('src');
      if (!src) return;
  
      const file = Item.Files.find(file => file.Path === src);
      if (!file || !file.Blob) {
        console.warn(`No se encontró el archivo para la imagen con src "${src}"`);
        return;
      }
  
      try {
        const imgURL = await Public.Scripts.Blob_To_Img_URL(file.Blob);
        img.setAttribute('src', imgURL);
      } catch (error) {
        console.error(`Error al procesar la imagen con src "${src}":`, error);
      }
    };
  
    const images = pageNode.getElementsByTagName('img');
    const imageProcessingPromises = Array.from(images).map(processImage);
  
    Promise.all(imageProcessingPromises)
    .then(() => {
      // console.log('Todas las imágenes han sido procesadas');
    })
    .catch(error => {
      console.error('Error al procesar las imágenes:', error);
    });

  }


  const Set_Emojis_Monocolor = () => {

    const pageNode = document.getElementById('_Page');
    pageNode.querySelectorAll('.emoji').forEach(emoji => {
      emoji.style.filter = 'brightness(0) saturate(1000%) sepia(1) hue-rotate(180deg)';
    });

  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>
  <div className="App_About_Detail">
    <!--<div className="Navbar">
      <h2 className="_Title">About</h2>
      <div className="_Info"><i className="icofont-info-square icofont-1x"/></div>
    </div>-->
    <div className="_Main">
      <div id="_Page" className="_Container" dangerouslySetInnerHTML=${{ __html: Html }}>
                  
      </div>
    </div>
  </div>
<//>
`);

};