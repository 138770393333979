export const Style = (styled, Proto, Props) => {
return styled.div`
  
width: 100%;
height: auto;
max-height: 100%;
padding: 0rem;
// overflow-y: scroll;
position: relative;
display: flex;
flex-direction: column;

._Items {
width: 100%;
height: 100%;
padding: 2rem 8rem 4rem 8rem;
overflow-y: scroll;
}


`;
}