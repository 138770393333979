export const Style = (styled, Proto, Props) => {
return styled.div`

min-width: 0;
height: 100%;
display: block;
position: relative;
background-color: var(--color-background);
overflow: hidden;

flex: 1;
display: flex;

`;
}