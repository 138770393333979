export const Styles = (styled, Proto, Props) => {
return styled.div`

width: 100%;
height: 100%;

._Dialog {
position: absolute;
bottom: 10%;
left: 50%;
transform: translateX(-50%); 
background: rgba(247, 247, 247, 1);
padding: 2rem;
z-index: 12;
color: #333;
font-family: "Ginora Sans";
// backdrop-filter: blur(30px);
border-radius: 7px;
cursor: pointer;

  &::before {
  content: "";
  position: absolute;
  top: -10px; /* La altura del triángulo */
  left: 50%;
  transform: translateX(-50%); /* Centrarlo */
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* La mitad del ancho del triángulo */
  border-right: 10px solid transparent; /* La mitad del ancho del triángulo */
  border-bottom: 10px solid rgba(247, 247, 247, 1); /* El color y la altura del triángulo */
  }

}

._Square {
position: relative;
margin-top: 17.5%; 
margin-left: 17.5%;
width: 65%;
height: 65%;
z-index: 2;
background: #aab368;
border-radius: 3px;
display: flex;
justify-content: center;
align-items: center;
// border: 2px solid rgba(255, 255, 255, 0.2);
// border-top: 1px solid rgba(255, 255, 255, 0.5);
box-shadow: 0px 0px 7px rgba(40, 40, 40, 0.9) inset;
border-radius: 7px;
overflow: hidden;
// box-shadow: 0px 0px 5px rgba(250, 250, 250, 0.3);
// border-bottom: 1px solid rgba(55, 55, 55, 0.5);
// box-shadow: 0px 0px 7px rgba(250, 250, 250, 0.3);

// animation: glow 5s linear infinite;

// display: none;
// &.Active { display: flex; }

}

@keyframes glow {
  0% {
    box-shadow: 0px 0px 7px rgba(250, 250, 250, 0.5);
  }
  50% {
    box-shadow: 0px 0px 5px rgba(250, 250, 250, 0.4);
  }
  100% {
    box-shadow: 0px 0px 7px rgba(250, 250, 250, 0.5);
  }
}

.Digitama { // ???
position: absolute;
z-index: 1;
width: 70%;
opacity: 0.5;
animation: pulsate 1.75s infinite;
filter: grayscale(200%);
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}


/* Game of Life */

#Grid {
width: 100%;
height: 100%;
position: relative;
opacity: 0.5;
z-index: 1;
display: grid;
grid-template-columns: repeat(11, 9.1%); // 100/11
grid-template-rows: repeat(11, 9.1%);
}

.Cell {
// cursor: pointer;
background-color: lightgray;
border: 1px solid rgba(255, 255, 255, 0.2);
width: 100%;
height: 100%;

  &:hover {
  background-color: rgba(0, 0, 0, 0.3);
  }

  &.Full {
  background-color: black;

    &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    }
    
  }

}

`;

}