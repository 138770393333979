export const Styles = (styled, Proto, Props) => {
return styled.div`

width: 100%;
height: 100%;
padding: 0;
margin: 0;
background: #eee;
display: flex;

._Read {
width: 100%;
height: 100%;
top: 0; left: 0;
background: #eee;
position: absolute;
overflow: hidden;
z-index: 100;
border-radius: 7px;
display: flex;
justify-content: center;
padding-top: 3rem;
}

#App_Readme {
width: 100%;
height: 100%;
/*display: flex;
flex-direction: column;
align-items: center;
justify-content: center;*/
animation: show-readme 0.5s;

  ._Page {
  width: 100%;
  height: 100%;
  padding: 4rem;
  position: relative;
  text-align: center;
  overflow-y: scroll;
  }

  ._Content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;

    @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 4rem;
    justify-content: center;
    align-items: center;
    }
    
  }

  ._Title {
  width: auto;
  display: inline-block;
  margin: 0 0 4rem 0;
  font-family: "Ginora Sans";
  text-align: center;
  color: rgba(112, 134, 207, 0.9);

    @media (max-width: 768px) {
    font-size: 4rem;
    }

  }

  p._Text, ._Image {
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  }

  p._Text {
  width: 100%;
  max-height: 100%;
  margin: 0;
  text-align: left;
  font-size: 2rem;
  color: #777;
  opacity: 0.8;
  // overflow-y: scroll;

    @media (max-width: 768px) {
    font-size: 3rem;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    overflow-y: auto;
    margin: 2rem 0;
    }

    span._Initori {
    color: #333;
    font-family: "Ginora Sans";
    }

    span._AuraAdventure {
    width: auto;
    text-align: center;
    color: #c0f3fe;
    font-family: "Ginora", "Pakal";
    font-size: 0.9em;
    text-shadow: 
    0 -1px 0 rgba(0, 0, 0, 0.5),   /* arriba */
    0 1px 0 rgba(0, 0, 0, 0.5),    /* abajo */
    -1px 0 0 rgba(0, 0, 0, 0.5),   /* izquierda */
    1px 0 0 rgba(0, 0, 0, 0.5);    /* derecha */
    
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    padding: 0.75rem 1rem 0.5rem 1rem;
    }

    span._Hidden {
    color: #eee;
    }

    span._Codes {      
    color: #c0f3fe;
    font-family: "Ginora", "Pakal";
    font-size: 0.9em;
    text-shadow: 
    0 -1px 0 rgba(0, 0, 0, 0.5),   /* arriba */
    0 1px 0 rgba(0, 0, 0, 0.5),    /* abajo */
    -1px 0 0 rgba(0, 0, 0, 0.5),   /* izquierda */
    1px 0 0 rgba(0, 0, 0, 0.5);    /* derecha */
    
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    padding: 0.75rem 1rem 0.5rem 1rem;
    display: inline-block;

      span._Disk {
      margin-right: 0.25rem;
      }
    }

  }

  ._Image { 
  width: 100%;
  max-height: 100%;
  margin-right: 3rem;

    img {
    width: 100%;
    }

    @media (max-width: 768px) {
    width: 80%;
    max-width: 80%;
    max-height: 100%;
    }

  }

}

@keyframes show-readme {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
`;

}