//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_App_Roadmap.js';

//----------------------------------------------------------------------------------------
  export const App_Roadmap = ({ Props }) => {
//----------------------------------------------------------------------------------------

  let URI = `${Public.ENV.URL}/Apps/Intro/Landing`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [Roadmap, SetRoadmap] = useState(undefined);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {
    Get_Roadmap();
  }, []);

  const Get_Roadmap = () => {
    Public.DB.Read({
      URI: `Items/Roadmap`, 
      Items: ["ALL"], 
      Callback: (items) => {
        SetRoadmap(items);
      }
    });
  }
  
  useEffect(() => { if(!Roadmap) { return; }
    console.log("⛩️ Roadmap Items", Roadmap);
  }, [Roadmap]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>
<div className="App_Roadmap">

  <div className="_Main">

    ${Roadmap && Array.isArray(Roadmap) && Roadmap.map((item, i) => {

      return html`
      <div key="Key_App_Roadmap_${i}">
      <h3>${item.ID}</h3>
      <img src=${Public.Scripts.Blob_To_Img_URL(item.Files[0].Blob)}/>
      </div>
      `;
  
    })}

  </div>

</div>
<//>
`);

};
