// import { Chip } from "../../../../../../Core/API/MODEL/Chip/Chip.js";
import { Style } from './Style/Style_Items_Chips_List.js';

export const Items_Chips_List = ({ Props }) => {

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

  const Part_Chip = Public.Scripts.Choose_Part('Chip');  

//████████████████████████████████████████████████████████████████████████████████████

  const [Chips, SetChips] = useState(undefined);

//=====================================================================================

  useEffect(() => {
    Get_Chips();
  }, []);

  const Get_Chips = () => {
    Public.DB.Read({
      URI: `Items/Chips`, 
      Items: ["ALL"], 
      Callback: (items) => {  
        console.log("Chips", items)
        SetChips(items);
      }
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>
<div className="ContainerFlex">

  ${Chips && Array.isArray(Chips) && Chips.map((item, i) => {

    return html`
    <${RouterDOM.Link} to="/Items/Chips/${item.ID}" key="Chip_${i}">
      
      <${Suspense} fallback=${html`<div></div>`}>
        ${html`<${Part_Chip} Props=${{
          Blob: item.Files[0].Blob,
          Color: item.ColorBackground,
          onClick: async (Callback) => { 
            await Callback();
          }
        }}/>
        `}
      <//>
    
    <//>
    `;

  })}

</div>
<//>
`);
}