export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: row;

  ._Buttons {
  padding: 0 2rem;
  display: flex;
  justify-content: flex-start;

  &._Horizontal {
  width: 100%;
  display: block;
  padding: 0;

    & > ._Button { 
    float: none;
    margin-bottom: 2rem; 
    }
  }

  ._Button {
  float: right;
  width: auto;
  padding: 1rem 2.5rem;
  font-size: 2rem;
  border-radius: 3rem;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
  color: #eee;
  background: #444;
  // border: 1px solid #999;
    
  display: flex;
    justify-content: space-around;
    align-items: center;

    :hover {
    background: #fff;
    }

    &._Submit { background: #82e2e5; &:hover { background: #94f4f7; } }
    &._Delete { background: #f25454; &:hover { background: #ef7070; } }
    &._Edit { background: #fff; color: #444; &:hover { background: #eee; } }
    &._Twitter { background: #82e2e5; &:hover { background: #91eaed; } }
    &._Instagram { background: #f09eed; &:hover { background: #f2b3f0; } }

    ._Back {
    color: #eee;
    background: #aaa;
    
      :hover {
      color: #fff;
      background: #ccc;
      }
      
    }
  
  }
  
}

`;

return Style;

}

