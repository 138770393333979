//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT_HOVER { constructor (MAIA) {
//---------------------------------------------------------------------------

  this.MAIA = MAIA;

  // Ent
  this.Ent = undefined;        // Cubit~Ent attached to the cursor to detect.
  
  // Hover
  this.Higher = undefined;        // Cubit Higher in the Z-index
  this.CubitHovered = undefined;  // Cubit Hovered
  this.Is_Selected = false;       // If the hover is also the selected cubit.

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Init () {
//---------------------------------------------------------------------------

  let Ent_Data = {
    ID: "Hover",
    Evts: [
      {
        Type: "CUBIT",
        ID: "Hover_Cubit",
        Props: {
          Coords: {x:0, y:0, z:0},
          Size: {x:1, y:1, z:1},
          Physic: { Matter: "VOID" },
        }
      }
    ]
  };

  this.Ent = this.MAIA.TREE.ENT.Create(Ent_Data);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Focus () {
//---------------------------------------------------------------------------

  // If there is a Cubit Hovered, reset by default
  if(this.CubitHovered) {

    // Except if it's selected
    if(this.CubitHovered._Ent?.Mode !== 'Selected') {
      this.CubitHovered._Ent.Mode = 'Default';
      this.CubitHovered = null;
    }

  }

  // If no Cubit overlap the Cursor, return.
  if(!this.Higher) { return; }
    
  // Set the Cubit
  let Ent = this.Higher?._Ent;
  this.CubitHovered = Ent.CUBIT;

  // Only Hovered if is not Selected
  if(Ent?.Mode !== 'Selected') {
    Ent.Mode = 'Hovered'; 
    this.Is_Selected = false;
  }
  else {
    this.Is_Selected = true;
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Follow (Cursor) {
//---------------------------------------------------------------------------

  // let Coord_Z = this.Hover.CUBIT.POS.Coords.z;
  let Coord_Z = 9999;
  let Coords_3D = this.MAIA.TREE.CAMERA.MATRIX.Position_3D(Cursor.x, Cursor.y, Coord_Z);

  // Move
  if(this.Ent.Find_Type('CUBIT')) {
    let {Result, Collisions} = this.Ent.Find_Type('CUBIT').POS.Teleport(Coords_3D);
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Find_Under () {
//---------------------------------------------------------------------------

  let Cubits_Under = [];
  this.MAIA.TREE.CUBIT.Cubits.map((cubit) => {
    
    if(cubit.ID === 'Hover_Cubit') {}
    if(cubit.ID === 'Seed_Cubit') {}
    else {

      // Check which Cubits are under, using this.ENT.CUBIT, which follows the Cursor
      let Overlap = this.MAIA.TREE.CAMERA.ISO.Wich_Cubit_Front(this.Ent.CUBIT, cubit);
      if(Overlap === null) { return; }
      if(Overlap === 'INTERSECTION' ) {}
      else {

        // If any Cubits overlap with the Selector Cubit at z:9999, <Hover>
        if(Overlap.ID === 'Hover_Cubit') { Cubits_Under.push(cubit);  }
        
      }
      
    }

  });

  return Cubits_Under;

}
//---------------------------------------------------------------------------
  Set_Higher (Cubits_Under) {
//---------------------------------------------------------------------------

  let CubitHigher = undefined;

  Cubits_Under.map((cubit) => { 
    if (CubitHigher === undefined) CubitHigher = cubit;
    // Find the Cubit in front of every other who is overlaping the "Hover_Cubit"
    let WichFront = this.MAIA.TREE.CAMERA.ISO.Wich_Cubit_Front(CubitHigher, cubit);
    if(WichFront === null || WichFront === 'INTERSECTION' ) { return; }
    else { CubitHigher = WichFront; }
  });

  this.Higher = CubitHigher;

}
//---------------------------------------------------------------------------
}