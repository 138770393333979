//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT_CREATE { constructor (MAIA) {
//---------------------------------------------------------------------------

  this.MAIA = MAIA;

  // Ent attached to the cursor.
  this.Data = undefined;
  this.Ent =  undefined;

  this.IsCorrect = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Init () {
//---------------------------------------------------------------------------
  
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Reset () {
//---------------------------------------------------------------------------

  if(this.Ent) { this.MAIA.TREE.ENT.Remove(this.Ent); }
  this.Data = null;
  this.Ent = null;
  this.IsCorrect = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Create (Data) { // More like "Create"
//---------------------------------------------------------------------------

  if (!Data || Object.keys(Data).length === 0) { return; }
  // console.log("Creating...", Data);
  if(!Data.Evts) { console.error("Data of new Ent don't have Evts", Data)}
  if(this.Ent) { console.error("💣 Error, el Ent previo sigue estando.", this.Ent); }
  this.Data = this.MAIA.CORE.UTILS.DeepCopy(Data);

//---------------------------------------------------------------------------
  this.Data.Proto = Data.Proto || Data.ID;
  this.Data.ID = "ID-ENT" + Math.floor(Math.random() * 100000);
  this.Data.Tag = "VOID";
//---------------------------------------------------------------------------

  // 🧊 Cubit // > refactor -> de momento, no debería haber más de 1 Cubit.
  if(!this.Data.Evts) { console.error(Data, this.Data)}
  let Cubit = this.Data.Evts.find(evt => evt.Type === "CUBIT");

  if (Cubit) {

    // Crear Ent en el <High>: 9999.
    console.log("🧊 Cubit ID:", Cubit.ID, "¿Proto?:", Cubit.Proto);
    Cubit.Proto = Cubit.Proto || Cubit.ID; // Si existe Proto, usar; si no, ID
    Cubit.Props.Coords = {x:0, y:0, z:200};
    Cubit.Tag = "User_Scene";

  }
  else { console.error("Cubit not found", Data); }

  try { 
  
    this.Ent = this.MAIA.TREE.ENT.Create(this.Data, () => {

      // Add Random IDs to all Evts
      this.Ent.Evts.map((evt) => {
        evt.ID = this.MAIA.CORE.UTILS.Random_ID(evt);
      });

      /*
      console.log(
      "🦉", this.Ent,
      "⭐ Ent ID: " + this.Ent.ID,
      "🏷️ Tag: " + this.Ent.Tag,
      "🧊 Cubit ID: " + this.Ent.CUBIT.ID, 
      "| Cubit Proto: " + this.Ent.CUBIT.Proto
      );
      */

    });

   } catch (error) {
    console.log("Error when creating new Ent from Prototype", error, this.Data);
  }

  return true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Confirm () {
//---------------------------------------------------------------------------

  if(!this.Ent) { return; }
  if(!this.IsCorrect) { return; }

  // console.log("✅ CONFIRM", this.Ent.CUBIT.POS.Coords)

  // Olvidar la referencia:
  this.Ent.Tag = "User_Scene";
  this.Ent = null;

  // Desactivar
  this.IsCorrect = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Clear () {
//---------------------------------------------------------------------------

  if(this.Ent?.CUBIT) { 
    
    // On every iteration, put the Cubit apart.
    this.Ent.CUBIT.POS.Vector(['z',  9999], true);
    this.IsCorrect = false;
   
  }
  else {

    // Si no hay Ent, crearlo.
    if(this.Data) { 
      console.info("🌀 Load again", this.Data);
      this.Create(this.Data);
    }

  }


}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Follow () {
//---------------------------------------------------------------------------

  if(!this.Data) { return; }

  let Cursor = this.MAIA.TREE.MODE.ACTIVE.Cursor;
  let Touching = this.MAIA.TREE.MODE.ACTIVE.HOVER.Higher;

  if(Touching) {
    
    // Si hay Ent, cambiar la posición para seguir el Cursor.
    if(this.Ent) {
    
      let Result = this.Colocate(this.Ent);
      if(Result) { this.IsCorrect = true; return; }

    }
  
  }
  
  this.IsCorrect = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Colocate (Ent) { if(!this.Ent.CUBIT) { return; }
//---------------------------------------------------------------------------

  let Zone = this.MAIA.TREE.MODE.ACTIVE.ZoneSelected;
  let Touching = this.MAIA.TREE.MODE.ACTIVE.HOVER.Higher;

  let Coords = {};

  if(Zone === "Top") {

    // Coords.x = Touching.POS.Bounds.xmin;
    // Coords.y = Touching.POS.Bounds.ymin;
    // Coords.z = Touching.POS.Bounds.zmax;

    let Cursor = this.MAIA.TREE.MODE.ACTIVE.Cursor;
    let Cubit_Selected = this.MAIA.TREE.MODE.ACTIVE.HOVER.Higher;  
    let maxZ = Cubit_Selected.POS.Bounds.zmax + 1;
    Coords = this.MAIA.TREE.CAMERA.MATRIX.Position_3D(Cursor.x, Cursor.y, maxZ);

  }
  else if(Zone === "FrontLeft") {

    Coords.x = Touching.POS.Coords.x - (Ent.CUBIT.POS.Size.x + 1);
    Coords.y = Touching.POS.Coords.y;
    Coords.z = Touching.POS.Coords.z + 0;

  } else if (Zone === "FrontRight") {

    Coords.x = Touching.POS.Coords.x;
    Coords.y = Touching.POS.Coords.y - (Ent.CUBIT.POS.Size.y + 1);
    Coords.z = Touching.POS.Coords.z + 0;

  }

  // this.MAIA.CORE.DEBUG.Add(`🗿 Touching: ${Touching.ID}`);
  // this.MAIA.CORE.DEBUG.Add(`🗿 x: ${Coords.x}, y: ${Coords.y}, z: ${Coords.z}`);

//---------------------------------------------------------------------------

  // Teleport:
  let {Result, Collisions} = this.Ent.CUBIT.POS.Teleport(Coords);
  // console.log(Result, Zone, Collisions, Coords);
  
  if(Result) { return true; }

}
//---------------------------------------------------------------------------
}