/*██████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█████████████░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░██░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░██████████████░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░█░░░█░░░░█░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░█░░░██░░░░░██░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░████████████░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
*/

  import { TREE } from "../TREE.js";
  import { ENT } from "./ENT/ENT.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_ENT extends TREE { constructor (MAIA) { super(MAIA);
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  
  this.Ents = [];

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.MAIA.DATA.ENT.Ents.map((Ent) => {
    this.Create(Ent);
  });

  console.log("Ents", this.Ents);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------
  
  this.Ents.map((Ent) => { 
    if(!Ent) { console.log("Ent not defined", Ent); return; }
    Ent.Run();
  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Create (Ent_Data, Callback) {
//----------------------------------------------------------------------------------------

  let Ent = new ENT(this.MAIA, Ent_Data, Callback);

  // Check if not present yet
  this.Ents.push(Ent);

  return Ent;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Find (Ent_ID, Callback) {
//----------------------------------------------------------------------------------------

  return this.Ents.find((Ent) => {
    if(!Ent) { console.error("Ent not defined", this.Ents); }
    return Ent.ID === Ent_ID;
  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Prototype (id) {
//----------------------------------------------------------------------------------------

  return this.MAIA.DATA.PROTO.Protos.find((proto) => { return proto.ID === id });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Remove (Ent) {
//----------------------------------------------------------------------------------------

  if(!Ent) { console.error("Try to remove Ent not defined", Ent); return; }

  // Send Removed() signal to all Evts
  Ent.Evts?.map((Evt) => { 
    
    // Custom callback to remove
    if (typeof Evt.Remove === "function") { Evt.Remove(); }
    
  });

  this.MAIA.TREE.EVT.Evts = this.MAIA.TREE.EVT.Evts.filter((evt) => evt._Ent.ID !== Ent.ID );

  // Remove Ent from the pool of Ents
  this.Ents = this.Ents.filter((ent) => Ent.ID !== ent.ID);
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Get_Data () {
//----------------------------------------------------------------------------------------

  let Ents_Data = [];

  // Make a copy of all Ents Data
  this.Ents.map((Ent) => {
    Ents_Data.push(Ent.Get_Data());
  });

  // Remove the "Special Ents"          // Discards if...
  Ents_Data = Ents_Data.filter((ent) => !ent.ID.startsWith("ID-ENT-SHADOW-"));
  Ents_Data = Ents_Data.filter((ent) => ent.ID !== 'Ent_Signal');
  Ents_Data = Ents_Data.filter((ent) => ent.ID !== 'Editor');
  Ents_Data = Ents_Data.filter((ent) => ent.ID !== 'Hover');
  Ents_Data = Ents_Data.filter((ent) => ent.Tag !== 'VOID');
      
  return Ents_Data;

}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

  console.log("💥 Reset Ents");

  for (let i = 0; i < this.Ents.length; i++) {

    // Realiza cualquier operación necesaria antes de eliminar el objeto
    let Ent = this.Ents[i];
  
    // Asigna null al objeto para eliminar la referencia
    this.Ents[i] = null;

  }

  this.Ents = [];

}
//----------------------------------------------------------------------------------------
  List_Ents_From_Evts (Evts) {
//----------------------------------------------------------------------------------------

  let Ents = [];

  Evts.map((Evt) => { // List all Ents
    
    let Is_Repeated = Ents.some(Ent => Ent.ID === Evt._Ent.ID);
    if(!Is_Repeated) { Ents.push(Evt._Ent); }
    
  });

  return Ents;

}
//----------------------------------------------------------------------------------------
}