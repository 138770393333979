export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
color: #333;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

.App_Blog_Detail {
width: 100%;
height: 100%;
padding: 0;
display: flex;
flex-direction: column;

  ._Main {
  width: 100%;
  height: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  // background: aqua;

    & > ._Container {
    height: 100%;
    max-width: 700px;
    }

  }

  ._Text {
    
  }

  ._Image {
  display: flex;
  justify-content: center;
  margin: 2rem 0;

    img {
    max-width: 420px;
    }

  }


}

`;

return Style;
}
