
  import { Styles } from './Styles_Login.js';

//----------------------------------------------------------------------------------------
  export const Login = ({ }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Public.Styles.Styled(Styles, {});

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const theForm = useRef(null);
  const [ShowLogin, SetShowLogin] = useState(false);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Send_Form = async (e) => {
    
    if(e) { e.preventDefault(); }
    let response = await fetch(`${Public.ENV.URL}/user/login`, {
      method: 'POST',
      body: new FormData(theForm.current)
    });

    let result = await response.json();
    if (result && result.Status === 'OK') {
      window.location.replace(`${Public.ENV.URL}/maia`);
    }
    else { 
      console.error("ERROR", result); 
    }
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => { // Detect [Esc] and [Enter] keys

    function handleKeyDown (event) {
      if (event.key === "Escape") {
        let FileSound = `${Public.ENV.URL}/Apps/Init/Media/Bit.ogg`;
        const player = new Tone.Player(FileSound).toDestination();
        player.volume.value = -4;
        player.autostart = true;
        SetShowLogin(!ShowLogin); // Toggle ShowLogin state
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => { document.removeEventListener("keydown", handleKeyDown); };
  
  }, [ShowLogin]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
${ShowLogin && html`
<${Style}>

  <form id="Form" className="_Form" ref=${theForm} onSubmit=${(e) => Send_Form(e)}>  
    <input type="text" name="Name" className="_Input_Name" />
    <input type="text" name="Password" className="_Input_Password"/>
    <button type="submit" style=${{display:"none"}}></button>
  </form>

<//>
`}
`);

//----------------------------------------------------------------------------------------
};
