export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
color: #333;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

.App_About_List {
width: 100%;
height: 100%;
padding: 0;
overflow-y: scroll;

display: flex;
justify-content: center;
flex-direction: column;
justify-content: space-between;

  ._Main {
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 4% 20%;

  ul {
  list-style: none;
  padding: 0;
  // border-top: 1px solid #ccc;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  }

    li {
    // border: 1px solid #ccc;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
      ._Thumbnail {
      width: 200px;
      height: 200px;
      background-size: cover;
      border-radius: 5px;

        img {
        width: 100%;
        height: 100%;
        border-radius: 7px;
        border: 4px solid rgba(0, 0, 0, 0.1);
        }

      }

      ._Title {
      font-family: 'Ginora Sans Bold';
      font-size: 2rem;
      color: #777;
      flex: 1;
      padding: 1rem;
      }

      &:hover {
      cursor: pointer;

        ._Title {
        color: #87afe6;
        }

      }

    }

  }

}

`;

return Style;
}
