//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class RENDER_BOARD_DARK { constructor (MAIA) {
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  
  this.Canvas = undefined;
  this.Ctx = undefined;

  this.CachedCanvas = undefined;
  this.MaxTimesLow = 0;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Canvas = this.MAIA.TREE.RENDER.Canvas;
  this.Ctx = this.MAIA.TREE.RENDER.Ctx;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run (Dark, Lights) {
//----------------------------------------------------------------------------------------

  this.MAIA.TREE.RENDER.BOARD.Lights = [];

  // this.Lights = this.MAIA.CORE.UTILS.DeepCopy(Lights);
  if (this.MAIA.TREE.MODE.ACTIVE.ID === 'EDIT') { return; }

  // Creamos CanvasTemp1 y lo llenamos de negro opaco.
  let canvasTemp1 = document.createElement('canvas');
  canvasTemp1.width = this.Canvas.width;
  canvasTemp1.height = this.Canvas.height;
  let ctxTemp1 = canvasTemp1.getContext('2d');
  ctxTemp1.fillStyle = "black";
  ctxTemp1.fillRect(0, 0, canvasTemp1.width, canvasTemp1.height);

  // Creamos CanvasTemp2
  let canvasTemp2 = document.createElement('canvas');
  canvasTemp2.width = this.Canvas.width;
  canvasTemp2.height = this.Canvas.height;
  let ctxTemp2 = canvasTemp2.getContext('2d');

  // Agrupar las luces por brillo
  let groups = {};
  for (let light of Lights) {
      if (!groups[light.Bright]) {
          groups[light.Bright] = [];
      }
      groups[light.Bright].push(light);
  }

  // Claves de brillo ordenadas en orden descendente
  let sortedBrights = Object.keys(groups).sort((a, b) => parseFloat(b) - parseFloat(a));

  for (let bright of sortedBrights) {
      // Limpiamos CanvasTemp2
      ctxTemp2.clearRect(0, 0, canvasTemp2.width, canvasTemp2.height);

      // Dibuja todas las luces con el brillo actual en CanvasTemp2
      for (let light of groups[bright]) {
          ctxTemp2.beginPath();
          ctxTemp2.arc(light.PosX, light.PosY, light.Radius, 0, 2 * Math.PI, false);
          ctxTemp2.fillStyle = "white";
          ctxTemp2.fill();
      }

      // Usamos CanvasTemp2 para "agujerear" el CanvasTemp1 con el brillo actual
      ctxTemp1.globalAlpha = parseFloat(bright);
      ctxTemp1.globalCompositeOperation = 'destination-out';
      ctxTemp1.drawImage(canvasTemp2, 0, 0);
      ctxTemp1.globalCompositeOperation = 'source-over';
      ctxTemp1.globalAlpha = 1.0; // Reseteamos la opacidad para la siguiente iteración
  }

  this.Ctx.globalAlpha = Dark;
  this.Ctx.drawImage(canvasTemp1, 0, 0);
  this.Ctx.globalAlpha = 1.0;

}
//----------------------------------------------------------------------------------------
  Draw () {
//----------------------------------------------------------------------------------------

  return;

  if(this.CachedCanvas) {
    this.Ctx.globalAlpha = 0.9;
    this.Ctx.drawImage(this.CachedCanvas, 0, 0);
    this.Ctx.globalAlpha = 1.0;
  }

}
//----------------------------------------------------------------------------------------
}