//------------------------------------------------------------------------------------

  import { Global } from './Global/Styles_Core_Global.js';
  import { Fonts } from './Fonts/Styles_Core_Fonts.js';
  import { Text } from './Text/Styles_Core_Text.js';
  import { Containers } from './Containers/Styles_Core_Containers.js';
  import { Buttons } from './Buttons/Styles_Core_Buttons.js';
  import { Input } from './Input/Styles_Core_Input.js';
  import { Dialog } from './Dialog/Styles_Core_Dialog.js';
  import { Notify } from './Notify/Styles_Core_Notify.js';
  import { Scrollbar } from './Scrollbar/Styles_Core_Scrollbar.js';
  import { Effects } from './Effects/Styles_Core_Effects.js';
  import { Modules } from './Modules/Styles_Core_Modules.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//------------------------------------------------------------------------------------
  export const Styles_Core = (styled, Proto, Props) => { return styled.div`
//------------------------------------------------------------------------------------

  // 🌍 Global
  //----------------------------------------------------------------------------------
  ${Global(styled, Proto, Props)} {}

  // 🔤 Fonts
  //----------------------------------------------------------------------------------
  ${Fonts(styled, Proto, Props)} {}

  // 🖊️ Text
  //----------------------------------------------------------------------------------
  ${Text(styled, Proto, Props)} {}

  // 📦 Containers
  //----------------------------------------------------------------------------------
  ${Containers(styled, Proto, Props)} {}

  // 🔴 Buttons
  //----------------------------------------------------------------------------------
  ${Buttons(styled, Proto, Props)} {}

  // 📜 Input
  //----------------------------------------------------------------------------------
  ${Input(styled, Proto, Props)} {}

  // 💬 Dialog
  //----------------------------------------------------------------------------------
  ${Dialog(styled, Proto, Props)} {}

  // 📨 Notify
  //----------------------------------------------------------------------------------
  ${Notify(styled, Proto, Props)} {}

  // ↕️ Scrollbar
  //----------------------------------------------------------------------------------
  ${Scrollbar(styled, Proto, Props)} {}

  // 🎊 Effects
  //----------------------------------------------------------------------------------
  ${Effects(styled, Proto, Props)} {}

  // 🟨 Modules
  //----------------------------------------------------------------------------------
  ${Modules(styled, Proto, Props)} {}

//------------------------------------------------------------------------------------
`; }