
  import { Public_Parts_Input } from './Input/Public_Parts_Input.js';
  import { Part_Canvas } from './Canvas/Part_Canvas.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class Public_Parts { constructor () {
//----------------------------------------------------------------------------------------

  this.Input = new Public_Parts_Input();
  this.Canvas = Part_Canvas;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Choose (Component_ID) {
//----------------------------------------------------------------------------------------
  
  return (async () => {
    const {default: Component} = await import(`../Parts/${Component_ID}/Parts_${Component_ID}.js`);
    return Component;
  })();

}
//----------------------------------------------------------------------------------------
}