export const Style = (styled, Proto, Props) => {
return styled.div`

width: 100%;
padding: 2rem;
// background: aquamarine;
display: flex;
justify-content: center;

._Image {
width: 55%;
margin-right: 5%;
 
  img { 
  width: 100%;
  }

}

._Content {
width: 40%;
color: #777;

h3 {
color: #444;
font-size: 3rem;
}

}

`;
}