export const Buttons = (styled, Proto, Props) => { return styled.css`

  .Button_Super {
  margin-top: 12rem;
  width: auto;
  height: auto;
  color: #fff;
  border-radius: 5px;
  font-family: 'Open Sans Bold', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  background: aquamarine;
  }

  .custom-btn {
  margin-top: 12rem;
  width: auto;
  height: auto;
  color: #fff;
  border-radius: 5px;
  font-family: 'Open Sans Bold', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none; }

  .btn {
  float: right;
  background: transparent;
  border: 8px solid rgba(100, 100, 100, 0.2);
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 0px 0px 20px 0px rgba(0, 0, 0, 0.2), 0px 0px 5px 0px rgba(0, 0, 0, 0.2); }

  .btn div {
  border-radius: 3px;
  padding: 4rem 6rem;
  background: #f796c0;
  background: radial-gradient(circle, #f796c0 0%, #76aef1 100%);
  line-height: 42px; }

  .btn div:hover {
  background: #f79dc4;
  background: radial-gradient(circle, #f7a9cb 0%, #89b9f3 100%);
  box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 0px 0px 20px 0px rgba(0, 0, 0, 0.2); }

  .btn div:active {
  background: #e089af;
  background: radial-gradient(circle, #e498b9 0%, #6f9cd4 100%);
  box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 0px 0px 20px 0px rgba(0, 0, 0, 0.5) inset; }


`}