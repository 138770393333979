export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
background: antiquewhite;
display: block;
padding: 6rem;

._Background {
z-index: 0;
position: absolute;
top: 0; left: 0;
width: 100%;
// height: 100vh;
}

._Box {
z-index: 1;
position: absolute;
top: 0; left: 0;
width: 100%;
padding: 4rem;
backdrop-filter: blur(20px);
background: rgba(0, 0, 0, 0.3);

input {
padding: 1rem 2rem;
// opacity: 0.5;
}

}


`;

return Style;

}

