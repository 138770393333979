// Native method of ES6:: structuredClone(value)

function getType(payload) {
  return Object.prototype.toString.call(payload).slice(8, -1)
}

function isArray(payload) {
  return getType(payload) === 'Array'
}

function isPlainObject(payload) {
  if (getType(payload) !== 'Object') return false
  return payload.constructor === Object && Object.getPrototypeOf(payload) === Object.prototype
}

export const DeepCopy = (target) => {
  if (isArray(target)) return target.map(i => DeepCopy(i))
  if (!isPlainObject(target)) return target
  return Object.keys(target)
    .reduce((carry, key) => {
      const val = target[key]
      carry[key] = DeepCopy(val)
      return carry
    }, {})
}
