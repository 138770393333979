export const Style = (styled, Proto, Props) => { const Style = styled.div`

display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
padding: 2rem;

canvas, img {
width: 48px;
height: 48px;

transform: rotate(0deg);
transition: transform 1.7s;

  &.rotated {
  transform: rotate(1440deg);
  }

}

&:hover {
  canvas, img {
    // transform: rotate(1440deg);
  }
}

span {
    font-size: 20px;
    letter-spacing: 2px;
    color: #eee;
    font-family: 'Kenney Square';
}


`; return Style; }