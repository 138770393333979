export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
padding: 0;
margin: 4rem 8rem 0rem 8rem;
overflow-y: scroll;

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;


`;

return Style;
}
