//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_Menu.js';

//----------------------------------------------------------------------------------------
  export const App_Menu = ({ Props }) => {
//----------------------------------------------------------------------------------------

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  let URI_SRC = `${Public.ENV.URL}/Apps/Sidebar/Menu/Media`;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Functions
//----------------------------------------------------------------------------------------

  const changeMenu = (name) => {
    
    Props.Section.set(name);
    Props.Modal.set({
      type: "",
      options: {}
    });
    Public.Scripts.Audio.Sound({ID: 'Click'});

  };

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

function RenderSidebar () {

  return (html`

  <!--<li className="tooltip">
  <span className="tooltiptext">Inicio</span>
  </li>-->

  <${RouterDOM.Link} to="/aura" key="Menu_Aura">
  <li onClick=${()=>{changeMenu("aura");}}
    className="${location.pathname === `/` || location.pathname.startsWith(`/aura`) ? "Active" : ""}">
    <img src="${URI_SRC}/Aura.png" className="icon"/>
  </li>
  <//>

  <${RouterDOM.Link} to="/intro" key="Menu_Manual">
  <li onClick=${()=>{ changeMenu("intro");}}
    className="${location.pathname.startsWith(`/intro`) ? "Active" : ""}">
    <img src="${URI_SRC}/Manual.png" className="icon"/>
  </li>
  <//>

  <${RouterDOM.Link} to="/maia" key="Menu_Maia">
  <li onClick=${()=>{changeMenu("maia");}}
  className="${location.pathname.startsWith(`/maia`) ? "Active" : ""}">
    <img src="${URI_SRC}/Chip.png" className="icon"/>
  </li>
  <//>

  ${Public.TOKEN.PRIVILEGE === 'ADMIN' && html`

  <${RouterDOM.Link} to="/egg" key="Menu_Egg">
  <li onClick=${()=>{changeMenu("egg");}}
    className="${location.pathname === `/` || location.pathname.startsWith(`/egg`) ? "Active" : ""}">
    <img src="${URI_SRC}/Egg.png" className="icon"/>
  </li>
  <//>
  
  <${RouterDOM.Link} to="/user" key="Menu_User">
  <li onClick=${()=>{changeMenu("user");}}
  className="${location.pathname.startsWith(`/user`) ? "Active" : ""}">
    <img src="${URI_SRC}/User.png" className="icon"/>
  </li>
  <//>

  <${RouterDOM.Link} to="/admin/calendar" key="Menu_Admin">
  <li onClick=${()=>{changeMenu("admin");}}
  className="${location.pathname.startsWith(`/admin`) ? "Active" : ""}">
    <img src="${URI_SRC}/Admin.png" className="icon"/>
  </li>
  <//>

  `}
  
  `);

}

//----------------------------------------------------------------------------------------

return (html`
<${Styled}>
  ${RenderSidebar()}
<//>
`);

//----------------------------------------------------------------------------------------
}

/*
<${RouterDOM.Link} to="/items/chips" key="Menu_Items">
<li onClick=${()=>{changeMenu("items/chips");}}
  className="${location.pathname.startsWith(`/items/chips`) ? "Active" : ""}">
  <img src="${URI_SRC}/Chip.png" className="icon"/>
</li>
<//>
*/