export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;

.Onda_Edit {
margin-top: 2rem;
padding: 2rem;
background-color: #f5f5f5;
color: #777;
border: 1px solid #ccc;
border-radius: 7px;
overflow: hidden;

  ._Top, .Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  }

  #FileInput {
  display: none;
  // visibility: hidden;
  // opacity: 0.2;
  }

  .FileLabel {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 1rem;
  display: inline-block;
  }

  ._Preview {
  width: 10rem;
  min-height: 10rem;
  // height: 100%;
  // max-height: 100px;
  margin-right: 2rem;
  display: block;
  position: relative;
  border: none;
  // border: 2px dashed #ccc;
  }

  textarea {
  width: 100%;
  height: 10rem;
  margin: 1rem 0;
  padding: 1rem;
  font-size: 1.8rem;
  border: none;
  border-radius: 7px;
  resize: none;
  }

  ._Submit {
  float: right;
  width: auto;
  padding: 1rem 2.5rem;
  font-size: 2rem;
  color: #eee;
  background: #82e2e5;
  border-radius: 3rem;
  border: none;
  cursor: pointer;

  :hover {
  color: #fff;
  background: #94f4f7;
  }
  
  }

}

  ._Micro {
  float: right;
  width: auto;
  padding: 1rem 2.5rem;
  font-size: 2rem;
  color: #eee;
  background: #dc6b53;
  border-radius: 3rem;
  border: none;
  cursor: pointer;

  :hover {
  color: #fff;
  background: #df8572;
  }
  
  }

`;

return Style;

}

