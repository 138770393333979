// import { Card } from "../../../../../Core/API/MODEL/Card/Card.js";

export const Items_Cards = ({ Props }) => {

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const styled = Public.Libs.styled;

  const Card = Public.Scripts.Choose_Part('Card');  

//████████████████████████████████████████████████████████████████████████████████████

  const Styled = styled.div`
  transform: translate3d(0, 0, 0.1px);

  .cards {
    width: 100%;
    height: auto;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: scroll;

    perspective: 2000px;
    position: relative;
    z-index: 1;
    transform: translate3d(0.1px, 0.1px, 0.1px);

    & > *{
      // Usar un Grid de 2D
      width: auto;
      width: 20%;
      max-width: 20%;
      margin: 0 5% 4% 0;
    }

  }

  `;

//████████████████████████████████████████████████████████████████████████████████████

  const [Cards, SetCards] = useState(undefined);

//=====================================================================================

  useEffect(() => {
    Get_Cards();
  }, []);

  const Get_Cards = () => {
    Public.DB.Read({
      URI: `Items/Cards`, 
      Items: ["1"], 
      Callback: (items) => {  
        SetCards(items);
      }
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>
<div className="cards">

  ${Cards && Cards.map((Props, i) => {
  
    return html`
    <${Suspense} fallback=${html`<div>...</div>`} key="Items_Cards_${i}">
      <${Card} Props=${Props}/>
    <//>
    `
    
  })}

</div>
<//>
`);
}