//----------------------------------------------------------------------------------------
  export class Public_Routes_Admin { constructor (Public) {
//----------------------------------------------------------------------------------------

  this.Public = Public;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Open (App, URI, Callback) {
//----------------------------------------------------------------------------------------

  let Form = new FormData();
  Form.append('App', App);
  Form.append('URI', URI);

  // Event.preventDefault();
  fetch(`${this.Public.ENV.URL}/admin/apps`, { 
      method: 'POST', 
      body: Form
  })
  .then((response) => { return response.json(); })
  .then(res => { Callback(res) });

}
//----------------------------------------------------------------------------------------
}