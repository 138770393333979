/*██████████████████████████████████████████████████████████
██ MAIA.TREE.CLIENT.DOM                                  ███
████████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░||░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░|-|░░░░░░░░||░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░| |░░░░░░░░||░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░ /   _| |_   \ ░░//░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|  / ,| |. \  |_//░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░| ( ( '-' ) ) |-'░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|  \ `'"'' /  |░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|   `-----'   ;░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|\___________/|░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|             ;░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░ \___________/░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
*/

//-----------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class CLIENT_DOM { constructor (MAIA) {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.State = {

  Render: {
    Ref: undefined,
  },

  Canvas: {
    Ref: undefined,
    Context: undefined,
    Size: { width: 0, height: 0},
    Center: { w: 0, h: 0},
    Rect: undefined,
  },

  /* Offscreen: {
    Ref: undefined,
    Context: undefined,
    Size: { width: 0, height: 0},
  }, */

  Panel: {
    Active: undefined,
    Target: undefined,
    Ref: undefined // (sustituir por Target)
  },

  Log: {
    Active: undefined,
    Target: undefined,
    Ref: undefined // (sustituir por Target)
  }

  };

  // this.Init();

}

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Init (Agent_DOM) {
//-----------------------------------------------------------------------------
  
  this.Set_DOM(Agent_DOM);
  this.Refresh();
  this.Set_ScreenFocus(true);

}
//-----------------------------------------------------------------------------
  Set_DOM (Agent_DOM) {
//-----------------------------------------------------------------------------

  this.State.Render.Ref = Agent_DOM.Render;
  this.State.Canvas.Ref = Agent_DOM.Canvas;
  this.State.Panel.Ref = Agent_DOM.Panel;
  this.State.Log.Ref = Agent_DOM.Log;
  // this.State.Offscreen.Ref = Agent_DOM.Paint;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Refresh () {
//-----------------------------------------------------------------------------
  
  // Save Context
  this.State.Canvas.Ctx = this.State.Canvas.Ref.getContext("2d", { alpha: true });

  // Save Size
  this.State.Canvas.Size.width = this.State.Render.Ref.offsetWidth;
  this.State.Canvas.Size.height = this.State.Render.Ref.offsetHeight;

  // Reflect size in DOM element
  this.State.Canvas.Ref.width = this.State.Render.Ref.offsetWidth;
  this.State.Canvas.Ref.height = this.State.Render.Ref.offsetHeight;

  // Absolute position of the Canvas
  this.State.Canvas.Rect = this.State.Canvas.Ref.getBoundingClientRect();

  // Define Center
  this.State.Canvas.Center = { 
    w: Math.floor(this.State.Canvas.Size.width / 2),
    h: Math.floor(this.State.Canvas.Size.height / 2)
  };

  /*
  // Offscreen
  const Offscreen = document.createElement('canvas');
  Offscreen.width = this.State.Canvas.Ref.width;
  Offscreen.height = this.State.Canvas.Ref.height;
  Offscreen.id = 'Maia_Offscreen';
  document.getElementById('Maia_Render').appendChild(Offscreen);
  this.State.Offscreen.Ref = Offscreen.transferControlToOffscreen();
  */

  // console.log("Refresh Canvas", this.State.Canvas.Center);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Detect_Focus(document){
//-----------------------------------------------------------------------------

  // If the user clicks in some "interative" element
  // (input, etc.), then the screen fades out a bit.
  
  // Add support for TextareaElement, etc.
  if(document.activeElement instanceof HTMLInputElement){
    // console.log("Cursor:: HTMLInputElement", this.State.Panel.Active)
    this.Set_ScreenFocus(false);
  } else {
    this.Set_ScreenFocus(true);
  }

}
//-----------------------------------------------------------------------------
  Set_ScreenFocus(isActive){
//-----------------------------------------------------------------------------

  if(isActive){
    this.State.Panel.Active = true;
    this.State.Panel.Ref.classList.remove('unfocus');
    this.State.Panel.Ref.classList.add('focus');
  }
  else {
    this.State.Panel.Active = false;
    this.State.Panel.Ref.classList.remove('focus');
    this.State.Panel.Ref.classList.add('unfocus');
  }

}
//-----------------------------------------------------------------------------
  Toggle_FullScreen(DOM) {
//-----------------------------------------------------------------------------

  if (!document.fullscreenElement        /* Standard syntax */
  ||  !document.webkitFullscreenElement  /* Safari and Opera syntax */
  ||  !document.msFullscreenElement)     /* IE11 syntax */
  {
    // Active the FullScreen in this DOM element.
    DOM.requestFullscreen();
  }
  else { // Is in full Screen
    if (document.exitFullscreen) { // [ESC]ape pressed!
      document.exitFullscreen();
    }
  }

}
//-----------------------------------------------------------------------------
}