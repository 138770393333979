/*██████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░&&& &&  & &&░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░&& &\/&\|& ()|/ @, &&░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░&\/(/&/&||/& /_/)_&/_&░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░&() &\/&|()|/&\/ '%" & ()░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░&_\_&&_\ |& |&&/&__%_/_& &&░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░&&   && & &| &| /& & % ()& /&&░░░░░░░░░░░░░░██
██░░░░░░░░░░░░()&_---()&\&\|&&-&&--%---()~░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░&&░░░░░\|||░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░|||░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░|||░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░|||░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░, -=-~  .-^- _░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
----------------------------------------------------------*/

  // import { TREE } from './TREE.js';
  import { TREE_ENT } from './ENT/TREE_ENT.js';
  import { TREE_SIG } from './SIG/TREE_SIG.js';
  import { TREE_EVT } from './EVT/TREE_EVT.js';
  import { TREE_CLIENT } from './CLIENT/TREE_CLIENT.js';
  import { TREE_CUBIT } from './CUBIT/TREE_CUBIT.js';
  import { TREE_MODE } from './MODE/TREE_MODE.js';
  import { TREE_PANEL } from './PANEL/TREE_PANEL.js';
  import { TREE_SOUND } from './SOUND/TREE_SOUND.js';
  import { TREE_CAMERA } from './CAMERA/TREE_CAMERA.js';
  import { TREE_RENDER } from './RENDER/TREE_RENDER.js';

//----------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class MAIA_TREE { constructor (MAIA) {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
/*💠*/  this.MAIA = MAIA;
//-----------------------------------------------------------------------------
/*🖱️*/  this.CLIENT = new TREE_CLIENT(MAIA);
//-----------------------------------------------------------------------------
/*🦉*/  this.ENT = new TREE_ENT(MAIA);
//-----------------------------------------------------------------------------
/*📜*/  this.SIG = new TREE_SIG(MAIA);
//-----------------------------------------------------------------------------
/*🕹️*/  this.EVT = new TREE_EVT(MAIA);
//-----------------------------------------------------------------------------
/*🧊*/  this.CUBIT = new TREE_CUBIT(MAIA);
//-----------------------------------------------------------------------------
/*🎨*/  this.RENDER = new TREE_RENDER(MAIA);
//-----------------------------------------------------------------------------	
/*🎩*/  this.MODE = new TREE_MODE(MAIA);
//-----------------------------------------------------------------------------
/*💬*/  this.PANEL = new TREE_PANEL(MAIA);
//-----------------------------------------------------------------------------
/*🔊*/  this.SOUND = new TREE_SOUND(MAIA);
//-----------------------------------------------------------------------------
/*🎥*/  this.CAMERA = new TREE_CAMERA(MAIA);
//-----------------------------------------------------------------------------

  // :: LOAD ::
  this.Loading = false;
  this.Loaded = false;
  this.Inited = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Load () {
//----------------------------------------------------------------------------------------

  if(this.Loaded)   { return true; }

	if(!this.Loading) { this.Loading = true;		
    this.Loaded = true;
	}

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init (Mode) {
//----------------------------------------------------------------------------------------

  this.ENT.Init();
  this.SIG.Init();
  this.CUBIT.Init();
  this.RENDER.Init();
  this.PANEL.Init();
  this.SOUND.Init();
  this.CAMERA.Init();
  this.MODE.Init(Mode); // Deprecated Mode arg: => obtener desde: this.MAIA.ENV.Mode

  this.Inited = true;

}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

  // Stop
  this.MAIA.Loaded = false;
  this.Loading = false;
  this.Loaded = false;
  this.Inited = false;

  // Reset memory
  this.MODE.Reset();
  this.ENT.Reset();
  this.CUBIT.Reset();
  this.RENDER.Reset();
  this.EVT.Reset();
  this.SOUND.Reset();
  
  /*
  this.SIG.SIGs = [];
  this.PANEL.Panels = [];
  this.SOUND.Reload();
  */

  console.log("Reseted ==============");

  // Reanude
  this.MAIA.Load();
  

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  this.CLIENT.Run();
  this.MODE.Run();
  this.SIG.Run();
  this.EVT.Run();
  this.ENT.Run();
  this.CUBIT.Run();
  this.RENDER.Run();
  this.CAMERA.Run();
  this.SOUND.Run();

}
//----------------------------------------------------------------------------------------
}