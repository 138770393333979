export const Style = (styled, Proto, Props) => { const Style = styled.div`

display: flex;
flex-direction: column;

  @media only screen and (max-width: 40rem) {
  width: 80%;
  flex-direction: row;
  }

li {
width: 100%;
// padding: 2rem;
font-size: 16px;
color: #eee;
display: flex; 
align-items: center;
justify-content: center;

  .icon{
  transition: 0.11s;
  width: 48px;
  // height: 48px;
  font-size: 1.6em;
  filter: grayscale(100%); //brightness(0.5) sepia(1) saturate(100%);
  }

  &:hover{
    cursor: pointer;

    .icon{ 
      filter: brightness(1.25);
    }
  }

  &.Active{ 
    color: #333;
    
    .icon{
        filter: grayscale(0%);
        // filter: brightness(0.5) sepia(1) saturate(250%) //hue-rotate(120deg);
    }
  }

}

`; return Style; }