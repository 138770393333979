//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT_GRAB { constructor (MAIA) {
//---------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.PositionStart = {w: 0, h: 0};  // when you click first
  this.PositionEnd = {w: 0, h: 0};    // when you drop
  this.Saved = {w: 0, h: 0};          // while you mantain
  this.Pressed = false;

  // Debug, esto actualmente se está guardando en cada Cubit INDIVIDUAL
  this.SavedCubit;
  this.MovedCubit;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Init () {
//---------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Run () {
//---------------------------------------------------------------------------

  let Input = this.MAIA.TREE.CLIENT.INPUT.State;

  // If, when grabbing the screen, the cursor is over some Panel, exit.
  if(this.MAIA.TREE.PANEL.Contains_Any(Input.Cursor.Hover.target)) {
    return false;
  }

  let Ent = this.MAIA.TREE.MODE.ACTIVE.SELECT.Ent;
  let Cubit = Ent?.CUBIT;

  /*████████████████████████████████   🖱️ Cursor   ███████████████████████████████████*/

  if (Input.Keyboard.Ctrl.Active) {
    this.MAIA.TREE.CLIENT.DOM.State.Render.Ref.style.cursor = "move"; // "grab"
  }
  else {
    // this.MAIA.TREE.CLIENT.DOM.State.Render.Ref.style.cursor = "auto";
  }

  /*████████████████████████████████    ⚾ Drag    ███████████████████████████████████*/

  if(Input.Cursor.Left.type === "mousedown") {

    if(!this.Pressed) { // Run only once, need to realease
      
      // Get origin choords once
      this.PositionStart.w = Input.Cursor.Left.PositionFirst.x;
      this.PositionStart.h = Input.Cursor.Left.PositionFirst.y;

      // Block until mouseup
      this.Pressed = true;

      // Fix:
      Input.Cursor.Left.PositionNow.x = Input.Cursor.Left.PositionFirst.x;
      Input.Cursor.Left.PositionNow.y = Input.Cursor.Left.PositionFirst.y;

    }

    // Get end choords always.
    this.PositionEnd.w = Input.Cursor.Left.PositionNow.x;
    this.PositionEnd.h = Input.Cursor.Left.PositionNow.y;
    
    let MoveCameraX = this.PositionEnd.w - this.PositionStart.w;
    let MoveCameraY = this.PositionEnd.h - this.PositionStart.h;

    if(MoveCameraX != 0 || MoveCameraY != 0){
      // Mouse Icon: move
      // this.MAIA.TREE.CLIENT.DOM.State.Render.Ref.style.cursor = "move";
    }

    /*████████████████████████████████   🗺️ Scene   ████████████████████████████████████*/

    if(Input.Keyboard.Ctrl.Active) {
      
      let Move = {
        w: this.Saved.w - MoveCameraX,
        h: this.Saved.h - MoveCameraY,
      }

      this.MAIA.TREE.CAMERA.Move = { w: Move.w, h: Move.h }
      
    }
    else {

    /*████████████████████████████████   🧊 Cubit   ████████████████████████████████████*/

      if (Cubit && this.MAIA.TREE.MODE.ACTIVE.HOVER.Is_Selected) {

        let Coords_2D = this.MAIA.TREE.CAMERA.MATRIX.Position_3D_To_2D(Cubit.POS.Coords);
        if(!Cubit.SavedCubit) { Cubit.SavedCubit = {...Coords_2D} }

        let Move = {
          w: Cubit.SavedCubit.w + MoveCameraX,
          h: Cubit.SavedCubit.h + MoveCameraY 
        }

        let Final_Coords = this.MAIA.TREE.CAMERA.MATRIX.Position_2D_To_3D(Move.w, Move.h, Cubit.POS.Coords.z);
        Cubit.POS.Teleport({ x: Final_Coords.x, y:Final_Coords.y, z: Cubit.POS.Coords.z });
        
        // Save Distance
        Cubit.MovedCubit = { w: Move.w, h: Move.h };

      }

    }

  }

  /*████████████████████████████████    🍃 Drop    ███████████████████████████████████*/

  else if(Input.Cursor.Left.type === "mouseup"){

    if(this.Pressed) {

      // Mouse Icon: auto
      // this.MAIA.TREE.CLIENT.DOM.State.Render.Ref.style.cursor = "auto";

      if(Input.Keyboard.Ctrl.Active) {

        // When cursor is out, set the actual position.
        this.Saved.w = this.MAIA.TREE.CAMERA.Move.w;
        this.Saved.h = this.MAIA.TREE.CAMERA.Move.h;

      }
      else if (Cubit) {
        
        if(!Cubit.MovedCubit) {
          Cubit.MovedCubit = { w: 0, h:0 }
        }

        Cubit.SavedCubit = {
          w: Cubit.MovedCubit.w,
          h: Cubit.MovedCubit.h
        }

      }
    
      // And release the click, so it can be clicked again.
      this.Pressed = false;

    }
  }

}
//---------------------------------------------------------------------------
}