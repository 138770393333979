//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT_UTILS { constructor (MAIA) {
//---------------------------------------------------------------------------

  this.MAIA = MAIA;
  this.ID = "UTILS";

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  _Get_Zone_Backup (CubitHover, Touching) {
//---------------------------------------------------------------------------

  let Zone = undefined;

  // Adjust the values based on the size and shape of your Cubit
  let cubitX = Touching.POS.Size.x
  let cubitY = Touching.POS.Size.y;
  let cubitZ = Touching.POS.Size.z;

  // Calculate the cursor's relative position inside the Touching
  let relativeX = CubitHover.POS.Coords.x - Touching.POS.Coords.x;
  let relativeY = CubitHover.POS.Coords.y - Touching.POS.Coords.y;
  let distanceCenter = Math.abs(relativeX - relativeY);

  relativeX += 9999;
  relativeY += 9999;
  relativeX -= Touching.POS.Coords.z;
  relativeY -= Touching.POS.Coords.z;

  if (((relativeY + relativeX)/2) > cubitX + (distanceCenter/2)) {
    Zone = "Top";
  } else if (relativeY > relativeX) {
    Zone = "FrontLeft";
  } else {
    Zone = "FrontRight";
  }

  // this.MAIA.CORE.DEBUG.Add("🎯 x: " + relativeX + ", y: " + relativeY + " *: " + distanceCenter)
  // this.MAIA.CORE.DEBUG.Add("🎯 Zone: " + Zone);

  return Zone;

}
//---------------------------------------------------------------------------
  Get_Zone (CubitHover, Touching) {
//---------------------------------------------------------------------------

  let Zone = undefined;

  // Adjust the values based on the size and shape of your Cubit
  let cubitX = Touching.POS.Size.x
  let cubitY = Touching.POS.Size.y;
  let cubitZ = Touching.POS.Size.z;

  // Calculate the cursor's relative position inside the Touching
  let relativeX = CubitHover.POS.Coords.x - Touching.POS.Coords.x;
  let relativeY = CubitHover.POS.Coords.y - Touching.POS.Coords.y;
  let distanceCenter = Math.abs(relativeX - relativeY);

  relativeX += 9999;
  relativeY += 9999;
  relativeX -= Touching.POS.Coords.z;
  relativeY -= Touching.POS.Coords.z;

  if (((relativeY + relativeX)/2) > cubitX + (distanceCenter/2)) {
    Zone = "Top";
  } else if (relativeY > relativeX) {
    Zone = "FrontLeft";
  } else {
    Zone = "FrontRight";
  }

  // this.MAIA.CORE.DEBUG.Add("🎯 x: " + relativeX + ", y: " + relativeY + " *: " + distanceCenter)
  // this.MAIA.CORE.DEBUG.Add("🎯 Zone: " + Zone);

  return Zone;

}
//------------------------------------------------------------------------------------
}