const Styled_Maia = styled.div`
width: 100%;
max-height: 100%;
height: 100%;

  #Maia_Case{
  width: 100%;
  // height: 100vh;
  height: 100%;
  max-height: 100%;
  position: relative;
  // box-shadow: ${Props => Props.Style.Transparent ? "none" : "0 0 20px rgba(255, 255, 255, 0.7) inset"};
  background: ${Props => Props.Style.Case ? "rgb(140, 212, 206)" : "transparent"}; // aqua
  border: ${Props => Props.Style.Case ? "1px solid rgba(177, 177, 177, 1)" : "none"};
  padding: ${Props => Props.Style.Case ? "24px 24px 48px 24px" : "0px"};
  // border-radius: ${Props => Props.Style.Round ? "100%" : "10px"};

    #Logo{
      width: 100%;
      height: 48px;
      position: absolute;
      bottom: 0;
      display: ${Props => Props.Style.Case ? "flex" : "none"};
      align-items: center;
      justify-content: center;
      margin-left: -1rem;
      
      img, i {
        width: 26px;
        font-size: 3rem;
        opacity: 1;
        color: #eee;
        // filter: grayscale(0%) brightness(200%);
        margin-right: 0.75rem;
        text-shadow: 0 0 1px rgba(40, 40, 40, 0.96);
      }

      span {
        // text-transform: uppercase;
        color: #eee;
        text-shadow: 0 0 1px rgba(40, 40, 40, 0.96);
        font-family: "Ginora Sans";
        font-weight: bold;
      }
    }

  }
  
  #Maia_Screen{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  // box-shadow: ${Props => Props.Style.Transparent ? "none" : "box-shadow: 0 0 20px rgba(0, 0, 0, 0.7) inset"};
  // background: ${Props => Props.Style.Round ? "transparent" : "#555"};
  // background: ${Props => Props.Style.Transparent ? "transparent" : "#555"};
  background: transparent;
  border-radius: ${Props => Props.Style.Round ? "100%" : "0"};

  ${({ Style }) => Style && Style.Case &&  `
  background: #444;
  border-radius: 40px;
  border-left: 4px solid rgba(140, 212, 206, 0.7);
  border-top: 4px solid rgba(140, 212, 206, 0.7);
  border-bottom: 4px solid rgba(202, 236, 233, 0.7);
  border-right: 4px solid rgba(202, 236, 233, 0.7);
  padding: 2rem;
  `}


  }

  #Maia_Render {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  // background: ${Props => Props.Style.Transparent ? "transparent" : "#222"};
  border-radius: ${Props => Props.Style.Round ? "100%" : "0px"};
  border-radius: ${Props => Props.Style.Case ? "40px" : "0px"};
  }

  /*
  #Maia_Offscreen {
  z-index: 7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background: transparent;
  display: block;
  border-radius: ${Props => Props.Style.Case && "40px"};
  }
  */
  
  #Maia_Canvas {
  z-index: 8;
  width: 100%;
  height: 100%;
  position: absolute;
  // visibility: hidden;
  top: 0; bottom: 0; left: 0; right: 0;
  // background: #333;
  display: block;
  border-radius: ${Props => Props.Style.Case && "40px"};
  }

  #Maia_Panel{
  z-index: 9;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: block;
  top: 0;
  right: 0;

    & > div[data-panel] {
    // width: 100%;
    // height: 100%;
    }

  }

  #Maia_Log {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background: transparent;
  display: block;
  visibility: hidden;

    video {
      top: 0%;
      left: -25%;
      width: 150%;
      height: 100%;
      position: absolute;
    }
  }


`;

export { Styled_Maia };