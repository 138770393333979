//----------------------------------------------------------------------------

  import { TREE } from "../TREE.js";

  import { CAMERA_MATRIX } from "./MATRIX/CAMERA_MATRIX.js";
  import { CAMERA_ISO } from "./ISO/CAMERA_ISO.js";
  import { CAMERA_FOCUS } from "./FOCUS/CAMERA_FOCUS.js";
  import { CAMERA_ZOOM } from "./ZOOM/CAMERA_ZOOM.js";
  import { CAMERA_ANIMATION } from "./ANIMATION/CAMERA_ANIMATION.js";

//-----------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class TREE_CAMERA extends TREE { constructor (MAIA) { super(MAIA);
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------

  this.MAIA = MAIA;
  
  // [State]
  this.Active = false;

  // [Coords]
  this.Origin = { w: 0, h: 0 };   // Origin of the Plane (0, 0)
  this.Move =   { w: 0, h: 0 };   // Moved with the Cursor
  this.Focus =  { w: 0, h: 0 };   // = Origin + Move
  
  // Animation
  this.Animation = { Time: {Actual: 0, Total: 0}, Function: undefined, Callback: undefined};

  // Interfaces
  this.MATRIX = new CAMERA_MATRIX(MAIA);
  this.ISO = new CAMERA_ISO(MAIA);
  this.ZOOM = new CAMERA_ZOOM(MAIA);
  this.FOCUS = new CAMERA_FOCUS(MAIA);
  this.ANIMATION = new CAMERA_ANIMATION(MAIA);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.MATRIX.Init();
  this.ISO.Init();
  this.ZOOM.Init();
  this.FOCUS.Init();
  this.ANIMATION.Init();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  this.Origin = this.MAIA.TREE.CLIENT.DOM.State.Canvas.Center;

  // Animation
  if(this.Animation.Function) { 

    if(this.Animation.Time.Total === 'Infinity') {
      this.Animation.Function(); 
    }
    // else if (Number.isNumber(this.Animation.Time.Actual)) {
    else if (this.Animation.Time.Actual !== undefined) {

      // this.Animation.Time.Actual += 1;
      let Delta = this.MAIA.CORE.TIME.Delta; 
      this.Animation.Time.Actual += Delta;

      if(this.Animation.Time.Actual <= this.Animation.Time.Total) { 
        this.Animation.Function(this.Animation.Time); 
      }
      else { // Finished animation

        this.Move = { // Move only to Panel_EditorTree.js Callback?
          w: this.Origin.w - this.Focus.w, 
          h: this.Origin.h - this.Focus.h
        };
        
        if(this.Animation.Callback) { this.Animation.Callback(this.Animation.Time); }
        // this.Reset_Animation();
        // this.Move = {w: 0, h: 0};

      }
    }
    
  }

  return;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Set_Animation ({Time, Function, Callback}) {
//----------------------------------------------------------------------------------------
// It can receive an string (Default, Play, Edit...) or directly a Function.
//----------------------------------------------------------------------------------------
  
  if(typeof Function !== 'function') { 

    if(Function === 'Default') {
      if(this.MAIA.TREE.MODE.ACTIVE.ID === 'PLAY') { Function = 'Play'; }
      if(this.MAIA.TREE.MODE.ACTIVE.ID === 'EDIT') { Function = 'Edit'; }
    }

    if(Function === 'Play') { 
      Function = () => {
        // this.MAIA.TREE.CAMERA.FOCUS.GRAB.Saved = {w:0, h:0};
        this.MAIA.TREE.CAMERA.Move = {w:0, h:0};
        let Player = this.MAIA.TREE.MODE.ACTIVE.Player;
        if(!Player || !Player.CUBIT) { return; }
        this.MAIA.TREE.CAMERA.FOCUS.Focus(Player);
      }
    }
    else if(Function === 'Edit') {
      Function = () => {
        this.MAIA.TREE.CAMERA.FOCUS.Move({
          w: -this.MAIA.TREE.CAMERA.Move.w, 
          h: -this.MAIA.TREE.CAMERA.Move.h
        });
      }
    }

  }

  if(!Time) { Time = "Infinity"; }

  // console.log("Callback", Callback)
  this.Animation = { Time: { Actual: 0, Total: Time }, Function, Callback };

}
//----------------------------------------------------------------------------------------
  Reset_Animation () {
//----------------------------------------------------------------------------------------

  this.Set_Animation({ Time: "Infinity", Function: 'Default' });

}
//----------------------------------------------------------------------------------------
}