
  // import { Synths } from './Synths/Synths.js';

//----------------------------------------------------------------------------------------
export class Audio { constructor () {
//----------------------------------------------------------------------------------------
  
  this.Howl = Howl;
  this.Init();

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------
  

}
//----------------------------------------------------------------------------------------
  async Play ({URI, Format, Loop, Volume}, Callback) {
//----------------------------------------------------------------------------------------  

  if(!this.Howl) { return; }

  let Sound = new this.Howl({
    src: [URI],
    format: [ Format || 'mp3'],
    loop: Loop || false,
    volume: Volume || 1,
    // fade: [0, Volume || 1, Fade || 0.2]
  });

  Sound.play();

}
//----------------------------------------------------------------------------------------
  async Sound ({ID, URI, Format, Loop, Volume}, Callback) {
//----------------------------------------------------------------------------------------  
  
  if(!URI && ID) {
    URI = `${Public.ENV.URL}/Public/Media/Sounds/${ID}.mp3`;
    // if(ID === 'Move') { URI = `${URI}/${ID}.mp3` }
  }

  await this.Play({URI, Format, Loop, Volume}, Callback);

}
//----------------------------------------------------------------------------------------
}