export class CAMERA_MATRIX { constructor (MAIA) {

  this.MAIA = MAIA;

}
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

}
///▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Position_3D_To_2D ({x, y, z}) {
//----------------------------------------------------------------------------------------
// Note: Number.parseInt seems to make some "Math.floor" to the numbers, causing glithes.
//----------------------------------------------------------------------------------------

  x = x * this.MAIA.TREE.CAMERA.ZOOM.Scale;
  y = y * this.MAIA.TREE.CAMERA.ZOOM.Scale;
  z = z * this.MAIA.TREE.CAMERA.ZOOM.Scale;
  return {
    w: ((x-y) * (Math.sqrt(3)/2)), // w: (x-y) * 1,
    h: (((-x-y) / 2) -z)           // h: (-x - (2*z) - y) / 2
  }
  
}
//----------------------------------------------------------------------------------------
  Position_2D_To_3D (w, h, z) {
//----------------------------------------------------------------------------------------
// https://stackoverflow.com/questions/73559567/reverse-isometric-projection-in-javascript
// Here, it is needed Math.round so the conversion from 2D <===> 3D is stable.
//----------------------------------------------------------------------------------------

  z = z / this.MAIA.TREE.CAMERA.ZOOM.Scale;
  w = w / this.MAIA.TREE.CAMERA.ZOOM.Scale;
  h = h / this.MAIA.TREE.CAMERA.ZOOM.Scale;

  let y = Math.round(-(h + z + w / Math.sqrt(3))); 
  let x = Math.round(2 * w / Math.sqrt(3) + y);

  return {x, y, z}
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Position_3D (w, h, z) {
//----------------------------------------------------------------------------------------
  w -= this.MAIA.TREE.CAMERA.Focus.w;
  h -= this.MAIA.TREE.CAMERA.Focus.h; 
  let Coords_3D = this.Position_2D_To_3D(w, h, z);  
  return Coords_3D;
}
//----------------------------------------------------------------------------------------
  Position_2D ({x, y, z}) {
//----------------------------------------------------------------------------------------
  let Coords_2D = this.Position_3D_To_2D({x, y, z});  
  Coords_2D.w += this.MAIA.TREE.CAMERA.Focus.w;
  Coords_2D.h += this.MAIA.TREE.CAMERA.Focus.h;
  Coords_2D.w = Math.floor(Coords_2D.w); // refactor => Math.floor on CAMERA.Focus?
  Coords_2D.h = Math.floor(Coords_2D.h);
  return Coords_2D;
}
//----------------------------------------------------------------------------------------
}