//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  export class MODE_EDIT_CREATE { constructor (MAIA) {
//---------------------------------------------------------------------------

  this.MAIA = MAIA;

  // Ent attached to the cursor.
  this.Data = undefined; this.DataCopy = undefined;
  this.Ent =  undefined;

  this.IsCorrect = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Init () {
//---------------------------------------------------------------------------
  
  
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Reset () {
//---------------------------------------------------------------------------

  if(this.Ent) { this.MAIA.TREE.ENT.Remove(this.Ent); }
  this.Data = null;
  this.Ent = null;
  this.IsCorrect = false;

  // Panel Proto: React: SetEntProto
  this.MAIA.TREE.MODE.ACTIVE.PANEL.Ent.Evt('Panel_Editor').React.SetEntProto(null);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  async Create (Ent_Data) {
//---------------------------------------------------------------------------

  // Backup Data
  this.Data = this.MAIA.CORE.UTILS.DeepCopy(Ent_Data);
  
  // Creating instance
  Ent_Data.ID = "Creating";
  
  let Cubit = Ent_Data.Evts.find(evt => evt.Type === "CUBIT");
  if (Cubit) {
    Cubit.Tag = "User_Scene";
    Cubit.Props.Coords = {x:0, y:0, z:9999};
  }

//---------------------------------------------------------------------------

  this.Ent = await this.MAIA.TREE.ENT.Create(Ent_Data);

//---------------------------------------------------------------------------
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Confirm () {
//---------------------------------------------------------------------------

  if(!this.Ent) { return; }
  if(!this.IsCorrect) { return; }

  // console.log("✅ CONFIRM", this.Ent.CUBIT.POS.Coords);

  // Confirm
  // let ID = this.DataCopy.ID.split('/').pop();
  this.Ent.ID = this.Data.ID;
  // this.Ent.Proto = this.Data.Proto;
  this.Ent.CUBIT.Refresh();
  
  // Olvidar la referencia:
  this.Ent = null;
  this.Data = null;

  // Desactivar
  this.IsCorrect = false;

  this.Reset();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Clear () {
//---------------------------------------------------------------------------

  if(this.Ent?.CUBIT) { 
    
    // On every iteration, put the Cubit apart.
    this.Ent.CUBIT.POS.Vector(['z',  9999], true);
    this.IsCorrect = false;
   
  }
  else {

    // Si no hay Ent, crearlo.
    if(this.Data) { 
      console.info("🌀 Load again", this.Data);
      this.Create(this.Data);
    }

  }


}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Follow () {
//---------------------------------------------------------------------------

  if(!this.Ent) { return; }

  let Cursor = this.MAIA.TREE.MODE.ACTIVE.Cursor;
  let Touching = this.MAIA.TREE.MODE.ACTIVE.HOVER.Higher;

  if(Touching) {
    
    // Si hay Ent, cambiar la posición para seguir el Cursor.
    if(this.Ent) {
    
      let Result = this.Colocate(this.Ent);
      if(Result) { this.IsCorrect = true; return; }

    }
  
  }
  
  this.IsCorrect = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Colocate (Ent) { if(!this.Ent.CUBIT) { return; }
//---------------------------------------------------------------------------

  let Zone = this.MAIA.TREE.MODE.ACTIVE.ZoneSelected;
  let Touching = this.MAIA.TREE.MODE.ACTIVE.HOVER.Higher;

  let Coords = {};

  if(Zone === "Top") {

    // Coords.x = Touching.POS.Bounds.xmin;
    // Coords.y = Touching.POS.Bounds.ymin;
    // Coords.z = Touching.POS.Bounds.zmax;

    let Cursor = this.MAIA.TREE.MODE.ACTIVE.Cursor;
    let Cubit_Selected = this.MAIA.TREE.MODE.ACTIVE.HOVER.Higher;  
    let maxZ = Cubit_Selected.POS.Bounds.zmax + 1;
    Coords = this.MAIA.TREE.CAMERA.MATRIX.Position_3D(Cursor.x, Cursor.y, maxZ);

  }
  else if(Zone === "FrontLeft") {

    Coords.x = Touching.POS.Coords.x - (Ent.CUBIT.POS.Size.x + 1);
    Coords.y = Touching.POS.Coords.y;
    Coords.z = Touching.POS.Coords.z + 0;

  } else if (Zone === "FrontRight") {

    Coords.x = Touching.POS.Coords.x;
    Coords.y = Touching.POS.Coords.y - (Ent.CUBIT.POS.Size.y + 1);
    Coords.z = Touching.POS.Coords.z + 0;

  }

  // this.MAIA.CORE.DEBUG.Add(`🗿 Touching: ${Touching.ID}`);
  // this.MAIA.CORE.DEBUG.Add(`🗿 x: ${Coords.x}, y: ${Coords.y}, z: ${Coords.z}`);

//---------------------------------------------------------------------------

  // Teleport:
  let {Result, Collisions} = this.Ent.CUBIT.POS.Teleport(Coords);
  // console.log(Result, Zone, Collisions, Coords);
  
  if(Result) { return true; }

}
//---------------------------------------------------------------------------
}