/*██████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░/                   \░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░/                  // \░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░/                  //   \░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░/                  / /    \░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░/__________________/ /      \░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░\                    \       \░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░\                    \       l░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░\                    \      /░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░\                    \    /░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░\                    \  /░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░\____________________\/░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
----------------------------------------------------------*/
//----------------------------------------------------------------------------------------

	import { TREE } from "../TREE.js";
	// import { Cubit } from './Cubit/Cubit.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_CUBIT extends TREE { constructor (MAIA) { super(MAIA);
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;
  
  this.Cubits = [];
  this.OrderThisFrame = false;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Order();

  // Set Relations
  this.Cubits.map((Cubit, i) => {
	  Cubit.POS.Set_Relations();
  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Run () { // Render Cubits
//----------------------------------------------------------------------------------------

  // Order Cubits, only if needed.
  if(this.OrderThisFrame) {
    this.OrderFrame();
    this.OrderThisFrame = false;
  }

  // Save context
  // this.MAIA.TREE.RENDER.Ctx.save();

  // Render Origin Point
  // let OriginCoords = this.MAIA.TREE.CAMERA.FOCUS.Get_Position({x:0, y:0, z:0});
  // this.MAIA.TREE.RENDER.PAINTER.Point(OriginCoords, this.MAIA.TREE.RENDER.Ctx, "#eee", 10);

  // Restore
  // this.MAIA.TREE.RENDER.Ctx.restore();

  // Reset properties that apply only to every frame (like, the hovering with the cursor)
  // this.Purify();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Order () {
//----------------------------------------------------------------------------------------

  this.OrderThisFrame = true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  OrderFrame () {
//----------------------------------------------------------------------------------------
// Asegurarse de que esta función se ejecuta como máximo una vez en cada Frame.
//----------------------------------------------------------------------------------------

  Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
  };

  let blocks = this.Cubits;
	var i, j, numBlocks = blocks.length;

	// Initialize the list of blocks that each block is behind.
	for (i=0; i<numBlocks; i++) {
		blocks[i].blocksBehind = [];
		blocks[i].blocksInFront = [];
	}

	// For each pair of blocks, determine which is in front and behind.
	var a,b,frontBlock;
	for (i=0; i<numBlocks; i++) {
		a = blocks[i];
		for (j=i+1; j<numBlocks; j++) {
			b = blocks[j];
			frontBlock = this.MAIA.TREE.CAMERA.ISO.Wich_Cubit_Front(a, b);
			if (frontBlock) {
				if (a == frontBlock) {
					a.blocksBehind.push(b);
					b.blocksInFront.push(a);
				}
				else if (b == frontBlock) {
					b.blocksBehind.push(a);
					a.blocksInFront.push(b);
				}
				else if (frontBlock === 'INTERSECTION') {

					if (
             a.PHY.Matter === 'VOID' || b.PHY.Matter === 'VOID' 
          || a.POS.Display === 'Hide' || b.POS.Display === 'Hide'
          ) {
						
					}
					else {
						if(!a._Ent) { console.error("Ent A: ", a); }
						console.log("=================================");
            console.error(`🚨 Error: ${frontBlock} | ${a.ID} (${a.Proto}) | ${b.ID} (${b.Proto}) `);
						console.error("🦉 Ents: ", a._Ent, b._Ent);
            console.error("🧊 Cubits Coords", a.POS.Coords, b.POS.Coords);
						console.log("=================================");
            // alert("Cubit Intersection");
					}
					
					// Nota para el futuro:
					// Si hay una intersección (por ejemplo, uno de los Cubits es piramidal
					// y el otro está parcialmente "dentro" de su espacio vacío),
					// entonces hay que obtener cuál es el Cúbit con el que tiene
					// la intersección, y averiguar cuál de los dos debe renderizarse primero.
				}
				else { // frontBlock => ?
					
				}
			} else {
				// frontBlock => null
			}
		}
	}

	// Get list of blocks we can safely draw right now.
	// These are the blocks with nothing behind them.
	var blocksToDraw = [];
	for (i=0; i<numBlocks; i++) {
		if (blocks[i].blocksBehind.length == 0) {
			blocksToDraw.push(blocks[i]);
		}
	}

	// While there are still blocks we can draw...
	var blocksDrawn = [];
	while (blocksToDraw.length > 0) {

		// Draw block by removing one from "to draw" and adding
		// it to the end of our "drawn" list.
		var block = blocksToDraw.pop();
		blocksDrawn.push(block);

		// Tell blocks in front of the one we just drew
		// that they can stop waiting on it.
		for (j=0; j<block.blocksInFront.length; j++) {
			var frontBlock = block.blocksInFront[j];

			// Add this front block to our "to draw" list if there's
			// nothing else behind it waiting to be drawn.
			frontBlock.blocksBehind.remove(block);
			if (frontBlock.blocksBehind.length == 0) {
				blocksToDraw.push(frontBlock);
			}
		}
	}

  this.Cubits = blocksDrawn;

}
//----------------------------------------------------------------------------------------
	Remove (Cubit) {
//----------------------------------------------------------------------------------------

  this.Cubits.map((cubit, i) => {

    if(cubit.ID === Cubit.ID) { this.Cubits.splice(i, 1); }

  });

}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

  console.log("💥 Reset Cubit");

  for (let i = 0; i < this.Cubits.length; i++) {

    // Realiza cualquier operación necesaria antes de eliminar el objeto
    let Cubit = this.Cubits[i];
  
    // Asigna null al objeto para eliminar la referencia
    this.Cubits[i] = null;

  }

  this.Cubits = [];

}
//----------------------------------------------------------------------------------------
}