//====================================================================================
// Un panel para renderizar el elemento <Canvas> y mantener la proporción.
//------------------------------------------------------------------------------------
//
//====================================================================================

export const Part_Canvas = ({ Proto, Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/
    
  const html = Public.Libs.html;
  const { useEffect, useRef, useState } = Public.Libs.React;
  // const Style = Styled(Public.Libs.styled, Proto.Styles);

/*████████████████████████████████   💾 STATE   ███████████████████████████████████*/

  const theCanvas = useRef(null);

  const [Image, Set_Image] = useState(Props.Image);
  const [Width, Set_Width] = useState(Props.Width ? Props.Width : 80);
  const [Height, Set_Height] = useState(Props.Height ? Props.Height : 80);

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

  const Generate_Canvas = (Image, Key_ID) => {

    // Create the Canvas:
    let Canvas = theCanvas.current;
    Canvas.width = Width; Canvas.height = Height;

    // Calcular la escala para mantener la proporción de la imagen
    let scale = Math.min(Canvas.width / Image.width, Canvas.height / Image.height);

    // Calcular la posición x y y para centrar la imagen
    let x = (Canvas.width / 2) - (Image.width / 2) * scale;
    let y = (Canvas.height / 2) - (Image.height / 2) * scale;

    // Dibujar la imagen del canvas original en el nuevo canvas, escalándola proporcionalmente y centrándola
    let Ctx = Canvas.getContext('2d');
    Ctx.drawImage(Image, 0, 0, Image.width, Image.height, x, y, Image.width * scale, Image.height * scale);

    // Draw the image (Classic)
    // Canvas.getContext('2d').drawImage(Image, 0, 0, Canvas.width, Canvas.height);
      
  }

/*█████████████████████████████████   🤖 HOOKS   ███████████████████████████████████*/


  useEffect(() => {  if(!theCanvas) { return; }
    Generate_Canvas(Image, Props.Key_ID);
  }, [Image]);


/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<canvas ref=${theCanvas} width=${300} height=${300}/>
`);

};