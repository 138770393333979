import { Styled } from './Style/Style_OAuth.js';

export const OAuth = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Main/Admin/Parts/Apis/OAuth`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);

  /*█████████████████████████████████   📚 STATE   ████████████████████████████████████*/

  const CLIENT_ID = '1002690986393-k53b090aou4qo0kh4mv74g3brpdija7k.apps.googleusercontent.com';
  const REDIRECT_URI = 'https://initori.com/apis/google';
  const SCOPES =
    'https://www.googleapis.com/auth/youtube.readonly \
    https://www.googleapis.com/auth/youtube.upload'
  ;

  /*█████████████████████████████████   📚 EFFECTS   ████████████████████████████████████*/

  var client;
  var access_token;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = initTokenClient;
    document.body.appendChild(script);
  }, []);

  function initTokenClient() {
    client = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      // scope: 'https://www.googleapis.com/auth/youtube.readonly',
      scope: SCOPES,
      prompt: 'consent',
      params: {
        access_type: 'offline'
      },
      callback: (tokenResponse) => {
        let access_token = tokenResponse.access_token;
        let refresh_token = tokenResponse.refresh_token;
        console.log("🪙", tokenResponse, access_token, refresh_token);
      },
    });
  }

  function getToken() {
    // Re-entrant function to request user consent.
    // Returns an access token to the callback specified in google.accounts.oauth2.initTokenClient
    // Use a user gesture to call this function and obtain a new, valid access token
    // when the previous token expires and a 401 status code is returned by Google API calls.
    client.requestAccessToken();
  }

  function revokeToken() {   
    google.accounts.oauth2.revoke(access_token, () => {
      console.log('access token revoked')
    });
  }

return (html`
<${Style}>
<div>
    
  <h2>OAuth Google</h2>
  <button onClick=${()=> getToken()}>Get token</button>
  <br />
  <button onClick=${()=> revokeToken()}>Revoke Token</button>
  
</div>
<//>
`);
}