export const Styles = (styled, Proto, Props) => {
return styled.div`

position: relative;
width: 100%;
height: 100%;
padding: 0;
margin: 0;
background: #eee;
display: flex;
// padding: 1rem;

._Digicode {
position: absolute;
top: 0; right: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
border-radius: 100%;
overflow: hidden;
z-index: 100;
font-size: 30px;
font-weight: bold;
// color: rgba(255, 255, 255, 0.8);
// background-color: lightblue;
// border: 1px solid rgba(20, 20, 20, 0.1);
background-color: #106a9c;
border: 15px solid rgba(240, 240, 240, 1);

  &.Active { animation: move-text 108s linear infinite; }

  &.Aura { background-color: #106a9c; }
  &.Eroda { background-color: #333; }
  &.Fuzz { background-color: #808000; }
  
}

@keyframes move-text {
  0% {
    // background-color: #106a9c;
    transform: rotate(0deg);
  }
  50% {
    // background-color: #17467d;
  }
  100% {
    // background-color: #106a9c;
    transform: rotate(360deg);
  }
}

._Digicode span {
// font-family: "Ginora Sans";
// font-family: 'Digicode';
font-family: 'Aurico';
font-size: 2rem;
position: absolute;
display: inline-block;
width: 30px;
height: 30px;
line-height: 30px;
text-align: center;
font-size: 20px;
font-weight: bold;
color: white;
transform-origin: center center;
visibility: hidden;
// transition: transform 0.2s ease-in-out;
animation: skyrim 5s infinite;

  &.Legible {
  font-family: "Ginora Sans";
  }

  &.Active {
    opacity: 1;
    visibility: visible;
  }

}

@keyframes skyrim {
  0% {
    color: rgba(71,86,131, 0.7);
    color: #eee;
    opacity: 0.15;
  }
  50% {
    color: #eee;
    opacity: 0.5;
    text-shadow: 0 0 4px rgba(171,186,231, 0.7);
  }
  100% {
    color: rgba(71,86,131, 0.7);
    color: #eee;
    opacity: 0.15;
  }
}


`;

}