export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
color: #333;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

.App_Roadmap {
width: 100%;
height: 100%;
// padding: 4rem;
// overflow-y: scroll;

display: flex;
justify-content: flex-start;
flex-direction: column;
justify-content: space-between;

  ._Main {
  flex: 1;
  width: 100%;
  height: 100%;
  // padding: 4% 20%;

    ._Map {
    height: 100%;

      img {
      width: 100%;
      }

    }
  }

}

`;

return Style;
}
