export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
border: 3px dashed rgba(50, 50, 50, 0.3);
border-radius: 7px;
padding: 2rem;
`;

return Style;

}

